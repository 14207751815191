import { Box, Text } from '../../../shared/components';
import { CaretUp } from '@phosphor-icons/react';
import { Collapse } from '../../../common/components/Collapse';
import { colors } from '../../../shared/styles';
import { PropertyManager, usePropertyManager } from '../../providers/property-manager/PropertyManager';

export const PropertiesGrouping = ({ label, description = '', defaultOpen = false, children, ...rest }) => {
  const { groups } = usePropertyManager();

  const open = PropertyManager.getGroup(groups, label, defaultOpen);
  const toggleGroup = () => PropertyManager.setGroup(groups, label, !open);

  return (
    <Box
      css={`
        border-top: 1px solid ${colors.gray[200]};
        padding: 0 16px;
        padding-bottom: 8px;
      `}
      {...rest}
    >
      <Box
        flex="space-between"
        css={`
          :hover {
            cursor: pointer;
          }
          border: 1px solid transparent;
          border-radius: 8px;
          padding: 8px 0;
          .caretUp {
            display: inline-block;
            transition: transform 0.3s ease;
            transform: rotate(${open ? '0deg' : '180deg'});
          }
        `}
        onClick={toggleGroup}
      >
        <Box>
          <Text label bold>
            {label}
          </Text>
          {description ? <Text helper>{description}</Text> : null}
        </Box>
        <CaretUp className="caretUp" />
      </Box>
      <Collapse isOpen={open}>
        <Box
          css={`
            box-sizing: border-box;
            display: grid;
            gap: 24px;
            padding: 16px 0;
          `}
        >
          {children}
        </Box>
      </Collapse>
    </Box>
  );
};
