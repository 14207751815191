import { useMemo } from 'react';
import { useFeatureFlagPayload } from 'posthog-js/react';
import { useStore } from '../store-provider/use-store';
import { MultiGuideAnalyticsPage } from './MultiGuideAnalyticsPage';
import { AnalyticsPage } from './AnalyticsPage';

export const AnalyticsRoute = () => {
  // Business Context
  const {
    data: { selectedBusiness },
  } = useStore();

  // Feature Flags
  const aggregatedGuideAnalyticsFF = useFeatureFlagPayload('aggregated-guide-analytics');

  // Determine if the user can view the aggregated guide analytics page.
  const canViewAggregatedGuideAnalytics = useMemo(
    () =>
      // TODO: Separate out Parent / Child Account views here as well in the future.
      selectedBusiness?.Type !== 'multi' && selectedBusiness?.Type !== 'carrier' && aggregatedGuideAnalyticsFF?.value,
    [selectedBusiness, aggregatedGuideAnalyticsFF]
  );

  if (canViewAggregatedGuideAnalytics) {
    return <MultiGuideAnalyticsPage />;
  } else {
    return <AnalyticsPage />;
  }
};
