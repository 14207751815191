import { Circle, CircleDashed } from '@phosphor-icons/react';
import { LegendOrdinal } from '@visx/legend';
import { scaleOrdinal } from '@visx/scale';
import { colors } from '../../../shared/styles';
import { Box, Text } from '../../../shared/components';
import { AGGREGATION_ALL, AGGREGATION_INDIVIDUAL } from '../../utils/dashboard';
import { LINE_SERIES_TOTAL_VIEWS, LINE_SERIES_UNIQUE_VISITORS } from '../../utils/traffic';

export const GuideTrafficChartLegend = ({
  aggregation = AGGREGATION_ALL,
  colorScale = scaleOrdinal(),
  hidden = [],
  toggle = () => {},
}) => {
  const lineSeriesScale = scaleOrdinal({
    domain:
      aggregation === AGGREGATION_ALL
        ? [LINE_SERIES_TOTAL_VIEWS, LINE_SERIES_UNIQUE_VISITORS]
        : [LINE_SERIES_UNIQUE_VISITORS],
    range: [colors.gray[500]],
  });
  return (
    <Box
      css={`
        display: flex;
        flex-direction: column;
        gap: 24px;
      `}
    >
      <LegendOrdinal scale={lineSeriesScale}>
        {(labels) => (
          <Box
            css={`
              display: flex;
              flex-direction: row;
              gap: 24px;
            `}
          >
            {labels.map(({ text, value }) => {
              return (
                <Box
                  css={`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 8px;
                    user-select: none;
                  `}
                  key={text}
                >
                  {text === LINE_SERIES_UNIQUE_VISITORS ? (
                    <CircleDashed color={value} size={16} weight="bold" />
                  ) : (
                    <Circle color={value} size={16} />
                  )}
                  <Text
                    label
                    css={`
                      font-size: 14px;
                      font-weight: 700;
                      user-select: none;
                    `}
                  >
                    {text}
                  </Text>
                </Box>
              );
            })}
          </Box>
        )}
      </LegendOrdinal>
      {aggregation === AGGREGATION_INDIVIDUAL && (
        <LegendOrdinal scale={colorScale}>
          {(labels) => (
            <Box
              css={`
                display: grid;
                grid-template-columns: repeat(6, 1fr);
                gap: 8px;
                width: 100%;
              `}
            >
              {labels.map((label) => {
                const { text, value } = label;
                return (
                  <Box
                    key={text}
                    css={`
                      display: flex;
                      align-items: center;
                      gap: 8px;
                      overflow: hidden;
                      cursor: pointer;
                      user-select: none;
                    `}
                    onClick={() => toggle(text)}
                  >
                    <Circle color={value} size={16} weight={hidden.includes(text) ? 'regular' : 'fill'} />
                    <Text
                      label
                      color={hidden.includes(text) ? colors.gray[500] : colors.black}
                      css={`
                        font-size: 14px;
                        font-weight: 700;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        user-select: none;
                      `}
                    >
                      {text}
                    </Text>
                  </Box>
                );
              })}
            </Box>
          )}
        </LegendOrdinal>
      )}
    </Box>
  );
};
