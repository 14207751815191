import { Box, Button, Text } from '../../shared/components';
import { animation, container, shadows } from '../../shared/shared-styles';
import { colors } from '../../shared/styles';
import { useStore } from '../../store-provider/use-store';
import { useContent } from '../providers/content/use-content';
import { ToolKit } from '../providers/tool-kit/ToolKit';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import EmptyEditor from '../../images/empty-editor.png';
import { useDebounceValue } from '../../shared/use-debounce-value';
import { useEffect } from 'react';

export const BottomDrag = ({ bodyRef, isLoading }) => {
  const { content } = useContent({ ignoreSelection: true });
  const toolkit = useToolKit();

  const {
    data: { devMode },
  } = useStore();

  const drag = ToolKit.getDrag(toolkit);
  const destinationId = content?.sections?.length ? content?.sections?.at(-1)?.id : '';
  const hasContent = content?.sections?.length === undefined ? true : !!content?.sections?.length;

  // Delays for better transitions
  const [delayed_dragging] = useDebounceValue(drag?.isDragging, 10);
  const [delayed_hasContent, delayed_setHasContent] = useDebounceValue(hasContent, 120);

  useEffect(() => {
    // Immediately set delayed animation when the page is done loading
    delayed_setHasContent(hasContent);
  }, [isLoading]);

  const openContext = (event) => {
    if (devMode) {
      event.preventDefault();
      ToolKit.openContextMenu(toolkit, {
        event,
        editor: bodyRef.current,
        context: {
          id: '',
          toolType: 'bottom-drag-zone',
        },
      });
    }
  };

  const addSection = () => {
    ToolKit.drop(toolkit, {
      type: 'create-section',
      edge: 'bottom',
      destinationId,
      toolType: 'section',
    });
  };

  return (
    <>
      <Box
        css={`
          position: relative;
          width: 100%;
          min-height: 400px;
          flex-grow: 1;
          ${!hasContent ? `background: linear-gradient(to bottom, white, rgba(0, 0, 0, 0.1));` : ''}
          :not(:hover) {
            .action {
              display: none;
            }
          }
        `}
        onMouseUp={() => ToolKit.drop(toolkit, { destinationId, edge: 'bottom' })}
        onContextMenu={openContext}
        flex="center start"
      >
        {drag?.isDragging && hasContent ? (
          <Box
            className="action"
            css={`
              width: 100%;
              height: 8px;
              border-radius: 30px;
              opacity: 0.6;
              background: linear-gradient(45deg, ${colors.purple}, ${colors.lightPurple}, ${colors.purple});
              ${shadows.lg}
            `}
          ></Box>
        ) : null}
      </Box>
      {!delayed_hasContent && !isLoading ? (
        <Box
          flex="center column"
          css={`
            user-select: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            margin-top: 10vh;
            gap: 24px;
            width: 100%;
            max-width: 600px;
            ${animation('grow', '.2s ease')}
            button {
              text-transform: none;
            }
            ${container.box}
            border-radius: 16px;
            padding: 32px 64px;
            transition: filter 0.3s ease, transform 0.1s ease;
            ${drag?.isDragging || delayed_dragging
              ? `
              pointer-events: none;
              filter: opacity(.2) brightness(.95);
              transform: scale(.75);
            `
              : ''}
            ${!!hasContent
              ? `
              transition: filter 0.1s ease, transform 0.1s ease;
              filter: opacity(.2) brightness(.95);
              transform: scale(0);
            `
              : ''}
          `}
        >
          <img src={EmptyEditor} />
          <Text h4 center>
            Drag and Drop a block to get started <br />
            or click "Add a Section".
          </Text>
          <Button purple onClick={addSection}>
            Add a Section
          </Button>
        </Box>
      ) : null}
    </>
  );
};
