import { subDays } from 'date-fns';
import { createContext, useContext, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { getDashboard } from '../api/dashboard';

const DashboardContext = createContext();

export const DashboardProvider = ({ businessId, id, children }) => {
  // Queries
  const {
    data: dashboard,
    isLoading: isLoadingDashboard,
    refetch,
  } = useQuery(['dashboard', id], () => getDashboard({ id, businessID: businessId }));

  // State
  const [range, setRange] = useState({ startDate: subDays(new Date(), 90), endDate: new Date() });

  // Memos
  const GUIDE_OPTIONS = useMemo(() => {
    if (isLoadingDashboard || !dashboard?.config?.guides?.length) return [];
    return dashboard.config.guides.map(({ Name, ID }) => ({ label: Name, value: ID }));
  }, [dashboard]);

  const updateRange = ({ startDate, endDate }) => {
    setRange({ startDate, endDate });
  };

  if (isLoadingDashboard) {
    return null;
  }

  return (
    <DashboardContext.Provider value={{ dashboard, isLoadingDashboard, refetch, range, GUIDE_OPTIONS, updateRange }}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = () => {
  return useContext(DashboardContext);
};
