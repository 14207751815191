import { Button, CircularProgress, TextField, Typography } from '@mui/material';
import { css } from '@emotion/css';
import React, { useMemo, useState } from 'react';
import { history } from '../history';
import { useMembers } from './use-members';
import { Plus } from '@phosphor-icons/react';
import { AddMember } from './add-member';
import { Div } from '../shared/components/div';
import { container } from '../shared/shared-styles';

const memberStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 81px;
  padding: 16px;
  border-bottom: 1px solid #d1dae3;
  cursor: pointer;
  :hover {
    background-color: #f5f7fa;
  }
`;

export const Members = () => {
  const [filter, setFilter] = useState('');
  const [addMemberModal, setAddMemberModal] = useState(false);
  const { data, isLoading } = useMembers();

  const selectMember = (member) => {
    history.push(`/members/${member.UserID}`);
  };

  const list = useMemo(() => {
    if (filter) {
      return data.filter(
        ({ user: { FirstName = '', LastName = '', Email = '' } }) =>
          FirstName.toLowerCase().includes(filter.toLowerCase()) ||
          LastName.toLowerCase().includes(filter.toLowerCase()) ||
          Email.toLowerCase().includes(filter.toLowerCase())
      );
    }
    return data;
  }, [filter, data.length]);

  return (
    <div>
      <Div
        css={css`
          ${container.header}
        `}
      >
        <h1>Members</h1>
        <div
          className={css`
            display: flex;
            align-items: center;
          `}
        >
          <TextField
            onChange={(e) => setFilter(e.target.value)}
            value={filter}
            placeholder="Search"
            style={{ margin: '0 16px', width: '250px', borderRadius: '16px' }}
            inputProps={{ style: { padding: '16px' } }}
            variant="outlined"
          />
          <Button onClick={() => setAddMemberModal(true)} variant="contained" color="primary" startIcon={<Plus />}>
            Add Member
          </Button>
        </div>
      </Div>
      <Typography style={{ margin: '16px', maxWidth: '800px', color: '#66737F' }}>
        These are the members for each business you have access to. If you are an admin you can change each members
        access individually by selecting from the list below.
      </Typography>

      <div
        className={css`
          margin: 32px 0;
          max-width: 1000px;
          padding-bottom: 100px;
        `}
      >
        <div
          className={css`
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #d1dae3;
            padding: 8px 16px;
          `}
        >
          <Typography style={{ fontSize: '16px', color: '#66737F' }}>Members</Typography>
        </div>

        {isLoading ? (
          <div
            className={css`
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 32px;
            `}
          >
            <CircularProgress />
          </div>
        ) : !!data.length ? (
          list.map((member) => (
            <div key={member.UserID} className={memberStyle} onClick={() => selectMember(member)}>
              <div>
                <Typography>
                  {member.user.FirstName} {member.user.LastName}
                </Typography>
                <Typography style={{ color: '#66737F' }}>{member.user.Email}</Typography>
              </div>
              <Typography>View</Typography>
            </div>
          ))
        ) : (
          <Typography style={{ margin: '32px', color: '#66737F' }}>You may not have access to view members.</Typography>
        )}
      </div>

      <AddMember display={addMemberModal} onClose={() => setAddMemberModal(false)} />
    </div>
  );
};
