import { MenuItem, IconButton } from '@mui/material';
import { css } from '@emotion/css';
import {
  ArrowLeft,
  CaretDown,
  CaretUp,
  Info,
  MagnifyingGlassPlus,
  MagnifyingGlassMinus,
  Table,
  ArrowUDownLeft,
  X,
  CheckCircle,
  StarFour,
  Path,
  Rows,
  CloudCheck,
  ArrowsCounterClockwise,
} from '@phosphor-icons/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { AutoPaster } from '../benefit-package/auto-paster-v2/auto-paster';
import { PRODUCT_HEADERS } from '../benefit-package/constants';
import { history } from '../history';
import { useQueryAPI, useRequests } from '../react-query';
import { Button, Div, Icon, Input, Modal, Text, Toggle } from '../shared/components';
import { BriteLoader } from '../shared/components/brite-loader';
import { Dropdown } from '../shared/components/dropdown';
import { animation, container, flex, scrollbar, shadows } from '../shared/shared-styles';
import { colors, mediaQueries } from '../shared/styles';
import { useDebounceValue } from '../shared/use-debounce-value';
import { useSearchParams } from '../shared/use-search-params';
import { useStateSync } from '../shared/use-state-sync';
import { useStore } from '../store-provider/use-store';
import { getLayoutObject, productUtils } from './configs/product-utils';
import { useProductData } from './configs/use-product-data';
import { ConnectedProduct } from './connected-product';
import { ProductForm } from './product-form';
import { ReactComponent as BriteTableGradient } from './Table-Gradient.svg';
import { useBasicReducer } from '../shared/use-basic-reducer';
import { UploadMedia } from '../benefit-package/auto-paster-v2/upload-media';
import { categorizeProducts } from '../benefit-package/creation-flow/utils';
import { titleMap } from '../benefit-package/product-list';
import { ACLWrapper } from '../shared/components/acl-wrapper';
import { SidePanel } from './side-panel/side-panel';
import { needsAttention } from '../react-query/resources/needs-attention';
import { useFeatureFlagPayload } from 'posthog-js/react';
import { ProductPreview } from './product-preview';
import { getFieldConfigs } from './configs/config-utils';
import { formFieldConfigs } from './configs/form.config';
import { Box } from '../shared/components/box';
import { useAutosave } from './useAutosave';
import { useEvent } from '../shared/use-event';

const autofillToggle = (toggled) => `
  min-width: max-content;
  position: relative;
  border: 1px solid ${colors.gray[300]};
  background-color: white;
  ${
    toggled
      ? `
  border: 1px solid ${colors.purple};
  ::before {
  z-index: -1;
    content: "";
    position: absolute;
    top: -2px;
    left: -2px;
    bottom: -2px;
    right: -2px;
    border-radius: 8px;
    background: ${colors.briteGradient}
  }
`
      : ``
  }
  border-radius: 8px;
  padding: 0 8px;
  padding-left: 32px;
  ${container.hover}
`;

const transformAiSuggestions = (data) => {
  const filtered = data?.filter(({ status }) => status === 'completed');

  const propertyMap = filtered?.reduce(
    (prev, item) => ({
      ...prev,
      [item?.property_chain]: item?.id,
    }),
    {}
  );
  const suggestions = filtered?.reduce((prev, item) => ({ ...prev, [item?.id]: item }), {});

  return {
    suggestions,
    propertyMap,
  };
};

export const Product = ({ businessId, packageId, planId, planType, goBack }) => {
  const aiAutofillFlag = useFeatureFlagPayload('ai-autofill');
  const [displayAutoFill, setDisplayAutoFill] = useState(false);
  const [openSections, setOpenSections] = useState('');

  const {
    data: { isBriteEmployee, lastGuideId, selectedSpreadsheetId = '' },
    setStore,
  } = useStore();

  const {
    params: { connectedProductId = '', mediaId = '', editFlagId = '', flagProperty = '' },
    updateParams,
    removeParam,
  } = useSearchParams();

  const { data: course } = useQueryAPI({
    url: `v2/course/${lastGuideId}`,
    defaultValue: {},
    enabled: false,
    cacheKey: ['editor'],
  });

  const [value, actions] = useBasicReducer({
    isSaving: false,
    showInfo: false,
    showUpload: false,
    displaySpreadsheets: false,
  });

  const infoRef = useRef();

  const state = useProductData({
    productId: planId,
    packageId: packageId,
    businessId,
  });

  const isAutoSaving = useAutosave(state);
  const hasUnsavedChanges = state?.save?.updatedAt > state?.save?.completedAt;
  useEffect(() => {
    if (!mediaId) {
      state?.aiSuggestions?.query?.refetch();
    }
  }, [mediaId]);

  const fieldConfigs = getFieldConfigs(formFieldConfigs, state);
  const layout = getLayoutObject(state);
  const filteredSuggestions = useMemo(() => {
    const data = transformAiSuggestions(state?.aiSuggestions?.query?.data);
    return Object.values(data?.suggestions || {})
      ?.filter((item) => {
        const config = fieldConfigs?.[item?.property_chain];
        return layout?.[item?.property_chain]?.Type !== 'label' && (!config?.hideField || config?.showSuggestion);
      })
      ?.sort((a, b) => {
        const a1 = layout?.[a?.property_chain];
        const b1 = layout?.[b?.property_chain];
        const value1 = a1?.sectionIdx * 1000 + a1?.fieldIdx;
        const value2 = b1?.sectionIdx * 1000 + b1?.fieldIdx;
        return value1 - value2;
      });
  }, [state?.aiSuggestions?.query?.dataUpdatedAt]);

  const autoFillProps = {
    layout,
    fieldConfigs,
    filteredSuggestions,
  };

  useEffect(() => {
    if (aiAutofillFlag?.value && filteredSuggestions?.length) {
      setDisplayAutoFill(true);
    }
  }, [aiAutofillFlag?.value, state?.aiSuggestions?.query?.dataUpdatedAt]);

  const {
    data: media,
    refetch: refetchMedia,
    cache: cacheMedia,
  } = useQueryAPI({
    url: `v2/media?related_id=${packageId}&include_private=true`,
    enabled: !!packageId,
    defaultValue: [],
    cacheTime: 0,
  });

  const [selectedMedia, setSelectedMedia] = useStateSync(
    (lastSelectedMedia) => media?.find((item) => mediaId === item?.ID) || lastSelectedMedia || media?.[0],
    [media, mediaId]
  );

  // Smooth transition into auto-paster
  const [hasMediaId] = useDebounceValue(!!mediaId, 300);
  // **********************************

  useEffect(() => {
    if (hasMediaId && !mediaId) {
      state?.aiSuggestions?.query?.refetch();
    }
  }, [mediaId]);

  useEffect(() => {
    if (!displayAutoFill) {
      setDisplayAutoFill(true);
    }
  }, [state?.suggestion?.updatedAt]);

  useEffect(() => {
    if (displayAutoFill && !openSections) {
      setOpenSections(new Date().toISOString());
    }
  }, [displayAutoFill]);

  const { isLoading } = state;

  const saveProduct = async () => {
    try {
      actions.set('isSaving', true);
      await productUtils.saveProduct(state);
    } catch (err) {
      console.log(err);
      toast.error('Failed to save benefit');
    } finally {
      actions.set('isSaving', false);
    }
  };

  const handleBackSave = async () => {
    if (state?.hasChanges && hasUnsavedChanges) {
      await productUtils.saveProduct(state);
    }
    goBack();
  };

  const handlePaste = () => {
    setStore('copy-field-value', null);
  };

  useEvent('paste', handlePaste, { element: document });

  useEffect(() => {
    return () => {
      setStore('copy-field-value', null);
      setStore('active-data-field', null);
    };
  }, []);

  useEffect(() => {
    if (state?.suggestion?.updatedAt) {
      setOpenSections(new Date().toISOString());
    }
  }, [state?.suggestion?.updatedAt]);

  const setMediaId = (mediaId) => {
    if (mediaId) {
      updateParams({ mediaId });
    } else {
      removeParam('mediaId');
    }
  };

  const handleMediaSelection = async () => {
    if (!media?.length) {
      actions.set('showUpload', true);
    }
    if (mediaId) {
      setMediaId('');
      try {
        // Save product before leaving spreadsheet importer
        await saveProduct();
      } catch (err) {
        console.log(err);
        toast.error('Error saving product');
      }
    } else if (!media?.length) {
      actions.set('displaySpreadsheets', true);
    } else {
      const mediaId = state?.suggestion?.data?.original_file_id || media?.[0]?.ID;
      setMediaId(mediaId);
    }
  };

  const handleMediaChange = (item) => {
    if (!mediaId) {
      setSelectedMedia(item);
    } else {
      updateParams({ mediaId: item?.ID });
    }
  };

  const closeFlag = () => {
    removeParam('editFlagId', 'flagProperty');
  };

  const [scale, setScale] = useState(1.8);

  const [delayedLoader, setDelayedLoader] = useState(isLoading);
  useEffect(() => {
    if (isLoading && !isAutoSaving) {
      setDelayedLoader(true);
    } else {
      setTimeout(() => setDelayedLoader(false), 100);
    }
  }, [isLoading]);

  const categorizedProducts = useMemo(() => {
    return categorizeProducts({ products: state?.productList });
  }, [state?.productList?.length]);

  return (
    <Modal full display={true}>
      <Div
        css={css`
          ${flex('space-between')}
          width: 100%;
          height: 100px;
          padding: 32px;
          border-bottom: 1px solid ${colors.gray[200]};
          background-color: white;
          ${shadows.sm}
        `}
      >
        <Div
          css={css`
            ${flex('left')}
            z-index: 100;
          `}
        >
          <Button
            styles="icon"
            onClick={handleBackSave}
            onDoubleClick={() => history.replace(`/${businessId}/packages/${packageId}`)}
          >
            <ArrowLeft />
          </Button>
          <Dropdown
            button={({ open }) => (
              <Div
                css={css`
                  ${flex('space-between')}
                  border-radius: 8px;
                  padding: 8px 16px;
                  margin: 0 8px;
                  min-width: 300px;
                  border: 1px solid ${colors.gray[200]};
                  ${container.hover}
                `}
              >
                <Text
                  h2
                  className={css`
                    margin-right: 16px;
                    max-width: 250px;
                  `}
                  ellipsis
                >
                  {state?.product?.ProductName || 'Edit Benefit'}
                </Text>
                {open ? <CaretUp size={24} /> : <CaretDown size={24} />}
              </Div>
            )}
            listWidth="350px"
            popper={{ placement: 'bottom-start' }}
          >
            <Div
              css={`
                background-color: white;
              `}
            >
              {Object.entries(categorizedProducts ?? {}).map(([key, products]) =>
                !!products?.length ? (
                  <Div
                    key={key}
                    css={css`
                      padding: 16px;
                      border-bottom: 1px solid ${colors.gray[300]};
                    `}
                  >
                    <Text
                      h4
                      css={`
                        margin-bottom: 8px;
                      `}
                    >
                      {titleMap[key]}
                    </Text>
                    {products?.map((product) => (
                      <Div
                        css={css`
                          padding: 8px 16px;
                          margin: 0 -16px;
                          ${container.hover}
                          border-radius: 8px;
                        `}
                        onClick={() =>
                          history.replace(
                            `/${businessId}/packages/${packageId}/${product?.Type}/${product?.ID}${history.location.search}`
                          )
                        }
                      >
                        <Text label>{product?.ProductName || PRODUCT_HEADERS?.[product?.Type]}</Text>
                        <Text>{PRODUCT_HEADERS?.[product?.Type]}</Text>
                      </Div>
                    ))}
                  </Div>
                ) : null
              )}
            </Div>
          </Dropdown>
          {PRODUCT_HEADERS?.[state?.product?.Type] ? (
            <Text
              label
              css={`
                padding: 4px 8px;
                border-radius: 30px;
                background-color: ${colors.black};
                color: white;
              `}
            >
              {PRODUCT_HEADERS?.[state?.product?.Type]}
            </Text>
          ) : null}
          {isBriteEmployee ? (
            <Button
              disabled={!course?.ID}
              styles="icon"
              onClick={() => history.push(`/${businessId}/courses/${course?.ID}`)}
              hoverLabel={course?.ID ? `Return to last guide (${course?.Name})` : ''}
              css={`
                margin-left: 16px;
              `}
            >
              <ArrowUDownLeft size={24} />
            </Button>
          ) : null}
        </Div>
        <Div
          css={css`
            ${flex('right grow')}
          `}
        >
          {hasMediaId && selectedMedia?.ContentType === 'application/pdf' ? (
            <div
              className={css`
                ${flex('right')}
              `}
            >
              <IconButton
                onClick={() => {
                  if (scale <= 0.4) return;
                  setScale(scale - scale * 0.3);
                }}
              >
                <MagnifyingGlassMinus
                  className={css`
                    color: ${colors.gray[600]};
                    &:hover {
                      color: ${colors.purple};
                    }
                  `}
                />
              </IconButton>
              <IconButton
                onClick={() => {
                  const newScale = scale * 1.3;
                  if (scale >= 4) return;
                  setScale(newScale);
                }}
              >
                <MagnifyingGlassPlus
                  className={css`
                    color: ${colors.gray[600]};
                    &:hover {
                      color: ${colors.purple};
                    }
                  `}
                />
              </IconButton>
            </div>
          ) : null}
          {aiAutofillFlag?.value && autoFillProps?.filteredSuggestions?.length ? (
            <Box
              flex="center grow"
              css={`
                flex-grow: 1;
              `}
            >
              <Box
                flex="left"
                css={autofillToggle(displayAutoFill)}
                onClick={() => setDisplayAutoFill(!displayAutoFill)}
              >
                <Text
                  label
                  bold
                  css={`
                    position: relative;
                  `}
                >
                  <StarFour
                    className={css`
                      position: absolute;
                      top: 0;
                      left: -16px;
                    `}
                    size={16}
                    color={colors.purple}
                    weight="fill"
                  />
                  Auto-Fill
                </Text>
                <Toggle
                  checked={displayAutoFill}
                  bgColorActive={colors.briteGradient}
                  css={`
                    pointer-events: none;
                  `}
                />
              </Box>
            </Box>
          ) : null}
          <Div
            css={css`
              ${flex('right')}
              transition: all 0.2s ease;
            `}
          >
            {mediaId ? (
              <Box
                flex="right"
                css={`
                  width: 300px;
                `}
              >
                <Button
                  styles="icon"
                  css={`
                    margin-right: 16px;
                    color: ${colors.purple};
                    ${animation('fadeIn', '.2s ease')}
                  `}
                  ref={infoRef}
                  onClick={() => actions.set('showInfo', true)}
                >
                  <Info />
                </Button>
                {media?.length ? (
                  <Dropdown
                    popper={{
                      placement: 'bottom-start',
                    }}
                    button={() => (
                      <Div
                        css={css`
                          max-width: 200px;
                          margin-right: 16px;
                          border-radius: 8px;
                          background-color: ${colors.gray[100]};
                          padding: 8px 16px;
                          cursor: pointer;
                          ${flex('right')}
                          ${animation('fadeIn', '.2s ease')}
                        p {
                            flex-grow: 1;
                          }
                        `}
                      >
                        <Text ellipsis label>
                          {selectedMedia?.Name}
                        </Text>
                        <CaretDown color={colors.black} />
                      </Div>
                    )}
                  >
                    {media?.map((item) => (
                      <MenuItem onClick={() => handleMediaChange(item)}>{item?.Name}</MenuItem>
                    ))}
                  </Dropdown>
                ) : null}
              </Box>
            ) : null}

            <ACLWrapper acls={['write:benefits_package']} resourceId={packageId}>
              {!aiAutofillFlag?.value ? (
                <Div
                  css={css`
                    border-radius: 8px;
                    background: linear-gradient(270deg, #62a7e5 0%, #a949f5 50%, #c05194 100%);
                    border-radius: 8px;
                    padding: 3px;
                    margin-right: 16px;
                    svg {
                      margin-left: 8px;
                      color: black;
                    }
                    cursor: pointer;
                  `}
                >
                  <Div
                    css={css`
                      ${flex('left')}
                      border-radius: 6px;
                      transition: background-color 0.3s ease;

                      ${mediaId
                        ? `
                        button {
                          color: white;
                        }
                      `
                        : `
                        background-color: white;
                      `};
                    `}
                    onClick={handleMediaSelection}
                  >
                    {mediaId ? <Table color="white" /> : <Icon SVG={BriteTableGradient} />}
                    <Button
                      naked
                      css={`
                        padding: 6px 16px;
                      `}
                    >
                      BRITE AUTO-FILL
                    </Button>
                  </Div>
                </Div>
              ) : (
                <>
                  <Box
                    flex="space-around"
                    css={`
                      border: 1px solid ${colors.gray[300]};
                      border-radius: 8px;
                      margin-right: 16px;
                      cursor: pointer;
                      svg {
                        min-width: 24px;
                        min-height: 24px;
                        margin-right: 8px;
                      }
                    `}
                    onClick={handleMediaSelection}
                  >
                    <Box
                      flex="center grow"
                      css={`
                        border-radius: 6px;
                        margin: 4px;
                        ${!mediaId
                          ? `
                          background-color: ${colors.gray[200]};  
                          color: ${colors.black};
                        `
                          : ''}
                        transition: background-color .2s ease;
                        padding: 8px;
                        width: 110px;
                      `}
                    >
                      <Rows size={24} />
                      <Text center label={!mediaId} bold>
                        FORM
                      </Text>
                    </Box>
                    <Box
                      flex="center grow"
                      css={`
                        border-radius: 8px;
                        margin: 4px;
                        ${!!mediaId
                          ? `
                          background-color: ${colors.gray[200]};  
                          color: ${colors.black};
                        `
                          : ''}
                        transition: background-color .2s ease;
                        padding: 8px;
                        width: 110px;
                      `}
                    >
                      <Path size={24} />
                      <Text center label={!!mediaId} bold>
                        FLOW
                      </Text>
                    </Box>
                  </Box>
                </>
              )}
            </ACLWrapper>
          </Div>

          {aiAutofillFlag?.value ? null : (
            <Button
              secondary
              onClick={goBack}
              css={`
                margin-right: 16px;
              `}
            >
              Close
            </Button>
          )}
          <ACLWrapper acls={['write:benefits_package']} resourceId={packageId}>
            <Button
              hoverLabel="Save"
              styles="icon"
              disabled={!state?.isValid}
              css={`
                width: 40px;
                height: 40px;
              `}
              onClick={saveProduct}
            >
              {value?.isSaving || isLoading || isAutoSaving ? <ArrowsCounterClockwise /> : <CloudCheck />}
            </Button>
          </ACLWrapper>
        </Div>
      </Div>
      {hasMediaId ? (
        <AutoPaster state={state} mediaId={mediaId} scale={scale} displayAutoFill={displayAutoFill} />
      ) : (
        <Div
          css={css`
            position: relative;
            ${flex('space-between')} width: 100%;
            ${mediaQueries.sm} {
              .preview {
                display: none;
              }
            }
          `}
        >
          <BriteLoader
            isLoading={delayedLoader}
            css={`
              height: 100%;
              padding-bottom: 35vh;
            `}
          >
            <Text
              h2
              css={`
                margin: 32px 0;
              `}
            >
              Fetching {PRODUCT_HEADERS[planType]}...
            </Text>
          </BriteLoader>

          <Div
            css={css`
              ${flex('center start grow')} height: calc(100vh - 100px);
              overflow: auto;
              ${scrollbar.hide}
            `}
          >
            <ProductForm state={state} openSections={openSections} />
          </Div>
          {aiAutofillFlag?.value && displayAutoFill ? (
            <SidePanel state={state} autoFillProps={autoFillProps} onClose={() => setDisplayAutoFill(false)} />
          ) : !selectedSpreadsheetId ? (
            <ProductPreview state={state} />
          ) : null}
        </Div>
      )}

      {!!connectedProductId && <ConnectedProduct productId={connectedProductId} details={state} />}

      <Modal display={value.showInfo} onClose={() => actions.set('showInfo', false)}>
        <Div
          css={css`
            width: 500px;
            padding: 32px;
            border: 2px solid ${colors.purple};
            border-radius: 16px;
            background-color: white;
            overflow: auto;
            .shortkey {
              ${flex('center')}
              border-radius: 4px;
              background-color: ${colors.gray[200]};
              padding: 4px 8px;
              margin: 0 4px;
            }
          `}
        >
          <Text h4>What is Brite Auto-Fill?</Text>
          <Text
            css={`
              padding: 16px 0;
            `}
          >
            With this feature you can reference and interact with your benefit detail files directly within Brite. All
            you have to do is click the document and Brite will paste the info automatically. Pretty neat, right?
          </Text>
          <Div
            css={css`
              border-top: 1px solid ${colors.gray[300]};
              border-bottom: 1px solid ${colors.gray[300]};
              padding: 16px 0;
              margin: 8px 0;
              ${flex('left')}
            `}
          >
            <Text label>Mapped Cell Current Plan</Text>
            <Div
              css={css`
                margin: 0 16px;
                width: 24px;
                height: 24px;
                border-radius: 8px;
                background-color: ${colors.lightPurple};
              `}
            />
            <Text label>Mapped Cell</Text>
            <Div
              css={css`
                margin: 0 16px;
                width: 24px;
                height: 24px;
                border-radius: 8px;
                background-color: ${colors.gray[100]};
                border: 1px solid ${colors.gray[300]};
              `}
            />
          </Div>
          <Text h4>Shortcuts</Text>

          <Div
            css={css`
              ${flex('space-between')}
            `}
          >
            <Text label>Toggle Focus</Text>
            <Div
              css={css`
                ${flex('right')}
                margin: 4px 0;
              `}
            >
              <Text label className="shortkey">
                control
              </Text>
              <Text
                css={`
                  margin: 0 4px;
                `}
              >
                +
              </Text>
              <Text label className="shortkey">
                F
              </Text>
            </Div>
          </Div>

          <Div
            css={css`
              ${flex('space-between')}
            `}
          >
            <Text label>Search Sheet</Text>
            <Div
              css={css`
                ${flex('right')}
                margin: 4px 0;
              `}
            >
              <Text label className="shortkey">
                control
              </Text>
              <Text
                css={`
                  margin: 0 4px;
                `}
              >
                +
              </Text>
              <Text label className="shortkey">
                S
              </Text>
            </Div>
          </Div>
          <Div
            css={css`
              ${flex('space-between')}
            `}
          >
            <Text label>Next Field</Text>
            <Div
              css={css`
                ${flex('right')}
                margin: 4px 0;
              `}
            >
              <Text label className="shortkey">
                right arrow
              </Text>
              <Text
                css={`
                  margin: 0 4px;
                `}
              >
                or
              </Text>
              <Text label className="shortkey">
                tab
              </Text>
            </Div>
          </Div>

          <Div
            css={css`
              ${flex('space-between')}
            `}
          >
            <Text label>Last Field</Text>
            <Div
              css={css`
                ${flex('right')}
                margin: 4px 0;
              `}
            >
              <Text label className="shortkey">
                left arrow
              </Text>
              <Text
                css={`
                  margin: 0 4px;
                `}
              >
                or
              </Text>
              <Text label className="shortkey">
                shift
              </Text>
              <Text label className="shortkey">
                tab
              </Text>
            </Div>
          </Div>
        </Div>
      </Modal>
      {value.showUpload ? (
        <UploadMedia
          onClose={() => actions.set('showUpload', false)}
          packageId={packageId}
          refetch={refetchMedia}
          displayDisclaimer={true}
          media={media}
          cacheMedia={cacheMedia}
        />
      ) : null}

      <FlagModal state={state} editFlagId={editFlagId} flagProperty={flagProperty} onClose={closeFlag} />
    </Modal>
  );
};

const FlagModal = ({ state, editFlagId, flagProperty, onClose }) => {
  const flagsRequests = useRequests(state?.flags);

  const isCreating = flagProperty && !editFlagId;
  const flag = needsAttention.utils.getFlagByPropertyChain(state?.flags, flagProperty);

  const [note, setNote, isSynced] = useStateSync(flag?.note || '', [state?.flags?.query?.dataUpdatedAt, flag?.note]);

  const handleResolve = async () => {
    try {
      onClose();
      const flagsPatch = needsAttention.utils.getPatch(flag?.id, { ...flag, resolved: true }, state?.flags?.params);
      await flagsRequests.patch(flagsPatch);
    } catch (err) {
      console.warn(err);
      toast.error(`Unable to resolve the flag.`);
    }
  };

  const createFlag = async () => {
    try {
      onClose();

      const flagsPost = needsAttention.utils.getPost(
        {
          note,
          resource_type: 'product',
          resource_id: state?.flags?.params?.productId,
          metadata: {
            benefits_package_id: state?.flags?.params?.packageId,
            property_chain: flagProperty,
          },
        },
        state?.flags?.params
      );
      await flagsRequests.post(flagsPost);
    } catch (err) {
      toast.error('Unable to create flag.');
    }
  };
  const saveChanges = async () => {
    try {
      onClose();
      const flagsPatch = needsAttention.utils.getPatch(
        flag?.id,
        {
          ...flag,
          note,
        },
        { businessId: flag?.business_id }
      );
      await flagsRequests.patch(flagsPatch);
    } catch (err) {
      console.warn(err);
      toast.error(`Unable to save changes.`);
    }
  };

  return (
    <Modal display={!!editFlagId || !!flagProperty} onClose={onClose}>
      <div
        className={css`
          width: 500px;
          padding: 32px;
        `}
      >
        <div
          className={css`
            ${flex('space-between')}
            margin-bottom: 16px;
          `}
        >
          <Text h2>{isCreating ? 'Flag Field' : 'Edit Field Flag'}</Text>
          <Button styles="icon" onClick={onClose}>
            <X />
          </Button>
        </div>
        <Text
          label
          bold
          css={`
            margin-bottom: 8px;
          `}
        >
          Flag Description
        </Text>
        <Input placeholder="Needs Attention" value={note} onChange={(e) => setNote(e.target.value)} rows={3} />
        <div
          className={css`
            ${flex('right')}
            margin-top: 24px;
          `}
        >
          <Button secondary onClick={isCreating ? onClose : handleResolve}>
            {isCreating ? null : <CheckCircle size={24} />}
            {isCreating ? 'Cancel' : 'Resolve'}
          </Button>
          <Button
            disabled={isSynced}
            css={`
              margin-left: 16px;
            `}
            onClick={isCreating ? createFlag : saveChanges}
          >
            {isCreating ? 'Submit' : 'Save'}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
