import { useRef } from 'react';
import { useEvent } from '../../shared/use-event';

const SCROLL_EDGE_THRESHOLD = 200; // px
const MAX_SCROLL_SPEED = 300; // px per tick
const SCROLL_INTERVAL = 200; // ms (about 60fps)

export const AutoScroll = ({ scrollRef }) => {
  const intervalRef = useRef(null);

  const onMouseMove = (event) => {
    const container = scrollRef.current;
    if (!container) return;

    const rect = container.getBoundingClientRect();
    const mouseY = event.clientY;

    let velocity = 0;

    if (mouseY < rect.top + SCROLL_EDGE_THRESHOLD) {
      velocity = rect.top - MAX_SCROLL_SPEED;
    } else if (mouseY > rect.bottom - SCROLL_EDGE_THRESHOLD) {
      velocity = rect.top + MAX_SCROLL_SPEED;
    }

    const now = Date.now() - intervalRef.current;
    if (velocity !== 0 && now > SCROLL_INTERVAL) {
      intervalRef.current = Date.now();
      container.scrollBy({ top: velocity });
    }
  };

  useEvent('mousemove', onMouseMove);
};
