import { useEffect, useRef } from 'react';
import { useSearchParams } from '../../shared/use-search-params';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import { SIDEBAR_TABS } from './constants';
import { ToolKit } from '../providers/tool-kit/ToolKit';

export const useEditorParams = () => {
  const initial = useRef();
  const toolkit = useToolKit();
  const { params, updateParams, removeParam } = useSearchParams();

  const { type: tab } = ToolKit.getCurrentTab(toolkit);

  const commitRouteTo = () => {
    const [routeToType, threadId, blockId, highlightId = ''] = params?.routingTo?.split(',');
    if (routeToType === 'comment') {
      setTimeout(() => {
        ToolKit.selectComment(toolkit, {
          source: 'query-param',
          blockId,
          threadId,
          highlightId: highlightId,
        });
        removeParam('routingTo');
      }, 1800);
    }
  };

  useEffect(() => {
    if (!initial.current) {
      if (params?.mode === 'collaborating') {
        if (tab !== SIDEBAR_TABS.COMMENTS) {
          ToolKit.setCurrentTab(toolkit, SIDEBAR_TABS.COMMENTS);
        }
        if (!!params?.routingTo) {
          commitRouteTo();
        }
      }
    }
    initial.current = true;
  }, [params?.mode, params.routingTo]);

  useEffect(() => {
    if (!initial.current && params?.mode === 'collaborating' && tab !== SIDEBAR_TABS.COMMENTS) {
      ToolKit.setCurrentTab(toolkit, SIDEBAR_TABS.COMMENTS);
    }
    initial.current = true;
  }, [params?.mode]);

  useEffect(() => {
    if (tab === SIDEBAR_TABS.COMMENTS) {
      updateParams({ mode: 'collaborating' });
    } else {
      updateParams({ mode: 'editing' });
    }
  }, [tab]);
};
