import { isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { createColumnHelper } from '@tanstack/react-table';
import { ParentSize } from '@visx/responsive';
import { scaleOrdinal } from '@visx/scale';
import { AnimatedAxis, AnimatedBarSeries, AnimatedBarStack, AnimatedGrid, Tooltip, XYChart } from '@visx/xychart';
import { Box, Text } from '../../../shared/components';
import { colors } from '../../../shared/styles';
import { getVisitorsByDevice } from '../../api/traffic';
import { useDashboard } from '../../context/DashboardContext';
import { AGGREGATION_ALL, AGGREGATION_INDIVIDUAL, AGGREGATION_OPTIONS } from '../../utils/dashboard';
import { mapVisitorsByDeviceData, TRAFFIC_DEVICES_COLOR_SCALE } from '../../utils/traffic';
import { ChartContainer } from '../chart/ChartContainer';
import { ChartControlDropdown } from '../chart/ChartControlDropdown';
import { ChartLegend } from '../chart/ChartLegend';
import { ChartTable } from '../chart/ChartTable';

export const VisitorsByDeviceChart = () => {
  // Routing
  const { businessId } = useParams();

  // Context
  const { dashboard, range } = useDashboard();

  // State
  const [aggregation, setAggregation] = useState(AGGREGATION_ALL);

  // Queries
  const { data, isLoading } = useQuery(['visitorsByDevice', businessId, range], () =>
    getVisitorsByDevice({ dashboard, range })
  );

  const visitorsByDeviceData = useMemo(() => {
    // Return an empty array if data is still loading or not available.
    if (isLoading || !data?.ViewsByDevice) return {};
    return mapVisitorsByDeviceData({ data });
  }, [data]);

  const devices = useMemo(() => {
    if (isEmpty(visitorsByDeviceData)) return [];
    const possibleDevices = Object.keys(TRAFFIC_DEVICES_COLOR_SCALE);
    return possibleDevices.filter((device) => visitorsByDeviceData[AGGREGATION_ALL.label].some((d) => d[device]));
  }, [visitorsByDeviceData]);

  const colorScale = scaleOrdinal({
    domain: devices,
    range: devices.map((device) => TRAFFIC_DEVICES_COLOR_SCALE[device]),
  });

  const columnHelper = createColumnHelper();

  const columns = [
    columnHelper.accessor('GuideName', {
      header: () => <ChartTable.ColumnHeader label="Guide Name" />,
      cell: (info) => <ChartTable.ColumnCell label={info.getValue()} />,
    }),
    ...devices.map((device) =>
      columnHelper.accessor(device, {
        header: () => <ChartTable.ColumnHeader capitalize label={device} />,
        cell: (info) => (
          <ChartTable.ColumnHelperCell
            label={info.getValue()?.Count || 0}
            helper={`(${info.getValue()?.Percent || 0}%)`}
          />
        ),
      })
    ),
  ];

  return (
    <ChartContainer
      isLoading={isLoading}
      title="Visitors by Device"
      description="Shows a breakdown of the devices visitors used when accessing guides associated with this dashboard."
      chartControls={
        <ChartControlDropdown
          label={aggregation.label}
          options={AGGREGATION_OPTIONS}
          onClick={(option) => setAggregation(option)}
        />
      }
    >
      <ParentSize>
        {({ width }) =>
          width > 0 && (
            <XYChart
              margin={{ top: 40, right: 40, bottom: 40, left: 100 }}
              width={width}
              height={
                visitorsByDeviceData[aggregation.label].length > 1
                  ? visitorsByDeviceData[aggregation.label].length * 100
                  : 200
              }
              xScale={{ type: 'linear' }}
              yScale={{ type: 'band', padding: 0.5 }}
            >
              <AnimatedAxis orientation="left" animationTrajectory="min" />
              <AnimatedAxis orientation="bottom" animationTrajectory="max" />
              <AnimatedGrid columns numTicks={5} />
              <AnimatedBarStack order="descending">
                {devices.map((device) => (
                  <AnimatedBarSeries
                    key={device}
                    dataKey={device}
                    data={visitorsByDeviceData[aggregation.label]}
                    xAccessor={(d) => d[device]?.Percent}
                    yAccessor={(d) => d.GuideName}
                    colorAccessor={() => colorScale(device)}
                  />
                ))}
              </AnimatedBarStack>
              <Tooltip
                snapTooltipToDatumX
                snapTooltipToDatumY
                showHorizontalCrosshair
                showVerticalCrosshair
                renderTooltip={({ tooltipData }) => {
                  const { datum, key } = tooltipData?.nearestDatum;
                  return (
                    <Box
                      css={`
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                      `}
                    >
                      <Text helper color={colors.gray[500]}>
                        {datum?.GuideName}
                      </Text>
                      <Box
                        css={`
                          display: flex;
                          flex-direction: row;
                          gap: 4px;
                        `}
                      >
                        <Text
                          helper
                          bold
                          color={colors.black}
                          css={`
                            text-transform: capitalize;
                          `}
                        >
                          {key}
                        </Text>
                        <Text helper bold color={colorScale(key)}>
                          {datum[key]?.Count} ({datum[key]?.Percent}%)
                        </Text>
                      </Box>
                    </Box>
                  );
                }}
              />
            </XYChart>
          )
        }
      </ParentSize>
      <ChartLegend scale={colorScale} />
      <ChartTable data={visitorsByDeviceData[AGGREGATION_INDIVIDUAL.label]} columns={columns} />
    </ChartContainer>
  );
};
