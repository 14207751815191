import React, { useState, useEffect } from 'react';
import { css } from '@emotion/css';
import CircularProgress from '@mui/material/CircularProgress';
import { CustomAxios } from '../redux/axios/axios';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/lab/Autocomplete';

import { sortBy } from 'lodash';
export const CourseSelector = (props) => {
  const [loading, setLoading] = useState(false);

  const [courses, setCourses] = useState([
    {
      ID: '00000000-0000-0000-0000-000000000000',
      Name: 'Create a New Guide',
    },
  ]);

  const [value, setValue] = useState(courses[0]);

  useEffect(() => {
    setValue(courses.find((option) => option.ID === props.courseId));
  }, [JSON.stringify(courses), props.courseId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await CustomAxios.get(`/v1/course`);

        let courses = response.data;

        courses = courses.filter((course) => course.Type !== 'vendor');
        courses = courses.filter((course) => course.Type !== 'survey');

        courses = sortBy(courses, (o) => o.Name.toLowerCase());

        let c = [];

        c.push({
          ID: '00000000-0000-0000-0000-000000000000',
          Name: 'Create a New Guide',
        });

        setCourses(c.concat(courses));

        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <div
        className={css`
          display: flex;
          justify-content: center;
          width: 100%;
          padding: 24px;
        `}
      >
        {' '}
        <CircularProgress />
      </div>
    );
  }

  return (
    <div
      className={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
      `}
    >
      <div>
        <Autocomplete
          id="course_select"
          options={courses}
          onChange={(event, newValue) => {
            if (newValue && newValue.ID) {
              props.handleSelect(newValue.ID);
            }
          }}
          value={value}
          noOptionsText={'No guide with that name'}
          disableClearable
          getOptionLabel={(option) => option.Name}
          style={{ width: 250 }}
          renderInput={(params) => <TextField {...params} label={'Select a Guide'} variant="outlined" />}
        />
      </div>
    </div>
  );
};
