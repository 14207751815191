import { ContentState, convertToRaw } from 'draft-js';

export const actions = {
  'open-website': {
    displayName: 'Open Website',
    tabType: 'new-tab',
    url: '',
  },
  'route-to-page': {
    displayName: 'Route To Page',
    tabType: 'same-tab',
    pageId: '',
  },
  'open-email': {
    displayName: 'Send Email',
    sendToEmail: '',
    subject: '',
    body: '',
  },
  'open-sms': {
    displayName: 'Send SMS',
    sendToNumber: '',
    message: '',
  },
  'call-phone': {
    displayName: 'Call Phone',
    callNumber: '',
  },
};

const getDefaultTextState = () => {
  const currentContent = ContentState.createFromText('');
  const content = '';
  const rawState = convertToRaw(currentContent);
  return { content, rawState };
};

export const defaultComponents = {
  pageBreak: {
    component: 'pageBreak',
    attributes: {
      style: {
        backgroundColor: 'transparent',
        minHeight: '64px',
        height: '100%',
        width: '100%',
      },
    },
    container: {
      attributes: {
        style: {
          display: 'flex',
          alignItems: 'center',
          height: 'auto',
          width: '100%',
        },
      },
    },
  },
  document: {
    component: 'document',
    container: {
      attributes: {
        style: {
          paddingTop: '8px',
          paddingBottom: '8px',
          paddingRight: '8px',
          paddingLeft: '8px',
        },
      },
    },
  },
  accordion: {
    component: 'accordion',
    data: {
      icon: '',
      title: {
        slate: [
          {
            type: 'p',
            children: [{ text: '' }],
          },
        ],
        content: '',
      },
      body: {
        slate: [
          {
            type: 'p',
            children: [{ text: '' }],
          },
        ],
        content: '',
      },
    },
  },
  pricing: {
    component: 'pricing',
    type: '',
    data: {
      variant: 'cost',
      variantStyle: 'clean',
    },
    container: {
      attributes: {
        style: {
          display: 'flex',
          alignItems: 'start',
          height: '100%',
          paddingTop: '8px',
          paddingBottom: '8px',
          paddingRight: '8px',
          paddingLeft: '8px',
        },
      },
    },
  },
  network: {
    component: 'network',
    type: '',
    data: {},
    container: {
      attributes: {
        style: {
          display: 'flex',
          alignItems: 'start',
          height: '100%',
          paddingTop: '8px',
          paddingBottom: '8px',
          paddingRight: '8px',
          paddingLeft: '8px',
        },
      },
    },
  },
  benefits: {
    component: 'benefits',
    type: '',
    data: {
      variant: 'plan',
    },
    container: {
      attributes: {
        style: {
          display: 'flex',
          alignItems: 'start',
          height: '100%',
          paddingTop: '8px',
          paddingBottom: '8px',
          paddingRight: '8px',
          paddingLeft: '8px',
        },
      },
    },
  },
  'content-block': {
    component: 'content-block',
    data: {},
    container: {
      attributes: {
        style: {
          display: 'flex',
          alignItems: 'start',
          height: '100%',
          paddingTop: '8px',
          paddingBottom: '8px',
          paddingRight: '8px',
          paddingLeft: '8px',
        },
      },
    },
  },
  empty: {
    component: 'empty',
    attributes: {
      style: {
        height: '100%',
        width: '100%',
        minHeight: '60px',
      },
    },
  },
  video: {
    component: 'video',
    attributes: {
      style: {
        minWidth: '100%',
        minHeight: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
      },
      src: '',
      frameBorder: '0',
      allowFullScreen: 'true',
      allow: 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture',
    },
    container: {
      attributes: {
        style: {
          overflow: 'hidden',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          width: '100%',
          position: 'relative',
          height: '0',
          paddingBottom: '56.52%',
        },
      },
    },
  },
  website: {
    component: 'website',
    attributes: {
      src: '',
      frameBorder: '0',
      width: '100%',
      height: '500px',
    },
    container: {
      attributes: {
        paddingTop: '8px',
        paddingBottom: '8px',
        paddingRight: '8px',
        paddingLeft: '8px',
      },
    },
  },
  textV2: {
    component: 'textV2',
    type: 'body',
    content: '',
    slateContent: [
      {
        type: 'p',
        children: [{ text: '' }],
      },
    ],
    container: {
      attributes: {
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0)',
          paddingTop: '8px',
          paddingBottom: '8px',
          paddingRight: '8px',
          paddingLeft: '8px',
        },
      },
    },
  },
  text: {
    component: 'text',
    type: 'body',
    ...getDefaultTextState(),
    container: {
      attributes: {
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0)',
          paddingTop: '8px',
          paddingBottom: '8px',
          paddingRight: '8px',
          paddingLeft: '8px',
        },
      },
    },
  },
  image: {
    component: 'image',
    attributes: {
      style: {
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        borderBottomLeftRadius: '8px',
        borderBottomRightRadius: '8px',
      },
      src: '',
      alt: 'Image',
    },
    container: {
      attributes: {
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0)',
          paddingTop: '8px',
          paddingBottom: '8px',
          paddingRight: '8px',
          paddingLeft: '8px',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
        },
      },
    },
  },
  space: {
    component: 'space',
    attributes: {
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
        minHeight: '48px',
        height: '100%',
        width: '100%',
      },
    },
    container: {
      attributes: {
        style: {
          display: 'flex',
          alignItems: 'center',
          height: 'auto',
          width: '100%',
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
        },
      },
    },
  },
  line: {
    component: 'line',
    container: {
      attributes: {
        style: {
          backgroundColor: 'rgba(0, 0, 0, 0)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'auto',
          paddingTop: '8px',
          paddingBottom: '8px',
          paddingRight: '8px',
          paddingLeft: '8px',
          width: '100%',
        },
      },
    },
  },
  button: {
    component: 'button',
    container: {
      attributes: {
        style: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          paddingTop: '8px',
          paddingBottom: '8px',
          paddingRight: '8px',
          paddingLeft: '8px',
        },
      },
    },
    content: 'Button',
    action: {
      type: 'open-website',
      data: { ...actions['open-website'] },
    },
  },

  'dev-block': {
    component: 'dev-block',
    type: 'code',
    data: {},
  },
};
