import { Heart } from '@phosphor-icons/react';
import { Box, Text, Toggle } from '../../../shared/components';
import { colors } from '../../../shared/styles';
import { ColorSetting } from '../Properties/ColorSetting';
import { PaddingSetting } from '../Properties/PaddingSetting';
import { Property } from '../Properties/property';
import { RadiusSetting } from '../Properties/RadiusSetting';
import {
  getBgColorProperties,
  getHideOnDesktop,
  getHideOnExport,
  getHideOnMobile,
  getPaddingProperties,
  getRadiusProperties,
  getSectionFullScreenProperties,
  getSectionHasBackgroundProperties,
} from './property-utils';
import { useToolKit } from '../../providers/tool-kit/use-tool-kit';
import { ToolKit } from '../../providers/tool-kit/ToolKit';
import { PropertiesGrouping } from './PropertiesGrouping';

export const SectionSettings = (props) => {
  const toolkit = useToolKit();
  const { data, update } = props;

  const padding = getPaddingProperties({ data, update, type: 'section' });
  const radius = getRadiusProperties({ data, update, type: 'section' });
  const bgColor = getBgColorProperties({ data, update, type: 'section' });
  const fullScreen = getSectionFullScreenProperties({ data, update });
  const hasBackground = getSectionHasBackgroundProperties({ data, update });
  const hideOnMobile = getHideOnMobile({ data, update });
  const hideOnDesktop = getHideOnDesktop({ data, update });
  const hideOnExport = getHideOnExport({ data, update });

  return (
    <>
      <Box>
        <Box
          css={`
            display: grid;
            gap: 24px;
            padding: 16px;
          `}
        >
          <Box card onClick={() => ToolKit.setModal(toolkit, { type: 'save-section', data })}>
            <Heart />
            <Text label>Save Section</Text>
          </Box>

          <PaddingSetting {...padding} />
        </Box>
        <PropertiesGrouping label="Background" defaultOpen>
          <Box flex="space-between">
            <Text label bold>
              Add Background
            </Text>
            <Toggle checked={hasBackground.value} onChange={(e) => hasBackground?.setValue(e.target.checked)} />
          </Box>
          <Box
            css={
              hasBackground?.value
                ? ''
                : `
                  opacity: .8;
                  pointer-events: none;  
                `
            }
          >
            <Box
              css={`
                display: grid;
                gap: 16px;
              `}
            >
              <Property disabled={!hasBackground.value}>
                <Box flex="space-between">
                  <Text label>Full Screen</Text>
                  <Toggle checked={fullScreen?.value} onChange={(e) => fullScreen?.setValue(e.target.checked)} />
                </Box>
              </Property>
              <ColorSetting {...bgColor} label="Background Color" disabled={!hasBackground?.value} />
              <RadiusSetting {...radius} disabled={!hasBackground?.value} />
            </Box>
          </Box>
        </PropertiesGrouping>
        <PropertiesGrouping label="Display Settings">
          <Box>
            <Box flex="space-between">
              <Text label>Hide on Desktop</Text>
              <Toggle checked={hideOnDesktop.value} onChange={(e) => hideOnDesktop?.setValue(e.target.checked)} />
            </Box>
            <Box flex="space-between">
              <Text label>Hide on Mobile</Text>
              <Toggle checked={hideOnMobile.value} onChange={(e) => hideOnMobile?.setValue(e.target.checked)} />
            </Box>
            <Box flex="space-between">
              <Text label>Hide on Export</Text>
              <Toggle checked={hideOnExport.value} onChange={(e) => hideOnExport?.setValue(e.target.checked)} />
            </Box>
          </Box>
        </PropertiesGrouping>
      </Box>
    </>
  );
};
