import { Tooltip } from '../../../common/components/Tooltip';
import { Box, Button, Dropdown, Text } from '../../../shared/components';
import { ToolKit } from '../../providers/tool-kit/ToolKit';
import { useToolKit } from '../../providers/tool-kit/use-tool-kit';
import { COLOR_MAP } from '../constants';
import { colorButtonStyles, colorPickerDropdownStyles } from './styles';
import { useEditorResource } from '../../../BriteEditor/use-editor-resource';
import { useMemo } from 'react';
import { useSync } from '../useSync';
import { PencilSimple, Plus, Prohibit } from '@phosphor-icons/react';
import { colors } from '../../../shared/styles';
import { useModal } from '../../../common/hooks/useModal';
import { CreateColorModal } from './CreateColorModal';
import { useSearchParams } from '../../../shared/use-search-params';
import { container } from '../../../shared/shared-styles';
import { Checkboard } from 'react-color/lib/components/common';

function rgbaToHex(color) {
  try {
    // Check if the color is in rgba() format
    const rgbaMatch = color.match(/^rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d*\.?\d+)\)$/);

    if (rgbaMatch) {
      let [_, r, g, b, a] = rgbaMatch;
      r = parseInt(r).toString(16).padStart(2, '0');
      g = parseInt(g).toString(16).padStart(2, '0');
      b = parseInt(b).toString(16).padStart(2, '0');

      // Convert alpha to 255 scale and then to hex
      a = Math.round(parseFloat(a) * 255)
        .toString(16)
        .padStart(2, '0');

      return `#${r}${g}${b}${a}`;
    }

    // If not rgba, return the original color value
    return color;
  } catch {
    return color;
  }
}

const PickerStyle = ({ inline, previewColor, colorName }) => {
  if (inline) {
    return (
      <Box
        css={`
          border-radius: 50%;
          padding: 0;
          border: 1px solid white;
        `}
      >
        <Color color={previewColor} />
      </Box>
    );
  } else {
    return (
      <Box
        css={`
          border-radius: 8px;
          border: 1px solid ${colors.gray[300]};
          background-color: white;
          ${container.hover}
          padding: 8px;
          gap: 16px;
        `}
        flex="left"
        onMouseDown={(e) => e.preventDefault()}
      >
        <Color color={previewColor} hideHover />
        <Text
          label
          ellipsis
          css={`
            flex-grow: 1;
            max-width: 160px;
          `}
        >
          {colorName}
        </Text>
      </Box>
    );
  }
};
export const ColorPicker = ({ color, update, label, clearColor = false, inline = true }) => {
  const toolkit = useToolKit();

  const modal = useModal();

  const { updateParams } = useSearchParams();

  const guide = ToolKit.getMetadata(toolkit, 'guide');
  const { data: designStyles } = useEditorResource('styles');
  const colorPalette = designStyles?.general?.colorPalette;

  const guideColors = useSync(guide?.data?.ColorPickerPresets || []);

  const getPreviewColor = () => {
    if (color?.startsWith('var(--')) {
      const key = color?.replace('var(--', '')?.replace(')', '');
      return colorPalette[key] || key;
    }
    return rgbaToHex(color);
  };

  const colorName = useMemo(() => {
    if (color === 'rgba(0, 0, 0, 0)') {
      return 'None';
    } else if (color?.startsWith('var(--')) {
      const key = color?.replace('var(--', '')?.replace(')', '');
      return COLOR_MAP[key] || colorPalette[key] || key;
    }
    return rgbaToHex(color);
  }, [color]);

  const designStyleColors = useMemo(() => {
    const list = Object.entries(colorPalette || {}).map(([name, value]) => ({ name, value }));
    return list.sort((a, b) => a.name.localeCompare(b.name));
  }, []);

  const previewColor = useMemo(() => {
    return getPreviewColor();
  }, [color, colorPalette]);

  const updateDesignStyleColor = (colorName) => update(`var(--${colorName})`);
  const updateGuideColor = (color) => update(color);

  const addGuideColor = (color) => {
    const colors = guideColors.value || [];
    colors.push(color);
    guide.save({ ColorPickerPresets: colors });
    guideColors.set(colors);
    updateGuideColor(color);
    modal.hide();
  };

  return (
    <>
      <Dropdown
        button={() => (
          <Tooltip label={label}>
            <PickerStyle inline={inline} previewColor={previewColor} colorName={colorName} />
          </Tooltip>
        )}
        popper={{
          placement: !inline ? 'left-start' : 'bottom-start',
        }}
        css={colorPickerDropdownStyles}
        stopPropagation
      >
        <Box
          data-scope="menu"
          css={`
            padding: 8px;
            gap: 8px;
            display: grid;
          `}
        >
          <Box
            flex="space-between"
            css={`
              padding: 8px;
              width: 250px;
            `}
          >
            <Text label bold>
              Design Style Colors
            </Text>
            <Button
              styles="icon sm"
              hoverLabel="Edit Design Styles"
              onClick={() => updateParams({ courseModal: 'design-styles' })}
            >
              <PencilSimple size={24} />
            </Button>
          </Box>
          <Box
            flex="left wrap"
            css={`
              gap: 8px;
              width: 250px;
              padding: 8px;
            `}
          >
            {clearColor ? <Color color="" label="Clear" onClick={() => update('')} /> : null}
            <Color color="rgba(0, 0, 0, 0)" label="Transparent" onClick={() => update('rgba(0, 0, 0, 0)')} />
            {designStyleColors?.map((item) => (
              <Color
                key={item.name}
                color={item.value}
                label={COLOR_MAP[item.name] || item.name}
                onClick={() => updateDesignStyleColor(item.name)}
                isCurrentColor={color === `var(--${item.name})` || color === item.name}
              />
            ))}
          </Box>
          <Box
            flex="space-between"
            css={`
              padding: 8px;
              padding-top: 16px;
              width: 250px;
            `}
          >
            <Text label bold>
              Guide Colors
            </Text>
            <Button
              styles="icon sm"
              css={`
                width: 28px;
                height: 28px;
                padding: 4px;
                border: 1px solid ${colors.gray[300]};
              `}
              onClick={() => modal.toggle()}
            >
              <Plus weight="bold" />
            </Button>
          </Box>
          <Box
            flex="left wrap"
            css={`
              gap: 8px;
              width: 250px;
              padding: 8px;
            `}
          >
            {guideColors?.value?.map((item, idx) => (
              <Color
                key={idx + item}
                color={item}
                isCurrentColor={color?.includes(item)}
                onClick={() => updateGuideColor(item)}
              />
            ))}
          </Box>
        </Box>
      </Dropdown>
      {modal.isOpen ? <CreateColorModal onClose={modal.hide} addGuideColor={addGuideColor} /> : null}
    </>
  );
};

export const Color = ({ color, isCurrentColor, label, onClick, size, hideHover = false }) => {
  return (
    <Tooltip label={label}>
      <Button
        css={colorButtonStyles(color, isCurrentColor, size, hideHover)}
        onClick={onClick}
        onMouseDown={(e) => e.preventDefault()}
      >
        <Box>
          {color === 'rgba(0, 0, 0, 0)' ? (
            <>
              <Checkboard />
              <Box />
            </>
          ) : color === '' ? (
            <Prohibit size={24} />
          ) : null}
        </Box>
      </Button>
    </Tooltip>
  );
};
