import { css } from '@emotion/css';
import { colors } from '../../shared/styles';
import { generateColors } from '../../BriteEditor/brite-editor';
import { scrollbar } from '../../shared/shared-styles';
import { buildCss } from '../../BriteEditor/utils';

export const overlay = `
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
`;

export const slateContainer = css`
  outline: none;
  width: 100%;
  cursor: text;
  p {
    margin: 0;
    padding: 0;
  }
  .center-element {
    background-color: ${colors.gray[200]};
    border-radius: 8px;
    padding: 8px;
    margin: 0;
    width: 100%;
  }
`;

const generateTypographyClasses = (styles) => {
  const classNames = Object.entries(styles?.text || {}).reduce((acc, [key, value]) => {
    return `
      ${acc}
      .text-${key}-component {
        ${buildCss(value)}
      }
    `;
  }, ``);
  return classNames;
};

export const editorContainer = (styles, readOnly) => `
  width: 100%;
  height: 100%;
  overflow: hidden;
  ${generateColors(styles)}
  ${generateTypographyClasses(styles)}
  ${readOnly ? 'pointer-events: none;' : ''}
`;

export const bodyStyles = `
  position: relative;
  flex-grow: 1;
  background-color: white;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: var(--background-color);
  padding-top: 2px;
  ${scrollbar.style}
`;
