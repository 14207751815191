import { css } from '@emotion/css';
import { Button, Div, Text, Toggle } from '../../../shared/components';
import { flex } from '../../../shared/shared-styles';
import DTImage from './enrollment-feedback.png';
import { useStateSync } from '../../../shared/use-state-sync';
import { TextField } from '@mui/material';

export const EnrollmentFeedback = ({ course, page, savePage, updateCourse, pageLease, pagesProps, saveCourse }) => {
  const {
    query: { data: pages = [] },
  } = pagesProps;

  const [enrollment, setEnrollment, isEnrollmentSynced] = useStateSync(course?.EnrollmentURL || '', [
    course?.EnrollmentURL,
  ]);

  const routeToCourse = () => {
    const pathParam = course.VanityURL || course.ID;

    window.open(`https://britehr.app/${pathParam}/${pages.length + 1}?lt=preview`, '_blank');
  };

  const handleUpdate = (value, isSynced) => {
    if (!isSynced) {
      saveCourse({
        EnrollmentURL: value,
      });
    }
  };

  return (
    <Div
      css={css`
        ${flex('center column')}

        overflow: auto;

        p {
          max-width: 700px;
        }
      `}
    >
      <img src={DTImage} alt="DT" />
      <Text
        h2
        css={`
          span {
            background: linear-gradient(270deg, #62a7e5 30.66%, #a949f5 37.58%, #c05194 44.65%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            font-feature-settings: 'clig' off, 'liga' off;
          }
          margin: 32px 0;
        `}
      >
        Enrollment & Feedback <span>Actions</span>
      </Text>
      <Text
        label
        center
        className={css`
          color: #66737f;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Roboto;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px; /* 150% */
          letter-spacing: 0.15px;
        `}
      >
        This page has been designed to grab your employees' attention and guide them to enroll in their benefits. The
        page is automatically generated for any guide that has an enrollment URL.
      </Text>

      <div
        className={css`
          margin-top: 32px;
          padding-top: 32px;
          border-top: 1px solid #d1dae3;
          width: 100%;
          max-width: 700px;
        `}
      >
        <div
          className={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 16px;
            margin-bottom: 8px;
          `}
        >
          <div
            className={css`
              color: var(--700, #25282d);
              font-feature-settings: 'clig' off, 'liga' off;
              /* H4 */
              font-family: Roboto;
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: 28px; /* 155.556% */
              letter-spacing: 0.15px;
            `}
          >
            Enrollment Button
          </div>
          <div>
            <Toggle
              checked={course?.EnrollmentURL}
              onChange={(event) => {
                if (event.target.checked) {
                  saveCourse({
                    EnrollmentURL: 'https://',
                  });
                } else {
                  saveCourse({
                    EnrollmentURL: '',
                  });
                }
              }}
            />
          </div>
        </div>

        <div
          className={css`
            color: var(--500, #66737f);
            font-feature-settings: 'clig' off, 'liga' off;
            /* Body - Dark */
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            letter-spacing: 0.15px;
          `}
        >
          The "Enrollment" button serves as a prominent call to action for employees to access their benefits
          administration tool.
        </div>

        {course?.EnrollmentURL && (
          <>
            {!enrollment && (
              <div
                className={css`
                  color: var(--Red, #eb4e3d);
                  font-feature-settings: 'clig' off, 'liga' off;
                  /* Helper */
                  font-family: Roboto;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 16px; /* 133.333% */
                  letter-spacing: 0.15px;
                  margin-top: 8px;
                `}
              >
                This feature requires an enrollment URL
              </div>
            )}
            <div
              className={css`
                color: var(--700, #25282d);
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Roboto;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 24px; /* 171.429% */
                letter-spacing: 0.15px;
                margin-top: 16px;
                margin-bottom: 4px;
              `}
            >
              Enrollment URL
            </div>
            <div>
              <TextField
                error={!enrollment}
                style={{ flexGrow: '1', width: '100%' }}
                value={enrollment}
                onChange={(e) => setEnrollment(e.target.value)}
                onBlur={() => handleUpdate(enrollment, isEnrollmentSynced)}
                variant="outlined"
              />
            </div>
            <div
              className={css`
                margin-top: 4px;
                color: var(--500, #66737f);
                font-feature-settings: 'clig' off, 'liga' off;
                font-family: Roboto;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px; /* 133.333% */
                letter-spacing: 0.15px;
              `}
            >
              Add a direct link for your employees enroll in their benefits
            </div>
          </>
        )}

        <div
          className={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 16px;
            margin-bottom: 8px;
            margin-top: 32px;
          `}
        >
          <div
            className={css`
              color: var(--700, #25282d);
              font-feature-settings: 'clig' off, 'liga' off;
              /* H4 */
              font-family: Roboto;
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: 28px; /* 155.556% */
              letter-spacing: 0.15px;
            `}
          >
            Confidence to Elect Survey
          </div>
          <div>
            <Toggle
              checked={course?.ShowFeedbackPage}
              onChange={(event) => {
                saveCourse({
                  ShowFeedbackPage: event.target.checked,
                });
              }}
            />
          </div>
        </div>
        <div
          className={css`
            color: var(--500, #66737f);
            font-feature-settings: 'clig' off, 'liga' off;
            /* Body - Dark */
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            letter-spacing: 0.15px;
            margin-bottom: 32px;
          `}
        >
          This survey is an excellent tool to help you gauge the value of your guide for employees.
        </div>

        <div
          className={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 16px;
            margin-bottom: 8px;
            margin-top: 42px;
          `}
        >
          <div
            className={css`
              color: var(--700, #25282d);
              font-feature-settings: 'clig' off, 'liga' off;
              /* H4 */
              font-family: Roboto;
              font-size: 18px;
              font-style: normal;
              font-weight: 700;
              line-height: 28px; /* 155.556% */
              letter-spacing: 0.15px;
            `}
          >
            View this page in action
          </div>
          <div>
            <Button onClick={routeToCourse} secondary disabled={!course?.EnrollmentURL && !course?.ShowFeedbackPage}>
              Preview
            </Button>
          </div>
        </div>
        <div
          className={css`
            color: var(--500, #66737f);
            font-feature-settings: 'clig' off, 'liga' off;
            /* Body - Dark */
            font-family: Roboto;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 150% */
            letter-spacing: 0.15px;
            margin-bottom: 60px;
          `}
        >
          This page will be hidden if neither option is enabled.
        </div>
      </div>
    </Div>
  );
};
