import { CustomAxios } from '../../redux/axios/axios';

export const getTrafficSnapshots = async ({ dashboard, range }) => {
  const guideIDs = dashboard?.config?.guides?.map(({ ID }) => ID);
  const { startDate, endDate } = range;

  const { data } = await CustomAxios.post('/v2/analytics/aggregate', { guideIDs, startDate, endDate });
  return data;
};

export const getTotalVisitors = async ({ dashboard, timeInterval, range }) => {
  const guideIDs = dashboard?.config?.guides?.map(({ ID }) => ID);
  const { startDate, endDate } = range;

  const { data } = await CustomAxios.post('/v2/analytics/viewsByInterval', {
    guideIDs,
    timeInterval: timeInterval.value,
    startDate,
    endDate,
  });
  return data;
};

export const getTrafficSources = async ({ dashboard, range }) => {
  const guideIDs = dashboard?.config?.guides?.map(({ ID }) => ID);
  const { startDate, endDate } = range;

  const { data } = await CustomAxios.post('/v2/analytics/viewsBySource', {
    guideIDs,
    startDate,
    endDate,
  });
  return data;
};

export const getVisitorsBySource = async ({ guide, timeInterval, range }) => {
  const guideIDs = [guide.value];
  const { startDate, endDate } = range;

  const { data } = await CustomAxios.post('/v2/analytics/sourcesByInterval', {
    guideIDs,
    timeInterval: timeInterval.value,
    startDate,
    endDate,
  });
  return data;
};

export const getVisitorsByDevice = async ({ dashboard, range }) => {
  const guideIDs = dashboard?.config?.guides?.map(({ ID }) => ID);
  const { startDate, endDate } = range;

  const { data } = await CustomAxios.post('/v2/analytics/viewsByDevice', {
    guideIDs,
    startDate,
    endDate,
  });
  return data;
};

export const getTrafficByLocation = async ({ guide, range }) => {
  const guideIDs = [guide.value];
  const { startDate, endDate } = range;

  const { data } = await CustomAxios.post('/v2/analytics/viewsByLocation', {
    guideIDs,
    startDate,
    endDate,
  });
  return data;
};
