import React, { useEffect } from 'react';
import { css } from '@emotion/css';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth0 } from '../Auth/react-auth0-wrapper';
import { toast } from 'react-toastify';
import { Modal } from '../shared/components';

export const UnAuthedLoader = () => {
  const { logout } = useAuth0();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      toast.error(`Error loading content.`);
      logout({ returnTo: window.location.origin });
    }, 6000);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <Modal
      display
      full
      css={`
        font-weight: 400;
        color: white;
        background: #3a4684;
        overflow: auto;
      `}
    >
      <div
        style={{
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          height: '100vh',
          width: '100vw',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: 'white',
        }}
      >
        <div
          className={css`
            display: flex;
            justify-content: center;
            margin-top: 80px;
          `}
        >
          <CircularProgress />
        </div>
      </div>
    </Modal>
  );
};
