import { css } from '@emotion/css';
import { cloneDeep } from 'lodash';
import { TextField } from '@mui/material';
import { WarningCircle } from '@phosphor-icons/react';
import { CustomAxios } from '../../redux/axios/axios';
import { Button, Div, Modal, Text } from '../../shared/components';
import { colors } from '../../shared/styles';

export const EmployeeCountModal = ({ hideEmployeeCount, guide, eligibleEmployee, setEligibleEmployee }) => {
  const updateEligibleEmployees = async (employeeCount) => {
    try {
      let course = cloneDeep(guide);
      delete course.Pages;
      course.EligibleEmployees = Number(employeeCount) || 0;
      await CustomAxios.put(`/v2/course/${guide.ID}`, course);
    } catch (e) {}
  };
  return (
    <Modal display onClose={() => hideEmployeeCount()}>
      <Modal.Paper width="600px">
        <Modal.Header title="Number of Employees" onClose={() => hideEmployeeCount()} />
        <Modal.Body>
          <Div
            css={css`
              display: flex;
              padding: 8px;
              margin: 24px 0;
              border-radius: 8px;
              background: ${colors.gray[100]};
              gap: 16px;
            `}
          >
            <Div>
              <WarningCircle weight="fill" color={colors.orange[100]} />
            </Div>
            <Text body>
              Changing the number of employees can significantly impact the analytics data displayed. This change will
              only affect the current guide.
            </Text>
          </Div>
          <Div
            css={css`
              margin: 24px 0;
            `}
          >
            <TextField
              autoFocus
              className={css`
                width: 100%;
              `}
              id="outlined-basic"
              variant="outlined"
              label="Number of Employees"
              type="number"
              value={eligibleEmployee}
              onChange={(e) => {
                if (e.target.value <= 9999999) {
                  setEligibleEmployee(e.target.value);
                  updateEligibleEmployees(e.target.value);
                }
              }}
            />
          </Div>
        </Modal.Body>
        <Modal.Actions>
          <Button secondary onClick={() => hideEmployeeCount()}>
            Cancel
          </Button>
          <Button primary onClick={() => hideEmployeeCount()}>
            Update
          </Button>
        </Modal.Actions>
      </Modal.Paper>
    </Modal>
  );
};
