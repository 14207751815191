import { Check, Plus } from '@phosphor-icons/react';
import { useRequests, useResource } from '../../react-query';
import { subscribableUsers } from '../../react-query/resources/subscribable-users';
import { Box } from './box';
import { Dropdown } from './dropdown';
import { UserAvatar } from './UserAvatar';
import { colors } from '../styles';
import { Text } from './text';
import { knockSubscriptionByTypeList } from '../../react-query/resources/knock-subscription-by-type-list';
import { Input } from './input';
import { useState } from 'react';
import { memberRoleMapping } from '../../members/business-members';
import { Button } from './button';
import { useInviteUser } from '../../Dashboard/useInviteUser';
import { toast } from 'react-toastify';
import { useStore } from '../../store-provider/use-store';

export const Subscribers = ({ resourceType, resourceId }) => {
  const {
    data: { selectedBusiness },
  } = useStore();

  const [data, setData] = useState({ email: '', roleId: 'viewer' });

  const subscribers = useResource(subscribableUsers, {
    transform: (data) => data?.Data,
    search: {
      dni_children: true,
      include_parent_access: true,
    },
  });

  const subscribedToGuide = useResource(knockSubscriptionByTypeList, {
    transform: (data) => data?.Data?.map(({ ID }) => ID),
    params: {
      objectType: resourceType,
      objectId: resourceId,
    },
  });

  const { sendInvite, isLoading } = useInviteUser({
    onSuccess: () => {
      setData({ email: '', roleId: 'viewer' });
      subscribers?.query?.refetch();
      subscribedToGuide?.query?.refetch();
      toast.success('Sent user invite!');
    },
  });
  const handleInvite = () => sendInvite(selectedBusiness?.ID, data?.email, data?.roleId);

  const subscribedToGuideRequests = useRequests(subscribedToGuide);

  const handleSubscribe = (e, user) => {
    e.stopPropagation();
    if (subscribedToGuide?.data?.includes(user?.UserID)) {
      const deleteConfig = knockSubscriptionByTypeList?.utils?.getDelete(user?.UserID, subscribedToGuide?.params);
      subscribedToGuideRequests?.delete(deleteConfig);
    } else {
      const postConfig = knockSubscriptionByTypeList?.utils?.getPost(user?.UserID, subscribedToGuide?.params);
      subscribedToGuideRequests?.post(postConfig);
    }
  };

  return (
    <Dropdown
      listWidth="500px"
      listMaxHeight="60vh"
      css={`
        padding: 0px;
        border-radius: 50%;
        :hover {
          background-color: none;
        }
      `}
      listCss={`
        overflow-y: visible;
        padding: 0;
      `}
      popper={{
        placement: 'bottom-end',
      }}
      button={() => (
        <Box
          flex="left"
          css={`
            position: relative;
            cursor: pointer;
          `}
        >
          <UserAvatar size={32} />
          <Box
            flex="center"
            css={`
              position: absolute;
              top: calc(50% - 8px);
              left: -8px;
              height: 16px;
              width: 16px;
              outline: 2px solid white;
              border-radius: 50%;
              background-color: ${colors.black};
            `}
          >
            <Plus color="white" size={8} weight="bold" />
          </Box>
        </Box>
      )}
      renderMenu={() => (
        <>
          <Box
            css={`
              padding: 16px;
              border-bottom: 1px solid ${colors.gray[300]};
              background-color: white;
              position: sticky;
              top: 0;
            `}
          >
            <Text h4>Invite to "{selectedBusiness?.Name}"</Text>
          </Box>

          <Box
            css={`
              display: grid;
              gap: 8px;
              margin: 16px;
            `}
            onClick={(e) => e.stopPropagation()}
          >
            <Box>
              <Text helper>Once a user has been invited, you can subscribe them to this guide's notifications.</Text>
            </Box>
            <Box
              flex="left"
              css={`
                gap: 16px;
              `}
            >
              <Input
                css={`
                  flex-grow: 1;
                `}
                placeholder="Email"
                value={data?.email}
                onChange={(e) => setData({ ...data, email: e.target.value })}
                endIcon={
                  <Dropdown
                    button={(props) => (
                      <Dropdown.Select
                        css={`
                          border: none;
                          border-radius: 0;
                          padding: 0;
                        `}
                        {...props}
                        label={memberRoleMapping?.[data?.roleId]?.display}
                      />
                    )}
                  >
                    {Object.entries(memberRoleMapping)?.map(([key, role]) => (
                      <Box option onClick={() => setData({ ...data, roleId: key })}>
                        {role?.display}
                      </Box>
                    ))}
                  </Dropdown>
                }
              />
              <Button
                disabled={!data?.email || isLoading}
                css={`
                  text-transform: none;
                `}
                onClick={handleInvite}
              >
                Invite
              </Button>
            </Box>
          </Box>
          <Box
            css={`
              padding-top: 8px;
              padding-bottom: 16px;
            `}
          >
            {subscribers?.data?.map((item) => (
              <Box
                key={item?.UserID}
                flex="left"
                hover
                css={`
                  padding: 8px;
                  margin: 0 8px;
                  border-radius: 8px;
                  :hover {
                    .hover-check {
                      ${subscribedToGuide?.data?.includes(item?.UserID) ? '' : `border: 1px solid ${colors.gray[500]};`}
                    }
                  }
                  svg {
                    min-width: 24px;
                  }
                `}
                onClick={(e) => handleSubscribe(e, item)}
              >
                <UserAvatar user={item} />
                <Box
                  css={`
                    width: calc(100% - 100px);
                    margin-left: 16px;
                  `}
                >
                  <Text ellipsis label>
                    {item?.FirstName} {item?.LastName}
                  </Text>
                  <Text ellipsis>{item?.Email}</Text>
                </Box>
                <Box
                  flex="center"
                  className="hover-check"
                  css={`
                    min-width: 24px;
                    width: 24px;
                    height: 24px;
                    margin-left: 16px;
                    border-radius: 8px;
                    ${subscribedToGuide?.data?.includes(item?.UserID)
                      ? `
                background-color: ${colors.black};
                border: 1px solid ${colors.black};
              `
                      : ''}
                  `}
                >
                  {subscribedToGuide?.data?.includes(item?.UserID) ? (
                    <Check color="white" size={16} weight="bold" />
                  ) : null}
                </Box>
              </Box>
            ))}
          </Box>
        </>
      )}
    />
  );
};
