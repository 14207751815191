import React, { useEffect } from 'react';
import { Conditional } from '../../utility/Conditional';
import { useContent } from '../../providers/content/use-content';
import { isEqual } from 'lodash';
import { updateContent } from './text-helpers';
import { Segment } from '../../providers/segment/Segment';
import { Modify } from '../../providers/content/content-modify';
import { convertToHtml } from '../../../BriteEditor/editor-components/text-v2/slate-utils';
import { useSessionId } from '../../utility/useSessionId';
import { useLifecycle } from '../../../shared/useLifecycle';
import { usePropertyManager } from '../../providers/property-manager/PropertyManager';

export const TextUpdatesManager = ({ block, text, slate }) => {
  return (
    <>
      <Conditional if={block?.isSelected}>
        <SendUpdates block={block} text={text} slate={slate} />
      </Conditional>
      <Conditional if={!block?.isSelected}>
        <ReceiveUpdates block={block} text={text} />
      </Conditional>
    </>
  );
};

export const ReceiveUpdates = ({ block, text }) => {
  const properties = Segment.getProperties(block.segment);
  const { slateContent } = properties;

  const acceptUpdate = () => {
    const hasChanges = !isEqual(text.slateProps.editor.children, slateContent);
    if (hasChanges) {
      updateContent(text.slateProps.editor, slateContent);
    }
  };

  useEffect(() => acceptUpdate(), [slateContent]);

  return null;
};

export const SendUpdates = ({ block, text }) => {
  const sessionId = useSessionId('text-block');
  const { local } = useContent();

  const { setProperties } = usePropertyManager();

  const update = () => {
    const setter = local.createBatch(sessionId);
    Modify.properties(setter, block.id, (properties) => ({
      ...properties,
      content: convertToHtml(text.slateProps.editor.children, { liveSmartFields: text.liveSmartFields }),
      slateContent: text.slateProps.editor.children,
    }));
  };

  const send = () => {
    const hasChanges = !isEqual(text.slateProps.editor.children, text.slateProps.initialValue);
    if (hasChanges) {
      local.sendBatch();
    } else {
      local.destroyBatch(sessionId);
    }
  };

  useEffect(() => setProperties(text.slateProps), [text.slateProps]);

  useEffect(() => {
    return () => {
      send();
      setProperties({});
    };
  }, []);

  useLifecycle(
    {
      onSubsequent: update,
    },
    [text.at]
  );

  return null;
};
