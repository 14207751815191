import { memo, useEffect, useMemo, useRef } from 'react';
import { Box, Button, Text } from '../../shared/components';
import { colors } from '../../shared/styles';
import { Row } from './Row';
import { Segment } from '../providers/segment/Segment';
import { DotsThree, SelectionPlus } from '@phosphor-icons/react';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import { ToolKit } from '../providers/tool-kit/ToolKit';
import { useSegment } from '../providers/segment/use-segment';
import { useDroppable } from '../utility/useDroppable';
import { Droppable } from '../utility/Droppable';
import { DROP_CREATE_BLOCK, DROP_CREATE_SECTION, DROP_MOVE_BLOCK, DROP_MOVE_SECTION } from '../utility/constants';
import { useStore } from '../../store-provider/use-store';
import { Conditional } from '../utility/Conditional';
import { getStyles, sectionCss } from './section-helpers';
import { animation } from '../../shared/shared-styles';

const containers = {
  row: Row,
};

export const Section = memo(({ section, root }) => {
  const {
    data: { devMode },
  } = useStore();

  const sectionRef = useRef();
  const sectionRefBackground = useRef();
  const toolkit = useToolKit();
  const segment = useSegment();

  const isGrouped = section?.properties?.isGrouped;
  const hasSelection = ToolKit.hasSelection(toolkit);
  const isSelected = Segment.getIsSelected(segment, toolkit);
  const childCount = section?.children?.length;
  const scrollTo = ToolKit.getScrollTo(toolkit);

  const viewMode = ToolKit.getViewMode(toolkit);
  const drag = ToolKit.getDrag(toolkit);

  const styles = useMemo(() => {
    return getStyles(root, section?.properties, viewMode);
  }, [root, section?.properties, viewMode]);

  useEffect(() => {
    if (scrollTo?.id === segment?.id) {
      sectionRef?.current?.scrollIntoView({ behavior: 'instant', block: 'center' });
    }
  }, [scrollTo?.updatedAt]);

  const sectionProperties = (event) => {
    if (!childCount) {
      return;
    }
    const isDirectClick =
      !event ||
      event?.type === 'click' ||
      event?.target === event?.currentTarget ||
      event?.target === sectionRefBackground?.current;

    if (isDirectClick && isGrouped) {
      ToolKit.select(toolkit, {
        scope: 'section',
        type: 'section',
        id: segment.id,
      });
      ToolKit.setDisplaySettings(toolkit, true);
    }
  };

  const handleDrag = (event) => {
    event.preventDefault();
    const isDirectClick =
      !event || event?.target === event?.currentTarget || event?.target === sectionRefBackground?.current;
    if (isDirectClick) {
      ToolKit.startDrag(toolkit, {
        toolType: 'section',
        sourceId: segment.id,
        type: DROP_MOVE_SECTION,
      });
    }
  };

  const openContextMenu = (event) => {
    const isDirectClick =
      !event ||
      event?.type === 'click' ||
      event?.target === event?.currentTarget ||
      event?.target === sectionRefBackground?.current;
    if (isDirectClick && isGrouped) {
      event.preventDefault();
      const editor = Segment.getElement(segment, 'editor');
      ToolKit.openContextMenu(toolkit, {
        event,
        editor,
        context: {
          id: segment?.id,
          toolType: 'section',
          scope: 'section',
        },
      });
      ToolKit.select(toolkit, { id: section?.id, scope: 'section' });
    }
  };

  const dropSectionTypes = [DROP_MOVE_SECTION, DROP_CREATE_SECTION, DROP_CREATE_BLOCK, DROP_MOVE_BLOCK];
  const isDropEnabled = drag?.isDragging && dropSectionTypes?.includes(drag?.type);
  const isDraggingDisabled = drag?.isDragging && !dropSectionTypes?.includes(drag?.type);
  const droppable = useDroppable({
    key: 'section',
    disabled: !isDropEnabled,
    activeRects: {
      top: [0, -1, 100, 50],
      bottom: [0, 50, 100, 101],
    },
  });

  const canDrag = !hasSelection && isGrouped && drag?.isAvailable && !toolkit.readOnly;

  const isDraggingSource = drag?.isDragging && drag?.sourceId === segment.id;

  return (
    <Box
      ref={sectionRef}
      flex="center"
      css={sectionCss({
        section,
        isSelected,
        childCount,
        isDropEnabled,
        isDraggingSource,
        isDraggingDisabled,
        devMode,
        showSections: isGrouped,
        readOnly: toolkit.readOnly,
      })}
      style={styles?.container}
      data-scope="section"
      onContextMenu={openContextMenu}
      onDoubleClick={sectionProperties}
      onDragStart={handleDrag}
      draggable={canDrag}
      {...droppable.attributes}
    >
      {/* Animate background color */}
      {childCount > 0 ? (
        <Box
          css={`
            position: absolute;
            top: 0;
            bottom: 0;
            margin: 0 auto;
            z-index: 0;
            pointer-events: none;
          `}
          data-section-background={`${section?.id}-section-background`}
          className="section-background"
          style={styles?.background}
          ref={sectionRefBackground}
        />
      ) : null}
      {/* HERE ^^^^^ */}

      <Box style={styles?.section} className="section" data-id={section?.id} data-selector-type="section">
        {!section?.children?.length ? (
          <EmptySection section={section} />
        ) : (
          section?.children?.map((child, index) => {
            const Child = containers[child.type];
            return (
              <Segment key={child?.id} element={sectionRef} scope="section" index={index} {...child}>
                <Child />
              </Segment>
            );
          })
        )}
      </Box>
      <Conditional if={isGrouped && childCount > 0 && !toolkit.readOnly}>
        <Box
          css={`
            position: absolute;
            ${isSelected ? `display: block;` : ''}
            top: 0;
            right: 0;
            z-index: 1000;
            margin: 4px;
            ${animation('fadeIn', '.2s ease')}
          `}
          className="hover-settings"
        >
          <Button
            data-scope="menu"
            styles="icon-dark sm"
            css={`
              width: 24px;
              height: 24px;
              background-color: ${colors.gray[600]};
              padding: 0;
            `}
            onClick={sectionProperties}
          >
            <DotsThree size={24} color="white" weight="bold" />
          </Button>
        </Box>
      </Conditional>
      <Droppable {...droppable} />
    </Box>
  );
});

export const EmptySection = ({ section }) => {
  const segment = useSegment();
  const toolkit = useToolKit();

  const openSectionMenu = () => ToolKit.setModal(toolkit, { type: 'sections-menu', data: section });

  const openContextMenu = (event) => {
    event.preventDefault();
    const editor = Segment.getElement(segment, 'editor');
    ToolKit.openContextMenu(toolkit, {
      event,
      editor,
      context: {
        id: segment?.id,
        toolType: 'section',
        scope: 'section',
      },
    });
  };

  return (
    <Box
      css={`
        width: 100%;
        padding: 32px;
        margin: 16px 0;
        gap: 16px;
        border-radius: 8px;
        transition: background-color 0.2s ease;
        :hover {
          cursor: pointer;
          background-color: ${colors.gray[200]};
        }
      `}
      flex="center"
      onClick={openSectionMenu}
      onContextMenu={openContextMenu}
    >
      <SelectionPlus size={64} color={colors.gray[400]} />
      <Box>
        <Text h4>Empty Section</Text>
        <Text label>Click here to add a saved section</Text>
      </Box>
    </Box>
  );
};
