import { useModalWithData } from '../../../../common/hooks/useModalWithData';
import { Box, Button, Text } from '../../../../shared/components';
import { colors } from '../../../../shared/styles';
import { Modify, safelySpread } from '../../../providers/content/content-modify';
import { useContent } from '../../../providers/content/use-content';
import { EditProductSelection } from '../EditProductSelection';

export const PricingToolbar = ({ id, productId, isSmartField, sync }) => {
  const { sendUpdates } = useContent();
  const modal = useModalWithData(false, '');

  const update = () => {
    Modify.properties(sendUpdates, id, () => ({ ...sync?.value }));
    modal.hide();
  };

  const updateStyle = (type) => {
    Modify.properties(sendUpdates, id, (properties) => ({
      data: {
        ...safelySpread(properties?.data),
        variantStyle: type,
      },
    }));
  };

  const hasProduct = !!sync?.value?.data?.id;
  const buttonLabel = hasProduct ? 'Change' : 'Select Product';
  return (
    <>
      <Box
        flex="left"
        css={`
          gap: 8px;
        `}
      >
        <Button editor onClick={() => modal.show('edit')}>
          {buttonLabel}
        </Button>
        {hasProduct ? (
          <Box
            flex="space-around"
            css={`
              position: relative;
              border-radius: 30px;
              background-color: ${colors.black};
              padding: 4px 8px;
              gap: 16px;
              cursor: pointer;
              > * {
                width: 50%;
                color: white;
                text-align: center;
                font-weight: bold;
                z-index: 1;
                padding: 4px;
              }
              ::after {
                content: '';
                position: absolute;
                top: 2px;
                bottom: 2px;
                width: 50%;
                border-radius: 30px;
                left: 0;
                transition: transform 0.2s ease;
                ${sync?.value?.data?.variantStyle === 'clean'
                  ? 'transform: translateX(2px);'
                  : 'left: -2px; transform: translateX(100%);'}
                background-color: ${colors.gray[500]};
              }
            `}
          >
            <Text onClick={() => updateStyle('clean')}>Clean</Text>
            <Text onClick={() => updateStyle('bold')}>Bold</Text>
          </Box>
        ) : null}
      </Box>

      {modal.data === 'edit' ? (
        <EditProductSelection
          title="Pricing"
          blockType="pricing"
          update={update}
          onClose={modal.hide}
          sync={sync}
          productId={productId}
          isSmartField={isSmartField}
        />
      ) : null}
    </>
  );
};
