import { Box, Modal } from '../../shared/components';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import { ToolKit } from '../providers/tool-kit/ToolKit';
import { Content } from '../providers/content/Content';
import { useContent } from '../providers/content/use-content';
import { scrollbar } from '../../shared/shared-styles';
import { colors } from '../../shared/styles';

export const ViewBlock = ({ onClose }) => {
  const { content } = useContent({ ignoreSelection: true });
  const toolkit = useToolKit();
  const { id } = ToolKit.getModalData(toolkit);
  const data = Content.get(content, id);
  return (
    <Modal display onClose={onClose}>
      <Modal.Paper width="700px">
        <Modal.Header onClose={onClose} title="Block Content" />
        <Modal.Body>
          <Box
            css={`
              height: 50vh;
              overflow: auto;
              padding: 16px;
              ${scrollbar.hide}
              background-color: ${colors.gray[100]};
              border-radius: 8px;
            `}
          >
            <pre>{JSON.stringify(data, null, 2)}</pre>
          </Box>
        </Modal.Body>
      </Modal.Paper>
    </Modal>
  );
};
