import { Box, Text, Toggle } from '../../../shared/components';
import { Property } from './property';

export const StrokePaddingSetting = (strokePadding) => {
  return (
    <Property>
      <Box flex="space-between">
        <Text label>Stroke Padding</Text>
        <Toggle checked={strokePadding.value} onChange={(e) => strokePadding?.setValue(e.target.checked)} />
      </Box>
    </Property>
  );
};
