import { Check, MagnifyingGlass } from '@phosphor-icons/react';
import { useState, useRef, useMemo } from 'react';
import { useQueryAPI } from '../react-query';
import { Box, Dropdown, Input, Text } from '../shared/components';
import { container, flex, scrollbar } from '../shared/shared-styles';
import { colors } from '../shared/styles';
import { useDebounceValue } from '../shared/use-debounce-value';
import { useStore } from '../store-provider/use-store';
import { useKeyboard } from '../Router/use-keyboard';

export const CompanySelect = ({
  selectedBusiness: businessOverride = null,
  selectBusiness,
  defaultBusinessList = [],
  css: cssString = '',
  listHeight = '45vh',
}) => {
  const {
    data: { selectedBusiness: currentSelectedBusiness },
  } = useStore();
  const selectedBusiness = businessOverride || currentSelectedBusiness;

  const [search, setSearch] = useState('');
  const [searchValue] = useDebounceValue(search, 200);

  const { data } = useQueryAPI({
    url: `v2/search/business?search=${searchValue}&limit=40&offset=0`,
    enabled: !!searchValue,
  });

  const [index, setIndex] = useState(0);
  const inputRef = useRef();

  const defaultListDep = defaultBusinessList.map(({ ID }) => ID).toString();

  const handleSelect = (selected) => {
    selectBusiness({ option: selected });
  };

  const list = useMemo(() => {
    if (!data?.data?.length) {
      const sortedParents = defaultBusinessList?.reduce(
        (prev, item) => ({
          ...prev,
          [item?.ParentID]:
            item?.ParentID === item?.ID
              ? [item, ...(prev?.[item?.ParentID] || [])]
              : [...(prev?.[item?.ParentID] || []), item],
        }),
        {}
      );
      return Object.values(sortedParents)?.reduce((prev, item) => [...prev, ...item], []);
    }
    return data?.data;
  }, [data?.data?.length, defaultListDep]);

  const keydown = (captured, event) => {
    if (captured === 'meta+KeyE') {
      event?.stopPropagation();
    } else if (captured === '+ArrowDown') {
      setIndex((idx) => {
        let index = idx + 1;
        return index >= list?.length ? 0 : index;
      });
    } else if (captured === '+ArrowUp') {
      setIndex((idx) => {
        let index = idx - 1;
        return index <= -1 ? list?.length - 1 : index;
      });
    } else if (captured === '+Enter') {
      handleSelect(list?.[index]);
    }
  };

  useKeyboard({ keydown });

  const dropdownWidth = '300px';

  return (
    <Dropdown
      css={`
        padding: 0px;
        width: ${dropdownWidth};

        p {
          font-weight: 700;
          font-size: 18px;
        }
        ${cssString}
      `}
      button={(props) => (
        <Dropdown.Select
          {...props}
          label={selectedBusiness?.Name}
          css={`
            padding: 16px;
          `}
        />
      )}
      listWidth={dropdownWidth}
      listMaxHeight={listHeight}
      listCss={`
          max-height: none; 
          overflow: hidden;
        `}
      onClose={() => {
        setSearch('');
        setIndex(0);
      }}
    >
      <Input
        ref={inputRef}
        css={`
          position: sticky;
          top: 0;
          margin: 0 8px;
        `}
        autoFocus
        placeholder="Search Businesses"
        startIcon={<MagnifyingGlass color={colors.gray[400]} />}
        value={search}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => setSearch(e.target.value)}
      />
      <Box
        css={`
          max-height: ${listHeight};
          overflow-y: auto;
          margin-top: 8px;
          padding-right: 8px;
          ${scrollbar.style};
        `}
      >
        {list?.map((business, idx) => {
          const isParent = business?.ID === business?.ParentID;
          return (
            <Box
              key={business?.ID}
              css={`
                ${flex('space-between')}
                ${container.hover}
                    ${idx === index ? `background-color: ${colors.gray[100]};` : ''}
                    border-radius: 8px;
                ${isParent ? `padding: 8px` : `padding: 4px 8px;`};
              `}
              onClick={() => handleSelect(business)}
              onMouseOver={() => setIndex(idx)}
            >
              <Box
                css={`
                  flex-grow: 1;
                  padding: 8px;
                  max-width: calc(100% - 32px);
                `}
              >
                <Text
                  label
                  ellipsis
                  css={`
                    flex-grow: 1;
                    max-width: 100%;
                    padding: 0;
                    margin: 0;
                    ${isParent && `font-weight: 600;`}
                  `}
                >
                  {business?.Name}
                </Text>
                {!isParent ? (
                  <Text
                    caption
                    css={`
                      padding: 0;
                      margin: 0;
                    `}
                  >
                    {business?.ParentName}
                  </Text>
                ) : null}
              </Box>
              {business?.ID === selectedBusiness?.ID ? <Check color={colors.purple} size={24} /> : null}
            </Box>
          );
        })}
      </Box>
    </Dropdown>
  );
};
