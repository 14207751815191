import { FirstAidKit } from '@phosphor-icons/react';
import { Box, Text } from '../../../shared/components';
import { colors } from '../../../shared/styles';
import { TOOL_TYPE_MAP } from '../../utility/constants';
import { animation } from '../../../shared/shared-styles';

export const EmptySmartBenefit = ({ type }) => {
  return (
    <Box
      css={`
        ${animation('fadeIn', '.2s ease')}
        width: 100%;
        background-color: ${colors.gray[600]};
        border-radius: 16px;
        padding: 32px 0;
        margin: 0 auto;
        gap: 8px;
        p {
          color: white;
        }
      `}
      flex="center column"
    >
      <FirstAidKit size={64} color="white" />
      <Text bold>{TOOL_TYPE_MAP[type]}</Text>
      <Text>Click to select a benefit product</Text>
    </Box>
  );
};
