import { Circle } from '@phosphor-icons/react';
import { LegendOrdinal } from '@visx/legend';
import { Box, Text } from '../../../shared/components';

export const ChartLegend = ({ scale }) => {
  return (
    <LegendOrdinal scale={scale}>
      {(labels) => (
        <Box
          css={`
            display: flex;
            flex-direction: row;
            gap: 24px;
          `}
        >
          {labels.map((label) => {
            return (
              <Box
                css={`
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  gap: 8px;
                `}
                key={label.text}
              >
                <Circle color={label.value} size={16} weight="fill" />
                <Text
                  label
                  css={`
                    text-transform: capitalize;
                    font-size: 14px;
                    font-weight: 700;
                  `}
                >
                  {label.text}
                </Text>
              </Box>
            );
          })}
        </Box>
      )}
    </LegendOrdinal>
  );
};
