import { Box, Icon, Input, Text, Toggle } from '../../../shared/components';
import { selectableBox } from '../../utility/settings/styles';
import { ColorSetting } from '../Properties/ColorSetting';
import { PaddingSetting } from '../Properties/PaddingSetting';
import { RadiusSetting } from '../Properties/RadiusSetting';
import {
  getAddLineProperties,
  getLineColorProperties,
  getLineStyleProperties,
  getLineWeightProperties,
  getPaddingProperties,
  getRadiusProperties,
} from './property-utils';
import { ReactComponent as LineWeight } from '../../../images/svg/line-weight.svg';
import { colors } from '../../../shared/styles';
import { PropertiesGrouping } from './PropertiesGrouping';

export const DividerSettings = (settingProps) => {
  const padding = getPaddingProperties({ ...settingProps });
  const radius = getRadiusProperties({ ...settingProps, type: 'container' });
  const addLine = getAddLineProperties(settingProps);
  const lineColor = getLineColorProperties(settingProps);
  const lineWeight = getLineWeightProperties(settingProps);
  const lineStyle = getLineStyleProperties(settingProps);

  return (
    <Box>
      <PropertiesGrouping label="Cell Styles" defaultOpen>
        <RadiusSetting {...radius} />
        <PaddingSetting {...padding} />
      </PropertiesGrouping>

      <PropertiesGrouping label="Line Styles">
        <Box flex="space-between">
          <Text label bold>
            Add Line
          </Text>
          <Toggle checked={addLine.value} onChange={(e) => addLine.setValue(e.target.checked)} />
        </Box>

        <Box
          css={`
            display: grid;
            gap: 24px;
            transition: opacity 0.2s ease;
            ${addLine.value ? 'opacity: 1;' : 'opacity: .5;'}
          `}
        >
          <Box
            css={`
              display: grid;
              gap: 8px;
            `}
          >
            <Text label>Line Color</Text>
            <ColorSetting {...lineColor} />
          </Box>

          <Box
            css={`
              display: grid;
              gap: 8px;
            `}
          >
            <Text label>Line Weight</Text>
            <Input
              startIcon={<Icon SVG={LineWeight} color={colors.black} />}
              value={lineWeight?.value}
              onChange={(e) => lineWeight?.setValue(e.target.value)}
            />
          </Box>

          <Box
            css={`
              display: grid;
              gap: 8px;
            `}
          >
            <Text label>Line Style</Text>
            <Box
              css={`
                transition: opacity 0.2s ease;
                ${addLine.value ? 'opacity: 1;' : 'opacity: .5;'}
              `}
            >
              <Box
                flex="space-between"
                css={`
                  gap: 8px;
                `}
              >
                <Box
                  css={selectableBox(lineStyle?.value === 'solid')}
                  flex="center"
                  onClick={() => lineStyle?.setValue('solid')}
                >
                  <Box
                    css={`
                      border-top: 3px solid var(--accent-color-0);
                      height: 3px;
                      width: 100%;
                      max-width: 20px;
                    `}
                  />
                </Box>

                <Box
                  css={selectableBox(lineStyle?.value === 'dashed')}
                  flex="center"
                  onClick={() => lineStyle?.setValue('dashed')}
                >
                  <Box
                    css={`
                      border-top: 3px dashed var(--accent-color-0);
                      height: 3px;
                      width: 100%;
                      max-width: 20px;
                    `}
                  />
                </Box>

                <Box
                  css={selectableBox(lineStyle?.value === 'dotted')}
                  flex="center"
                  onClick={() => lineStyle?.setValue('dotted')}
                >
                  <Box
                    css={`
                      border-top: 4px dotted var(--accent-color-0);
                      height: 4px;
                      width: 100%;
                      max-width: 20px;
                    `}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </PropertiesGrouping>
    </Box>
  );
};
