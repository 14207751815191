import { Box, Icon, Input, Text } from '../../../shared/components';
import { selectableBox } from '../../utility/settings/styles';
import { Property } from './property';
import { useEffect, useState } from 'react';
import { Collapse } from '../../../common/components/Collapse';
import { ReactComponent as Padding } from '../../../images/svg/padding.svg';
import { AlignBottomSimple, AlignLeftSimple, AlignRightSimple, AlignTopSimple, Prohibit } from '@phosphor-icons/react';
import { colors } from '../../../shared/styles';
import { getNumber } from '../Settings/property-utils';
import { container } from '../../../shared/shared-styles';

const getCurrentType = (value) => {
  const sizeList = Object.values(value || {});
  const sizeListString = sizeList.toString();

  if (sizeListString === '8px,8px,8px,8px') {
    return 'small';
  } else if (sizeListString === '24px,24px,24px,24px') {
    return 'medium';
  } else if (sizeListString === '40px,40px,40px,40px') {
    return 'large';
  }

  return 'custom';
};

const sizeMap = {
  small: '8px',
  medium: '24px',
  large: '40px',
};

export const PaddingSetting = ({ value, setValue, disabled }) => {
  const [customize, setCustomize] = useState(false);
  const currentType = getCurrentType(value);

  useEffect(() => {
    if (currentType === 'custom') {
      // add timeout for a smooth animation effect
      setTimeout(() => {
        setCustomize(true);
      }, 300);
    }
  }, [currentType]);

  const setPresetValue = (type) => {
    setCustomize(false);
    setValue('all', sizeMap[type]);
  };

  const updateCustomValue = (key, value) => {
    const newValue = `${value}px`;
    setValue(key, newValue);
  };

  const clearPadding = () => setValue('all', '0px');

  const canClearPadding = Object.values(value || {}).some((v) => v !== '0px');

  return (
    <Property disabled={disabled}>
      <Text label>Padding</Text>
      <Box
        flex="stretch"
        css={`
          gap: 8px;
        `}
      >
        <Box
          flex="center"
          css={selectableBox(!customize && currentType === 'small')}
          onClick={() => setPresetValue('small')}
        >
          <Text h4>S</Text>
        </Box>
        <Box
          flex="center"
          css={selectableBox(!customize && currentType === 'medium')}
          onClick={() => setPresetValue('medium')}
        >
          <Text h4>M</Text>
        </Box>
        <Box
          flex="center"
          css={selectableBox(!customize && currentType === 'large')}
          onClick={() => setPresetValue('large')}
        >
          <Text h4>L</Text>
        </Box>
        <Box flex="center" css={selectableBox(customize)} onClick={() => setCustomize(true)}>
          <Icon SVG={Padding} size={24} color="currentColor" />
        </Box>
      </Box>
      <Collapse isOpen={customize}>
        <Box
          css={`
            display: grid;
            gap: 8px;
            input {
              height: 32px;
            }
          `}
        >
          <Box
            flex="space-between"
            css={`
              padding: 8px 16px;
              margin: 4px -8px;
              border-radius: 8px;
              gap: 16px;
              ${container.hover}
              ${canClearPadding
                ? ''
                : `
                opacity: .5;
                pointer-events: none;  
              `}
            `}
            onClick={clearPadding}
          >
            <Text label>Clear Padding</Text>
            <Prohibit size={24} weight="bold" />
          </Box>
          <Box
            flex="space-around"
            css={`
              gap: 8px;
            `}
          >
            <Input
              value={getNumber(value?.paddingLeft)}
              onChange={(e) => updateCustomValue('paddingLeft', e.target.value)}
              startIcon={<AlignLeftSimple size={16} color={colors.gray[400]} weight="bold" />}
            />
            <Input
              value={getNumber(value?.paddingRight)}
              onChange={(e) => updateCustomValue('paddingRight', e.target.value)}
              startIcon={<AlignRightSimple size={16} color={colors.gray[400]} weight="bold" />}
            />
          </Box>
          <Box
            flex="space-around"
            css={`
              gap: 8px;
            `}
          >
            <Input
              value={getNumber(value?.paddingTop)}
              onChange={(e) => updateCustomValue('paddingTop', e.target.value)}
              startIcon={<AlignTopSimple size={16} color={colors.gray[400]} weight="bold" />}
            />
            <Input
              value={getNumber(value?.paddingBottom)}
              onChange={(e) => updateCustomValue('paddingBottom', e.target.value)}
              startIcon={<AlignBottomSimple size={16} color={colors.gray[400]} weight="bold" />}
            />
          </Box>
        </Box>
      </Collapse>
    </Property>
  );
};
