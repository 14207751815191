import { Desktop, DeviceMobileCamera } from '@phosphor-icons/react';
import { Box, Button } from '../../shared/components';
import { shadows } from '../../shared/shared-styles';
import { ToolKit } from '../providers/tool-kit/ToolKit';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';

export const ViewOptions = () => {
  const toolkit = useToolKit();
  const viewMode = ToolKit.getViewMode(toolkit);
  const setViewMode = (mode) => ToolKit.setViewMode(toolkit, mode);

  return (
    <Box
      css={`
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 10000;
        pointer-events: none;
      `}
    >
      <Box
        flex="left"
        css={`
          pointer-events: all;
          background-color: white;
          margin: 32px 56px;
          padding: 8px;
          gap: 8px;
          border-radius: 8px;
          ${shadows.md}
        `}
      >
        <Button styles={viewMode === 'desktop' ? 'icon-active sm' : 'icon sm'} onClick={() => setViewMode('desktop')}>
          <Desktop size={24} />
        </Button>
        <Button styles={viewMode === 'mobile' ? 'icon-active sm' : 'icon sm'} onClick={() => setViewMode('mobile')}>
          <DeviceMobileCamera size={24} />
        </Button>
      </Box>
    </Box>
  );
};
