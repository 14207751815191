import { Editable, ReactEditor, Slate, useSlate } from 'slate-react';
import { Box } from '../box';
import { css, cx } from '@emotion/css';
import { Text, textTheme } from '../text';
import { SlateTextboxContext, SlateTextboxProvider } from './SlateCommentProvider';
import { forwardRef, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useResource } from '../../../react-query';
import { subscribableUsers } from '../../../react-query/resources/subscribable-users';
import { colors } from '../../styles';
import { useKeyboard } from '../../../Router/use-keyboard';
import { insertNode } from '../../../BriteEditor/editor-components/text-v2/slate-utils';
import { Editor, insertText, Transforms } from 'slate';
import { getMentionName, removeNode } from './utils';
import { useFuse } from '../../useFuse';
import { shadows } from '../../shared-styles';
import { UserAvatar } from '../UserAvatar';

const slateContainer = css`
  ${textTheme?.label}
  cursor: text;
  padding: 0;
  outline: none;
  p,
  span {
    margin: 0;
  }
`;

export const EditableSlate = ({ autoFocus, ...rest }) => {
  const editor = useSlate();
  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        ReactEditor.focus(editor);
      }, 10);
    }
  }, [autoFocus]);
  return <Editable {...rest} />;
};

export const SlateComment = forwardRef(
  ({ css: cssString = '', slateComment, mention = {}, placeholder = '', autoFocus = false }, ref) => {
    return (
      <Box css={cssString} ref={ref}>
        <SlateTextboxProvider>
          <Slate {...slateComment?.slateProps}>
            <EditableSlate
              {...slateComment?.editorProps}
              placeholder={placeholder}
              className={cx(slateContainer, 'slate-editable')}
              autoFocus={autoFocus}
            />
            <MentionSearch {...mention} />
          </Slate>
        </SlateTextboxProvider>
      </Box>
    );
  }
);

export const MentionSearch = ({ width }) => {
  const editor = useSlate();
  const scrollRef = useRef();
  const { state } = useContext(SlateTextboxContext);

  const usersResource = useResource(subscribableUsers, {
    transform: (data) => data?.Data,
    search: {
      dni_children: true,
      include_parent_access: true,
    },
  });

  const fuse = useFuse(
    usersResource?.data,
    {
      keys: ['FirstName', 'LastName', 'Email'],
    },
    [usersResource?.query?.dataUpdatedAt]
  );

  const [index, setIndex] = useState(0);

  const value = state?.searchValue?.replace('@', '');

  const list = useMemo(() => {
    const searchValue = value?.toLowerCase();
    if (searchValue === '') {
      return usersResource?.data;
    }

    const results = fuse?.search(searchValue);
    return results?.map((r) => r?.item);
  }, [usersResource?.data?.length, value]);

  useEffect(() => {
    setIndex(0);
  }, [value]);

  const selectUser = (user) => {
    const node = removeNode(
      editor,
      (n) => !Editor.isBlock(editor, n) && n.type === 'mention' && n.data.mentionId === state?.id
    );
    if (user?.UserID) {
      const text = getMentionName(user);
      insertNode(editor, {
        ...node,
        children: [{ text }],
        data: {
          ...node?.data,
          user,
        },
      });
      const after = Editor.after(editor, editor?.selection);
      Transforms.select(editor, {
        anchor: after,
        focus: after,
      });
      insertText(editor, ' ');
    }
  };

  const keydown = (captured, event) => {
    if (captured === '+ArrowDown') {
      event.preventDefault();
      event.stopPropagation();
      setIndex((index) => {
        const next = index + 1;
        if (next >= list?.length) {
          return 0;
        }
        return next;
      });
    } else if (captured === '+ArrowUp') {
      event.preventDefault();
      event.stopPropagation();
      setIndex((index) => {
        const next = index - 1;
        if (next < 0) {
          return list.length - 1;
        }
        return next;
      });
    } else if (captured === '+Enter' && state?.isSelected) {
      event.stopPropagation();
      event.preventDefault();
      const user = list[index];
      selectUser(user);
    }
  };

  useKeyboard({ keydown, options: { useCapture: true } });

  useEffect(() => {
    if (scrollRef?.current) {
      scrollRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'nearest',
      });
    }
  }, [scrollRef?.current]);

  if (state?.isSelected && list?.length) {
    return (
      <Box
        css={`
          position: absolute;
          top: calc(100% + 16px);
          left: calc(50% - ${width / 2}px);
          width: ${width}px;
          background-color: white;
          border-radius: 8px;
          padding: 8px;
          max-height: 25vh;
          overflow-y: auto;
          overflow-x: hidden;
          z-index: 100;
          ${shadows.md}
        `}
        scrollbar
      >
        {list?.map((item, idx) => (
          <Box
            flex="left"
            hover
            css={`
              padding: 8px;
              border-radius: 8px;
              ${index === idx
                ? `
                background-color: ${colors.gray[100]};
              `
                : ''}
            `}
            ref={index === idx ? scrollRef : null}
            onClick={() => selectUser(item)}
          >
            <UserAvatar user={item} size={32} />
            <Box
              css={`
                margin-left: 16px;
                flex-grow: 1;
                max-width: calc(100% - 48px);
                p {
                  font-size: 0.95em;
                }
              `}
            >
              {item?.FirstName || item?.LastName ? (
                <Text label ellipsis>
                  {item?.FirstName} {item?.LastName}
                </Text>
              ) : null}
              <Text ellipsis>{item?.Email}</Text>
            </Box>
          </Box>
        ))}
      </Box>
    );
  }

  return null;
};
