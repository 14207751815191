import { MapPin } from '@phosphor-icons/react';
import { Box, Button, Text } from '../../../../shared/components';
import { container } from '../../../../shared/shared-styles';
import { useNetworkBlock } from './useNetworkBlock';
import { Toolbar } from '../../../utility/Toolbar';
import { NetworkToolbar } from './NetworkToolbar';
import { EmptySmartBenefit } from '../EmptySmartBenefit';
import { ErrorSmartBenefit } from '../ErrorSmartBenefit';

export const NetworkBlock = () => {
  const block = useNetworkBlock();

  const { ProductNetworkName, ProductNetworkDescription, ProductNetworkProviderSearchUrl, Details } =
    block?.product || {};

  const {
    IsMultiCarrier,
    MultiCarrierNetworkName,
    MultiCarrierNetworkCarrierSearchUrl,
    NetworkName,
    NetworkDescription,
    NetworkProviderSearchUrl,
  } = Details || {};

  const openSearch = (url) => window.open(url, '_blank');

  return (
    <>
      {!block?.sync?.value?.data?.id ? (
        <EmptySmartBenefit type="network" />
      ) : !block?.product?.ID ? (
        <ErrorSmartBenefit type="network" />
      ) : (
        <Box
          css={`
            ${container.box}
            width: 100%;
            margin: 8px;
            padding: 24px 32px;
          `}
        >
          <Box flex="space-between">
            <Text
              css={`
                width: min-content;
                border-radius: 30px;
                background-color: var(--accent-color-4);
                color: var(--accent-color-1);
                padding: 4px 16px;
                margin-right: 16px;
                font-weight: bold;
              `}
            >
              Network
            </Text>
            {IsMultiCarrier ? (
              <Text label bold>
                {ProductNetworkName || NetworkName ? ProductNetworkName || NetworkName : ''}
                {MultiCarrierNetworkName ? ` | ${MultiCarrierNetworkName}` : ''}
              </Text>
            ) : ProductNetworkName || NetworkName ? (
              <Text label bold>
                {ProductNetworkName || NetworkName}
              </Text>
            ) : null}
          </Box>
          <Box
            css={`
              padding-top: 32px;
            `}
          >
            <Box
              flex="left"
              css={`
                p {
                  margin-left: 16px;
                }
              `}
            >
              <MapPin />
              <Text h2>Search for network providers</Text>
            </Box>
            <Text
              css={`
                margin: 16px 0;
              `}
            >
              {!IsMultiCarrier ? ProductNetworkDescription || NetworkDescription : ''}
            </Text>
            {!IsMultiCarrier && (
              <Button
                onClick={() => {
                  openSearch(ProductNetworkProviderSearchUrl || NetworkProviderSearchUrl);
                }}
                css={`
                  background-color: var(--accent-color-1);
                `}
              >
                Search Network
              </Button>
            )}
            {IsMultiCarrier && (
              <Box
                css={`
                  display: flex;
                  gap: 16px;
                  flex-wrap: wrap;
                `}
              >
                <Button
                  onClick={() => {
                    openSearch(ProductNetworkProviderSearchUrl || NetworkProviderSearchUrl);
                  }}
                  css={`
                    background-color: var(--accent-color-1);
                  `}
                >
                  Search {ProductNetworkName || NetworkName}
                </Button>
                <Button
                  onClick={() => {
                    openSearch(MultiCarrierNetworkCarrierSearchUrl);
                  }}
                  css={`
                    background-color: var(--accent-color-1);
                  `}
                >
                  Search {MultiCarrierNetworkName}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      )}
      <Toolbar isSelected={block?.isSelected} anchor={block?.anchor}>
        <NetworkToolbar
          id={block?.id}
          sync={block?.sync}
          productId={block?.productId}
          isSmartField={block?.isSmartField}
        />
      </Toolbar>
    </>
  );
};
