import { toast } from 'react-toastify';
import { guide as GUIDE_USE_RESOURCE, useRequests, useResource } from '../../../react-query';
import { Button, Modal, Text } from '../../../shared/components';
import { colors } from '../../../shared/styles';

export const RemoveLinkModal = ({ hide = () => {}, hideLaunchGuide = () => {}, guide = {} }) => {
  const guideResource = useResource(GUIDE_USE_RESOURCE, { queryKey: ['editor', 'guide-lease'] });
  const guideRequests = useRequests(guideResource);

  const removeLink = async () => {
    const payload = {
      VanityURL: '',
      Passcode: '',
    };

    try {
      const putGuide = GUIDE_USE_RESOURCE?.utils?.getPut(guide.ID, {
        ...guideResource?.query?.data,
        ...payload,
      });
      await guideRequests.put(putGuide);
      hide();
      hideLaunchGuide();
    } catch (err) {
      toast.error(`Error removing link...`);
    }
  };

  return (
    <Modal display onClose={() => hide()}>
      <Modal.Paper width="500px">
        <Modal.Header title="Are you sure you want to remove this link?" onClose={() => hide()} />
        <Modal.Body>
          <Text body>
            Anyone who tries to use this link will no longer have access to this guide with or without assigned
            passcodes.
          </Text>
        </Modal.Body>
        <Modal.Actions>
          <Button secondary onClick={() => hide()}>
            Cancel
          </Button>
          <Button primary bgColor={colors.red[100]} onClick={() => removeLink()}>
            Remove
          </Button>
        </Modal.Actions>
      </Modal.Paper>
    </Modal>
  );
};
