import { cloneElement, useState } from 'react';
import { usePopper } from 'react-popper';
import { colors } from '../../../shared/styles';
import { Portal } from '../../../shared/components/Portal';
import { Box } from '../../../shared/components';
import { useEvent } from '../../../shared/use-event';

export const ChartTooltip = ({ children, renderTooltip = <></> }) => {
  // State
  const [anchor, setAnchor] = useState(null);
  const [tooltip, setTooltip] = useState(null);
  const [open, setOpen] = useState(false);

  // Popper
  const { styles, attributes } = usePopper(anchor, tooltip, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 5], // Horizontal (x-axis) offset is 0, vertical (y-axis) offset is 5px
        },
      },
    ],
  });

  useEvent('mouseenter', () => setOpen(true), { element: anchor });
  useEvent('mouseleave', () => setOpen(false), { element: anchor });

  return (
    <>
      {cloneElement(children, { ref: setAnchor })}
      {open ? (
        <Portal>
          <Box
            ref={setTooltip}
            css={`
              background-color: #ffffff;
              border: 1px solid ${colors.gray[300]};
              border-radius: 8px;
              box-shadow: 0px 2px 2px 0px rgba(191, 191, 191, 0.25);
              padding: 8px 16px;
              pointer-events: none;
              z-index: 2000;
            `}
            style={styles.popper}
            {...attributes.popper}
          >
            {renderTooltip()}
          </Box>
        </Portal>
      ) : null}
    </>
  );
};
