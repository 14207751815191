import * as uuid from 'uuid';
import { getAction, insertAction, removeEmptyActions, updateAction } from './action-utils';
import { addChild, findAdjacentChildIndex, getChildFromAction } from './child-utils';
import { createRowAction } from './row-utils';
import { createColumnAction } from './column-utils';
import { compileCompositionActions } from './composition-utils';

export const createSectionAction = (index, children = []) => {
  const id = uuid.v4();
  const section = {
    id,
    type: 'section',
    properties: {},
    children,
  };
  const action = {
    at: index,
    id,
    old: null,
    data: section,
    updates: section,
    property: 'sections',
    type: 'insert',
  };
  return action;
};

export const addBlockToAdjacentSection = ({ composition, edge, destination, source }) => {
  const content = composition?._content;

  const index = findAdjacentChildIndex(content.sections, {
    edge,
    id: destination?.id,
  });

  const sectionAction = createSectionAction(index);
  insertAction(composition, sectionAction);

  // Create new row
  const row = createRowAction(sectionAction.id, []);
  insertAction(composition, row);

  // Create new column with child
  const child = getChildFromAction(source);
  const column = createColumnAction(row.id, [child]);
  updateAction(composition, source.id, 'update', { parentId: column.id });
  insertAction(composition, column);

  // Add column to row
  const columnAsChild = getChildFromAction(column);
  const rowChildren = addChild(columnAsChild, row.data.children, { edge, id: destination.data.id });
  updateAction(composition, row.id, 'update', { children: rowChildren });

  const rowAsChild = getChildFromAction(row);
  const children = [rowAsChild];
  updateAction(composition, sectionAction.id, 'update', { children });
};

export const moveSection = ({ composition, source, destination, edge }) => {
  const content = composition._content;
  const old = structuredClone(content.sections);
  const section = content.sections.find((section) => section.id === source.id);
  const sections = content.sections.filter((section) => section.id !== source.id);
  const index = findAdjacentChildIndex(sections, { id: destination.id, edge });
  sections.splice(index, 0, section);
  const action = { type: 'set-all', property: 'sections', updates: sections, old };
  return [action];
};

export const createSection = ({ composition, drag }) => {
  let index = 0;
  if (drag?.destinationId) {
    const destination = getAction(composition, drag?.destinationId);
    index = findAdjacentChildIndex(composition?._content.sections, { id: destination.id, edge: drag?.edge });
  }
  const action = createSectionAction(index);
  insertAction(composition, action);
  updateAction(composition, action?.id, 'update', { properties: { isGrouped: true } });
  removeEmptyActions(composition);
  const actions = compileCompositionActions(composition);
  return actions;
};
