import { Text } from '../../../shared/components';
import { ColorPicker } from '../../utility/settings/ColorPicker';
import { Property } from './property';

export const ColorSetting = ({ label, value, setValue, disabled }) => {
  return (
    <Property disabled={disabled}>
      <Text label>{label}</Text>
      <ColorPicker color={value} update={setValue} label={label} inline={false} />
    </Property>
  );
};
