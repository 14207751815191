import { useEffect } from 'react';
import { useEditorResource } from '../../BriteEditor/use-editor-resource';
import { useContent } from '../providers/content/use-content';
import { ToolKit } from '../providers/tool-kit/ToolKit';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import { getCommentsByBlock } from './editor-utils';

export const useCommentsByBlock = () => {
  const { content } = useContent({ ignoreSelection: true });
  const toolkit = useToolKit();
  const comments = useEditorResource('comments');
  const page = ToolKit.getMetadata(toolkit, 'page');

  useEffect(() => {
    const commentsByComponent = getCommentsByBlock(page?.data?.ID, content, comments?.data);
    ToolKit.setCommentsByBlock(toolkit, commentsByComponent);
  }, [content, comments?.dataUpdatedAt]);
};
