import React, { useRef, useState, useEffect } from 'react';
import { useThemedProps } from '../../shared/useThemedProps';

export const Collapse = ({ isOpen, timeout = 300, children, ...rest }) => {
  const collapseProps = useThemedProps(rest, {});

  const [height, setHeight] = useState(isOpen ? 'auto' : 0);
  const [overflow, setOverflow] = useState(isOpen ? 'visible' : 'hidden');
  const [hasMounted, setHasMounted] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    if (!hasMounted) {
      setHasMounted(true);
      return;
    }
    if (isOpen) {
      // When expanding, first set the actual height, then set it to 'auto' after animation
      const contentHeight = contentRef.current.scrollHeight;
      setHeight(contentHeight + 'px');

      const timer = setTimeout(() => {
        setHeight('auto');
        setOverflow('visible');
      }, timeout);

      return () => clearTimeout(timer);
    } else {
      // When collapsing, first set height to the actual height, then to 0 for collapsing animation
      const contentHeight = contentRef.current.scrollHeight;
      setHeight(contentHeight + 'px');
      setOverflow('hidden');

      setTimeout(() => {
        setHeight(0);
      }, 10);
    }
  }, [isOpen, timeout]);

  return (
    <div
      ref={contentRef}
      {...collapseProps}
      style={{
        height,
        overflow,
        transition: `height ${timeout}ms ease, margin-bottom .2s ease`,
        marginBottom: overflow === 'hidden' ? '-6px' : 0,
      }}
    >
      {children}
    </div>
  );
};
