import React, { useState, useMemo } from 'react';
import { format } from 'date-fns';
import { ColumnChart } from 'react-chartkick';
import { useQueryAPI } from '../react-query/query-api';
import { Checkbox, CircularProgress, TextField, Typography } from '@mui/material';
import { css } from '@emotion/css';
import { stringToMutedColorHex } from '../Notifications/components/ActorIcons';
import { Box } from '../shared/components/box';

const colorBox = (color) => css`
  width: 16px;
  height: 16px;
  background-color: ${color};
  margin: 8px;
`;

const getSetByKey = (list, key) => (list ? [...new Set(list.map((item) => item[key]))] : []);

const config = {
  company: {
    url: '/v1/analytics/aggregatedcourseviews',
    idKey: 'BusinessID',
    nameKey: 'BusinessName',
  },
  course: {
    url: 'v1/analytics/courseviews',
    idKey: 'CourseID',
    nameKey: 'CourseName',
  },
};

export const ViewsChart = ({ type, guides = [], selectCourse }) => {
  const [chartData, setChartData] = useState({});
  const [data, setData] = useState({});
  const [filter, setFilter] = useState('');

  // Add a full day to each month (e.g. 08-01-2022 becomes 08-02-2022) so that regardless of timezone, the correct month is displayed.
  const fullDay = 24 * 60 * 60 * 1000;
  const formatDate = function (date) {
    return format(new Date(Date.parse(date) + fullDay), 'MMM yyyy');
  };

  const handleData = (views) => {
    if (views.Data && views.Data.length) {
      const months = getSetByKey(views.Data, 'Month');

      const defaultValues = months.reduce((p, month) => ({ ...p, [formatDate(month)]: 0 }), {});

      const values = views.Data.reduce(
        (prev, item) => ({
          ...prev,
          [item[config[type].idKey]]: {
            ...defaultValues,
            ...(prev[item[config[type].idKey]] || {}),
            [formatDate(item.Month)]: item.ViewsCount,
            name: item[config[type].nameKey],
            id: item[config[type].idKey],
          },
        }),
        {}
      );

      const chartFormat = Object.values(values).reduce((prev, item) => {
        const { name, id, ...data } = item;
        const totalCount = Object.values(data).reduce((p, i) => p + i, 0);
        return [
          ...prev,
          {
            totalCount,
            name,
            data,
            id,
            color: stringToMutedColorHex(new Date().toISOString()),
          },
        ];
      }, []);

      const sorted = chartFormat.sort((a, b) => {
        return b.totalCount - a.totalCount;
      });

      const selected = sorted.slice(0, 3);

      setData(sorted);
      setChartData(selected);
    }
  };

  const { isLoading } = useQueryAPI({
    url: config[type].url,
    onMount: handleData,
    defaultValue: { Data: [] },
  });

  const handleSelect = (event, item) => {
    event.stopPropagation();
    setChartData((list) => {
      if (event.target.checked) {
        return [...list, item];
      }
      return list.filter(({ id }) => id !== item.id);
    });
  };

  const filteredList = useMemo(() => {
    if (filter) {
      return data.filter(({ name }) => name.toLowerCase().includes(filter.toLowerCase()));
    }
    return data;
  }, [filter, data.length]);

  return (
    <div>
      {isLoading ? (
        <div
          className={css`
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          <CircularProgress />
        </div>
      ) : (
        <div
          className={css`
            max-width: 600px;
            width: 100%;
            margin: 16px auto;
          `}
        >
          <ColumnChart data={chartData} legend={false} />
          <Typography style={{ textAlign: 'center', textTransform: 'capitalize' }}>{type} View Count</Typography>
          <TextField
            variant="outlined"
            placeholder="Search"
            style={{ width: '100%', margin: '8px 0' }}
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
          {!!filteredList.length &&
            filteredList.map((item) => (
              <Box
                className={css`
                  margin: 16px 0;
                  cursor: ${type === 'course' ? 'pointer' : ''};
                  &:hover {
                    background-color: ${type === 'course' ? '#e8edf3' : 'transparent'};
                  }
                `}
                key={item.id}
                onClick={(e) => {
                  if (type !== 'course') {
                    return;
                  }
                  for (let guide of guides) {
                    if (guide.ID === item.id) {
                      selectCourse(guide);
                    }
                  }
                }}
              >
                <div
                  className={css`
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin: 8px;
                  `}
                >
                  <div
                    className={css`
                      display: flex;
                      align-items: center;
                    `}
                  >
                    <div className={colorBox(item.color)} />
                    <Typography>{item.name}</Typography>
                  </div>
                  <div
                    className={css`
                      display: flex;
                      align-items: center;
                    `}
                  >
                    <Typography>{item.totalCount} Views</Typography>
                    <Checkbox
                      onClick={(e) => handleSelect(e, item)}
                      color="primary"
                      checked={chartData.findIndex(({ id }) => id === item.id) > -1}
                    />
                  </div>
                </div>
              </Box>
            ))}
        </div>
      )}
    </div>
  );
};
