import { css } from '@emotion/css';
import { Tooltip } from '../../../common/components/Tooltip';
import { Box } from '../../../shared/components';
import { Toolbar } from '../../utility/Toolbar';
import { ButtonToolbar } from './ButtonToolbar';
import { useHandleAction } from './useActions';
import { useButtonBlock } from './useButtonBlock';

const buttonContainer = `
  max-width: 100%;
  button {
    width: auto;
    max-width: 100%;
    display: inline-block;
    word-wrap: break-word;
    white-space: normal;
    outline: none;
    border: none;
    background: none;
    appearance: none;
    text-align: center;
    padding: 0;
    margin: 0;
    overflow: hidden;
    resize: none;
    transition: padding .2s ease, font-size .2s ease;
  }
`;

export const ButtonBlock = () => {
  const block = useButtonBlock();
  const handleAction = useHandleAction();
  return (
    <>
      <Box css={buttonContainer}>
        <Tooltip label={block?.isSelected ? block?.properties?.action?.displayName : ''}>
          <button
            className={
              block?.isSelected && block?.properties?.action
                ? css`
                    :hover {
                      filter: brightness(1.3);
                    }
                  `
                : ''
            }
            style={block?.style}
            onClick={handleAction}
          >
            {block?.sync?.value?.content}
          </button>
        </Tooltip>
      </Box>
      <Toolbar isSelected={block?.isSelected} anchor={block?.anchor}>
        <ButtonToolbar id={block?.id} sync={block?.sync} buttonSize={block?.buttonSize} />
      </Toolbar>
    </>
  );
};
