import { css, cx } from '@emotion/css';
import { Children, useState } from 'react';
import { animation, container } from '../shared-styles';
import { useOutsideClick } from '../use-outside-click';
import { Div } from './div';
import { Text } from './text';
import { Box } from './box';
import { Portal } from './Portal';
import { usePopper } from 'react-popper';
import { useLifecycle } from '../useLifecycle';
import { useThemedProps } from '../useThemedProps';
import { CaretUp } from '@phosphor-icons/react';
import { colors } from '../styles';

const dropdownTheme = {
  default: `
    position: relative;
    background-color: white;
    border-radius: 8px;
    padding: 8px;
  `,
  clear: `
    padding: 0;
    background: none;
  `,
  disabled: `
    opacity: .6;
    pointer-events: none;
  `,
};

const transformOriginMap = {
  top: 'bottom center',
  'top-start': 'bottom left',
  'top-end': 'bottom right',
  bottom: 'top center',
  'bottom-start': 'top left',
  'bottom-end': 'top right',
  left: 'center right',
  'left-start': 'top right',
  'left-end': 'bottom right',
  right: 'center left',
  'right-start': 'top left',
  'right-end': 'bottom left',
};

export const Dropdown = ({
  button,
  // Render either children or renderMenu
  children,
  renderMenu = null,
  //
  isInitiallyOpen = false,
  // TODO: Remove stopPropagation prop and fix
  //       all DropdownMenu's using stopPropagation
  stopPropagation = false,
  css: cssString,
  flex = '',
  outsideClickProps = {},
  listWidth,
  listMaxHeight = '40vh',
  listCss = '',
  onClose = null,
  clear = false,
  // react-popper props
  popper: popperProps = {},
  disabled = false,
  ...rest
}) => {
  const hasChildren = Children.count(children) > 0;
  const hasRenderMenu = renderMenu !== null;
  if (!hasRenderMenu && !hasChildren) {
    throw new Error('Dropdown requires children or renderMenu prop');
  }

  const [open, setOpen] = useState(isInitiallyOpen);
  const ref = useOutsideClick((event) => {
    const dropdownList = event.target.closest('[data-dropdown-list]');
    if (!dropdownList || (!!dropdownList && !hasRenderMenu)) {
      setOpen(false);
    }
  }, outsideClickProps);
  const [anchor, setAnchor] = useState(null);
  const [popper, setPopper] = useState(null);
  const { styles, attributes } = usePopper(anchor, popper, popperProps);

  const props = useThemedProps({ flex, css: cssString, disabled, clear, ...rest }, dropdownTheme);

  useLifecycle(
    {
      onSubsequent: () => {
        if (!open) {
          onClose?.();
        }
      },
    },
    [open]
  );

  const renderChildren = renderMenu === null ? children : renderMenu({ open, setOpen });

  return (
    <Div {...props} ref={setAnchor} onClick={() => setOpen(!hasRenderMenu ? !open : true)}>
      {button({ open })}
      {open ? (
        <Portal>
          <div ref={ref}>
            <Box
              css={`
                z-index: 1000;
              `}
              ref={setPopper}
              style={styles.popper}
              {...attributes.popper}
            >
              <Box
                scrollbar
                data-dropdown-list
                className={cx(
                  css`
                    ${container.box}
                    transform-origin: ${transformOriginMap[popperProps.placement] || 'top center'};
                    ${animation('grow', '.2s ease')}
                    padding: 8px;
                    margin-top: 8px;
                    overflow: auto;
                    overflow-x: hidden;
                    max-height: ${listMaxHeight};
                    min-width: ${listWidth};
                    border-radius: 8px;
                    ::-webkit-scrollbar-track {
                      border-radius: 50%;
                    }
                    ${listCss}
                  `,
                  'dropdown-list'
                )}
                onMouseUp={(e) => {
                  if (stopPropagation) {
                    e.stopPropagation();
                  }
                }}
                onClick={(e) => {
                  if (stopPropagation) {
                    e.stopPropagation();
                  }
                }}
              >
                {renderChildren}
              </Box>
            </Box>
          </div>
        </Portal>
      ) : null}
    </Div>
  );
};

Dropdown.Select = ({ label = '', open, css: cssString, ...rest }) => (
  <Box
    css={`
      border-radius: 8px;
      border: 1px solid ${colors.gray[300]};
      padding: 8px;
      ${container.hover}
      ${cssString}
      .caretUp {
        display: inline-block;
        transition: transform 0.3s ease;
        transform: rotate(${open ? '0deg' : '180deg'});
      }
    `}
    flex="space-between"
    {...rest}
  >
    <Text
      label
      ellipsis
      css={`
        flex-grow: 1;
      `}
    >
      {label}
    </Text>
    <CaretUp size={24} color="currentColor" className="caretUp" />
  </Box>
);

Dropdown.Item = ({ children, onClick, css: cssStyle, className }) => {
  return (
    <Div
      flex="left"
      css={cx(
        css`
          gap: 8px;
          padding: 8px;
          border-radius: 8px;
          ${container.hover}
        `,
        css`
          ${cssStyle}
        `
      )}
      className={className}
      onClick={onClick}
    >
      <Text label>{children}</Text>
    </Div>
  );
};
