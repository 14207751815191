import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Box } from '../../../shared/components';
import { getEngagementSnapshots } from '../../api/engagement';
import { useDashboard } from '../../context/DashboardContext';
import { SnapshotCard } from '../SnapshotCard';

export const EngagementSnapshots = () => {
  // Routing
  const { businessId } = useParams();

  // Context
  const { dashboard, range } = useDashboard();

  // Queries
  const { data: snapshots, isLoading: isLoadingSnapshots } = useQuery(['engagement-snapshots', businessId, range], () =>
    getEngagementSnapshots({ dashboard, range })
  );

  return (
    <Box
      css={`
        display: flex;
        align-items: center;
        gap: 8px;
        width: 100%;
      `}
    >
      <SnapshotCard
        isLoading={isLoadingSnapshots}
        title="Total Page Views"
        renderContent={<SnapshotCard.Number value={snapshots?.TotalPageViews} />}
      />
      <SnapshotCard
        isLoading={isLoadingSnapshots}
        title="Average Pages Per Visitor"
        renderContent={<SnapshotCard.Number value={Math.round(snapshots?.AveragePagesPerVisitor)} />}
      />
      <SnapshotCard
        isLoading={isLoadingSnapshots}
        title="Average Time Per Visitor"
        renderContent={<SnapshotCard.Time value={snapshots?.AverageTimePerVisitorSeconds} />}
      />
    </Box>
  );
};
