import { Warning } from '@phosphor-icons/react';
import { Box, Button, Modal, Text } from '../shared/components';
import { colors } from '../shared/styles';
import { CustomAxios } from '../redux/axios/axios';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { fulfilledPromises } from '../react-query/promise-manager';

export const UpdateToV2Modal = ({ pages, refetchAll, onClose }) => {
  const [isUpdating, setIsUpdating] = useState(false);
  const update = async () => {
    setIsUpdating(true);
    let promises = {};
    try {
      const promiseObject = pages?.query?.data?.reduce((acc, page) => {
        const url = `v2/course/${page?.CourseID}/pages/${page?.ID}/migrate`;
        const promise = CustomAxios.post(url);
        return { ...acc, [page?.ID]: promise };
      }, {});
      promises = await fulfilledPromises('allSettled', promiseObject);
      setIsUpdating(false);
      refetchAll();
    } catch (err) {
      setIsUpdating(false);
      toast.error('Error updating the pages...');
      console.log(promises);
    } finally {
      onClose();
    }
  };

  return (
    <Modal display onClose={onClose}>
      <Modal.Paper>
        <Modal.Header title="Update Available" onClose={onClose} />
        <Modal.Body>
          <Text label>The updated editor comes with some great new features and improvements we think you’ll love</Text>
          <Box
            flex="left"
            css={`
              border-radius: 8px;
              background-color: ${colors.gray[100]};
              padding: 16px;
              gap: 16px;
              margin: 16px 0;
              svg {
                min-width: 32px;
              }
            `}
          >
            <Warning color={colors.orange[100]} size={32} />
            <Text label>Once you update, you won’t be able to switch back to the previous version</Text>
          </Box>
        </Modal.Body>
        <Modal.Actions>
          <Button secondary onClick={onClose}>
            Cancel
          </Button>
          <Button purple onClick={update} disabled={isUpdating}>
            Update
          </Button>
        </Modal.Actions>
      </Modal.Paper>
    </Modal>
  );
};
