import { useParams } from 'react-router';
import { useSearchParams } from '../../../shared/use-search-params';
import { useKnock } from '../../../Notifications/useKnock';
import { useStore } from '../../../store-provider/use-store';
import { useAcls } from '../../../shared/use-acls';
import { useEffect, useMemo, useState } from 'react';
import { WRITE_COURSE } from '../../../shared/acl-constants';
import { useEditorResource } from '../../../BriteEditor/use-editor-resource';
import * as uuid from 'uuid';
import { convertSlateToComment, subscribeMentions } from '../../../shared/components/slate-text/utils';
import { CustomAxios } from '../../../redux/axios/axios';
import { ToolKit } from '../../providers/tool-kit/ToolKit';
import { useToolKit } from '../../providers/tool-kit/use-tool-kit';
import { useOptimistically } from '../../../react-query/use-resource';
import { useContent } from '../../providers/content/use-content';
import { Content } from '../../providers/content/Content';

export const sortComments = (content, list) => {
  // Sort comments by component position
  return list.sort((a, b) => {
    const aId = a?.metadata?.componentId;
    const bId = b?.metadata?.componentId;
    const aPosition = Content.getPositionValue(content, aId);
    const bPosition = Content.getPositionValue(content, bId);
    return aPosition - bPosition;
  });
};

export const useComments = () => {
  const { businessId, courseId } = useParams();
  const {
    params: { pageId },
  } = useSearchParams();

  const { content } = useContent({ ignoreSelection: true });
  const toolkit = useToolKit();

  const [filters, setFilters] = useState(['unresolved']);

  const { data } = useStore();

  const userId = data?.user?.ID;

  const comments = useEditorResource('comments', { enabled: true });
  const optimistically = useOptimistically(comments?.queryKey);

  const knock = useKnock();

  useEffect(() => {
    comments?.refetch();
  }, [knock?.updatedAt]);

  const canWrite = useAcls([WRITE_COURSE]) || data?.isBriteEmployee;

  const { threads, resolved, replies } = useMemo(() => {
    const list = comments?.data?.filter((item) => item?.metadata?.pageId === pageId);

    return sortComments(content, list)?.reduce(
      (prev, item) => {
        if (item?.metadata?.threadId) {
          return {
            ...prev,
            replies: {
              ...prev?.replies,
              [item?.metadata?.threadId]: [...(prev?.replies?.[item?.metadata?.threadId] || []), item],
            },
          };
        }
        if (item?.resolved) {
          return {
            ...prev,
            resolved: [...prev?.resolved, item],
          };
        } else {
          return {
            ...prev,
            threads: [...prev?.threads, item],
          };
        }
      },
      { threads: [], resolved: [], replies: {} }
    );
  }, [filters?.toString(), knock?.updatedAt, comments?.data?.length, comments?.dataUpdatedAt, pageId]);

  const getThreadById = (threadId) => {
    return threads?.find((item) => item?.id === threadId);
  };

  const submitReply = async ({ threadId, slateContent }) => {
    const thread = getThreadById(threadId);
    const componentId = thread?.metadata?.componentId;
    const replyData = convertSlateToComment(slateContent);
    try {
      subscribeMentions('guide', courseId, replyData?.mentions);
      const body = {
        id: uuid.v4(),
        business_id: businessId,
        note: replyData?.preview,
        created_by_user_id: data?.user?.ID,
        resource_id: courseId,
        resource_type: 'guide',
        metadata: {
          pageId,
          threadId,
          componentId,
          username: data?.user?.FirstName || data?.user?.Email,
          content: replyData?.content,
          contentSerializer: replyData?.serializer,
        },
      };
      optimistically.update((data) => [...data, body]);
      await CustomAxios.post(`/v1/bp/needs_attention?business_id=eq.${businessId}`, body, {
        headers: {
          'Accept-Profile': 'brite',
          'Content-Profile': 'brite',
          Prefer: 'return=representation',
        },
      });
    } catch (err) {
      optimistically.revert();
      console.log(err);
    } finally {
      comments?.refetch();
    }
  };

  const createRequest = async (body) => {
    try {
      optimistically.update((data) => [...data, body]);
      await CustomAxios.post(`/v1/bp/needs_attention?business_id=eq.${businessId}`, body, {
        headers: {
          'Accept-Profile': 'brite',
          'Content-Profile': 'brite',
          Prefer: 'return=representation',
        },
      });
    } catch (err) {
      optimistically.revert();
      console.log(err);
    } finally {
      comments?.refetch();
    }
  };

  const create = ({ blockId, slateContent }) => {
    const slate = convertSlateToComment(slateContent);
    try {
      subscribeMentions('guide', courseId, slate?.mentions);
      const body = {
        id: uuid.v4(),
        business_id: businessId,
        note: slate?.preview,
        created_by_user_id: data?.user?.ID,
        resource_id: courseId,
        resource_type: 'guide',
        metadata: {
          pageId,
          componentId: blockId,
          username: data?.user?.FirstName || data?.user?.Email,
          content: slate?.content,
          contentSerializer: slate?.serializer,
        },
      };
      createRequest(body);
      return body;
    } catch (err) {
      console.log(err);
    }
  };

  const resolve = async (thread) => {
    try {
      const list = [thread, ...(replies?.[thread?.id] || [])];
      const ids = list?.map((item) => item?.id);
      const bodyList = list.map((item) => ({ ...item, resolved: !thread?.resolved }));
      optimistically.update((comments) => {
        return comments?.map((item) => {
          if (ids?.includes(item?.id)) {
            return { ...item, resolved: !thread?.resolved };
          }
          return item;
        });
      });
      await Promise.allSettled(
        bodyList?.map((item) => {
          return CustomAxios.patch(`/v1/bp/needs_attention?id=eq.${item?.id}&business_id=eq.${businessId}`, item, {
            headers: {
              'Accept-Profile': 'brite',
              'Content-Profile': 'brite',
            },
          });
        })
      );
    } catch (err) {
      optimistically.revert();
      console.warn(err);
    } finally {
      comments?.refetch();
    }
  };

  const remove = async (thread) => {
    ToolKit.resetComment(toolkit);
    try {
      const list = [thread, ...(replies?.[thread?.id] || [])];
      const ids = list?.map((item) => item?.id);
      optimistically.update((data) => data?.filter((item) => !ids?.includes(item?.id)));
      await Promise.allSettled(
        list?.map((item) => {
          return CustomAxios.delete(`/v1/bp/needs_attention?id=eq.${item?.id}&business_id=eq.${businessId}`, {
            headers: {
              'Accept-Profile': 'brite',
              'Content-Profile': 'brite',
            },
          });
        })
      );
    } catch (err) {
      optimistically.revert();
      console.warn(err);
    } finally {
      comments?.refetch();
    }
  };

  return {
    canWrite,
    threads,
    resolved,
    replies,
    userId,
    submitReply,
    resolve,
    create,
    remove,
    filters,
    setFilters,
  };
};
