import {
  addDays,
  eachDayOfInterval,
  eachWeekendOfMonth,
  format,
  getMonth,
  isBefore,
  isSameDay,
  isSaturday,
  setMonth,
  subDays,
} from 'date-fns';
import { useForm } from '../use-form';
import { Box } from './box';
import { Text, textTheme } from './text';
import { colors } from '../styles';
import { useEffect, useMemo, useRef } from 'react';
import { extractNumbers } from '../../benefit-package/auto-paster-v2/paste-utils';
import { Button } from './button';
import { CaretLeft, CaretRight } from '@phosphor-icons/react';
import { useStateSync } from '../use-state-sync';
import { useDebounceValue } from '../use-debounce-value';

const Range = ({
  start,
  end,
  setRange,
  autoSetEnd = null,
  maxEndDate = null,
  minEndDate = null,
  maxStartDate = null,
  minStartDate = null,
  css: cssString = '',
}) => {
  const isInitial = useRef(true);
  const form = useForm({ start, end }, [start, end]);
  const setStart = (date) => form.merge({ start: date });
  const setEnd = (date) => form.merge({ end: date });

  const [hasDelayedChanges] = useDebounceValue(form?.hasChanges, 500, [form?.hasChanges]);

  useEffect(() => {
    if (form.hasChanges) {
      setRange(form.values);
    }
  }, [hasDelayedChanges]);

  useEffect(() => {
    if (isInitial.current) {
      isInitial.current = false;
    } else if (autoSetEnd && form?.hasChanges) {
      setEnd(autoSetEnd(form.values.start));
    }
  }, [form.values.start]);

  useEffect(() => {
    if (isBefore(form.values.end, form.values.start)) {
      setStart(form.values.end);
    }
  }, [form.values.end]);

  return (
    <Box css={cssString} flex="left">
      <Box
        css={`
          flex-grow: 1;
          margin-right: 32px;
        `}
      >
        <Text label bold>
          Start Date
        </Text>
        <Input date={form.values.start} setDate={setStart} />
        <Calendar
          css={`
            margin-top: 32px;
          `}
          size="medium"
          date={form.values.start}
          setDate={setStart}
          maxDate={maxStartDate}
          minDate={minStartDate}
        />
      </Box>
      <Box
        css={`
          flex-grow: 1;
        `}
      >
        <Text label bold>
          End Date
        </Text>
        <Input date={form.values.end} setDate={setEnd} />
        <Calendar
          css={`
            margin-top: 32px;
          `}
          size="medium"
          date={form.values.end}
          setDate={setEnd}
          maxDate={maxEndDate}
          minDate={minEndDate}
        />
      </Box>
    </Box>
  );
};

const Calendar = ({ date, setDate, size = 'large', minDate = null, maxDate = null, css: cssString = '' }) => {
  const [current, setCurrent] = useStateSync(date, [date]);

  const subMonth = () => {
    setCurrent(setMonth(current, getMonth(current) - 1));
  };
  const addMonth = () => {
    setCurrent(setMonth(current, getMonth(current) + 1));
  };

  const weeks = useMemo(() => {
    const weekends = eachWeekendOfMonth(current);
    let weeks = [];
    for (const week of weekends) {
      if (isSaturday(week)) {
        const start = subDays(week, 6);
        const days = eachDayOfInterval({ start, end: week });
        weeks.push(days);
      }
    }
    const lastDay = weeks?.at(-1)?.at(-1);
    const lastWeekend = weekends?.at(-1);
    if (!isSameDay(lastDay, lastWeekend)) {
      const end = addDays(lastDay, 7);
      const days = eachDayOfInterval({ start: lastWeekend, end });
      weeks.push(days);
    }

    return weeks;
  }, [current]);

  const sizeValue = useMemo(() => {
    if (size === 'small') {
      return { day: '24px', text: '12px', height: '140px' };
    } else if (size === 'medium') {
      return { day: '32px', text: '14px', height: '180px' };
    } else if (size === 'large') {
      return { day: '48px', text: '16px', height: '240px' };
    }
  }, [size]);

  return (
    <Box
      css={`
        margin-top: 16px;
        ${cssString}
      `}
    >
      <Box
        flex="space-between middle"
        css={`
          margin: 8px 0;
        `}
      >
        <Button styles="icon sm" onClick={() => subMonth()}>
          <CaretLeft />
        </Button>
        <Text
          label
          bold
          css={`
            flex-grow: 1;
            text-align: center;
          `}
        >
          {format(current, 'MMMM yyyy')}
        </Text>
        <Button styles="icon sm" onClick={() => addMonth()}>
          <CaretRight />
        </Button>
      </Box>
      <Box
        css={`
          margin: 16px 0;
          height: ${sizeValue.height};
        `}
      >
        {weeks.map((week, i) => (
          <Box flex="space-between">
            {week.map((day) => {
              const isDisabled =
                (minDate !== null && day.getTime() < minDate.getTime()) ||
                (maxDate !== null && day.getTime() > maxDate.getTime());
              return (
                <Text
                  onClick={isDisabled ? null : () => setDate(day)}
                  css={`
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: ${sizeValue.day};
                    height: ${sizeValue.day};
                    text-align: left;
                    margin-left: 16px;
                    margin: auto;
                    font-size: ${sizeValue.text};
                    ${isDisabled ? 'opacity: .5;' : ''}
                    ${isSameDay(day, date)
                      ? `
                        border-radius: 50%;
                        background-color: ${colors.purple};
                        color: white;
                        font-weight: bold;
                      `
                      : `
                        :hover {
                        cursor: pointer;
                          border-radius: 50%;
                          background-color: ${colors.gray[100]};
                        }
                    `}
                  `}
                >
                  {format(day, 'd')}
                </Text>
              );
            })}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const Input = ({ date, setDate, css: cssString = '' }) => {
  const [month, setMonth] = useStateSync(format(date, 'MM'), [date]);
  const [day, setDay] = useStateSync(format(date, 'dd'), [date]);
  const [year, setYear] = useStateSync(format(date, 'yyyy'), [date]);

  const handleMonth = (e) => {
    const value = extractNumbers(e.target.value);
    if (value > 12) {
      setMonth(12);
    } else if (value < 10) {
      setMonth(`0${value}`);
    } else if (value < 1) {
      setMonth(`0${1}`);
    } else {
      setMonth(value);
    }
  };

  const handleDay = (e) => {
    const value = extractNumbers(e.target.value);
    const maxDay = new Date(year, month, 0).getDate();
    if (value > maxDay) {
      setDay(maxDay);
    } else if (value < 10) {
      setDay(`0${value}`);
    } else if (value < 1) {
      setDay(`0${1}`);
    } else {
      setDay(value);
    }
  };

  const handleYear = (e) => {
    const value = extractNumbers(e.target.value);
    if (value < 10) {
      setYear(`000${value}`);
    } else if (value < 100) {
      setYear(`00${value}`);
    } else if (value < 1000) {
      setYear(`0${value}`);
    } else {
      setYear(value);
    }
  };

  const onBlur = () => {
    const date = new Date(year, month - 1, day);
    setDate(date);
  };

  return (
    <Box
      css={`
        border: 1px solid ${colors.gray[300]};
        border-radius: 8px;
        padding: 8px;
        p {
          margin-left: 2px;
        }
        input {
          ${textTheme?.label}
          max-width: 22px;
          text-align: right;
          flex-grow: 1;
          border: none;
          padding: 0;
          margin: 0;
          outline: 0;
          :focus {
            background-color: ${colors.gray[100]};
            border-radius: 8px;
          }
          :hover {
            border: none;
          }
        }
        .year-input {
          max-width: 42px;
        }
        ${cssString}
      `}
      flex="left middle"
    >
      <input className="month-input" value={month} onBlur={onBlur} onChange={handleMonth} />
      <Text>/</Text>
      <input className="day-input" value={day} onBlur={onBlur} onChange={handleDay} />
      <Text>/</Text>
      <input className="year-input" value={year} onBlur={onBlur} onChange={handleYear} />
    </Box>
  );
};

const getDateFromISO = (date, fallback = new Date()) => {
  try {
    // Adjust ISO string to avoid unwanted local time conversion by 'new Date'.
    const cleanISOString = date.replace('T', ' ').replace('Z', '');
    return new Date(cleanISOString);
  } catch {
    return fallback;
  }
};

// Named Dates so as not to conflict with native Date constructor.
export const Dates = {
  Range,
  Calendar,
  Input,
  getDateFromISO,
};

export default Dates;
