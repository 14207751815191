import { DownloadSimple, FileText, UploadSimple } from '@phosphor-icons/react';
import { ExtensionIconMapper } from '../../../Documents/ExtensionIconMapper';
import { Box, BriteLoader, Text } from '../../../shared/components';
import { colors } from '../../../shared/styles';
import { useDocumentBlock } from './useDocumentBlock';
import { container } from '../../../shared/shared-styles';
import { overlay } from '../../utility/styles';
import { Toolbar } from '../../utility/Toolbar';
import { DocumentToolbar } from './DocumentToolbar';
import { useContent } from '../../providers/content/use-content';
import { Modify } from '../../providers/content/content-modify';
import { useUpload } from '../../utility/useUpload';

export const DocumentBlock = () => {
  const block = useDocumentBlock();

  return (
    <>
      <Box
        css={`
          box-sizing: border-box;
          width: 100%;
        `}
      >
        {!block?.document.value ? (
          <EmptyDocument block={block} />
        ) : (
          <Box
            flex="left"
            css={`
              background-color: ${colors.gray[100]};
              border-radius: 8px;
              max-width: 100%;
              height: 64px;
              padding: 0 24px;
              svg {
                min-width: 32px;
              }
            `}
          >
            <ExtensionIconMapper color="var(--button-background-color)" fileType={block.document?.value?.FileType} />
            <Text
              label
              bold
              ellipsis
              css={`
                flex-grow: 1;
                margin: 0 16px;
                min-width: 0;
              `}
            >
              {block?.document?.value?.Name}
            </Text>
            <DownloadSimple size={32} />
          </Box>
        )}
      </Box>
      <Toolbar isSelected={block?.isSelected} anchor={block?.anchor}>
        <DocumentToolbar id={block?.id} document={block?.document} />
      </Toolbar>
    </>
  );
};

const EmptyDocument = ({ block }) => {
  return (
    <Box
      flex="center column"
      css={`
        position: relative;
        background-color: ${colors.gray[100]};
        border-radius: 8px;
        width: 100%;
        min-height: 140px;
        ${container.hover}
        > * {
          ${block?.isSelected ? '' : 'pointer-events: none;'}
        }
      `}
    >
      {block?.isSelected ? (
        <UploadDocument block={block} />
      ) : (
        <>
          <FileText size={48} />
          <Text bold label>
            Document
          </Text>
        </>
      )}
    </Box>
  );
};

const UploadDocument = ({ block }) => {
  const { sendUpdates } = useContent();
  const updateDocument = (document) => Modify.properties(sendUpdates, block?.id, () => ({ document }));

  const { getRootProps, getInputProps, isDragActive, isLoading } = useUpload(updateDocument);

  return (
    <Box
      flex="center column"
      css={`
        ${overlay}
        z-index: 100;
        border-radius: 8px;
        ${isDragActive ? `border: 2px dashed ${colors.purple};` : ''}
      `}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {isLoading ? (
        <BriteLoader size={48} weight={8} overlay={false} />
      ) : isDragActive ? (
        <UploadSimple size={48} color={colors.purple} />
      ) : (
        <FileText size={48} />
      )}
      {isDragActive ? (
        <Text bold>Drop file to upload</Text>
      ) : (
        <Text bold label>
          Drop a file here, or click to edit
        </Text>
      )}
    </Box>
  );
};
