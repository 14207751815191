import { MenuItem } from '@mui/material';
import { MegaphoneSimple } from '@phosphor-icons/react';
import { useFeatureFlagPayload } from 'posthog-js/react';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { Notifications } from './Notifications/Notifications';
import { extractSearchQuery } from './react-query';
import { Box, Button, DropMenu, Text, UserAvatar } from './shared/components';
import { container, flex } from './shared/shared-styles';
import { colors } from './shared/styles';
import { useStore } from './store-provider/use-store';
import { MultiChooseCompanyContainer } from './Sidebar/MultiChooseCompany';
import { UserSettings } from './user-settings';

export const MultiHeader = (props) => {
  const {
    data: { isBriteEmployee, user, selectedBusiness, parentBusiness },
  } = useStore();

  const requestBuildsFF = useFeatureFlagPayload('request-builds');
  const editorCollaborationFF = useFeatureFlagPayload('editor-collaboration');
  const [modal, setModal] = useState('');

  // ************************************************
  // TODO: Remove alongside editorCollaborationFF
  const [hasUnseenReleases, setHasUnseenReleases] = useState(window.rnw.unseen_count > 0);
  const displayRelease = () => {
    window.rnw('show');
    setHasUnseenReleases(false);
  };
  // ************************************************

  const requestBuild = () => {
    const query = {
      name: encodeURIComponent(parentBusiness?.ParentName || parentBusiness?.Name),
      email: encodeURIComponent(user?.Email),
      firstname: encodeURIComponent(user?.FirstName),
      lastname: encodeURIComponent(user?.LastName),
      subject: encodeURIComponent(selectedBusiness?.Name),
    };
    const searchQuery = extractSearchQuery(query);
    window.open(`https://share.hsforms.com/1ocfe_278SJmEYkjOl5h9fg3ihp9?${searchQuery}`, '_blank');
  };

  const location = useLocation();

  const userDisplay = useMemo(() => {
    let displayText = '';
    if (user) {
      if (user.FirstName) {
        displayText = `${user.FirstName}`;
      }
      if (user.LastName) {
        displayText += ` ${user.LastName}`;
      }
      if (!displayText) {
        displayText = user.Email;
      }
    }
    return displayText;
  }, [user?.FirstName, user?.LastName, user?.Email]);

  if (!isBriteEmployee && (location.pathname.includes('dashboard') || location.pathname.includes('members'))) {
    return null;
  }

  return (
    <>
      <Box
        css={`
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 24px 40px;
          position: sticky;
          top: 0;
          background-color: white;
          z-index: 10;
        `}
      >
        <MultiChooseCompanyContainer />
        <Box
          css={`
            display: flex;
            flex-direction: row;
            align-items: center;
          `}
        >
          {editorCollaborationFF?.value ? (
            <Notifications />
          ) : (
            <Box
              css={`
                position: relative;
              `}
            >
              <Button styles="icon" onClick={displayRelease}>
                <MegaphoneSimple />
              </Button>
              {hasUnseenReleases ? (
                <Box
                  css={`
                    position: absolute;
                    top: 4px;
                    right: 8px;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: ${colors.red[100]};
                  `}
                />
              ) : null}
            </Box>
          )}
          <DropMenu
            transformOrigin={{
              vertical: -48,
            }}
            button={
              <Box
                css={`
                  ${flex('left')}
                  border-radius: 30px;
                  ${container.hover}
                  padding: 8px;
                  padding-right: 16px;
                `}
              >
                <UserAvatar />
                <Text
                  label
                  bold
                  css={`
                    margin-left: 16px;
                  `}
                >
                  {userDisplay}
                </Text>
              </Box>
            }
          >
            <MenuItem onClick={() => setModal('edit-user')}>User Settings</MenuItem>
            <MenuItem
              onClick={() => {
                window.open('https://britehr.app/Help-Center-Resources/1', '_blank');
              }}
            >
              Learn Brite
            </MenuItem>
            {requestBuildsFF?.value ? <MenuItem onClick={requestBuild}>Request a Build</MenuItem> : null}
            <MenuItem onClick={() => props.logout({ returnTo: window.location.origin })}>Logout</MenuItem>
          </DropMenu>
        </Box>
      </Box>
      <UserSettings display={modal === 'edit-user'} onClose={() => setModal('')} />
    </>
  );
};
