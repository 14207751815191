import { ProductSummary } from '../../../../products/product-summary';
import { Toolbar } from '../../../utility/Toolbar';
import { EmptySmartBenefit } from '../EmptySmartBenefit';
import { ErrorSmartBenefit } from '../ErrorSmartBenefit';
import { SummaryToolbar } from './SummaryToolbar';
import { useSummaryBlock } from './useSummaryBlock';

export const SummaryBlock = () => {
  const block = useSummaryBlock();

  return (
    <>
      {!block?.sync?.value?.data?.id ? (
        <EmptySmartBenefit type="benefits" />
      ) : !block?.product?.ID ? (
        <ErrorSmartBenefit type="benefits" />
      ) : (
        <ProductSummary state={block?.productState} styles={block?.designStyles} />
      )}
      <Toolbar isSelected={block?.isSelected} anchor={block?.anchor}>
        <SummaryToolbar
          id={block?.id}
          sync={block?.sync}
          productId={block?.productId}
          isSmartField={block?.isSmartField}
        />
      </Toolbar>
    </>
  );
};
