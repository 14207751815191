import { toast } from 'react-toastify';
import { CustomAxios } from '../redux/axios/axios';
import { validateEmail } from '../Courses/ContactInfo';
import { useState } from 'react';

export const useInviteUser = (props = {}) => {
  const { onSuccess, onError, onSettled } = props;
  const [isLoading, setIsLoading] = useState(false);

  const handleConflict = async (businessId, email, roleId) => {
    let userId = '';
    try {
      const { data: user } = await CustomAxios.get(`public/v1/users/${email}`, {
        headers: { BusinessID: businessId },
      });
      userId = user.ID;
      await CustomAxios.post(
        `v1/user/${user.ID}/access/${businessId}/role/${roleId}`,
        {},
        {
          headers: { BusinessID: businessId },
        }
      );
      onSuccess?.(userId);
    } catch (err) {
      console.warn(err);
      if (err.response.status === 500 && userId) {
        onSuccess?.(userId);
      }
      toast.error(`Failed to add user access.`);
    }
  };

  const sendInvite = async (businessId, email, roleId = 'viewer') => {
    const validEmail = validateEmail(email);
    if (!validEmail) {
      onError?.('invalid-email');
    } else if (!!businessId) {
      try {
        setIsLoading(true);
        const { data } = await CustomAxios.post(
          `v1/users/${email}`,
          {
            Email: email,
            RoleID: roleId,
            Type: 'single',
            Business: { ID: businessId },
          },
          {
            headers: { BusinessID: businessId },
          }
        );
        window?.ChurnZero?.push(['trackEvent', 'Settings', `User created: ${email}`, 1]);
        onSuccess?.(data.ID);
      } catch (err) {
        console.warn(err);
        if (err.response.status === 409) {
          await handleConflict(businessId, email, roleId);
        } else {
          toast.error(`Failed to add user access.`);
        }
      } finally {
        setIsLoading(false);
        onSettled?.();
      }
    }
  };

  return { sendInvite, isLoading };
};
