import { css } from '@emotion/css';
import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import { HandWaving, UploadSimple, X } from '@phosphor-icons/react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useModal } from '../../../common/hooks/useModal';
import { CustomAxios } from '../../../redux/axios/axios';
import { Button, Modal, Text } from '../../../shared/components';
import { useStore } from '../../../store-provider/use-store';
import { EmailEditor } from '../../text-editor';
import { ImportEmailAddressesModal } from './ImportEmailAddressesModal';
import { MessageTemplatesModal } from './MessageTemplatesModal';
import { LaunchSuccessModal } from './LaunchSuccessModal';

export const EmailMessageModal = ({ hide = () => {}, guide = {} }) => {
  const [loading, setLoading] = useState(false);
  const [emailSubject, setEmailSubject] = useState('');
  const [emailSenderName, setEmailSenderName] = useState('');
  const [currentEmailAddress, setCurrentEmailAddress] = useState('');
  const [emailAddresses, setEmailAddresses] = useState(new Set());
  const [emailAgree, setEmailAgree] = useState(false);

  const messageTemplatesModal = useModal();
  const importEmailAddressesModal = useModal();
  const launchSuccessModal = useModal();

  const {
    data: { selectedBusiness },
  } = useStore();

  const sendEmailMessage = async (bodyHTMLString) => {
    if (bodyHTMLString?.length > 150000) {
      toast.error(`Email is too large. Please reduce the contents.`);
      return;
    }
    setLoading(true);

    if (currentEmailAddress) {
      emailAddresses.add(currentEmailAddress);
    }
    const emails = [...emailAddresses];

    try {
      if (emails.length > 0) {
        const body = {
          emailBody: bodyHTMLString,
          emailAddresses: emails,
          guideID: guide.ID,
          emailSubject: emailSubject,
          fromName: emailSenderName,
        };
        await CustomAxios.post(`/v1/email/sendGuide`, body);
      }

      setLoading(false);
      launchSuccessModal.toggle();
      window?.ChurnZero?.push(['trackEvent', 'Guide Sent', '', 1]);
    } catch (e) {
      setLoading(false);
      toast.error(`Failed to send email.`);
      console.log(e);
    }
  };

  if (launchSuccessModal.isOpen) {
    return (
      <LaunchSuccessModal
        hide={() => {
          hide();
          launchSuccessModal.hide();
        }}
      />
    );
  }

  return (
    <Modal
      full
      display
      onClose={() => {
        hide();
      }}
      css={`
        overflow-y: auto;
        padding-bottom: 100px;
      `}
    >
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 28px;
          border-bottom: 1px solid #d1dae3;
        `}
      >
        <Text h1>Email Guide Link</Text>
        <div
          className={css`
            display: flex;
            align-items: center;
            gap: 16px;
          `}
        >
          <Button styles="icon" hoverLabel="Message Templates Help" onClick={() => messageTemplatesModal.toggle()}>
            <HandWaving />
          </Button>
          <Button
            secondary
            onClick={() => {
              hide();
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={loading || !emailAgree || !emailSubject || !emailSenderName || emailAddresses.length < 1}
            onClick={() => {
              // Update the CTA link in the email body to actually go to the guide, and overwrite the input field with a button so it cannot be edited, and so that it can be clicked.
              // This is done here for now so that the user can edit the CTA text rather than having it open a new tab.
              let emailCTA = document.getElementById('email-editor-cta');
              let emailCTAText = document.getElementById('email-editor-cta-button');

              // Add the link so that this is now a clickable link in the email.
              emailCTA.href = 'https://britehr.app/' + guide.VanityURL;

              const CTAButton = document.createElement('button');
              CTAButton.innerHTML = emailCTAText.value;
              CTAButton.style =
                emailCTAText.attributes.style.value +
                ' cursor: pointer; padding-left:48px; padding-right:48px; padding-top:12px; padding-bottom:12px;';
              emailCTA.replaceChildren(CTAButton);

              sendEmailMessage(document.getElementById('emailBodySpan').outerHTML);
            }}
          >
            Send Email
          </Button>
        </div>
      </div>
      <div
        className={css`
          padding-right: 16px;
          padding-left: 16px;
          padding-top: 48px;
          padding-bottom: 48px;
          max-width: 700px;
          margin: 0 auto;
          overflow: auto;
        `}
      >
        <div
          id="padding-for-upper-fields"
          className={css`
            padding-right: 35px;
            padding-left: 32px;
          `}
        >
          <div
            id="subject-sender"
            className={css`
              flex-direction: row;
              display: flex;
            `}
          >
            <div
              className={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
              `}
            ></div>
            <div
              className={css`
                flex-grow: 1;
                width: 100%;
                margin-right: 16px;
                margin-bottom: 24px;
              `}
            >
              <Text label>Email Subject</Text>
              <TextField
                style={{ width: '100%' }}
                variant="outlined"
                placeholder="Enter Email Subject"
                value={emailSubject}
                onChange={(e) => setEmailSubject(e.target.value)}
              />
            </div>
            <div
              className={css`
                flex-grow: 1;
                width: 100%;
              `}
            >
              <Text label>Sender Name</Text>
              <TextField
                style={{ width: '100%' }}
                variant="outlined"
                placeholder="Enter Sender Name"
                value={emailSenderName}
                onChange={(e) => setEmailSenderName(e.target.value)}
              />
            </div>
          </div>
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <Text label>Email Addresses</Text>
          </div>
          <div
            className={css`
              display: flex;
              justify-content: space-between;
              align-items: center;
            `}
          >
            <div
              className={css`
                flex-grow: 1;
                width: 100%;
                margin-right: 16px;
              `}
            >
              <TextField
                style={{ width: '100%' }}
                variant="outlined"
                placeholder="Enter Email Address"
                value={currentEmailAddress}
                onChange={(e) => setCurrentEmailAddress(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <Button
                      naked
                      onClick={() => {
                        if (currentEmailAddress) {
                          emailAddresses.add(currentEmailAddress);
                        }

                        setCurrentEmailAddress('');
                      }}
                    >
                      Add
                    </Button>
                  ),
                }}
              />
            </div>
            <div>
              <Button
                secondary
                onClick={() => {
                  importEmailAddressesModal.toggle();
                }}
              >
                <UploadSimple size={24} style={{ height: '40px' }} />
                Import
              </Button>
            </div>
          </div>
          <div
            className={css`
              max-height: 250px;
              overflow: auto;
              margin-bottom: 32px;
              margin-top: 8px;
              background: ${emailAddresses.size > 0 ? '#f5f7fa' : 'fff'};
              border-radius: 8px;
              padding: ${emailAddresses.size > 0 ? '8px' : '0px'};
            `}
          >
            {emailAddresses.size > 0
              ? [...emailAddresses].map((emailAddress) => {
                  return (
                    <div
                      key={emailAddress}
                      className={css`
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                      `}
                    >
                      <div>{emailAddress}</div>
                      <div>
                        <Button
                          styles="icon"
                          onClick={() => {
                            const next = new Set(emailAddresses);
                            next.delete(emailAddress);
                            setEmailAddresses(next);
                          }}
                        >
                          <X />
                        </Button>
                      </div>
                    </div>
                  );
                })
              : null}
          </div>
        </div>
        <EmailEditor business={selectedBusiness} guide={guide} editorID="emailBodySpan" />
        <FormControlLabel
          style={{ fontSize: '12px', marginBottom: '12px' }}
          control={
            <Checkbox
              checked={emailAgree}
              onChange={(e) => {
                setEmailAgree(e.target.checked);
              }}
              name="checkedB"
              color="primary"
            />
          }
          label={
            <div
              className={css`
                font-size: 12px;
                padding-top: 8px;
              `}
            >
              By clicking here, I represent that I have the authorization of the Company to send the above email which
              is not for marketing or solicitation and that Brite bears no responsibility for the content of the email
              message, including the content of any external links.
            </div>
          }
        />
      </div>
      {messageTemplatesModal.isOpen && (
        <MessageTemplatesModal hideMessageTemplates={() => messageTemplatesModal.hide()} guide={guide} />
      )}
      {importEmailAddressesModal.isOpen && (
        <ImportEmailAddressesModal
          hideImportEmailAddresses={() => importEmailAddressesModal.hide()}
          emailAddresses={emailAddresses}
          setEmailAddresses={setEmailAddresses}
        />
      )}
    </Modal>
  );
};
