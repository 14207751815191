import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from '../shared/use-search-params';
import { useStore } from '../store-provider/use-store';
import { CustomAxios } from '../redux/axios/axios';
import { useEvent } from '../shared/use-event';

export const WebsocketContext = createContext();

const websocketIs = (ws) => {
  switch (ws?.current?.readyState) {
    case WebSocket.CONNECTING:
      return 'connecting';
    case WebSocket.OPEN:
      return 'connected';
    case WebSocket.CLOSING:
      return 'disconnecting';
    case WebSocket.CLOSED:
      return 'disconnected';
    default:
      return 'unknown';
  }
};

export const useWS = () => useContext(WebsocketContext);

const RECONNECT_AFTER_MS = 3000;

export const WS = ({ children }) => {
  const ws = useRef(null);
  const subscriptions = useRef(new EventTarget());
  const meta = useRef({
    lastPong: null,
    interval: null,
    reconnect: null,
    reconnectAfterMS: RECONNECT_AFTER_MS,
  });

  const {
    data: { selectedBusiness, user },
  } = useStore();

  const [status, setStatus] = useState('uninitialized');
  const [reconnect, setReconnect] = useState(null);
  const [subs, setSubs] = useState([]);

  const url = useMemo(() => {
    const localToken = localStorage.getItem('authHeader');
    const token = localToken?.replace('Bearer ', '');
    const url = CustomAxios.baseURL();
    const nextUrl = url.replace(/^http/, 'ws');
    if (nextUrl && selectedBusiness?.ID && token) {
      return `${nextUrl}ws/connect?token=${token}&businessid=${selectedBusiness?.ID}&X-Brite-App-Version=${window.b_version}`;
    }
    return '';
  }, [selectedBusiness?.ID]);

  const queryParams = useSearchParams();

  const handleMessage = useCallback((event) => {
    if (event?.data === 'pong') {
      meta.current.lastPong = Date.now();
      return;
    }
    const message = JSON.parse(event.data);
    if ('message' in message) {
      const subscriptionKey = `echo-${message?.message?.event?.subscriptionKey || 'unknown'}`;
      subscriptions.current.dispatchEvent(new CustomEvent(subscriptionKey, { detail: message }));
    } else {
      const subscriptionKey = message?.event?.subscriptionKey || 'unknown';
      subscriptions.current.dispatchEvent(new CustomEvent(subscriptionKey, { detail: message }));
    }
  }, []);

  const initiatePing = () => {
    ws.current.send('ping');
    if (meta.current.interval !== null) {
      clearInterval(meta.current.interval);
    }
    meta.current.interval = setInterval(() => {
      const now = Date.now();
      if (meta?.current?.lastPong && now - meta?.current?.lastPong > 40000) {
        setStatus('disconnected');
        setReconnect(Date.now());
        clearInterval(meta.current.interval);
      } else if (websocketIs(ws) === 'connected') {
        ws.current.send('ping');
      }
    }, 30000);
  };

  const close = () => {
    if (ws.current) {
      ws.current.onclose = null;
      ws.current.onopen = null;
      ws.current.onerror = null;
      ws.current.onmessage = null;
    }
    ws?.current?.close?.(CloseEvent.CLOSE_NORMAL);
  };

  const connect = useCallback(() => {
    const isValid = !!url;
    if (!isValid) return;

    close();

    ws.current = new WebSocket(url);
    ws.current.onopen = () => {
      initiatePing();
      setStatus(websocketIs(ws));
      meta.current.reconnectAfterMS = RECONNECT_AFTER_MS;
      clearTimeout(meta.current.reconnect);
    };

    ws.current.onclose = () => {
      setStatus(websocketIs(ws));
      clearInterval(meta.current.interval);
      meta.current.reconnect = setTimeout(() => {
        connect();
        meta.current.reconnectAfterMS = meta?.current?.reconnectAfterMS * 2;
      }, meta?.current?.reconnectAfterMS);
    };
    ws.current.onerror = (error) => {
      console.error('WebSocket error:', error);
      setStatus('error');
    };
    ws.current.onmessage = handleMessage;
  }, [url]);

  useEffect(() => {
    connect();

    return () => {
      close();
      clearTimeout(meta?.current?.reconnect);
      clearInterval(meta?.current?.interval);
    };
  }, [url, reconnect]);

  const send = useCallback(
    (event, data) => {
      const { Business, ...rest } = user;
      const client = {
        user: rest,
        pathname: window.location.pathname,
        params: queryParams?.params,
      };
      const message = { event, data, client };
      const recentStatus = websocketIs(ws);
      if (recentStatus !== 'connected') {
        return {};
      }
      ws?.current?.send(JSON.stringify(message));
      return message;
    },
    [user?.ID, status]
  );

  const add = useCallback((key, callback, echo) => {
    setSubs((prev) => [...prev, key]);
    subscriptions.current.addEventListener(key, (event) => callback(event.detail));
    if (echo) {
      const echoKey = `echo-${key}`;
      subscriptions.current.addEventListener(echoKey, (event) => echo(event.detail));
    }
  }, []);

  const remove = useCallback((key, callback, echo) => {
    setSubs((prev) => prev.filter((sub) => sub !== key));
    subscriptions.current.removeEventListener(key, (event) => callback(event.detail));
    if (echo) {
      const echoKey = `echo-${key}`;
      subscriptions.current.removeEventListener(echoKey, (event) => echo(event.detail));
    }
  }, []);

  useEvent('beforeunload', () => {
    close();
  });

  return <WebsocketContext.Provider value={{ send, status, add, remove, subs }}>{children}</WebsocketContext.Provider>;
};
