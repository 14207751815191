import { css, cx } from '@emotion/css';
import { Button, Div, DropMenu, Text } from '../shared/components';
import { colors } from '../shared/styles';
import { buildCss } from './utils';
import { DragTile, FontLinks } from './utility-components';
import { DotsThree, HandGrabbing, WarningCircle } from '@phosphor-icons/react';
import { EditorComponents, SaveContentBlock } from './toolbar-menus';
import { ViewOptions, MemoizedRow } from './layout-components';
import { useBriteEditor } from './use-brite-editor';
import { useCallback, useEffect, useRef, useState, useMemo, useContext } from 'react';
import { useEvent } from '../shared/use-event';
import { MenuItem } from '@mui/material';
import { BackgroundImage } from './utility-components/background-image';
import { container, flex, scrollbar } from '../shared/shared-styles';
import { useSearchParams } from '../shared/use-search-params';
import { FindAndReplace } from './find-and-replace';
import { useStore } from '../store-provider/use-store';
import { editorUtils, handleConvert, iterateLayout, modifyContent } from './provider/utils';
import { Skeleton } from '@mui/lab';
import { useEditorResource } from './use-editor-resource';
import { DevProfiler } from '../shared/components/dev-profiler';
import { CommentsView } from './comments-view';
import { EditorDetailsContext } from './provider/editor-detail-provider';
import { useFeatureFlagPayload } from 'posthog-js/react';
import { UserOverlay } from './UserOverlay';

export const generateColors = (styles) => {
  const designStyleColors = Object.entries(styles?.general?.colorPalette || {})?.reduce((prev, [key, value]) => {
    return prev + `--${key}:${value};`;
  }, '');
  return designStyleColors;
};

const designStylesCss = (style) => {
  return css`
    ${buildCss(style)}
    user-select: none;
    overflow: visible;

    @keyframes fadeinmenu {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
  `;
};

const loadContainer = css`
  display: flex;
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: white;
  min-height: calc(100vh - 100px);
`;

const dropContainer = ({ drag, type, isEmpty = false }) => css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: border 0.1s ease;

  ${type === 'top' && isEmpty && `display: none;`}

  ${type === 'top' &&
  (drag?.isDragging
    ? `
    height: 21px;
    border-bottom: 5px solid ${colors.gray[100]};
    :hover {
      border-bottom: 5px solid ${colors.purple};
    }
  `
    : `
    margin-top: 16px;
    border-bottom: 5px solid transparent;
  `)}

  ${type === 'bottom' && `height: 400px;`}
  
  ${type === 'bottom' &&
  isEmpty &&
  `
    margin-top: 32px;
    border-radius: 8px;
    outline: 4px dashed ${colors.gray[200]};
    background-color: ${colors.gray[100]};
  `}

  ${drag?.isDragging &&
  type === 'bottom' &&
  (isEmpty
    ? `
      :hover {
        border: none;
        background-color: ${colors.gray[200]};
      }
    `
    : `
      border-top: 5px solid ${colors.gray[100]};
      :hover {
        border-top: 5px solid ${colors.purple};
      }
    `)}
`;

export const disableDragComponents = ['text', 'textV2', 'document', 'accordion'];

export const BriteEditor = (props) => {
  const { pagesQuery, refetchAll } = props;

  const liveUserFF = useFeatureFlagPayload('live-user');

  const briteEditorRef = useRef();
  const { params, updateParams, removeParam } = useSearchParams();

  const { editor, readyId, editorRef, isLoading, setViewMode } = useBriteEditor(props);

  const { editorDetails, dispatch } = useContext(EditorDetailsContext);

  const {
    data: { isBriteEmployee, devMode },
  } = useStore();

  const { data: styles } = useEditorResource('styles');

  const {
    state: { drag, layout, viewMode, course, inlineColorMap },
  } = editor;

  const hasTextV1InGuide = useMemo(() => {
    if (layout && isBriteEmployee) {
      let hasTextV1 = false;
      if (layout) {
        iterateLayout(layout, (_, item) => {
          hasTextV1 = item?.component === 'text';
          return hasTextV1;
        });
      }

      return hasTextV1;
    }
    return false;
  }, [isBriteEmployee, editor?.state?.syncRenderState]);

  // *********************************************************************
  // Handle text-v1 to text-v2 conversion vvvvv
  // TODO: Delete this when text-v1 is migrated entirely (may never happen)
  const handleManualConversion = () => {
    const next = handleConvert(layout);
    modifyContent?.set(editor, [], next?.rows, { syncRenderState: true });
  };
  // Handle text-v1 to text-v2 conversion ^^^^^

  // ******************************************************************

  // DRAG SCROLLING *************************************************** vvv
  const [scrollingDirection, setScrollingDirection] = useState('');
  const scrollRef = useRef();

  const startScrolling = (direction) => {
    const move = direction === 'up' ? -200 : 200;
    const position = editorRef?.current?.scrollTop;
    editorRef.current?.scrollTo({ top: position + move });
    scrollRef.current = setTimeout(() => {
      startScrolling(scrollingDirection);
    }, 220);
  };

  useEffect(() => {
    if (drag?.isDragging && scrollingDirection) {
      startScrolling(scrollingDirection);
    } else {
      clearTimeout(scrollRef?.current);
    }
  }, [scrollingDirection, drag?.isDragging]);

  const handleScroll = (e) => {
    if (drag?.isDragging) {
      const activeRange = 100;
      const headerHeight = 100;
      const topRange = activeRange + headerHeight;
      const bottomRange = window.innerHeight - headerHeight - activeRange;
      if (e.clientY < topRange) {
        setScrollingDirection('up');
      } else if (e.clientY > bottomRange) {
        setScrollingDirection('down');
      } else {
        setScrollingDirection('');
      }
    } else {
      setScrollingDirection('');
    }
  };
  // DRAG SCROLLING *************************************************** ^^^^

  const resetDrag = () => {
    if (drag?.isDragging) {
      editorUtils?.setProperty(editor, 'drag', null);
    }
  };

  useEvent('mouseup', resetDrag);

  const dragOver = useCallback(
    (position = '') => {
      if (!drag?.isDragging) {
        return;
      }
      editorUtils?.setProperty(editor, 'drag', {
        ...drag,
        destination: { location: [], position },
      });
    },
    [drag?.isDragging]
  );

  const rootStyle = viewMode === 'mobile' ? { ...layout.root.style, maxWidth: '400px' } : layout.root.style;

  const setRoot = (root) => {
    editorUtils?.setProperty(editor, 'layout', { ...layout, root }, { forceSave: true });
  };

  const resetSelection = () => editorUtils?.setProperty(editor, 'selection', null);

  useEvent('mousedown', resetSelection);

  const pageId = props?.query?.data?.ID;

  const overlayKey = `${props?.course?.ID}:${pageId}`;

  // LOADING
  if (params?.pageId !== pageId || !readyId || isLoading || styles === null) {
    return (
      <Div css={loadContainer}>
        <Skeleton
          variant="rect"
          style={{
            flexGrow: 1,
            borderRadius: '8px',
            height: 'calc(100vh - 32px)',
            margin: '16px',
          }}
        />
        <EditorComponents editor={editor} disabled={true} />
      </Div>
    );
  }

  return (
    <>
      <Div
        ref={briteEditorRef}
        css={css`
          ${flex('space-between')}
          width: 100%;
          height: calc(100vh - ${editorDetails?.containerBounds?.top}px);
          overflow: hidden;
          position: relative;
        `}
        onClick={() => {
          editorUtils?.setProperty(editor, 'multiSelect', null);
          editorUtils?.setProperty(editor, 'selection', null);
          dispatch({
            type: 'SET',
            key: 'commenting',
            payload: {},
          });
        }}
        onMouseDown={(e) => e.stopPropagation()}
        onContextMenu={(e) => e.preventDefault()}
        data-editor={true}
      >
        <DragTile editor={editor} />

        <Div
          className={css`
            ${flex('center start')}
            flex-grow: 1;
            width: 100%;
            height: 100%;
            background-color: ${styles?.general?.backgroundColor || '#FFF'};
            overflow: hidden;
            ${generateColors(styles)}
          `}
          style={layout?.root?.containerStyle || {}}
        >
          <FontLinks fontURLs={styles?.general?.fontURLs} />

          <ViewOptions devMode={devMode} viewMode={viewMode} setViewMode={setViewMode} />

          <Div
            className={css`
              position: absolute;
              top: 8px;
              left: 8px;
              z-index: 900;
            `}
          >
            <DropMenu
              title="Page Options"
              button={
                <Button
                  disabled={!props?.isEditable}
                  styles="icon"
                  className={css`
                    background-color: white;
                  `}
                >
                  <DotsThree />
                </Button>
              }
            >
              <MenuItem onClick={() => updateParams({ courseModal: 'edit-background-image' })}>
                Background Image
              </MenuItem>
            </DropMenu>
          </Div>

          <BackgroundImage
            display={params?.courseModal === 'edit-background-image'}
            onClose={() => removeParam('courseModal')}
            root={layout?.root || {}}
            setRoot={setRoot}
          />

          {isBriteEmployee && hasTextV1InGuide ? (
            <Div
              css={css`
                position: absolute;
                top: 16px;
                right: 278px;
                ${flex('left')}
                ${container?.hover}
                padding: 8px 16px;
                border-radius: 8px;
                border: 1px solid ${colors.gray[300]};
                z-index: 10000;
                background-color: white;
                p {
                  margin-right: 16px;
                }
              `}
              onClick={handleManualConversion}
            >
              <Text label>Upgrade Page to Text V2</Text>
              <WarningCircle color={colors.red[100]} weight="fill" />
            </Div>
          ) : null}

          <Div
            className={cx(css`
              ${flex('center start')}
              position: relative;
              width: 100%;
              height: 100%;
              padding: 0 146px;
              padding-bottom: 200px;
              overflow-y: auto;
              overflow-x: hidden;
              ${scrollbar.style}
            `)}
            ref={editorRef}
            onMouseMove={handleScroll}
          >
            {props.isEditable ? null : (
              <Div
                css={css`
                  position: absolute;
                  top: 0;
                  right: 0;
                  left: 0;
                  bottom: calc(100% - ${editorRef?.current?.scrollHeight}px);
                  z-index: 1500;
                  background-color: white;
                  opacity: ${pageId === readyId ? 0 : 1};
                  ${pageId === readyId && `transition: all .1s ease-in;`}
                `}
              />
            )}

            <DevProfiler id="editor">
              <div
                data-editor={true}
                className={css`
                  ${designStylesCss(rootStyle)} z-index: 5;
                `}
              >
                {!!layout?.rows?.length && (
                  <div
                    className={dropContainer({ type: 'top', drag })}
                    onClick={() => editorUtils?.setProperty(editor, 'selection', null)}
                    onMouseUp={() => dragOver('row-start')}
                  />
                )}

                <div
                  className={
                    !!layout?.rows?.length
                      ? css`
                          padding: 8px;
                          // outline: 1px dashed ${colors.gray[300]};
                          border-radius: 16px;
                        `
                      : ''
                  }
                >
                  {!!layout?.rows?.length &&
                    layout?.rows?.map((row, idx) =>
                      row?.rowId ? (
                        <MemoizedRow
                          key={`${editor?.state?.syncRenderState}-${row.rowId}-${idx}`}
                          row={row}
                          index={idx}
                          editor={editor}
                          versionId={props.versionId}
                          pageId={pageId}
                        />
                      ) : null
                    )}
                </div>
                <div
                  className={dropContainer({
                    type: 'bottom',
                    drag,
                    isEmpty: !layout?.rows?.length,
                  })}
                  onClick={() => editorUtils?.setProperty(editor, 'selection', null)}
                  onMouseUp={() => dragOver('row-length')}
                >
                  {!layout?.rows?.length && (
                    <Div
                      css={css`
                        ${flex('jcc aic column')}
                      `}
                    >
                      <HandGrabbing size={96} color={colors.gray[300]} />
                      <Text styles="h3 mt" color={colors.gray[400]}>
                        Drag in a tile to get started
                      </Text>
                    </Div>
                  )}
                </div>
              </div>
            </DevProfiler>
            {liveUserFF?.value ? (
              <UserOverlay ref={editorRef} type="guide:page" id={overlayKey} editor={editor} />
            ) : null}
          </Div>
        </Div>

        {params?.mode === 'collaborating' ? (
          <CommentsView />
        ) : (
          <EditorComponents editor={editor} disabled={!props.isEditable} />
        )}

        {params?.courseModal === 'find-and-replace' && (
          <FindAndReplace
            element={editorUtils.getSelection(editor)}
            pagesQuery={pagesQuery}
            courseId={course?.ID}
            display={true}
            onClose={() => removeParam('courseModal')}
            refetchAll={refetchAll}
            inlineColorMap={inlineColorMap}
            styles={styles}
          />
        )}

        {params?.courseModal === 'save-content-block' && (
          <SaveContentBlock
            display={params?.courseModal === 'save-content-block'}
            onClose={() => removeParam('courseModal')}
            editor={editor}
          />
        )}
      </Div>
    </>
  );
};
