import { useDrop } from './useDrop';
import { useCacheEditorResources } from '../../BriteEditor/use-editor-resource';
import { useEditorShortkeys } from './keyboard/useEditorShortkeys';
import { useDeselect } from './useDeselect';
import { useContent } from '../providers/content/use-content';
import { useCommentsByBlock } from './useCommentsByBlock';
import { useEditorParams } from './useEditorParams';

export const useEditor = (guide, page) => {
  const { ws, content, sendUpdates } = useContent({ ignoreSelection: true });

  // LOAD RESOURCES
  const isLoadingResources = useCacheEditorResources({ course: guide?.data });

  // Custom Hooks
  useDrop(sendUpdates);
  useEditorShortkeys({ ws, sendUpdates, content });
  useDeselect();
  useCommentsByBlock();
  useEditorParams();

  // Return
  const isLoading = isLoadingResources || page?.isLoading;

  return {
    isLoading,
    content,
  };
};
