import { Box, Button, Text } from '../../shared/components';
import { useContent } from '../providers/content/use-content';
import { ToolKit } from '../providers/tool-kit/ToolKit';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import { scrollbar, shadows } from '../../shared/shared-styles';
import { colors } from '../../shared/styles';
import { Content } from '../providers/content/Content';
import { Modify } from '../providers/content/content-modify';
import { useEditorResource } from '../../BriteEditor/use-editor-resource';
import { ImageSettings } from './Settings/ImageSettings';
import { TOOL_TYPE_MAP } from '../utility/constants';
import { X } from '@phosphor-icons/react';
import { ButtonSettings } from './Settings/ButtonSettings';
import { useDebounce } from '../../shared/use-debounce';
import { TextSettings } from './Settings/TextSettings';
import { useSessionId } from '../utility/useSessionId';
import { DividerSettings } from './Settings/DividerSettings';
import { SectionSettings } from './Settings/SectionSettings';
import { NetworkSearchSettings } from './Settings/NetworkSearchSettings';
import { SummarySettings } from './Settings/SummarySettings';
import { PricingSettings } from './Settings/PricingSettings';

export const propertiesMap = {
  image: ImageSettings,
  button: ButtonSettings,
  textV2: TextSettings,
  space: DividerSettings,
  line: DividerSettings,
  section: SectionSettings,
  network: NetworkSearchSettings,
  benefits: SummarySettings,
  pricing: PricingSettings,
};

export const PropertiesPanel = () => {
  const { data: designStyles } = useEditorResource('styles');
  const { content, local } = useContent({ ignoreSelection: true });

  const sessionId = useSessionId('properties-panel');

  const toolkit = useToolKit();
  const isDisplaySettings = ToolKit.getIsDisplaySettings(toolkit);
  const selection = ToolKit.getSelection(toolkit);
  const data = Content.get(content, selection?.id);

  const debounce_send = useDebounce(local.sendBatch, 500);

  const update = (updaterFn, value) => {
    const setter = local.createBatch(sessionId);
    Modify.merge(setter, selection?.id, (data) => updaterFn(data, value));
    debounce_send();
  };

  const BlockSettings = propertiesMap[data?.type];

  return (
    <Box
      data-scope="menu"
      css={`
        position: absolute;
        z-index: 1000;
        top: 16px;
        bottom: 16px;
        right: 12px;
        width: 300px;
        @keyframes slideLeft {
          from {
            transform: translateX(200%);
          }
          to {
            transform: translateX(0);
          }
        }

        @keyframes slideRight {
          from {
            transform: translateX(0);
          }
          to {
            transform: translateX(200%);
          }
        }

        ${isDisplaySettings ? `animation: slideLeft 0.3s ease forwards;` : `animation: slideRight 0.3s ease forwards;`}
        ${shadows.lg}
        background-color: white;
        padding: 24px 0;
        border-left: 1px solid ${colors.gray[300]};
        border-radius: 16px;
        z-index: 1000;
        overflow: hidden;
      `}
    >
      <Box
        flex="space-between"
        css={`
          margin-bottom: 24px;
          padding: 0 16px;
          transition: opacity 0.2s ease;
          ${isDisplaySettings
            ? `
            opacity: 1;  
          `
            : `
              opacity: 0;
              filter: blur(4px);
            `}
        `}
      >
        <Text h4>{TOOL_TYPE_MAP[data?.type]}</Text>
        <Button styles="icon sm" onClick={() => ToolKit.setDisplaySettings(toolkit, false)}>
          <X size={24} />
        </Button>
      </Box>
      <Box
        css={`
          height: calc(100% - 40px);
          padding-bottom: 64px;
          overflow-y: scroll;

          ${scrollbar.hide}
        `}
      >
        {BlockSettings ? <BlockSettings data={data} designStyles={designStyles} update={update} /> : null}
      </Box>
    </Box>
  );
};
