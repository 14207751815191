import { createContext, useEffect, useRef, useState } from 'react';
import {
  getBlockIndices,
  getDropActions,
  getRemoveSelectionActions,
  getUpdateAction,
  getWeightedAggregateIndices,
} from './content-utils';
import { compileCompositionActions, getComposition } from './composition/composition-utils';
import { createSectionAction } from './composition/section-utils';
import { attachAction, getAction, removeEmptyActions, updateAction } from './composition/action-utils';
import { getChildFromAction } from './composition/child-utils';
import { compileBatchIntoActions, getBatch } from '../../utility/editor-utils';
import { useSubscription } from '../../../WebSocket/useSubscription';
import { useToolKit } from '../tool-kit/use-tool-kit';
import { ToolKit } from '../tool-kit/ToolKit';
import { useStore } from '../../../store-provider/use-store';

// (DEV being the user) user-facing methods are found here.
// Functions that that these methods use should be
// added to the content-utils.js file.

export const ContentContext = createContext();

export const Content = ({ children, page }) => {
  const {
    data: { devMode },
  } = useStore();

  const toolkit = useToolKit();
  const guide = ToolKit.getMetadata(toolkit, 'guide');

  // PAGE CONTENT
  const [content, setContent] = useState({ ...page?.data?.Content });

  const subscription = useSubscription({
    type: 'guide-page-content',
    keys: [guide?.data?.ID, page?.data?.ID],
    enabled: !!guide?.data?.ID && !!page?.data?.ID,
    onMessage: (message) => {
      switch (message?.event?.action) {
        case 'editor_content_actions': {
          setContent((old) => {
            const content = Content.commit(old, message?.data?.actions);
            return content;
          });
          break;
        }
        case 'editor_content_undo': {
          setContent((old) => {
            const content = Content.commit(old, message?.data?.actions);
            return content;
          });
          break;
        }
        case 'editor_content_redo': {
          setContent((old) => {
            const content = Content.commit(old, message?.data?.actions);
            return content;
          });
          break;
        }
        default:
          break;
      }
    },
    onEcho: (message) => {
      if (message?.status === 'failed' && message?.message?.event?.action === 'editor_content_actions') {
        page?.refetch();
        toolkit.setReadOnly(true);
      }
    },
  });

  const localActions = useRef(null);

  useEffect(() => {
    if (!page?.isLoading) {
      const content = structuredClone(page?.data?.Content);
      setContent(content);
    } else {
      setContent({});
    }
  }, [page?.isLoading, page?.data?.ID, page?.data?.Content?.root]);

  useEffect(() => {
    return () => page?.refetch();
  }, []);

  const ws = {
    send: (actions) => {
      if (actions?.length) {
        if (devMode) {
          console.log(actions);
        }
        subscription.send('editor_content_actions', {
          pageId: page?.data?.ID,
          actions,
        });
      }
    },
    undo: () => subscription.send('editor_content_undo', { pageId: page?.data?.ID }),
    redo: () => subscription.send('editor_content_redo', { pageId: page?.data?.ID }),
  };

  const sendUpdates = (updaterFn) => {
    setContent((old) => {
      const { content, actions } = updaterFn(old);
      ws.send(actions);
      return content;
    });
  };

  // TODO: sendBatch and createBatch are good candidates for web worker:
  const createBatch = (batchId) => (updaterFn) => {
    setContent((old) => {
      const { content, actions } = updaterFn(old);
      const local = getBatch(localActions.current, batchId, actions);
      localActions.current = local;
      return content;
    });
  };

  const sendBatch = () => {
    if (localActions !== null) {
      const actions = compileBatchIntoActions(localActions.current);
      ws.send(actions);
      localActions.current = null;
    }
  };

  const destroyBatch = (batchId) => {
    try {
      delete localActions.current.history[batchId];
      delete localActions.current.changes[batchId];
      const hasHistory = Object.keys(localActions?.current?.history || {}).length > 0;
      const hasChanges = Object.keys(localActions?.current?.changes || {}).length > 0;
      if (!hasHistory && !hasChanges) {
        localActions.current = null;
      }
    } catch (err) {
      console.warn(err);
    }
  };

  const value = {
    content,
    setContent,
    sendUpdates,
    ws,
    local: {
      sendBatch,
      createBatch,
      destroyBatch,
    },
  };

  return <ContentContext.Provider value={value}>{children}</ContentContext.Provider>;
};

//   /\   |--------------------------------------------------------------------------------|
// / ! \  | WARNING: Don't make changes to the Content.commit without also updating the BE |
//<-----> |--------------------------------------------------------------------------------|

Content.commit = (old, actions) => {
  const content = { ...old };
  for (const action of actions) {
    switch (action.type) {
      //
      case 'remove': {
        if (action?.property === 'sections') {
          const sections = [...content.sections];
          const nextSections = sections.filter((section) => section.id !== action?.id);
          content.sections = structuredClone(nextSections);
        } else if (action?.property === 'data') {
          delete content.data[action?.id];
        }
        break;
      }
      //
      case 'update': {
        if (action?.property === 'sections') {
          content.sections = content.sections.map((section) =>
            section.id === action?.id ? { ...section, ...action?.updates } : section
          );
        } else if (action?.property === 'data') {
          content.data[action?.id] = {
            ...content.data[action?.id],
            ...action?.updates,
          };
        }
        break;
      }
      //
      case 'insert': {
        if (action?.property === 'data') {
          content.data[action?.id] = { ...action?.updates };
        } else if (action?.property === 'sections') {
          content.sections.splice(action?.at, 0, action?.updates);
        }
        break;
      }
      //
      case 'set-all': {
        if (action.property === 'sections') {
          content.sections = [...action.updates];
        } else if (action.property === 'data') {
          content.data = { ...action.updates };
        }
        break;
      }
      //
      default:
        break;
    }
  }

  return content;
};

Content.drop = async (setter, drag) => {
  setter((old) => {
    const actions = getDropActions(old, drag);
    const content = Content.commit(old, actions);
    return { content, actions };
  });
};

Content.bulkUpdate = async (setter, updates) => {
  setter((old) => {
    let actions = [];
    let content = { ...old };
    for (const { id, updateFn } of updates) {
      const action = getUpdateAction(content, id, updateFn);
      content = Content.commit(content, [action]);
      actions.push(action);
    }
    return { content, actions };
  });
};

Content.update = async (setter, id, updateFn) => {
  setter((old) => {
    const action = getUpdateAction(old, id, updateFn);
    const content = Content.commit(old, [action]);
    return { content, actions: [action] };
  });
};

Content.remove = async (setter, id) => {
  setter((old) => {
    const actions = getRemoveSelectionActions(old, id);
    const content = Content.commit(old, actions);
    return { content, actions };
  });
};

Content.get = (content, id) => {
  if (id in content.data) {
    return content.data[id];
  } else {
    const section = content.sections.find((section) => section.id === id);
    if (section) {
      return section;
    }
  }
  return null;
};

Content.getPositionValue = (content, id) => {
  const block = Content.get(content, id);
  if (block?.parentId) {
    const list = getBlockIndices(content, block);
    const positionValue = getWeightedAggregateIndices(list);
    return positionValue;
  }
  return null;
};

Content.getChildCount = (content, id) => {
  const block = Content.get(content, id);
  return block?.children?.length || 0;
};

Content.getParent = (content, id, type = '') => {
  const child = Content.get(content, id);
  let item = Content.get(content, child?.parentId);
  while ((!!type && item && item?.type !== type) || item?.type === 'section') {
    item = Content.get(content, item?.parentId);
  }
  if (!type || item?.type === type) {
    return item;
  }
  return {};
};

Content.getChildren = (content, children) => {
  const list = children.map((child) => Content.get(content, child?.id));
  return list;
};

Content.traverseDescendants = (content, id, fn) => {
  const block = Content.get(content, id);
  fn(block);
  if (block?.children) {
    for (const child of block.children) {
      Content.traverseDescendants(content, child.id, fn);
    }
  }
};

Content.getSectionContent = (content, id) => {
  let data = {};
  const section = Content.get(content, id);
  Content.traverseDescendants(content, id, (block) => (data[block.id] = block));
  return { data, sections: [section] };
};

Content.mergeToSection = (setter, selection) => {
  setter((old) => {
    const firstSection = selection?.multiSelect?.sections?.[0];
    const index = old?.sections?.findIndex((section) => section?.id === firstSection?.id);

    const composition = getComposition(old);
    const sectionAction = createSectionAction(index);
    attachAction(composition, sectionAction);
    let sectionIds = [];
    for (const item of selection?.multiSelect?.sections) {
      if (item?.type === 'section') {
        sectionIds.push(item?.id);
        const action = getAction(composition, item?.id);
        attachAction(composition, action);
        updateAction(composition, action?.id, 'remove', {});
      }
    }

    let children = [];
    for (const item of selection?.multiSelect?.list) {
      const action = getAction(composition, item?.id);
      if (sectionIds.includes(action?.data?.parentId)) {
        attachAction(composition, action);
        updateAction(composition, action?.id, 'update', { parentId: sectionAction?.id });
        const child = getChildFromAction(action);
        children.push(child);
      }
    }
    updateAction(composition, sectionAction?.id, 'update', {
      children,
      properties: { isGrouped: true },
    });

    removeEmptyActions(composition);
    const actions = compileCompositionActions(composition);
    const content = Content.commit(old, actions);
    return { content, actions };
  });
};
