import { css } from '@emotion/css';
import { Box, Div } from '../shared/components';
import { flex } from '../shared/shared-styles';
import { DesignStylesList } from './design-styles-list';
import { DesignStylesViewer } from './design-styles-viewer';
import { EditStyle } from './edit-style/edit-style';

export const DesignStyles = (props) => {
  const { designStyleProps } = props;
  const { current } = designStyleProps || {};

  return (
    <Div
      css={css`
        width: 100%;
      `}
    >
      <Box
        flex="center start"
        css={`
          width: 100%;
          max-width: 1200px;
          margin: 0 auto;
          padding-top: 16px;
        `}
      >
        {!current?.ID ? null : <EditStyle {...props} />}
        {!current?.ID ? <DesignStylesList {...props} /> : <DesignStylesViewer {...props} />}
      </Box>
    </Div>
  );
};

export const leftPanelStyle = css`
  flex-grow: 1;
  max-width: 700px;
  padding-bottom: 100px;
`;

export const colorContainer = (color) => css`
  width: 24px;
  height: 24px;
  background-color: ${color};
  outline: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
`;

export const buttonContainer = (data) => {
  const fontFamily = data?.Body?.Font?.Label
    ? `font-family: ${data?.Body?.Font.Label}, "${data?.Body?.Font.Value}";`
    : ``;
  return css`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 8px 22px;
    gap: 8px;
    border: none;
    border-radius: 8px;
    font-weight: 700;
    font-style: normal;
    font-size: 15px;
    ${fontFamily}
    letter-spacing: 0.46px;
    box-sizing: border-box;
    cursor: pointer;
    outline: none;
    width: max-content;
    background-color: ${data?.Body?.ButtonColor};
    color: ${data?.Body?.ButtonProperties?.FontColor || data?.Body?.BackgroundColor};
  `;
};

export const lineContainer = (data) => {
  return css`
    border-top-width: ${data?.Line?.Weight || '2'}px;
    border-top-style: ${data?.Line?.Style || 'solid'};
    border-top-color: ${data?.Line?.Color || 'var(--accent-color-0)'};
    border-radius: ${data?.Line?.BorderRadius || '0'}px;
  `;
};
