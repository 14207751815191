import { Tooltip } from '../../../common/components/Tooltip';
import { Box, Text } from '../../../shared/components';
import { Toolbar } from '../../utility/Toolbar';
import { SpaceToolbar } from './SpaceToolbar';
import { useSpaceBlock } from './useSpaceBlock';
import { getNumber } from '../../sidebar/Settings/property-utils';
import { overlay } from '../../utility/styles';
import { colors } from '../../../shared/styles';
import { animation } from '../../../shared/shared-styles';

export const SpaceBlock = () => {
  const block = useSpaceBlock();
  const weight = getNumber(block?.lineStyles?.borderTopWidth);

  return (
    <>
      {block?.hasPageBreak ? <PageBreakSpace block={block} /> : null}
      <Box
        css={`
          position: relative;
          overflow: hidden;
          transition: border-radius 0.2s ease, min-height 0.2s ease;
          min-height: ${block?.hasPageBreak ? '64px' : '8px'};
        `}
        flex="center"
        style={block.styles}
      >
        <Box
          style={block?.lineStyles}
          css={`
            position: absolute;
            top: calc(50% - ${weight / 2}px);
            width: 100%;
            pointer-events: none;
          `}
          flex="center"
        />
        {block?.hasPageBreak ? null : <DefaultSpace block={block} />}
      </Box>
      <Toolbar isSelected={block.isSelected} anchor={block.anchor}>
        <SpaceToolbar
          id={block.id}
          height={block.height}
          bgColor={block.bgColor}
          breakAfter={block.breakAfter}
          type={block?.segment?.type}
        />
      </Toolbar>
    </>
  );
};

const DefaultSpace = ({ block }) =>
  block?.isSelected ? (
    <Tooltip label={`Min Height ${block.height.value}`}>
      <Box
        css={`
          outline: 1px dotted rgba(200, 200, 200, 0.7);
          width: 100%;
          height: ${block.height.value}px;
          :hover {
            background-color: rgba(150, 150, 150, 0.1);
          }
        `}
      />
    </Tooltip>
  ) : null;

const PageBreakSpace = ({ block }) => {
  return (
    <Tooltip label={block?.isSelected ? 'Page breaks only affect the exported PDF.' : ''}>
      <Box
        css={`
          ${overlay}
          left: -100%;
          right: -100%;
          z-index: 100;
          background-color: rgba(0, 0, 0, 0.05);
          box-shadow: inset 0px 4px 10px ${colors.gray[600]}44;
          border-bottom: 1px dashed ${colors.gray[300]};
          border-top: 1px dashed ${colors.gray[300]};
          ${animation('fadeIn', '.2s ease')}
        `}
        flex="center"
      >
        <Text
          css={`
            background-color: rgba(0, 0, 0, 0.4);
            padding: 0 8px;
            border-radius: 30px;
            color: white;
            font-weight: bold;
          `}
        >
          Page break
        </Text>
      </Box>
    </Tooltip>
  );
};
