import { MagnifyingGlass } from '@phosphor-icons/react';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { useModal } from '../common/hooks/useModal';
import { Box, Button, Loader, Input, Text } from '../shared/components';
import { colors } from '../shared/styles';
import { getDashboards } from './api/dashboard';
import { getGuides } from './api/guide';
import { DashboardModal } from './modals/DashboardModal';
import { useGuidesList } from './utils/hooks/useGuidesList';
import { DashboardCard } from './DashboardCard';
import { FilterDropdown } from './FilterDropdown';
import { GuideCard } from './GuideCard';
import { SortDropdown } from './SortDropdown';

export const MultiGuideAnalyticsPage = () => {
  // Routing
  const history = useHistory();
  const { businessId } = useParams();

  // Queries
  const {
    data: dashboards,
    isLoading: isLoadingDashboards,
    refetch,
  } = useQuery(['dashboards', businessId], () => getDashboards({ businessID: businessId }));
  const { data: guides, isLoading: isLoadingGuides } = useQuery(['guides', businessId], getGuides);

  // State
  const [search, setSearch] = useState('');
  const [filterVal, setFilterVal] = useState([]);
  const [sortVal, setSortVal] = useState('mostviews');

  // Custom Hooks
  const guidesList = useGuidesList({ guides, search, filterVal, sortVal });

  // Modals
  const dashboardModal = useModal();

  // Handlers
  const handleGuideSelect = (guide) => {
    history.push(`/${businessId}/courses/${guide.ID}/analytics`);
  };

  if (dashboardModal.isOpen) {
    return <DashboardModal hide={() => dashboardModal.hide()} refetch={refetch} />;
  }

  return (
    <Box
      css={`
        display: flex;
        flex-direction: column;
        gap: 40px;
      `}
    >
      <Box
        css={`
          display: flex;
          justify-content: space-between;
        `}
      >
        <Text h1>Analytics</Text>
        <Button onClick={() => dashboardModal.toggle()}>New Dashboard</Button>
      </Box>
      {!isLoadingDashboards && dashboards.length !== 0 && (
        <Box
          css={`
            display: flex;
            flex-direction: column;
            gap: 16px;
          `}
        >
          <Text h2>Dashboards</Text>
          <Box
            css={`
              display: grid;
              grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
              gap: 24px;
            `}
          >
            {dashboards.map((dashboard) => (
              <DashboardCard key={dashboard.id} data={dashboard} refetch={refetch} />
            ))}
          </Box>
        </Box>
      )}
      <Box
        css={`
          display: flex;
          flex-direction: column;
          gap: 16px;
        `}
      >
        <Text h2>Guides</Text>
        <Box
          css={`
            display: flex;
            justify-content: space-between;
            gap: 16px;
          `}
        >
          <Box>
            <Input
              startIcon={<MagnifyingGlass size={24} color={colors.gray[500]} />}
              styles="search"
              css={`
                display: flex;
                width: 300px;
                padding: 0px 16px;
                align-items: center;
              `}
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>
          <Box
            css={`
              display: flex;
              gap: 16px;
            `}
          >
            <FilterDropdown setFilterVal={setFilterVal} filterVal={filterVal} />
            <SortDropdown setSortVal={setSortVal} sortVal={sortVal} />
          </Box>
        </Box>
      </Box>
      {isLoadingGuides ? (
        <Loader />
      ) : (
        <Box
          css={`
            display: flex;
            flex-direction: column;
            gap: 24px;
          `}
        >
          {guidesList?.map((guide) => (
            <GuideCard key={guide.ID} onSelect={() => handleGuideSelect(guide)} guide={guide} />
          ))}
        </Box>
      )}
    </Box>
  );
};
