import { PlusCircle } from '@phosphor-icons/react';
import { Box } from '../../../shared/components';
import { Editable, Slate } from 'slate-react';
import { useAccordionBlock } from './useAccordionBlock';
import { slateContainer } from '../../utility/styles';
import { Collapse } from '../../../common/components/Collapse';
import { Conditional } from '../../utility/Conditional';
import { UpdateManager } from './UpdateManager';
import { Toolbar } from '../../utility/Toolbar';

export const AccordionBlock = () => {
  const block = useAccordionBlock();

  return (
    <>
      <Box
        css={`
          padding: 16px;
          border: 1px solid var(--accent-color-4);
          background-color: var(--background-color);
          border-radius: 8px;
          width: 100%;
          margin: 8px;
          .plus-icon {
            transition: transform 0.4s ease;
            transform: ${block?.expanded?.value ? 'rotate(45deg)' : 'rotate(0deg)'};
          }
        `}
        onClick={() => block?.expanded?.set(!block?.expanded?.value)}
      >
        <Conditional if={block?.isSelected}>
          <UpdateManager block={block} />
        </Conditional>
        <Box
          flex="left"
          css={`
            gap: 16px;
            svg {
              min-width: 32px;
            }
          `}
        >
          <PlusCircle className="plus-icon" color="var(--button-background-color)" weight="fill" />
          <Box
            css={`
              width: 100%;
            `}
            onClick={(e) => e.stopPropagation()}
          >
            <Slate {...block?.title?.slateProps}>
              <Editable
                {...block?.title?.editorProps}
                style={block?.titleStyle}
                className={slateContainer}
                readOnly={!block?.isSelected}
                placeholder="Subject"
              />
            </Slate>
          </Box>
        </Box>
        <Collapse isOpen={block?.expanded?.value}>
          <Box
            css={`
              margin-left: 48px;
              margin-top: 8px;
            `}
            onClick={(e) => e.stopPropagation()}
          >
            <Slate {...block?.body?.slateProps}>
              <Editable
                {...block?.body?.editorProps}
                style={block?.bodyStyle}
                className={slateContainer}
                readOnly={!block?.isSelected}
                placeholder="Description"
              />
            </Slate>
          </Box>
        </Collapse>
      </Box>
      <Toolbar isSelected={block?.isSelected} anchor={block?.anchor} />
    </>
  );
};
