import { AGGREGATION_ALL, AGGREGATION_INDIVIDUAL, formatDateByDay, formatDateByMonth } from './dashboard';

// GUIDE TRAFFIC CHART

export const TIME_INTERVAL_DAY = { label: 'Day', value: 'day' };
export const TIME_INTERVAL_WEEK = { label: 'Week', value: 'week' };
export const TIME_INTERVAL_MONTH = { label: 'Month', value: 'month' };
export const TIME_INTERVAL_OPTIONS = [TIME_INTERVAL_DAY, TIME_INTERVAL_WEEK, TIME_INTERVAL_MONTH];

export const LINE_SERIES_TOTAL_VIEWS = 'Total Views';
export const LINE_SERIES_UNIQUE_VISITORS = 'Unique Visitors';
export const LINE_SERIES_OPTIONS = [LINE_SERIES_TOTAL_VIEWS, LINE_SERIES_UNIQUE_VISITORS];

export const formatDateByTimeInterval = (date, timeInterval) =>
  timeInterval === TIME_INTERVAL_MONTH ? formatDateByMonth(date) : formatDateByDay(date);

export const mapGuideTrafficData = ({ dashboard, cumulative, aggregation, timeInterval, data }) => {
  if (aggregation === AGGREGATION_ALL) {
    let cumulativeTotal = 0;
    return {
      GuideNames: LINE_SERIES_OPTIONS,
      Data: data.ViewsPerInterval.map(
        ({
          TimeInterval: date,
          TotalCumulativeUniqueUsersPerInterval,
          TotalUniqueUsersPerInterval,
          TotalViewsPerInterval,
        }) => {
          // Calculate cumulative total views if required.
          if (cumulative) cumulativeTotal += TotalViewsPerInterval;
          return {
            Date: formatDateByTimeInterval(date, timeInterval),
            [LINE_SERIES_TOTAL_VIEWS]: cumulative ? cumulativeTotal : TotalViewsPerInterval,
            [LINE_SERIES_UNIQUE_VISITORS]: cumulative
              ? TotalCumulativeUniqueUsersPerInterval
              : TotalUniqueUsersPerInterval,
          };
        }
      ),
    };
  } else if (aggregation === AGGREGATION_INDIVIDUAL) {
    // Extract guide names from the dashboard configuration.
    const GuideNames = dashboard?.config?.guides?.map(({ Name }) => Name) || [];
    const LastKnownValues = {};

    return {
      GuideNames,
      Data: data.ViewsPerInterval.map(({ GuideVisits, TimeInterval: date }) => {
        // Initialize the guide views for the current interval with last known values.
        const GuideViewsPerInterval = { Date: formatDateByTimeInterval(date, timeInterval) };

        // Populate initial values for each guide.
        GuideNames.forEach((guideName) => {
          GuideViewsPerInterval[guideName] = cumulative ? LastKnownValues[guideName] || 0 : 0;
        });

        // Update the guide views with the current interval data.
        GuideVisits.forEach(({ GuideName, TotalCumulativeUnique, TotalUnique }) => {
          const uniqueVisitors = cumulative ? TotalCumulativeUnique : TotalUnique;
          GuideViewsPerInterval[GuideName] = uniqueVisitors;
          LastKnownValues[GuideName] = uniqueVisitors;
        });

        return GuideViewsPerInterval;
      }),
    };
  }
};

// TRAFFIC SOURCES CHART

export const SOURCE_GUIDE_LINK = 'Guide Link';
export const SOURCE_QR_CODE = 'QR Code';
export const SOURCE_TEXT = 'Text';
export const SOURCE_EMAIL = 'Email';
export const SOURCE_SLACK = 'Slack';
export const SOURCE_BRITE_HANDOUT = 'Brite Handout';
export const SOURCE_TEAMS = 'Teams';
export const SOURCE_OTHER = 'Other';

export const SOURCES_COLOR_SCALE = {
  [SOURCE_GUIDE_LINK]: '#A085FC',
  [SOURCE_QR_CODE]: '#DA66E8',
  [SOURCE_TEXT]: '#FB59E1',
  [SOURCE_EMAIL]: '#FF719C',
  [SOURCE_SLACK]: '#FF9586',
  [SOURCE_BRITE_HANDOUT]: '#FFAF71',
  [SOURCE_TEAMS]: '#FFC559',
  [SOURCE_OTHER]: '#9AA7B5',
};

const formatSources = ({ GuideName, Sources, TotalCount }) => {
  // Calculate the percentage for each source.
  const sourcesWithPercentages = Sources.reduce((acc, { Value, Count }) => {
    acc[Value] = { Count, Percent: ((Count / TotalCount) * 100).toFixed(1) };
    return acc;
  }, {});

  // Ensure the total percentage sums to 100% by adjusting for any rounding differences.
  const totalPercentage = Object.values(sourcesWithPercentages).reduce(
    (sum, source) => sum + parseFloat(source.Percent),
    0
  );
  const difference = 100 - totalPercentage;

  // Adjust the last source's percentage to account for any rounding differences.
  const sourceKeys = Object.keys(sourcesWithPercentages);
  if (sourceKeys.length > 0) {
    const lastSourceKey = sourceKeys[sourceKeys.length - 1];
    sourcesWithPercentages[lastSourceKey].Percent = (
      parseFloat(sourcesWithPercentages[lastSourceKey].Percent) + difference
    ).toFixed(1);
  }

  return { GuideName, ...sourcesWithPercentages };
};

export const mapTrafficSourcesData = ({ data }) => {
  // Initialize an object to accumulate traffic source data across all guides.
  const aggregatedTrafficSource = { GuideName: AGGREGATION_ALL.label, Sources: [], TotalCount: 0 };

  // Process each guide's traffic source data.
  const sources = data.ViewsBySource.map(({ GuideName, Sources, TotalCount }) => {
    // Update the cumulative traffic source data for each source in the current guide.
    Sources.forEach((source) => {
      const existingSource = aggregatedTrafficSource.Sources.find((s) => s.Value === source.Value);
      if (existingSource) {
        // Increment the count if the source already exists in the cumulative data.
        existingSource.Count += source.Count;
      } else {
        // Add the new source to the cumulative data if it doesn't exist.
        aggregatedTrafficSource.Sources.push({ ...source });
      }
    });

    // Increment the total count for the cumulative data.
    aggregatedTrafficSource.TotalCount += TotalCount;

    return { GuideName, Sources, TotalCount };
  });

  return {
    [AGGREGATION_ALL.label]: [formatSources(aggregatedTrafficSource)],
    [AGGREGATION_INDIVIDUAL.label]: sources.map(formatSources),
  };
};

// VISITORS BY SOURCE CHART

export const mapVisitorsBySourceData = ({ guide, cumulative, timeInterval, data }) => {
  const GuideName = guide?.label; // Extract the guide name from the provided guide object.
  const LastKnownValues = {}; // Store the last known cumulative values for each source.

  const Data = data.SourcesPerInterval.map(({ GuideSources, TimeInterval: date }) => {
    // Initialize the data object for the current time interval.
    const GuideSourcesPerInterval = { Date: formatDateByTimeInterval(date, timeInterval) };

    // Pre-fill all sources with either 0 or the last known value (for cumulative mode).
    for (const source in SOURCES_COLOR_SCALE) {
      GuideSourcesPerInterval[source] = cumulative ? LastKnownValues[source] || 0 : 0;
    }

    // Update the source counts for the current interval.
    for (const { Sources } of GuideSources) {
      for (const { Value, Count } of Sources) {
        // Update the count based on whether cumulative mode is enabled.
        GuideSourcesPerInterval[Value] = cumulative ? (LastKnownValues[Value] || 0) + Count : Count;

        // Update the last known value for the source.
        LastKnownValues[Value] = GuideSourcesPerInterval[Value];
      }
    }

    return GuideSourcesPerInterval; // Return the processed data for the current interval.
  });

  return { GuideName, Data }; // Return the guide name and the processed data.
};

// VISITORS BY DEVICE CHART

export const TRAFFIC_DEVICES_COLOR_SCALE = {
  desktop: '#A085FC',
  mobile: '#FF719C',
  tablet: '#FFAF71',
};

export const mapVisitorsByDeviceData = ({ data }) => {
  // Initialize an object to aggregate visitor data by device across all guides.
  const aggregatedVisitorsByDevice = { GuideName: AGGREGATION_ALL.label };
  let totalVisitorCount = 0;

  // Aggregate device data across all guides.
  data.ViewsByDevice.forEach(({ Devices }) => {
    Object.entries(Devices).forEach(([device, count]) => {
      // Initialize the device entry if it doesn't exist.
      if (!aggregatedVisitorsByDevice[device]) {
        aggregatedVisitorsByDevice[device] = { Count: 0, Percent: 0 };
      }
      // Accumulate the count for the device.
      aggregatedVisitorsByDevice[device].Count += count;
      totalVisitorCount += count;
    });
  });

  // Calculate percentages for each device in the aggregated data.
  Object.entries(aggregatedVisitorsByDevice).forEach(([device, data]) => {
    if (device !== 'GuideName') {
      data.Percent = ((data.Count / totalVisitorCount) * 100).toFixed(1);
    }
  });

  // Process individual guide data.
  const individualVisitorsByDevice = data.ViewsByDevice.map(({ GuideName, Devices }) => {
    const totalGuideVisitorCount = Object.values(Devices).reduce((sum, count) => sum + count, 0);

    // Calculate percentages for each device in the current guide.
    const devicesWithPercentages = Object.entries(Devices).reduce((acc, [device, count]) => {
      acc[device] = { Count: count, Percent: ((count / totalGuideVisitorCount) * 100).toFixed(1) };
      return acc;
    }, {});

    return { GuideName, ...devicesWithPercentages };
  });

  return {
    [AGGREGATION_ALL.label]: [aggregatedVisitorsByDevice],
    [AGGREGATION_INDIVIDUAL.label]: individualVisitorsByDevice,
  };
};
