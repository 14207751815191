import { Children, cloneElement, useState } from 'react';
import { usePopper } from 'react-popper';
import { useThemedProps } from '../../shared/useThemedProps';
import { colors } from '../../shared/styles';
import { Portal } from '../../shared/components/Portal';
import { Box, Text } from '../../shared/components';
import { shadows } from '../../shared/shared-styles';
import { useEvent } from '../../shared/use-event';

export const tooltipTheme = {
  default: `
    pointer-events: none;
    z-index: 2000;
    background-color: ${colors.black};
    ${shadows.lg}
    padding: 8px 16px;
    border-radius: 8px;
    max-width: 300px;
    p {
      color: white;
      font-weight: bold;
    }
    p[data-description="true"] {
      color: ${colors.gray[100]};
    }
    strong {
      background-color: rgba(255, 255, 255, .2);
      border-radius: 4px;
      padding: 2px 4px;
    }
  `,
};

export const Tooltip = ({ label = '', popper: popperProps = {}, description = '', children, ...rest }) => {
  // We only want one child for the tooltip
  const child = Children.only(children);
  const tooltipProps = useThemedProps(rest, tooltipTheme);
  const [anchor, setAnchor] = useState(null);
  const [tooltip, setTooltip] = useState(null);
  const { styles, attributes } = usePopper(anchor, tooltip, {
    placement: 'top',
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, 5], // Horizontal (x-axis) offset is 0, vertical (y-axis) offset is 5px
        },
      },
    ],
    ...popperProps,
  });
  const [open, setOpen] = useState(false);

  useEvent('mouseenter', () => setOpen(true), { element: anchor });
  useEvent('mouseleave', () => setOpen(false), { element: anchor });

  if (!label) {
    return child;
  }

  return (
    <>
      {cloneElement(child, { ref: setAnchor })}
      {open ? (
        <Portal>
          <Box {...tooltipProps} ref={setTooltip} style={styles.popper} {...attributes.popper}>
            <Text helper>{label}</Text>
            {description && (
              <Text helper data-description={'true'} css="margin-top: 4px;">
                {description}
              </Text>
            )}
          </Box>
        </Portal>
      ) : null}
    </>
  );
};
