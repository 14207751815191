import ReactCrop from 'react-image-crop';
import { Box, Button, Dropdown, Modal } from '../../shared/components';
import { colors } from '../../shared/styles';
import { useRef } from 'react';
import { useSync } from './useSync';
import { CircleDashed, FlipHorizontal, FlipVertical, Selection, Trash } from '@phosphor-icons/react';
import { getImageTransform } from '../blocks/image-block/image-helpers';
import { Tooltip } from '../../common/components/Tooltip';

const ASPECT_RATIO_MAP = {
  none: { label: 'None', value: undefined },
  square: { label: 'Square 1:1', value: 1 },
  normal: { label: 'Normal 4:3', value: 4 / 3 },
  wide: { label: 'Wide 16:9', value: 16 / 9 },
  cinema: { label: 'Cinema 21:9', value: 21 / 9 },
};

export const ImageCropper = ({ img, currentCrop, onClose, updateCrop }) => {
  const ref = useRef();

  const {
    scale = 1,
    rotate = 0,
    circularCrop = false,
    aspectRatioValue = 'none',
    horizontalFlip = false,
    verticalFlip = false,
    ...rest
  } = currentCrop || {};

  const sync = useSync({
    crop: rest || undefined,
    aspectRatioValue,
    horizontalFlip,
    verticalFlip,
    circularCrop,
    rotate,
    scale,
  });

  const aspect = ASPECT_RATIO_MAP?.[sync?.value?.aspectRatioValue]?.value || undefined;

  const updateCircularToggle = (value) => {
    sync?.merge({
      circularCrop: value,
      aspectRatioValue: 'none',
      crop: null,
    });
  };

  const resetEdits = () => {
    sync?.set({
      crop: undefined,
      circularCrop: false,
      rotate: 0,
      scale: 1,
      aspectRatioValue: 'none',
      horizontalFlip: false,
      verticalFlip: false,
    });
  };

  const classer = sync?.value?.circularCrop ? 'circle' : 'square';

  const update = () => {
    if (!sync?.value?.crop) {
      updateCrop(null);
    } else {
      const { crop = {}, ...rest } = sync?.value;
      updateCrop({ ...crop, ...rest });
    }
    onClose();
  };

  const selectAll = () => {
    sync?.merge({
      crop: {
        unit: '%',
        x: 0,
        y: 0,
        width: 100,
        height: 100,
      },
    });
  };

  const verticalProps = sync?.value?.verticalFlip
    ? { weight: 'fill', color: colors.purple }
    : { weight: 'regular', color: colors.black };
  const horizontalProps = sync?.value?.horizontalFlip
    ? { weight: 'fill', color: colors.purple }
    : { weight: 'regular', color: colors.black };

  const hasCrop = Object.keys(sync?.value?.crop || {}).length > 0;

  return (
    <Modal display data-scope="modal" onClose={onClose}>
      <Modal.Paper
        width="100%"
        css={`
          max-width: 800px;
        `}
      >
        <Modal.Header title="Crop Image" onClose={onClose} />
        <Modal.Body>
          <Box
            css={`
              max-width: 800px;
              width: 100%;
              .${classer} {
                transition: background-color 0.2s ease;
                background-color: ${colors.purple};
                svg {
                  color: white;
                }
              }
            `}
          >
            <Box
              flex="left"
              css={`
                gap: 16px;
                margin-bottom: 16px;
              `}
            >
              <Box
                flex="left"
                css={`
                  border-radius: 30px;
                  background-color: ${colors.gray[100]};
                  border: 1px solid ${colors.gray[300]};
                  width: min-content;
                  gap: 4px;
                  padding: 4px;
                `}
              >
                <Button styles="icon sm" className="square" onClick={() => updateCircularToggle(false)}>
                  <Selection size={24} color="currentColor" />
                </Button>
                <Button styles="icon sm" className="circle" onClick={() => updateCircularToggle(true)}>
                  <CircleDashed size={24} color="currentColor" />
                </Button>
              </Box>
              <Box
                flex="left"
                css={`
                  flex-grow: 1;
                  gap: 8px;
                  ${!hasCrop
                    ? `
                    opacity: 0.5;
                    pointer-events: none;  
                  `
                    : ''}
                `}
              >
                <Button styles="icon sm" onClick={() => sync?.merge({ verticalFlip: !sync?.value?.verticalFlip })}>
                  <FlipVertical size={24} {...verticalProps} />
                </Button>
                <Button styles="icon sm" onClick={() => sync?.merge({ horizontalFlip: !sync?.value?.horizontalFlip })}>
                  <FlipHorizontal size={24} {...horizontalProps} />
                </Button>
              </Box>
              <Dropdown
                clear
                button={(props) => (
                  <Tooltip label="Aspect Ratio">
                    <Dropdown.Select
                      css={`
                        width: 150px;
                      `}
                      {...props}
                      label={ASPECT_RATIO_MAP?.[sync?.value?.aspectRatioValue]?.label}
                    />
                  </Tooltip>
                )}
              >
                <Box
                  data-scope="menu"
                  css={`
                    width: 150px;
                  `}
                >
                  {Object.keys(ASPECT_RATIO_MAP).map((key) => (
                    <Box option onClick={() => sync?.merge({ aspectRatioValue: key })}>
                      {ASPECT_RATIO_MAP[key].label}
                    </Box>
                  ))}
                </Box>
              </Dropdown>
            </Box>
            <Box
              css={`
                background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.2)),
                  repeating-linear-gradient(
                    0deg,
                    rgba(255, 255, 255, 0.2) 0px,
                    rgba(255, 255, 255, 0.2) 1px,
                    transparent 1px,
                    transparent 20px
                  ),
                  repeating-linear-gradient(
                    90deg,
                    rgba(255, 255, 255, 0.2) 0px,
                    rgba(255, 255, 255, 0.2) 1px,
                    transparent 1px,
                    transparent 20px
                  );
                background-size: 20px 20px; /* Grid cell size */

                background-color: ${colors.gray[600]};
                padding: 48px;
                border-radius: 8px;
              `}
            >
              <Box
                flex="center"
                css={`
                  width: 100%;
                  overflow: visible;
                  .ReactCrop__child-wrapper {
                    max-width: 100%;
                    max-height: 100%;
                  }
                `}
                onDoubleClick={selectAll}
              >
                <ReactCrop
                  style={{
                    overflow: 'hidden',
                    backgroundColor: colors.gray[600],
                  }}
                  crop={sync?.value?.crop}
                  onChange={(c, crop) => sync?.merge({ crop })}
                  onDragEnd={(e) => {
                    if (sync?.value?.crop.height === 0 && sync?.value?.crop.width === 0) {
                      resetEdits();
                    }
                  }}
                  circularCrop={sync?.value?.circularCrop}
                  aspect={aspect}
                  ruleOfThirds
                >
                  <img
                    alt="Cropped"
                    src={img?.src}
                    ref={ref}
                    style={{
                      transform: getImageTransform(sync?.value),
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                  />
                </ReactCrop>
              </Box>
            </Box>
          </Box>
        </Modal.Body>
        <Modal.Actions>
          <Button
            styles="icon sm"
            hoverLabel="Remove Edits"
            css={`
              margin-right: auto;
            `}
            onClick={resetEdits}
          >
            <Trash size={24} />
          </Button>
          <Button secondary onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={update}>Update</Button>
        </Modal.Actions>
      </Modal.Paper>
    </Modal>
  );
};
