import { useEffect, useRef } from 'react';

export function useInterval(callback, delay, options = {}) {
  const { enabled = true } = options;

  const interval = useRef();
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      if (!!enabled) {
        interval.current = setInterval(tick, delay);
      }
      return () => clearInterval(interval.current);
    }
  }, [enabled, delay]);

  return interval;
}
