import { useContext } from 'react';
import { ContentContext } from './Content';
import { useToolKit } from '../tool-kit/use-tool-kit';
import { ToolKit } from '../tool-kit/ToolKit';

export const useContent = (options = {}) => {
  // ignoreSelection should only be used in very specific cases.
  // useContent is designed to only be used within a component
  // that is conditionally rendered within a selected state.
  const { ignoreSelection = false } = options;

  const toolkit = useToolKit();
  const hasSelection = ToolKit.hasSelection(toolkit);

  if (!hasSelection && !ignoreSelection) {
    throw new Error('useContent cannot be used outside a selection context');
  }

  return useContext(ContentContext);
};
