import { useMemo } from 'react';
import { Box, Text } from '../../../shared/components';
import { ActionSetting } from '../Properties/ActionSetting';
import { AlignSetting } from '../Properties/AlignSetting';
import { ColorSetting } from '../Properties/ColorSetting';
import { PaddingSetting } from '../Properties/PaddingSetting';
import { RadiusSetting } from '../Properties/RadiusSetting';
import { SliderSetting } from '../Properties/SliderSetting';
import { StrokeSetting } from '../Properties/StrokeSetting';
import { PropertiesGrouping } from './PropertiesGrouping';
import {
  getActionProperties,
  getAlignProperties,
  getBgColorProperties,
  getPaddingProperties,
  getRadiusProperties,
  getWidthProperties,
} from './property-utils';

export const ImageSettings = (props) => {
  const width = getWidthProperties(props);
  const horizontalAlign = getAlignProperties({ ...props, alignKey: 'justifyContent' });
  const verticalAlign = getAlignProperties({ ...props, alignKey: 'alignItems' });
  const actions = getActionProperties(props);
  const padding = getPaddingProperties(props);
  const imageRadius = getRadiusProperties({ ...props, type: 'block' });
  const containerRadius = getRadiusProperties({ ...props, type: 'container' });
  const bgColor = getBgColorProperties(props);

  return (
    <Box>
      <PropertiesGrouping label="Action" description={actions.description}>
        <ActionSetting value={actions.value} setValue={actions.setValue} />
      </PropertiesGrouping>
      <PropertiesGrouping label="Styles" defaultOpen>
        <RadiusSetting label="Image Radius" value={imageRadius.value} setValue={imageRadius.setValue} />
        <SliderSetting
          label={'Size'}
          props={{
            'aria-label': 'Size',
            min: 10,
            max: 100,
          }}
          value={width.value}
          setValue={width.setValue}
        />
      </PropertiesGrouping>
      <PropertiesGrouping label="Cell Styles">
        <AlignSetting
          label="Align"
          type="horizontal"
          value={horizontalAlign.value}
          setValue={horizontalAlign.setValue}
          disabled={width.value === 100}
        />
        <AlignSetting
          label="Vertical Align"
          type="vertical"
          value={verticalAlign.value}
          setValue={verticalAlign.setValue}
        />
        <ColorSetting {...bgColor} />
        <RadiusSetting label="Radius" value={containerRadius.value} setValue={containerRadius.setValue} />
        <StrokeSetting {...props} />
        <PaddingSetting value={padding.value} setValue={padding.setValue} />
      </PropertiesGrouping>
    </Box>
  );
};
