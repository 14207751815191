import React from 'react';
import { createRoot } from 'react-dom/client';
import { createTheme, ThemeProvider } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';
import { IconContext } from '@phosphor-icons/react';
import { ToastContainer } from 'react-toastify';
import { AppDecider } from './app-decider';
import errorHandler from './lib/stackdriver/errorHandler';
import { StoreProvider } from './store-provider/provider';
import 'react-toastify/dist/ReactToastify.css';
import './toastify-brite-theme.css';
import { colors } from './shared/styles';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const theme = createTheme({
  overrides: {
    MuiButton: {
      root: {
        paddingTop: '8px',
        paddingBottom: '8px',
        borderRadius: '8px',
      },
    },
    MuiTooltip: {
      tooltip: {
        fontFamily: 'Roboto',
        fontStyle: 'normal',
        fontWeight: 'bold',
        lineHeight: '24px',
        letterSpacing: '0.15px',
        fontSize: 16,
        backgroundColor: colors.black,
        color: 'white',
        padding: '8px 16px',
        borderRadius: '8px',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
      },
    },
  },
  typography: {
    fontFamily: ['Montserrat', 'Roboto', 'sans-serif'].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    button: {
      fontWeight: 700,
    },
  },

  palette: {
    text: {
      primary: '#25282D',
      secondary: '#66737F',
    },
    primary: {
      main: '#25282D',
    },
    action: {
      hover: '#D1DAE3',
    },
    secondary: {
      main: '#b1b1b1',
    },
  },
});

// Set defaults for all phosphor icons.
const phosphorTheme = {
  color: colors.black,
  size: 32,
  weight: 'regular',
  mirrored: false,
};

// init stackdriver error logging
const version = process.env.REACT_APP_GIT_SHA ? process.env.REACT_APP_GIT_SHA : 'unknown_version';
const branch = process.env.REACT_APP_GIT_BRANCH ? process.env.REACT_APP_GIT_BRANCH : 'unknown_branch';
window.b_version = `${branch}-${version}`;

errorHandler.start({
  key: 'AIzaSyAzd0qhj-7FGS0zdseN09-WnHoa_6BYjQA',
  projectId: 'be-brite',
  service: 'admin',
  version: branch + '.' + version,
  disabled: window.location.hostname === 'localhost',
});

console.log('VERSION >>>', version);
console.log('BRANCH >>>', branch);

setInterval(function () {
  window.location.reload();
  console.log('refresh to avoid token expiring after 12 hours');
}, 43200000);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <IconContext.Provider value={phosphorTheme}>
        <StoreProvider>
          <ToastContainer position="top-center" />
          <AppDecider />
        </StoreProvider>
      </IconContext.Provider>
    </ThemeProvider>
  </QueryClientProvider>,
  container
);
