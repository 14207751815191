import { startOfDay, endOfDay } from 'date-fns';
import { ArrowLeft, CalendarBlank } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useModal } from '../common/hooks/useModal';
import { Box, Button, Dropdown, Text } from '../shared/components';
import { DateRangeModal } from '../shared/DateRangeModal';
import { mediaQueryFn } from '../shared/styles';
import { useDashboard } from './context/DashboardContext';
import { DATE_RANGE_OPTIONS, formatDateByDay, getStartAndEndDate, LAST_90_DAYS } from './utils/dashboard';
import { DashboardDescription } from './DashboardDescription';

export const DashboardHeader = () => {
  // Context
  const { dashboard, range, updateRange, refetch } = useDashboard();

  // State
  const [dateRange, setDateRange] = useState(LAST_90_DAYS);
  const [isDescriptionVisible, setIsDescriptionVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      setIsDescriptionVisible(scrollTop === 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // Modals
  const dateRangeModal = useModal();

  const handleDateRangeChange = (dateRange) => {
    if (dateRange === 'Custom') {
      dateRangeModal.toggle();
    } else {
      const { startDate, endDate } = getStartAndEndDate(dateRange);
      if (startDate && endDate) {
        setDateRange(dateRange);
        updateRange({ startDate, endDate });
      }
    }
  };

  // Routing
  const history = useHistory();
  const { businessId } = useParams();

  const goBackToDashboard = () => history.push(`/${businessId}/analytics`);

  return (
    <>
      {dateRangeModal.isOpen && (
        <DateRangeModal
          title="Custom Date Range"
          modalProps={dateRangeModal}
          range={{
            start: range.startDate,
            end: range.endDate,
          }}
          setRange={({ start, end }) => {
            // Adjust the start and end dates to include the full day.
            const adjustedStart = startOfDay(new Date(start));
            const adjustedEnd = endOfDay(new Date(end));

            // Format the adjusted dates for display.
            const formattedRange = `${formatDateByDay(adjustedStart)} - ${formatDateByDay(adjustedEnd)}`;

            // Update the date range in the context and local state.
            updateRange({ startDate: adjustedStart, endDate: adjustedEnd });
            setDateRange(formattedRange);

            // Close the modal after updating the range.
            dateRangeModal.hide();
          }}
        />
      )}
      <Box
        css={`
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          width: 100%;
        `}
      >
        <Box
          css={`
            display: flex;
            justify-content: space-between;
            width: 100%;
          `}
        >
          <Box
            css={`
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 8px;
            `}
          >
            <Button
              icon
              css={`
                padding: 0px;
              `}
              onClick={() => goBackToDashboard()}
            >
              <ArrowLeft />
            </Button>
            <Text h1 ellipsis>
              {dashboard?.name}
            </Text>
          </Box>
          <Box
            css={`
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 16px;
            `}
          >
            {/* Uncomment this code block when the settings button is implemented. */}
            {/* <Button
            icon
            css={`
              padding: 0px;
            `}
          >
            <DotsThree />
          </Button> */}
            <Dropdown
              button={() => (
                <Button
                  secondary
                  css={`
                    text-transform: capitalize;
                    padding: 12px 16px;
                    gap: 8px;
                  `}
                >
                  <CalendarBlank size={24} weight="bold" />
                  <Text
                    body
                    css={`
                      ${mediaQueryFn('less-than', '1200px')} {
                        display: none;
                      }
                    `}
                  >
                    {dateRange}
                  </Text>
                </Button>
              )}
            >
              {DATE_RANGE_OPTIONS.map((option) => (
                <Box option key={option} onClick={() => handleDateRangeChange(option)}>
                  {option}
                </Box>
              ))}
            </Dropdown>
            {/* Uncomment this code block when share button is implemented */}
            {/* <Button
            primary
            css={`
              padding: 12px 24px;
              gap: 8px;
            `}
          >
            <Text
              h4
              css={`
                font-size: 14px;

                ${mediaQueryFn('less-than', '1200px')} {
                  display: none;
                }
              `}
              color="#FFFFFF"
            >
              Share
            </Text>
            <PaperPlaneTilt color="#FFFFFF" size={24} weight="bold" />
          </Button> */}
          </Box>
        </Box>
        <Box
          css={`
            opacity: ${isDescriptionVisible ? 1 : 0};
            transform: translateY(${isDescriptionVisible ? '0' : '-20px'});
            height: ${isDescriptionVisible ? 'auto' : '0'};
            transition: opacity 0.3s ease, transform 0.3s ease, height 0.3s ease;
            width: 100%;
          `}
        >
          <DashboardDescription data={dashboard} refetch={refetch} />
        </Box>
      </Box>
    </>
  );
};
