import { Box, Text, Toggle } from '../../../shared/components';

export const ChartControlToggle = ({ label = '', value = false, onChange = () => {}, toggleProps = {} }) => {
  return (
    <Box
      css={`
        display: flex;
        justify-content: center;
        align-items: center;
      `}
    >
      <Text body>{label}</Text>
      <Toggle {...toggleProps} value={value} onChange={() => onChange()} />
    </Box>
  );
};
