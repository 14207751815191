import { MenuItem } from '@mui/material';
import { css } from '@emotion/css';
import { DotsThree, X } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { fetchCourses } from '../Courses/course-requests';
import { history } from '../history';
import { useQueryAPI } from '../react-query';
import { CustomAxios } from '../redux/axios/axios';
import { Button, Div, DropMenu, Loader, Modal, Select, Text } from '../shared/components';
import { flex } from '../shared/shared-styles';
import { colors } from '../shared/styles';
import { useStateSync } from '../shared/use-state-sync';
import { getUnlayerPreview } from './unlayer-preview';
import { useAcls } from '../shared/use-acls';
import { WRITE_COURSE } from '../shared/acl-constants';

export const TemplateModal = ({ template, businessId, setModalItems, ...modalProps }) => {
  const [unlayerImgSrc, setUnlayerImgSrc] = useState('');
  const [isLoadingUnlayer, setIsLoadingUnlayer] = useState(false);

  const { data: courses, isLoading } = useQueryAPI({
    urlKey: 'v1/courses',
    queryFn: fetchCourses,
    select: (data) => data.filter(({ ArchivedAt }) => ArchivedAt === null),
    defaultValue: [],
  });

  const [guideId, setGuideId] = useStateSync('', [modalProps.display]);
  const [loading, setLoading] = useState(false);
  const canEditTemplates = useAcls([WRITE_COURSE]);

  const updateUnlayerImgSrc = async () => {
    if (template?.Content) {
      try {
        setIsLoadingUnlayer(true);
        const url = await getUnlayerPreview(template?.Content);
        setUnlayerImgSrc(url);
      } catch (err) {
        console.warn(err);
      } finally {
        setIsLoadingUnlayer(false);
      }
    }
  };

  useEffect(() => {
    if (template?.Type === 'regular') {
      updateUnlayerImgSrc();
    }
  }, [template?.Content]);

  const addTemplate = async () => {
    const defaultPage = {
      Type: template?.Type || 'regular',
      Name: template?.Name,
      Content: template?.Content,
      FrontendID: '_' + Math.random().toString(36).substr(2, 9),
      IsLocked: false,
      TemplateID: '00000000-0000-0000-0000-000000000000',
      CourseID: guideId,
    };

    try {
      setLoading(true);
      const { data } = await CustomAxios.post(`/v2/course/${guideId}/pages`, defaultPage);
      history.push(`/${businessId}/courses/${guideId}?pageId=${data?.ID}`);
    } catch (err) {
      console.warn(err);
      toast.error(`Failed to create new page.`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {modalProps.display && (
        <Modal {...modalProps}>
          <Div
            css={css`
              width: 700px;
            `}
          >
            <Div
              css={css`
                padding: 32px;
                width: 100%;
                ${flex('space-between')} box-sizing: border-box;
                border-bottom: 1px solid ${colors.gray[300]};
              `}
            >
              <Text h2>{template?.Name}</Text>
              <Button styles="icon" onClick={modalProps.onClose}>
                <X />
              </Button>
            </Div>

            <Div
              css={css`
                position: relative;
                width: 100%;
                height: 400px;
                padding: 0;
                ${template.Type === 'regular' && 'overflow-y: auto;'}
              `}
            >
              <Loader
                type="three-dots"
                isLoading={template.Type === 'brite-editor' || isLoadingUnlayer}
                className={css`
                  ${flex('center')} height: 397px;
                `}
              />
              {template.Type === 'regular' ? (
                <img
                  src={unlayerImgSrc}
                  alt="Regular Template"
                  className={css`
                    width: 100%;
                  `}
                />
              ) : template.Type === 'brite-editor' ? (
                <iframe
                  title="Template"
                  className={css`
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    border: none;
                    overflow: hidden;
                    width: 100%;
                    height: 400px;
                    z-index: 1000;
                  `}
                  src={`https://britehr.app/brite-template-preview/${template?.ID}?lt=preview`}
                />
              ) : null}
            </Div>
            <Div
              css={css`
                padding: 32px;
                width: 100%;
                ${flex('space-between')} border-top: 1px solid ${colors.gray[300]};
              `}
            >
              <Select
                css={`
                  width: 280px;
                `}
                placeholder="Select a Guide"
                value={guideId}
                onChange={(e) => setGuideId(e.target.value)}
              >
                <Loader type="three-dots" isLoading={isLoading} />
                {courses?.map(({ ID, Name }) => (
                  <MenuItem key={ID} value={ID}>
                    {Name}
                  </MenuItem>
                ))}
              </Select>
              <Div
                css={css`
                  ${flex('right')}
                `}
              >
                <Button
                  secondary
                  css={`
                    margin-right: 16px;
                  `}
                  onClick={modalProps.onClose}
                >
                  Close
                </Button>
                <Button
                  disabled={!guideId || loading}
                  onClick={addTemplate}
                  css={`
                    position: relative;
                  `}
                >
                  <Loader
                    type="three-dots"
                    isLoading={loading}
                    className={css`
                      ${flex('center start')} background-color: ${colors.gray[300]};
                    `}
                  />
                  Use Template
                </Button>
              </Div>
              {businessId === template?.BusinessID && canEditTemplates && (
                <DropMenu
                  button={
                    <Button styles="icon">
                      <DotsThree />
                    </Button>
                  }
                >
                  <MenuItem
                    style={{ padding: '8px 24px', fontWeight: 'bold' }}
                    onClick={() => history.push(`/${businessId}/templates/${template?.ID}`)}
                  >
                    Edit Original
                  </MenuItem>
                  <MenuItem
                    style={{ padding: '8px 24px', fontWeight: 'bold' }}
                    onClick={() => setModalItems(['share', template?.ID])}
                  >
                    Share
                  </MenuItem>
                  <MenuItem
                    style={{
                      padding: '8px 24px',
                      color: colors.red[100],
                      fontWeight: 'bold',
                    }}
                    onClick={() => setModalItems(['delete', template?.ID])}
                  >
                    Delete
                  </MenuItem>
                </DropMenu>
              )}
            </Div>
          </Div>
        </Modal>
      )}
    </>
  );
};
