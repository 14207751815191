import { isEmpty } from 'lodash';
import { MenuItem } from '@mui/material';
import { useMutation } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { DotsThree, SquaresFour } from '@phosphor-icons/react';
import { useModal } from '../common/hooks/useModal';
import { Box, Button, DropMenu, Text } from '../shared/components';
import { colors } from '../shared/styles';
import { deleteDashboard, duplicateDashboard } from './api/dashboard';
import { DashboardNameModal } from './modals/DashboardNameModal';

export const DashboardCard = ({ data = {}, refetch = () => {} }) => {
  // Routing
  const history = useHistory();
  const { businessId } = useParams();

  // Modals
  const dashboardNameModal = useModal();

  // TODO: UI states for all the mutations below.
  const handleDuplicateDashboard = useMutation(() => duplicateDashboard({ dashboard: { ...data } }), {
    onMutate: () => {},
    onSuccess: () => refetch(),
    onError: () => {},
    onSettled: () => {},
  });

  const handleDeleteDashboard = useMutation(() => deleteDashboard({ id: data.id }), {
    onMutate: () => {},
    onSuccess: () => refetch(),
    onError: () => {},
    onSettled: () => {},
  });

  const goToDashboard = () => {
    history.push(`/${businessId}/analytics/dashboards/${data.id}/traffic`);
  };

  return (
    <>
      <Box
        css={`
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          padding: 24px;
          border: 2px solid transparent;
          border-radius: 16px;
          box-shadow: 0px 2px 8px 0px rgba(37, 40, 45, 0.2);
          width: 100%;

          &:hover {
            cursor: pointer;
            :hover {
              border: 2px solid ${colors.gray[500]};
            }
          }
        `}
        onClick={() => goToDashboard()}
      >
        <Box
          css={`
            display: flex;
            justify-content: space-between;
            align-items: center;
            align-self: stretch;
          `}
        >
          <SquaresFour weight="fill" />
          <DropMenu
            button={
              <Button styles="icon">
                <DotsThree />
              </Button>
            }
          >
            <MenuItem onClick={() => dashboardNameModal.toggle()}>Change Name</MenuItem>
            <MenuItem onClick={() => handleDuplicateDashboard.mutate()}>Duplicate</MenuItem>
            <MenuItem onClick={() => handleDeleteDashboard.mutate()}>Delete</MenuItem>
          </DropMenu>
        </Box>
        <Box
          css={`
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-top: 16px;
            gap: 8px;
            width: 100%;
          `}
        >
          <Text h4>{data.name}</Text>
          <Box
            css={`
              display: flex;
              flex-direction: row;
              justify-content: left;
              align-items: center;
              gap: 8px;
              width: 100%;
            `}
          >
            <Text
              body
              color={colors.black}
              css={`
                text-wrap: nowrap;
              `}
            >
              <strong>{data?.config?.guides?.length}</strong> Guides
            </Text>
            {!isEmpty(data.description) && (
              <>
                <Box
                  css={`
                    width: 1px;
                    height: 16px;
                    background: ${colors.gray[300]};
                  `}
                />
                <Text body ellipsis color={colors.gray[400]}>
                  {data.description}
                </Text>
              </>
            )}
          </Box>
        </Box>
      </Box>
      {dashboardNameModal.isOpen && (
        <DashboardNameModal data={data} hide={() => dashboardNameModal.hide()} refetch={refetch} />
      )}
    </>
  );
};
