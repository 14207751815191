import { useCallback } from 'react';
import { ComponentIconMap } from '../../BriteEditor/toolbar-menus';
import { Box, Text } from '../../shared/components';
import { container, scrollbar } from '../../shared/shared-styles';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import { ToolKit } from '../providers/tool-kit/ToolKit';
import { DROP_CREATE_BLOCK, DROP_CREATE_SECTION, TOOL_TYPE_MAP } from '../utility/constants';
import { overlay } from '../utility/styles';

const containerStyle = `
  margin: 16px;
  > p {
    margin: 8px 0;
    margin-bottom: 16px;
  }
`;

export const Tools = () => {
  const toolkit = useToolKit();

  const drag = ToolKit.getDrag(toolkit);

  const handleDrag = (event, type) => {
    event.preventDefault();
    const dragType = type === 'section' ? DROP_CREATE_SECTION : DROP_CREATE_BLOCK;
    ToolKit.startDrag(toolkit, {
      type: dragType,
      toolType: type,
    });
  };

  const Tool = useCallback(
    ({ type }) => {
      return (
        <Box
          flex="left"
          css={`
            gap: 16px;
            padding: 8px;
            border-radius: 8px;
            ${container.hover}
            ${type in TOOL_TYPE_MAP ? '' : 'opacity: .5; pointer-events: none;'}
          `}
          draggable={true}
          onDragStart={(e) => handleDrag(e, type)}
        >
          <ComponentIconMap type={type} size={24} />
          <Text label>{TOOL_TYPE_MAP?.[type]}</Text>
        </Box>
      );
    },
    [handleDrag]
  );

  return (
    <Box
      css={`
        position: relative;
        user-select: none;
        width: 100%;
        height: 100%;
        transition: opacity 0.3s ease;
        overflow-y: auto;
        overflow-x: clip;
        ${scrollbar.hide}
      `}
    >
      <Box css={containerStyle}>
        <Text label bold>
          Elements
        </Text>
        <Tool type="section" />
        <Tool type="textV2" />
        <Tool type="button" />
        <Tool type="space" />
        <Tool type="accordion" />
        <Tool type="pageBreak" />
      </Box>

      <Box css={containerStyle}>
        <Text label bold>
          Media
        </Text>
        <Tool type="image" />
        <Tool type="document" />
        <Tool type="video" />
        <Tool type="website" />
      </Box>

      <Box css={containerStyle}>
        <Text label bold>
          Smart Components
        </Text>

        <Tool type="benefits" />
        <Tool type="pricing" />
        <Tool type="network" />
      </Box>

      {drag?.isDragging ? (
        <Box
          css={`
            ${overlay}
            opacity: 0.3;
            background: white;
            filter: blur(8px);
          `}
          flex="center"
        />
      ) : null}
    </Box>
  );
};
