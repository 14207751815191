import { useModalWithData } from '../../../../common/hooks/useModalWithData';
import { Box, Button } from '../../../../shared/components';
import { Modify } from '../../../providers/content/content-modify';
import { useContent } from '../../../providers/content/use-content';
import { EditProductSelection } from '../EditProductSelection';

export const SummaryToolbar = ({ id, productId, isSmartField, sync }) => {
  const { sendUpdates } = useContent();
  const modal = useModalWithData(false, '');

  const update = () => {
    Modify.properties(sendUpdates, id, () => ({ ...sync?.value }));
    modal.hide();
  };

  const buttonLabel = !!sync?.value?.data?.id ? 'Change' : 'Select Product';

  return (
    <>
      <Box flex="left">
        <Button editor onClick={() => modal.show('edit')}>
          {buttonLabel}
        </Button>
      </Box>
      {modal.data === 'edit' ? (
        <EditProductSelection
          title="Summary"
          blockType="benefits"
          update={update}
          onClose={modal.hide}
          sync={sync}
          productId={productId}
          isSmartField={isSmartField}
        />
      ) : null}
    </>
  );
};
