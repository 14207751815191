import { CustomAxios } from '../../redux/axios/axios';

export const getDashboard = async ({ businessID, id }) => {
  const { data } = await CustomAxios.get('/v1/bp/guide_dashboard', {
    headers: { 'Accept-Profile': 'brite' },
    params: { id: `eq.${id}`, business_id: `eq.${businessID}` },
  });
  return data?.length !== 0 ? data[0] : {};
};

export const getDashboards = async ({ businessID }) => {
  const { data } = await CustomAxios.get('/v1/bp/guide_dashboard', {
    headers: { 'Accept-Profile': 'brite' },
    params: { business_id: `eq.${businessID}`, order: 'created_at.asc' },
  });
  return data;
};

export const createDashboard = async ({ businessID, name, guides }) => {
  const { data } = await CustomAxios.post(
    '/v1/bp/guide_dashboard',
    {
      business_id: businessID,
      name,
      description: '',
      config: { guides },
    },
    { headers: { 'Content-Profile': 'brite' } }
  );
  return data;
};

export const duplicateDashboard = async ({ dashboard }) => {
  // Destructure to exclude id, created_at, and modified_at.
  const { id, created_at, modified_at, ...rest } = dashboard;
  const { data } = await CustomAxios.post(
    '/v1/bp/guide_dashboard',
    {
      ...rest,
    },
    { headers: { 'Content-Profile': 'brite' } }
  );
  return data;
};

export const updateDashboard = async ({ dashboard }) => {
  const { data } = await CustomAxios.put(
    '/v1/bp/guide_dashboard',
    {
      ...dashboard,
    },
    {
      headers: { 'Content-Profile': 'brite' },
      params: { id: `eq.${dashboard.id}` },
    }
  );
  return data;
};

export const deleteDashboard = async ({ id }) => {
  const { data } = await CustomAxios.delete('/v1/bp/guide_dashboard', {
    headers: { 'Content-Profile': 'brite' },
    params: { id: `eq.${id}` },
  });
  return data;
};
