import { Tooltip } from '../../../common/components/Tooltip';
import { Box, Text } from '../../../shared/components';
import { animation, shadows } from '../../../shared/shared-styles';
import { colors } from '../../../shared/styles';
import { overlay } from '../../utility/styles';
import { Toolbar } from '../../utility/Toolbar';
import { usePageBreakBlock } from './usePageBreakBlock';

export const PageBreakBlock = () => {
  const block = usePageBreakBlock();

  return (
    <>
      <Box style={block?.styles} />
      <Tooltip label={block?.isSelected ? 'Page breaks only affect the exported PDF.' : ''}>
        <Box
          css={`
            ${overlay}
            left: -100%;
            right: -100%;
            z-index: 100;
            background-color: rgba(0, 0, 0, 0.08);
            box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.25);
            border-bottom: 1px dashed ${colors.gray[400]};
            border-top: 1px dashed ${colors.gray[400]};
            ${animation('fadeIn', '.2s ease')}
          `}
          flex="center"
        >
          <Text
            css={`
              background-color: ${colors.gray[600]};
              padding: 4px 16px;
              border-radius: 30px;
              color: white;
              font-weight: bold;
              ${shadows.md}
            `}
          >
            Page break
          </Text>
        </Box>
      </Tooltip>
      <Toolbar isSelected={block?.isSelected} anchor={block?.anchor} />
    </>
  );
};
