import { useEditorResource } from '../../../BriteEditor/use-editor-resource';
import { Segment } from '../../providers/segment/Segment';
import { useSmartField } from '../../utility/editor-utils';
import { useBlock } from '../../utility/useBlock';
import { useImage } from '../../utility/useImages';
import { useSync } from '../../utility/useSync';

export const useImageBlock = () => {
  const block = useBlock();
  const { data: designStyles } = useEditorResource('styles');
  const properties = Segment.getProperties(block.segment);
  const blockStyles = Segment.getStyle(block.segment);

  const style = {
    ...designStyles?.image,
    ...blockStyles,
  };

  const attributes = properties.attributes;
  const { value: srcRaw, validUrl, isSmartField, smartFields } = useSmartField(attributes?.src || '');

  const sync = useSync({
    alt: attributes?.alt,
    src: srcRaw,
    crop: properties?.crop || null,
    smartFieldDisplay: properties?.smartFieldDisplay,
  });

  const image = useImage(sync?.value?.src);

  const isFallbackImage = !sync?.value?.src || validUrl === false || image?.error;

  return {
    ...block,
    isFallbackImage,
    sync,
    style,
    image,
    validUrl,
    isSmartField,
    smartFields,
  };
};
