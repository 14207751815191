import { css } from '@emotion/css';
import { flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { Box, Text } from '../../../shared/components';
import { colors } from '../../../shared/styles';

export const ChartTable = ({ data, columns }) => {
  // Initialize the table with the provided data and columns.
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (!data || data.length === 0) {
    return null;
  }

  return (
    <Box
      css={`
        padding-right: 16px;
        padding-bottom: 8px;
        width: 100%;
        max-height: 500px;
        overflow-y: auto;
      `}
    >
      <table
        className={css`
          width: 100%;
          border-collapse: collapse;
        `}
      >
        <ChartTable.Head table={table} />
        <ChartTable.Body table={table} />
      </table>
    </Box>
  );
};

const chartTableRowStyles = css`
  border-bottom: 1px solid ${colors.gray[300]};
`;

const chartTableCellStyles = css`
  padding: 16px 0px;
  text-align: left;
`;

ChartTable.Head = ({ table }) => (
  <thead
    className={css`
      position: sticky;
      top: 0;
      background-color: #ffffff;
      z-index: 1;
    `}
  >
    {table.getHeaderGroups().map((headerGroup) => (
      <tr key={headerGroup.id} className={chartTableRowStyles}>
        {headerGroup.headers.map((header) => (
          <th key={header.id} className={chartTableCellStyles}>
            <Text
              label
              as="span"
              css={`
                font-size: 14px;
                font-weight: 700;
              `}
            >
              {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
            </Text>
          </th>
        ))}
      </tr>
    ))}
  </thead>
);

ChartTable.Body = ({ table }) => (
  <tbody>
    {table.getRowModel().rows.map((row) => (
      <tr key={row.id} className={chartTableRowStyles}>
        {row.getVisibleCells().map((cell) => (
          <td key={cell.id} className={chartTableCellStyles}>
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        ))}
      </tr>
    ))}
  </tbody>
);

ChartTable.ColumnHeader = ({ capitalize = false, label = '' }) => (
  <Text
    caption
    color={colors.black}
    css={`
      font-weight: 700;
      ${capitalize && `text-transform: capitalize;`}
    `}
  >
    {label}
  </Text>
);

ChartTable.ColumnCell = ({ label = '' }) => (
  <Text
    label
    css={`
      font-size: 14px;
    `}
  >
    {label}
  </Text>
);

ChartTable.ColumnHelperCell = ({ label = '', helper = '' }) => (
  <Box
    css={`
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      gap: 8px;
    `}
  >
    <Text label>{label}</Text>
    <Text helper>{helper}</Text>
  </Box>
);
