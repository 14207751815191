import { Info } from '@phosphor-icons/react';
import { toast } from 'react-toastify';
import { CustomAxios } from '../../../redux/axios/axios';
import { Box, Button, Divider, Modal, Text } from '../../../shared/components';
import { colors } from '../../../shared/styles';
import { useStore } from '../../../store-provider/use-store';

export const ExportGuideToPDFModal = ({ guide, display, onClose }) => {
  const {
    data: { user },
  } = useStore();

  const modalProps = {
    display,
    onClose,
  };

  const downloadPDF = async () => {
    // Check if guide has brite editor pages. If any page is the brite editor, use the new pdf generation endpoint.
    const hasBriteEditor = guide.Pages.some((page) => page.Type === 'brite-editor' || page.Type === 'brite-editor-v2');

    if (hasBriteEditor) {
      try {
        let url = `/v1/guide/${guide.ID}/pdf`;

        if (guide.Passcode) {
          url += '?passcode=' + guide.Passcode;
        }

        const pdfPromise = CustomAxios.post(url, {}, { responseType: 'arraybuffer', timeout: 300000 });

        modalProps.onClose();

        toast.promise(
          pdfPromise,
          {
            pending: 'Generating PDF...',
            success: 'PDF generated successfully.\nCheck your downloads.',
            error: 'Unable to generate PDF. Please try again later.',
          },
          {
            position: 'bottom-center',
          }
        );

        pdfPromise.then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${guide.Name ? guide.Name : guide.ID}.pdf`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        });
      } catch (e) {
        toast.error('Unable to generate full PDF. Please try again later.');
        console.error(e);
      }
    } else {
      try {
        // Legacy PDF generation for unlayer guides
        await CustomAxios.post(`/v1/course/${guide.ID}/download/${user.ID}`, {}, {});
        toast.success('PDF is being generating, you will be emailed when it is complete.');
      } catch (e) {
        toast.error('Unable to generate PDF. Please try again later.');
        console.error(e);
      }
    }
  };

  return (
    <Modal {...modalProps}>
      <Modal.Paper
        css={`
          display: flex;
          width: 656px;
          padding: 32px;
          flex-direction: column;
          align-items: flex-start;
        `}
      >
        <Modal.Header title="Export Guide as PDF" onClose={modalProps.onClose} />
        <Modal.Body>
          <Box
            css={`
              display: flex;
              flex-direction: column;
              gap: 24px;
            `}
          >
            <Box
              css={`
                display: flex;
                padding: 16px 24px;
                align-items: center;
                gap: 16px;
                align-self: stretch;
                border-radius: 8px;
                background: ${colors.gray[100]};
              `}
            >
              <Info color={colors.purple} weight="fill" />
              <Text label>
                Exporting a guide may take up to 2 minutes. Please keep Brite open until the export is finished.
              </Text>
            </Box>
            <Divider />
            <Box
              css={`
                display: flex;
                height: 32px;
                padding: 4px 16px;
                align-items: center;
                gap: 8px;
                align-self: stretch;
                border-left: 4px solid ${colors.purple};
              `}
            >
              <Text h4>Design Tips for Exporting</Text>
            </Box>
            <Box>
              <Text label bold>
                Interactive Elements
              </Text>
              <Text body>
                Elements such as videos and buttons do not always translate well to print. They are automatically
                displayed as QR codes and links in the PDF. You may choose to hide these components in the editor.
              </Text>
            </Box>
            <Box>
              <Text label bold>
                Page Breaks
              </Text>
              <Text body>
                Brite automatically adds a page break after each page in a guide. We do our best to keep the content
                together but you may need to make adjustments in the editor.
              </Text>
            </Box>
          </Box>
        </Modal.Body>
        <Modal.Actions>
          <Button secondary onClick={() => modalProps.onClose()}>
            Cancel
          </Button>
          <Button onClick={() => downloadPDF()}>Export</Button>
        </Modal.Actions>
      </Modal.Paper>
    </Modal>
  );
};
