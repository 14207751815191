import { css } from '@emotion/css';
import { X } from '@phosphor-icons/react';
import { useMemo } from 'react';
import { Button, Div, Input, Text, Icon, Dropdown, Box } from '../../shared/components';
import { flex } from '../../shared/shared-styles';
import { colors } from '../../shared/styles';
import { useEditorResource } from '../use-editor-resource';
import { addSmartFieldToCache, SmartFields } from './smart-fields';

import { ReactComponent as SmartText } from '../../images/svg/smart-text.svg';
import { ReactComponent as SmartImage } from '../../images/smart-image.svg';
import { Tooltip } from '../../common/components/Tooltip';

export const SmartFieldInput = ({
  value,
  inputProps,
  smartFieldDisplay,
  handleSmartField,
  removeSmartField,
  nameFilter,
  css: cssStyle = '',
  name = 'Text',
}) => {
  const isLinkSmartField = value?.startsWith('{{') && value?.endsWith('}}');
  const { cache } = useEditorResource('liveSmartFields');
  const smartFields = cache?.data?.Data;
  const smartField = useMemo(() => {
    if (isLinkSmartField && smartFields?.[value]?.Success) {
      const display = smartFieldDisplay || smartFields[value]?.Value || value;
      return {
        display,
        hoverLabel: smartFields[value]?.Value || value,
      };
    }
    return {
      display: smartFieldDisplay || value,
    };
  });

  const handleSmartFieldAdd = (item) => {
    addSmartFieldToCache(cache, item?.data);
    handleSmartField(item);
  };

  return isLinkSmartField ? (
    <Div
      css={css`
        ${flex('space-between')} width: 100%;
        border-radius: 8px;
        padding: 8px;
        border: 1px solid ${colors.gray[300]};
        ${cssStyle}
      `}
    >
      <Tooltip label={value || smartField?.hoverLabel || ''}>
        <Text
          label
          bold
          ellipsis
          css={`
            border-radius: 30px;
            ${!!smartField?.hoverLabel
              ? `
                background-color: ${colors.gray[200]};
              `
              : `
              background-color: ${colors.red[200]};
              `};
            padding: 4px 8px;
          `}
        >
          {smartField?.display}
        </Text>
      </Tooltip>
      <SmartButtons
        handleSmartField={handleSmartFieldAdd}
        removeSmartField={removeSmartField}
        isLinkSmartField={isLinkSmartField}
        nameFilter={nameFilter}
        name={name}
      />
    </Div>
  ) : (
    <Input
      className={css`
        ${cssStyle}
      `}
      endIcon={
        <SmartButtons
          handleSmartField={handleSmartFieldAdd}
          removeSmartField={removeSmartField}
          isLinkSmartField={isLinkSmartField}
          nameFilter={nameFilter}
          name={name}
        />
      }
      {...inputProps}
    />
  );
};

const SmartButtons = ({ handleSmartField, removeSmartField, isLinkSmartField, nameFilter, name = 'Text' }) => {
  return (
    <>
      {isLinkSmartField ? (
        <Button styles="icon sm" hoverLabel={`Remove Smart ${name}`} onClick={removeSmartField}>
          <X color={'#9AA7B5'} size="24" />
        </Button>
      ) : (
        <Dropdown
          clear
          stopPropagation
          button={() => (
            <Button styles="icon sm" hoverLabel={`Smart ${name}`}>
              {name === 'Image' ? <SmartImage /> : <Icon SVG={SmartText} size={24} />}
            </Button>
          )}
        >
          <Box
            data-scope="menu"
            css={`
              width: 400px;
            `}
          >
            <SmartFields handleSmartField={handleSmartField} nameFilter={nameFilter} name={name} />
          </Box>
        </Dropdown>
      )}
    </>
  );
};
