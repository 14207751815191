import { useBriteSlate } from '../../../BriteEditor/editor-components/accordion/useBriteSlate';
import { useEditorResource } from '../../../BriteEditor/use-editor-resource';
import { Segment } from '../../providers/segment/Segment';
import { useBlock } from '../../utility/useBlock';
import { useSync } from '../../utility/useSync';

export const useAccordionBlock = () => {
  const block = useBlock();

  const expanded = useSync(block?.isSelected);

  const { data: liveSmartFields } = useEditorResource('liveSmartFields');
  const { data: designStyles } = useEditorResource('styles');

  const textStyles = designStyles?.text;
  const properties = Segment.getProperties(block?.segment);
  const style = Segment.getStyle(block?.segment);

  const title = useBriteSlate(properties?.data?.title);
  const titleStyle = {
    ...textStyles?.h4,
    color: style?.color || textStyles?.h4?.color || '',
  };

  const body = useBriteSlate(properties?.data?.body);
  const bodyStyle = {
    ...textStyles?.body,
    color: style?.color || textStyles?.body?.color || '',
  };

  return {
    ...block,
    properties,
    expanded,
    title,
    titleStyle,
    body,
    bodyStyle,
    liveSmartFields,
  };
};
