import { SmartFieldInput } from '../../../BriteEditor/utility-components/smart-field-input';
import { Box } from '../../../shared/components';
import { useDebounce } from '../../../shared/use-debounce';
import { Modify } from '../../providers/content/content-modify';
import { useContent } from '../../providers/content/use-content';
import { getVideoUrl } from './video-utils';

export const VideoToolbar = ({ id, videoUrl, src }) => {
  const { sendUpdates } = useContent();

  const handleSmartField = ({ data }) => {
    Modify.properties(sendUpdates, id, (properties) => ({
      videoUrl: data?.name,
      attributes: { ...properties?.attributes, src: data?.value },
    }));
  };

  const removeSmartField = () => {
    Modify.properties(sendUpdates, id, (properties) => ({
      videoUrl: '',
      attributes: { ...properties?.attributes, src: '' },
    }));
  };
  const debounceChange = useDebounce((updateFn) => Modify.properties(sendUpdates, id, updateFn), 500);

  const onChange = (event) => {
    const formattedSrc = getVideoUrl(event.target.value);
    videoUrl.set(event.target.value);
    src.set(formattedSrc);
    debounceChange((properties) => ({
      videoUrl: event.target.value,
      attributes: {
        ...properties.attributes,
        src: formattedSrc,
      },
    }));
  };

  return (
    <Box
      flex="left"
      css={`
        gap: 8px;
      `}
    >
      <SmartFieldInput
        css={`
          min-width: 150px;
          height: 40px;
          border-radius: 30px;
          padding: 0 16px;
          border: none;
          :hover {
            border: none;
          }
        `}
        nameFilter="URL"
        value={src?.value}
        smartFieldDisplay={videoUrl.value}
        handleSmartField={handleSmartField}
        removeSmartField={removeSmartField}
        inputProps={{
          placeholder: 'Video URL',
          value: videoUrl.value,
          onChange,
        }}
      />
    </Box>
  );
};
