import { Box, Loader, Text } from '../../shared/components';
import { colors } from '../../shared/styles';

export const SnapshotCard = ({ isLoading = false, title = '', renderContent = <></> }) => {
  return (
    <Box
      css={`
        padding: 16px 24px;
        width: 100%;
        border-radius: 16px;
        background: ${colors.gray[100]};
      `}
    >
      <Box
        css={`
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
        `}
      >
        <Text body ellipsis>
          {title}
        </Text>
        {isLoading ? (
          <Box
            css={`
              display: flex;
              align-items: center;
              height: 48px;
            `}
          >
            <Loader />
          </Box>
        ) : (
          renderContent
        )}
      </Box>
    </Box>
  );
};

// A subcomponent of SnapshotCard to display a number with formatting.
SnapshotCard.Number = ({ value = 0 }) => <Text h1>{value?.toLocaleString()}</Text>;

// A subcomponent of SnapshotCard to display time in hours, minutes, and seconds.
SnapshotCard.Time = ({ value = 0 }) => {
  // Calculate hours, minutes, and seconds from the given value (in seconds).
  const hours = Math.floor(value / 3600);
  const minutes = Math.floor((value % 3600) / 60);
  const seconds = Math.ceil(value % 60);

  // A helper component to display a single time unit with its label.
  const TimeDisplay = ({ time = 0, label = '' }) => (
    <Text h1>
      {time}{' '}
      <Text body as="span">
        {label}
      </Text>
    </Text>
  );

  return (
    <Box
      css={`
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        gap: 8px;
      `}
    >
      {/* Display hours if greater than 0. */}
      {hours > 0 && <TimeDisplay time={hours} label="hr" />}
      {/* Display minutes if greater than 0. */}
      {minutes > 0 && <TimeDisplay time={minutes} label="min" />}
      {/* Always display seconds. */}
      <TimeDisplay time={seconds} label="sec" />
    </Box>
  );
};
