import { css, cx } from '@emotion/css';
import { useMemo } from 'react';
import { colors } from '../styles';
import { Tooltip } from '../../common/components/Tooltip';

const dotStyle = (color) => css`
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: ${color};
  border-radius: 50%;
`;

export const Dot = ({
  children,
  display = true,
  placement = 'top-right',
  spacing = -8,
  verticalSpacing = null,
  horizontalSpacing = null,
  color = colors.red[100],
  className,
  hoverLabel = '',
  css: cssString = '',
}) => {
  const placementStyle = useMemo(() => {
    const horizontal = horizontalSpacing ?? spacing;
    const vertical = verticalSpacing ?? spacing;
    if (placement === 'top-right') {
      return `top: ${vertical}px; right: ${horizontal * 2}px;`;
    } else if (placement === 'top-left') {
      return `top: ${vertical}px; left: ${horizontal * 2}px;`;
    } else if (placement === 'bottom-right') {
      return `bottom: ${vertical}px; right: ${horizontal * 2}px;`;
    } else if (placement === 'bottom-left') {
      return `bottom: ${vertical}px; left: ${horizontal * 2}px;`;
    }
  }, [placement]);

  return (
    <div
      className={cx(
        css`
          position: relative;
          width: max-content;
        `,
        className
      )}
    >
      {display && (
        <Tooltip label={hoverLabel}>
          <div
            className={css`
              cursor: default;
              ${placementStyle}
              ${dotStyle(color)}
              ${cssString}
            `}
          />
        </Tooltip>
      )}
      {children}
    </div>
  );
};
