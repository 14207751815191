import { useState } from 'react';
import { css } from '@emotion/css';
import { Button, IconButton } from '@mui/material';
import { Div, Modal } from '../shared/components';
import { PageTemplates } from './PageTemplates';
import { VendorLibrary } from './VendorLibrary';
import { ArrowLeft, X } from '@phosphor-icons/react';

export const UseTemplateModal = ({ hideUseTemplate, currentFolder, selectedGuideType }) => {
  const [useTemplateType, setUseTemplateType] = useState('');
  return (
    <Modal display full onClose={() => hideUseTemplate()}>
      <Div
        css={css`
          display: flex;
          flex-direction: column;
          height: calc(100vh);
        `}
      >
        <Div
          css={css`
            height: 80px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            box-shadow: 0px 1px 0px #cbd5e0;
          `}
        >
          <Div
            css={css`
              font-size: 20px;
              margin: 14px;
            `}
          >
            {useTemplateType && (
              <IconButton aria-label="back" onClick={() => setUseTemplateType('')}>
                <ArrowLeft />
              </IconButton>
            )}
            Use a template
          </Div>
          <Div
            css={css`
              margin: 14px;
            `}
          >
            <IconButton aria-label="Close" onClick={() => hideUseTemplate()}>
              <X />
            </IconButton>
          </Div>
        </Div>
        <Div
          css={css`
            padding: 30px;
            overflow: auto;
            margin-bottom: 24px;
            width: 100%;
          `}
        >
          {!useTemplateType && (
            <Div
              css={css`
                width: 500px;
                margin: 12px auto;
              `}
            >
              <Div
                css={css`
                  font-weight: bold;
                  font-size: 34px;
                  line-height: 44px;
                  color: #25282d;
                `}
              >
                Use a Brite template or
                <br />
                your own template?
              </Div>
              <Div
                css={css`
                  display: flex;
                  justify-content: flex-start;
                  margin-top: 32px;
                `}
              >
                <Div
                  css={css`
                    margin-right: 24px;
                  `}
                >
                  <Button
                    variant="outlined"
                    aria-label="Use a Brite template"
                    color="primary"
                    onClick={() => setUseTemplateType('vendor')}
                  >
                    Use a Brite template
                  </Button>
                </Div>
                <Div>
                  <Button
                    variant="outlined"
                    aria-label="Your saved page templates"
                    color="primary"
                    onClick={() => setUseTemplateType('own')}
                  >
                    Use your template
                  </Button>
                </Div>
              </Div>
            </Div>
          )}
          {useTemplateType === 'own' && (
            <PageTemplates currentFolder={currentFolder} selectedGuideType={selectedGuideType} />
          )}
          {useTemplateType === 'vendor' && (
            <VendorLibrary currentFolder={currentFolder} selectedGuideType={selectedGuideType} />
          )}
        </Div>
      </Div>
    </Modal>
  );
};
