import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { Box } from '../../../shared/components';
import { getTrafficSnapshots } from '../../api/traffic';
import { useDashboard } from '../../context/DashboardContext';
import { SnapshotCard } from '../SnapshotCard';

export const TrafficSnapshots = () => {
  // Routing
  const { businessId } = useParams();

  // Context
  const { dashboard, range } = useDashboard();

  // Queries
  const { data: snapshots, isLoading: isLoadingSnapshots } = useQuery(['traffic-snapshots', businessId, range], () =>
    getTrafficSnapshots({ dashboard, range })
  );

  return (
    <Box
      css={`
        display: flex;
        align-items: center;
        gap: 8px;
        width: 100%;
      `}
    >
      <SnapshotCard
        isLoading={isLoadingSnapshots}
        title="Total Views"
        renderContent={<SnapshotCard.Number value={snapshots?.TotalViews} />}
      />
      <SnapshotCard
        isLoading={isLoadingSnapshots}
        title="Unique Visitors"
        renderContent={<SnapshotCard.Number value={snapshots?.TotalUniqueUsers} />}
      />
      <SnapshotCard
        isLoading={isLoadingSnapshots}
        title="Views per Unique Visitor"
        renderContent={<SnapshotCard.Number value={snapshots?.ViewsPerUser.toFixed(1)} />}
      />
    </Box>
  );
};
