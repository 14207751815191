import { Box, Text } from '../../../shared/components';
import { selectableBox } from '../../utility/settings/styles';
import { Property } from './property';

export const StrokeStyleSetting = ({ value, setValue }) => {
  return (
    <Property>
      <Text label>Stroke Style</Text>
      <Box
        flex="space-between"
        css={`
          gap: 8px;
        `}
      >
        <Box css={selectableBox(value === 'solid')} flex="center" onClick={() => setValue('solid')}>
          <Box
            css={`
              border-top: 3px solid var(--accent-color-0);
              height: 3px;
              width: 100%;
              max-width: 20px;
            `}
          />
        </Box>

        <Box css={selectableBox(value === 'dashed')} flex="center" onClick={() => setValue('dashed')}>
          <Box
            css={`
              border-top: 3px dashed var(--accent-color-0);
              height: 3px;
              width: 100%;
              max-width: 20px;
            `}
          />
        </Box>

        <Box css={selectableBox(value === 'dotted')} flex="center" onClick={() => setValue('dotted')}>
          <Box
            css={`
              border-top: 4px dotted var(--accent-color-0);
              height: 4px;
              width: 100%;
              max-width: 20px;
            `}
          />
        </Box>
      </Box>
    </Property>
  );
};
