import { Box, Text, Toggle } from '../../../shared/components';
import {
  getHasStrokeProperties,
  getHideStrokeProperties,
  getStrokeColorProperties,
  getStrokePaddingProperties,
  getStrokeStyleProperties,
  getStrokeWeightProperties,
} from '../Settings/property-utils';
import { ColorSetting } from './ColorSetting';
import { StrokeWeightSetting } from './StrokeWeightSetting';
import { StrokeStyleSetting } from './StrokeStyleSetting';
import { StrokePaddingSetting } from './StrokePaddingSetting';
import { HideStrokeSetting } from './HideStrokeSetting';
import { Collapse } from '../../../common/components/Collapse';
import { colors } from '../../../shared/styles';
import { useEffect, useState } from 'react';

export const StrokeSetting = (props) => {
  const hasStroke = getHasStrokeProperties(props);
  const strokeWeight = getStrokeWeightProperties(props);
  const strokeColor = getStrokeColorProperties(props);
  const strokeStyle = getStrokeStyleProperties(props);
  const hideStroke = getHideStrokeProperties(props);
  const strokePadding = getStrokePaddingProperties(props);

  const [open, setOpen] = useState(hasStroke);

  useEffect(() => {
    setOpen(hasStroke?.value || false);
  }, []);

  const toggleStroke = (event) => {
    setOpen(event.target.checked);
    hasStroke?.setValue(event.target.checked);
  };

  return (
    <Box
      css={`
        margin: 0 -8px;
      `}
    >
      <Box
        flex="space-between"
        css={`
          padding: 0 8px;
        `}
      >
        <Text label bold>
          Add Stroke
        </Text>
        <Toggle checked={open} onChange={toggleStroke} />
      </Box>
      <Collapse isOpen={open}>
        <Box
          css={`
            display: grid;
            gap: 24px;
            transition: opacity 0.3s ease;
            background-color: ${colors.gray[100]};
            padding: 16px;
            border-radius: 8px;
            ${!open ? `opacity: .5; pointer-events: none;` : ''}
          `}
        >
          <StrokeWeightSetting {...strokeWeight} />
          <ColorSetting label="Stroke Color" {...strokeColor} />
          <StrokeStyleSetting {...strokeStyle} />
          <HideStrokeSetting {...hideStroke} />
          <StrokePaddingSetting {...strokePadding} />
        </Box>
      </Collapse>
    </Box>
  );
};
