import { CaretDown } from '@phosphor-icons/react';
import { Box, Button, Dropdown, Text } from '../../../shared/components';

export const ChartControlDropdown = ({ label = '', options = [], onClick = () => {} }) => {
  return (
    <Box
      css={`
        display: flex;
        justify-content: center;
        align-items: center;
      `}
    >
      <Dropdown
        button={({ open }) => (
          <Button
            secondary
            css={`
              text-transform: capitalize;
              padding: 8px 16px;
              gap: 16px;
            `}
          >
            <Text body>{label}</Text>
            <Box
              css={`
                display: flex;
                justify-content: center;
                align-items: center;
                transition: transform 0.3s;
                transform: rotate(${open ? 180 : 0}deg);
              `}
            >
              <CaretDown size={16} weight="bold" />
            </Box>
          </Button>
        )}
      >
        {options.map((option) => (
          <Box option key={option.label} onClick={() => onClick(option)}>
            {option.label}
          </Box>
        ))}
      </Dropdown>
    </Box>
  );
};
