import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { CustomAxios } from '../redux/axios/axios';
import IconButton from '@mui/material/IconButton';
import { CircularProgress, Select, TextField, Typography } from '@mui/material';
import { css } from '@emotion/css';
import Button from '@mui/material/Button';
import posthog from '../lib/analytics/posthog';
import { toast } from 'react-toastify';
import { useQueryAPI, USER_BUSINESSES_LIST } from '../react-query';
import { useStore } from '../store-provider/use-store';
import { Box, Modal } from '../shared/components';
import { Tooltip } from '../common/components/Tooltip';
import { DotsThree } from '@phosphor-icons/react';

export function MoreMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDownloadPDFModal, setShowDownloadPDFModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [moveModal, setMoveModal] = useState(false);
  const [moveToCompanyID, setMoveToCompanyID] = useState(props.businessID);
  const [courseName, setCourseName] = useState(`${props.courseName} copy`);

  const {
    data: { user },
  } = useStore();
  const { ID: userId = '' } = user;

  const updateUserBusinesses = (data) => {
    if (data && data.Parent && data.Companies && data.Companies.length) {
      return [data.Parent, ...data.Companies];
    }
    return [];
  };

  const { data: userCompanies } = useQueryAPI({
    url: USER_BUSINESSES_LIST,
    params: { userId },
    enabled: !!userId,
    select: updateUserBusinesses,
    staleTime: 1000 * 60 * 60,
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadAsPDF = async () => {
    setShowDownloadPDFModal(true);
    setLoading(true);
    setError('');
    try {
      await CustomAxios.post(`/v1/course/${props.courseId}/download/${userId}`);
      setLoading(false);
      posthog.capture('Downloaded PDF', {
        courseId: props.courseId,
      });
    } catch (e) {
      setLoading(false);
      setError('Error downloading pdf. Please try again.');
    }
  };

  const handleMoveVsDuplicate = () => {
    if (userCompanies.length) {
      setMoveModal(true);
    } else {
      props.duplicateCourse(props.courseId);
    }
    handleClose();
  };

  const handleMove = async () => {
    if (props.businessID === moveToCompanyID) {
      props.duplicateCourse(props.courseId);
    } else {
      try {
        setLoading(true);
        const url = `/v1/course/${props.courseId}/copies/${moveToCompanyID}`;
        await CustomAxios.post(url, { Name: courseName, CourseFolderID: null });
        const company = userCompanies.find(({ ID }) => ID === moveToCompanyID);
        toast.success(`Successfully duplicated to ${company.Name}`);
        setMoveModal(false);
      } catch (err) {
        console.warn(err);
        toast.error(`We're sorry, it looks like we couldn't duplicate the guide. Please try again.`);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <React.Fragment>
      <Tooltip label="More">
        <IconButton aria-label="More" color="default" onClick={handleClick}>
          <DotsThree />
        </IconButton>
      </Tooltip>
      <Menu id="more-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {props.viewAnalytics && (
          <MenuItem
            onClick={() => {
              props.showAnalytics();
              handleClose();
            }}
          >
            View Analytics
          </MenuItem>
        )}
        <MenuItem onClick={handleMoveVsDuplicate}>Duplicate</MenuItem>
        <MenuItem
          onClick={() => {
            downloadAsPDF();
          }}
        >
          Download as PDF
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.deleteCourse(props.courseId);
            handleClose();
          }}
        >
          Delete
        </MenuItem>
      </Menu>

      {showDownloadPDFModal && (
        <Modal display onClose={() => setShowDownloadPDFModal(false)}>
          {loading && (
            <Box flex="center">
              <CircularProgress />
            </Box>
          )}

          {!loading && !error && (
            <div
              className={css`
                padding: 24px;
              `}
            >
              <span
                className={css`
                  text-align: center;
                  font-size: 24px;
                  font-weight: bold;
                `}
              >
                Success!
              </span>
              <br />
              <br />
              We will email you when the PDF is ready. The PDF will also show up in Documents.
            </div>
          )}

          {error && (
            <div
              className={css`
                padding: 24px;
              `}
            >
              {error}
            </div>
          )}

          <div
            className={css`
              margin: 24px auto;
              display: flex;
              justify-content: center;
            `}
          >
            <Button onClick={() => setShowDownloadPDFModal(false)}>Close</Button>
          </div>
        </Modal>
      )}
      {moveModal && (
        <Modal display onClose={() => setShowDownloadPDFModal(false)}>
          <div
            className={css`
              display: flex;
              justify-content: center;
              height: max-content;
              width: 400px;
              padding: 16px;
              > * {
                margin: 8px;
              }
            `}
          >
            <div>
              <Typography variant="h6" align="center">
                Duplicate
              </Typography>
              <br />
              <Typography variant="body1">Save duplicate to</Typography>
              <Select
                variant="outlined"
                style={{ width: '200px' }}
                value={moveToCompanyID}
                onChange={(event) => setMoveToCompanyID(event.target.value)}
              >
                {userCompanies.map(({ ID, Name }) => (
                  <MenuItem value={ID} key={ID}>
                    {Name}
                  </MenuItem>
                ))}
              </Select>
              <br />
              <br />
              <Typography variant="body1">Guide Name</Typography>
              <TextField
                variant="outlined"
                placeholder="Guide Name"
                value={courseName}
                onChange={(event) => setCourseName(event.target.value)}
              />
              <br />
              <br />
              <div
                className={css`
                  display: flex;
                  justify-content: space-around;
                  margin: 8px;
                `}
              >
                <Button color="primary" onClick={() => setMoveModal(false)}>
                  Cancel
                </Button>
                <Button color="primary" variant="contained" onClick={handleMove} disabled={loading}>
                  {loading ? 'Saving' : 'Submit'}
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </React.Fragment>
  );
}
