import { Editor, Transforms } from 'slate';

export const inlineElements = ['link', 'smart-field'];

export const withInlines = (editor) => {
  const { isInline } = editor;
  editor.isInline = (element) => inlineElements.includes(element.type) || isInline(element);
  return editor;
};

export const updateContent = (editor, slateNodes) => {
  try {
    Transforms.delete(editor, {
      at: {
        anchor: Editor.start(editor, []),
        focus: Editor.end(editor, []),
      },
    });

    if (editor?.children?.length) {
      Transforms.removeNodes(editor, { at: [0] });
    }

    Transforms.insertNodes(editor, slateNodes);
  } catch (err) {
    console.warn(err);
  }
};
