import { Box } from '../../../shared/components';
import { ActionSetting } from '../Properties/ActionSetting';
import { AlignSetting } from '../Properties/AlignSetting';
import { PaddingSetting } from '../Properties/PaddingSetting';
import { RadiusSetting } from '../Properties/RadiusSetting';
import { StrokeSetting } from '../Properties/StrokeSetting';
import { PropertiesGrouping } from './PropertiesGrouping';
import { getActionProperties, getAlignProperties, getPaddingProperties, getRadiusProperties } from './property-utils';

export const ButtonSettings = (props) => {
  const { data, update, send } = props;
  const padding = getPaddingProperties({ data, update });
  const horizontalAlign = getAlignProperties({ data, update, alignKey: 'justifyContent' });
  const verticalAlign = getAlignProperties({ data, update, alignKey: 'alignItems' });
  const actions = getActionProperties({ data, update });
  const radius = getRadiusProperties({ data, update });

  return (
    <Box>
      <PropertiesGrouping label="Actions" description={actions.description}>
        <ActionSetting value={actions.value} setValue={actions.setValue} send={send} />
      </PropertiesGrouping>
      <PropertiesGrouping label="Cell Styles" defaultOpen>
        <AlignSetting label="Align" type="horizontal" {...horizontalAlign} send={send} />
        <AlignSetting label="Vertical Align" type="vertical" {...verticalAlign} send={send} />
        <RadiusSetting {...radius} />
        <StrokeSetting {...props} />
        <PaddingSetting value={padding.value} setValue={padding.setValue} send={send} />
      </PropertiesGrouping>
    </Box>
  );
};
