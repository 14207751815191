import { createContext, useContext, useState } from 'react';
import { useToolKit } from '../tool-kit/use-tool-kit';
import { ToolKit } from '../tool-kit/ToolKit';

const PropertyContext = createContext();

// This context is only to be used as a communication
// between "isSelected" components (ie, Toolbars) and
// the PropertiesPanel components.
export const PropertyManager = ({ bodyElement, children }) => {
  const toolkit = useToolKit();
  const selection = ToolKit.getSelection(toolkit);

  const [properties, setProperties] = useState({});
  const [openGroups, setOpenGroups] = useState({});

  const groups = {
    data: openGroups,
    set: setOpenGroups,
    type: selection?.type,
  };

  return (
    <PropertyContext.Provider value={{ bodyElement, properties, setProperties, groups }}>
      {children}
    </PropertyContext.Provider>
  );
};

export const usePropertyManager = () => useContext(PropertyContext);

PropertyManager.setGroup = (groups, key, value) => {
  groups.set((prev) => ({
    ...prev,
    [groups.type]: {
      ...prev[groups.type],
      [key]: value,
    },
  }));
};

PropertyManager.getGroup = (groups, key, defaultValue = false) => groups?.data?.[groups?.type]?.[key] ?? defaultValue;
