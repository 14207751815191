import { Segment } from '../../providers/segment/Segment';
import { useBlock } from '../../utility/useBlock';

export const usePageBreakBlock = () => {
  const block = useBlock();
  const styles = Segment.getStyle(block.segment);

  return {
    ...block,
    styles,
  };
};
