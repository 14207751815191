import { useMemo } from 'react';
import * as uuid from 'uuid';
import { useEditorResource } from '../../BriteEditor/use-editor-resource';
import { isSmartText } from '../../BriteEditor/editor-components/image/image';

const getSectionProperties = (properties) => {
  const isGrouped = Object.keys(properties || {})?.length;
  const hasBackground = properties?.fullScreenWidth || !!Object.keys(properties?.style || {})?.length;
  return {
    ...properties,
    isGrouped,
    hasBackground,
  };
};

const consolidateSectionProperties = (sections) => {
  const propertiesList = sections.map((section) => getSectionProperties(section?.properties || {}));
  let consolidatedProperties = {};
  for (const properties of propertiesList) {
    const entries = Object.entries(properties || {});
    for (const [key, value] of entries) {
      consolidatedProperties[key] = consolidatedProperties?.[key] || value;
    }
  }
  return consolidatedProperties;
};

const consolidateChildren = (sections) => {
  let children = [];
  for (const section of sections) {
    children = [...children, ...(section?.children || [])];
  }
  return children;
};

export const convertToSingleSection = (oldContent) => {
  let content = {
    sections: [],
    data: { ...oldContent?.data },
  };
  const properties = consolidateSectionProperties(oldContent?.sections);
  const children = consolidateChildren(oldContent?.sections);

  let section = oldContent?.sections?.at(0);
  section.properties = properties;
  section.properties.isGrouped = true;
  section.children = children;
  content.sections = [section];

  for (const child of children) {
    content.data[child.id] = {
      ...content.data[child.id],
      parentId: section.id,
    };
  }

  return content;
};

export const migrateV2 = (content) => {
  let next = {
    root: content?.root || {},
    sections: [],
    data: {},
  };

  for (const row of content?.rows || []) {
    const sectionId = uuid.v4();
    const { type, rowId = uuid.v4(), columns, stackColumns, ...sectionProperties } = row;
    const properties = getSectionProperties(sectionProperties);
    next.sections.push({
      id: sectionId,
      type: 'section',
      properties,
      children: [{ id: rowId, type: 'row' }],
    });

    let children = [];
    for (const column of columns) {
      const columnId = uuid.v4();
      const { id: blockId = uuid.v4(), width, component, ...blockProperties } = column;
      let list = [];
      if (column.type === 'list') {
        for (const item of column.list) {
          const { id: blockId, component, ...blockProperties } = item;
          list.push({ type: component, id: blockId });
          next.data[blockId] = {
            id: blockId,
            type: component,
            parentId: columnId,
            properties: {
              ...blockProperties,
            },
          };
        }
      } else {
        list.push({ id: blockId, type: component });
        next.data[blockId] = {
          id: blockId,
          type: component,
          parentId: columnId,
          properties: {
            ...blockProperties,
          },
        };
      }

      const properties = width ? { width } : {};
      children.push({ id: columnId, type: 'column' });
      next.data[columnId] = {
        id: columnId,
        type: 'column',
        properties,
        children: list,
        parentId: rowId,
      };
    }

    next.data[rowId] = {
      properties: { stackColumns },
      children,
      id: rowId,
      type: 'row',
      parentId: sectionId,
    };
  }

  return next;
};

export const getScope = (event) => {
  const blockElement = event.target.closest('[data-scope]');
  if (blockElement) {
    return blockElement.getAttribute('data-scope');
  }
  return null;
};

export const getCanDeselect = (scope) => {
  switch (scope) {
    case 'context-menu':
    case 'menu':
    case 'modal':
    case 'block':
    case 'toolbar':
      return true;
    default: {
      return false;
    }
  }
};

export const formatUrl = (text) => {
  let url = text;
  if (!/^https?:\/\//i.test(url) && !/^http?:\/\//i.test(url)) {
    url = 'https://' + url;
  }
  try {
    const data = new URL(url);
    return [data.href, true];
  } catch (err) {
    return ['', false];
  }
};

export const getIsValidUrl = (url) => {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
};

export const useIsValidUrl = (url) => useMemo(() => getIsValidUrl(url), [url]);

export const useSmartField = (value, fallback = '') => {
  const { data: smartFields } = useEditorResource('liveSmartFields');
  const isSmartField = isSmartText(value);

  const nextValue = useMemo(() => {
    if (isSmartField && smartFields?.[value]?.Success) {
      return smartFields?.[value]?.Value !== '<no value>' ? smartFields?.[value]?.Value : '';
    } else if (isSmartField) {
      return fallback;
    }
    return value;
  }, [isSmartField, value, smartFields?.[value]?.Value]);

  const validUrl = useIsValidUrl(nextValue);

  return {
    value: nextValue,
    validUrl,
    isSmartField,
    smartFields,
  };
};

// BATCHING: We needed a way to manage large local updates
// while maintaining history integrity and not overwhelming
// the websocket with too many messages.
export const buildBatchHistory = (current, batchId, actions) => {
  const history = current?.history || {};
  history[batchId] = current?.history?.[batchId] || {};
  for (const action of actions) {
    // We only want to accept the first "old" value for each action
    if (!(action.id in history[batchId])) {
      history[batchId][action.id] = structuredClone(action.old);
    }
  }
  return history;
};

export const getBatchChanges = (current, batchId, actions) => {
  const cloneActions = structuredClone(actions);
  const changes = {};
  changes[batchId] = cloneActions;
  return {
    ...(current?.changes || {}),
    ...changes,
  };
};

export const getBatch = (current, batchId, actions) => {
  const history = buildBatchHistory(current, batchId, actions);
  const changes = getBatchChanges(current, batchId, actions);
  return {
    history,
    changes,
  };
};

export const compileBatchIntoActions = (current) => {
  const changes = current?.changes || {};
  const history = current?.history || {};

  const actions = Object.entries(changes || {}).reduce((prev, [batchId, actions]) => {
    const list = actions.map((item) => {
      const old = history?.[batchId]?.[item.id] || item.old;
      return {
        ...item,
        old,
      };
    });
    return [...prev, ...list];
  }, []);

  return actions;
};

// END BATCHING CODE

export const getCommentsByBlock = (currentPageId, content, comments) => {
  return comments?.reduce((prev, item) => {
    const {
      metadata: { componentId, pageId, threadId },
      resolved,
      id,
    } = item;
    const thread = comments?.find((comment) => comment?.id === threadId);
    const contentHasBlockId = componentId in (content?.data || {});
    if (resolved || thread?.resolved || !contentHasBlockId) {
      return prev;
    } else if (pageId === currentPageId && contentHasBlockId) {
      return {
        ...prev,
        [componentId]: [...(prev?.[componentId] || []), id],
      };
    }
    return prev;
  }, {});
};

export const getThreadByBlock = (commentsByBlock, comments) => {
  const commentMap = comments?.reduce((prev, item) => ({ ...prev, [item?.id]: item }), {});
  const threadId = commentsByBlock?.find((commentId) => {
    const thread = commentMap?.[commentId];
    return !('threadId' in (thread?.metadata || {})) || thread?.id === thread?.metadata?.threadId;
  });
  return commentMap?.[threadId];
};

export const extractNumber = (value) => {
  try {
    return Number(value?.replace(/\D/g, '') || 0);
  } catch {
    return 0;
  }
};

export const dispatchEvent = (type, detail = {}) => {
  window.dispatchEvent(new CustomEvent(type, { detail }));
};

dispatchEvent.EVENT_TYPES = {
  EDITOR_UNDO: 'EDITOR_UNDO',
  EDITOR_REDO: 'EDITOR_REDO',
};
