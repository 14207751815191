import { Content } from '../../providers/content/Content';
import { getCopiableData, getPaste } from '../../providers/content/content-utils';
import { ToolKit } from '../../providers/tool-kit/ToolKit';
import { SIDEBAR_TABS } from '../constants';

const DELETE_SELECTION = {
  action: 'DELETE_SELECTION',
  match: ({ meta }) => {
    return !meta.any && meta?.key === 'Backspace';
  },
  onKeydown: (context) => {
    const { toolkit, sendUpdates } = context;
    const selection = ToolKit.getSelection(toolkit);
    if (selection?.id) {
      Content.remove(sendUpdates, selection?.id);
    }
  },
};

const OPEN_TOOLS_TAB = {
  action: 'OPEN_TOOLS_TAB',
  match: ({ meta }) => !meta.any && meta.key === 't',
  onKeydown: (context) => {
    const { toolkit } = context;
    const { type } = ToolKit.getCurrentTab(toolkit);
    const next = type === SIDEBAR_TABS.TOOLS ? '' : SIDEBAR_TABS.TOOLS;
    ToolKit.setCurrentTab(toolkit, next);
  },
};

const OPEN_COMMENTS_TAB = {
  action: 'OPEN_COMMENTS_TAB',
  match: ({ meta }) => !meta.any && meta.key === 'c',
  onKeydown: (context) => {
    const { toolkit } = context;
    const { type } = ToolKit.getCurrentTab(toolkit);
    const next = type === SIDEBAR_TABS.COMMENTS ? '' : SIDEBAR_TABS.COMMENTS;
    ToolKit.setCurrentTab(toolkit, next);
  },
};

const OPEN_CONNECTIONS_TAB = {
  action: 'OPEN_CONNECTIONS_TAB',
  match: ({ meta }) => !meta.any && meta.key === 'n',
  onKeydown: (context) => {
    const { toolkit } = context;
    const { type } = ToolKit.getCurrentTab(toolkit);
    const next = type === SIDEBAR_TABS.CONNECTIONS ? '' : SIDEBAR_TABS.CONNECTIONS;
    ToolKit.setCurrentTab(toolkit, next);
  },
};

const COPY = {
  action: 'COPY',
  match: ({ meta }) => meta.cmd && meta.key === 'c',
  onKeydown: (context) => {
    context.event.preventDefault();
    console.log('copy');
    const selection = ToolKit.getSelection(context.toolkit);
    const hasSingleSelection = ToolKit.hasSingleSelection(context.toolkit);
    if (hasSingleSelection) {
      const block = Content.get(context.content, selection?.id);
      const data = getCopiableData(block, {
        type: 'block',
        editor: 'brite-editor-v2',
        selection: 'single',
      });
      console.log(data);
      navigator.clipboard.writeText(data);
    }
  },
};

const CUT = {
  action: 'CUT',
  match: ({ meta }) => meta.cmd && meta.key === 'x',
  onKeydown: (context) => {
    context.event.preventDefault();
    const selection = ToolKit.getSelection(context.toolkit);
    const hasSingleSelection = ToolKit.hasSingleSelection(context.toolkit);
    if (hasSingleSelection) {
      const block = Content.get(context.content, selection?.id);
      const data = getCopiableData(block, {
        type: 'block',
        editor: 'brite-editor-v2',
        selection: 'single',
      });
      navigator.clipboard.writeText(data);
      Content.remove(context.sendUpdates, selection?.id);
      ToolKit.deselect(context.toolkit);
    }
  },
};

const PASTE = {
  action: 'PASTE',
  match: ({ meta }) => meta.cmd && meta.key === 'v',
  onKeydown: async (context) => {
    context.event.preventDefault();
    const selection = ToolKit.getSelection(context.toolkit);
    const hasSingleSelection = ToolKit.hasSingleSelection(context.toolkit);
    try {
      const copy = await navigator?.clipboard?.readText();
      const copiedData = JSON.parse(copy);
      if (copiedData?.metadata?.type === 'block' && copiedData?.metadata?.editor === 'brite-editor-v2') {
        if (hasSingleSelection) {
          const pasteDrag = getPaste(context.content, copiedData, selection?.id);
          ToolKit.drop(context.toolkit, pasteDrag);
        } else {
          const lastSectionId = context?.content?.sections?.at(-1)?.id;
          const pasteDrag = getPaste(context.content, copiedData, lastSectionId);
          ToolKit.drop(context.toolkit, pasteDrag);
          ToolKit.scrollTo(context.toolkit, lastSectionId);
        }
      }
    } catch (err) {
      console.log(err);
    }
  },
};

const UNDO = {
  action: 'UNDO',
  match: ({ meta }) => !meta.shift && meta.cmd && meta.key === 'z',
  onKeydown: async (context) => {
    context.event.preventDefault();
    context.event.stopPropagation();
    context.ws.undo();
  },
};

const REDO = {
  action: 'REDO',
  match: ({ meta }) => meta.cmd && meta.shift && meta.key === 'z',
  onKeydown: async (context) => {
    context.event.preventDefault();
    context.event.stopPropagation();
    context.ws.redo();
  },
};

export const shortcuts = [
  DELETE_SELECTION,
  OPEN_TOOLS_TAB,
  OPEN_COMMENTS_TAB,
  OPEN_CONNECTIONS_TAB,
  COPY,
  PASTE,
  CUT,
  UNDO,
  REDO,
];

export const getKeydownAction = (props) => {
  const shortcut = shortcuts.find((shortcut) => {
    const match = shortcut.match(props);
    if (typeof match === 'object') {
      return match?.[props.event.type];
    } else {
      return match;
    }
  });

  return shortcut?.action || '';
};

export const getKeyupAction = (props) => {
  const shortcut = shortcuts.find((shortcut) => shortcut.match(props));
  return shortcut?.action || '';
};

export const KEYDOWN_ACTIONS = shortcuts.reduce((acc, value) => {
  if (value.onKeydown) {
    acc[value.action] = value.onKeydown;
  }
  return acc;
}, {});

export const KEYUP_ACTIONS = shortcuts.reduce((acc, value) => {
  if (value.onKeyup) {
    acc[value.action] = value.onKeyup;
  }
  return acc;
}, {});
