import { BusinessMembers } from '../members/business-members';
import { CarrierPreview } from './CarrierPreview';
import { css } from '@emotion/css';
import { CustomAxios } from '../redux/axios/axios';
import { makeStyles } from '@mui/styles';
import { useStore } from '../store-provider/use-store';
import Box from '@mui/material/Box';
import React from 'react';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tabs from '@mui/material/Tabs';
import { Box as BriteBox, Text } from '../shared/components';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`settings-tabpanel-${index}`}
      aria-labelledby={`settings-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export const Settings = () => {
  const [value, setValue] = React.useState(0);
  const [pageDataRows, setPageDataRows] = React.useState([]);

  const {
    data: { selectedBusiness },
  } = useStore();

  const fetchChildren = async () => {
    try {
      const response = await CustomAxios.get(`/v2/business/${selectedBusiness.ID}/children`);
      setPageDataRows(response.data);
    } catch (e) {
      console.log('error getting children', e);
    }
  };

  const { Type: businessType = 'single' } = selectedBusiness;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchChildren();
      } catch (e) {}
    };

    if (selectedBusiness.ID) {
      fetchData();
    }
  }, [selectedBusiness.ID]);

  const getTotalLives = () => {
    var total = 0;
    for (let i = 0; i < pageDataRows.length; i++) {
      total += pageDataRows[i].EmployeeCount;
    }
    return total.toLocaleString('en-US');
  };

  return (
    <BriteBox page>
      <div>
        <div
          className={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}
        >
          <div
            className={css`
              font-weight: bold;
              font-size: 34px;
              line-height: 42px;
              color: #25282d;
              margin-right: 12px;
              margin-left: 8px;
              margin-bottom: 32px;
            `}
          >
            Settings
          </div>
        </div>

        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          aria-label="settings tabs"
          style={{ borderBottom: '1px solid #D1DAE3' }}
        >
          <Tab label="Members" />
          <Tab label="Billing" />

          {businessType === 'carrier' && <Tab label="Preview" />}
        </Tabs>

        <TabPanel value={value} index={0}>
          <BusinessMembers />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <CarrierPreview {...selectedBusiness} />
        </TabPanel>

        <TabPanel value={value} index={1}>
          {pageDataRows.length !== 0 && (
            <div
              className={css`
                padding-left: 5px;
                padding-right: 10px;
                padding-top: 5px;
                padding-bottom: 5px;
              `}
            >
              <div
                className={css`
                  display: flex;
                  margin-top: 24px;
                  align-items: center;
                `}
              >
                <div
                  className={css`
                    width: 200px;
                    background: #ffffff;
                    border: 1px solid #d1dae3;
                    border-radius: 8px;
                    padding: 24px;
                    margin-right: 24px;
                  `}
                >
                  <div
                    className={css`
                      font-family: 'Roboto';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 24px;
                      /* identical to box height, or 150% */
                      letter-spacing: 0.15px;
                      color: #66737f;
                    `}
                  >
                    Total Lives
                    {
                      <div
                        className={css`
                          font-family: 'Roboto';
                          font-style: normal;
                          font-weight: 700;
                          font-size: 34px;
                          line-height: 48px;
                          text-underline-offset: 8px;
                          letter-spacing: 0.25px;
                          color: #25282d;
                        `}
                      >
                        {getTotalLives()}
                      </div>
                    }
                  </div>
                  <div
                    className={css`
                      font-family: 'Roboto';
                      font-style: normal;
                      font-weight: 700;
                      font-size: 34px;
                      line-height: 48px;
                      letter-spacing: 0.25px;
                      color: #25282d;
                    `}
                  ></div>
                </div>
                <div
                  className={css`
                    width: 180px;
                    background: #ffffff;
                    border: 1px solid #d1dae3;
                    border-radius: 8px;
                    padding: 24px;
                    margin-right: 24px;
                  `}
                >
                  <div
                    className={css`
                      font-family: 'Roboto';
                      font-style: normal;
                      font-weight: 400;
                      font-size: 16px;
                      line-height: 24px;
                      /* identical to box height, or 150% */
                      letter-spacing: 0.15px;
                      color: #66737f;
                    `}
                  >
                    Total Clients
                    {
                      <div
                        className={css`
                          font-family: 'Roboto';
                          font-style: normal;
                          font-weight: 700;
                          font-size: 34px;
                          line-height: 48px;
                          text-underline-offset: 8px;
                          letter-spacing: 0.25px;
                          color: #25282d;
                        `}
                      >
                        {pageDataRows.length.toLocaleString('en-US')}
                      </div>
                    }
                  </div>
                  <div
                    className={css`
                      font-family: 'Roboto';
                      font-style: normal;
                      font-weight: 700;
                      font-size: 34px;
                      line-height: 48px;
                      /* identical to box height, or 141% */
                      letter-spacing: 0.25px;
                      color: #25282d;
                    `}
                  >
                    <span
                      className={css`
                        font-family: 'Roboto';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        /* identical to box height, or 150% */
                        letter-spacing: 0.15px;
                        color: #66737f;
                      `}
                    ></span>
                  </div>
                </div>
              </div>
              <div
                className={css`
                  width: 100%;
                  max-width: 700px;
                `}
              >
                <EnhancedTable rows={pageDataRows} />
              </div>
            </div>
          )}
          <Text
            css={`
              font-weight: bold;
            `}
            label
          >
            Contact support to setup / edit your billing details.{' '}
            <span
              className={css`
                font-weight: bold;
              `}
            >
              support@britebenefits.com
            </span>
          </Text>
        </TabPanel>
      </div>
    </BriteBox>
  );
};

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Client Name',
  },
  {
    id: 'eligibleEmployee',
    numeric: true,
    disablePadding: false,
    label: 'Number Of Eligible Employees',
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              hideSortIcon={headCell.id === 'name'}
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              <span
                className={css`
                  font-family: 'Roboto';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 24px;
                  letter-spacing: 0.15px;
                  color: #66737f;
                `}
              >
                {headCell.label}
              </span>
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function stableSort(array, comparator) {
  if (comparator === `desc`) {
    return array.sort((a, b) => b.EmployeeCount - a.EmployeeCount);
  } else {
    return array.sort((a, b) => a.EmployeeCount - b.EmployeeCount);
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },

  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function EnhancedTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('page');

  const handleRequestSort = (event, property) => {
    if (property === 'name') {
      return;
    }
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table className={classes.table} aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table">
          <EnhancedTableHead
            classes={classes}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={props.rows.length}
          />
          <TableBody>
            {stableSort(props.rows, order).map((row, index) => {
              return (
                <TableRow tabIndex={-1} key={row.Name}>
                  <TableCell align="left">{row.Name}</TableCell>
                  <TableCell align="left">{row.EmployeeCount.toLocaleString('en-US')}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <div
        className={css`
          display: flex;
          justify-content: center;
          margin-top: 18px;
        `}
      ></div>
    </div>
  );
}
