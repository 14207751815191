import { useParams } from 'react-router';
import { useEditorResource } from '../../../BriteEditor/use-editor-resource';
import { useQueryAPI } from '../../../react-query';
import { sortMap } from '../../../BriteEditor/editor-components/benefits/add-benefit';
import { useMemo } from 'react';
import { PRODUCT_HEADERS } from '../../../benefit-package/constants';

const displayMap = { ...PRODUCT_HEADERS, custom: 'Custom' };
const pricingTypes = ['insurance_plan', 'dental_buyup', 'vision_buyup', 'hsa_buyup'];
const networkTypes = ['insurance_plan', 'dental_buyup', 'vision_buyup'];

export const useProductSelection = (blockType, type, id) => {
  const { businessId } = useParams();

  const { data: productsList, isLoading: isLoadingProducts } = useEditorResource('products', {
    select: (data) =>
      data?.sort(
        (a, b) =>
          a?.MultiNetworkID?.localeCompare?.(b?.MultiNetworkID) ||
          sortMap?.[a?.MultiNetworkCategory]?.localeCompare?.(sortMap[b?.MultiNetworkCategory])
      ),
  });

  const { data: programData, isLoading: isLoadingPrograms } = useQueryAPI({
    url: `public/v1/business/${businessId}/programs`,
    defaultValue: [],
  });

  const selectableProducts = useMemo(() => {
    if (type === 'hsa_buyup') {
      const productIds = productsList?.map(({ ID }) => ID);
      return programData?.filter(
        ({ ProgramType, PlanID }) => ProgramType === 'health_savings_account' && productIds.includes(PlanID)
      );
    }
    return productsList?.filter((p) => {
      return p.Type === type;
    });
  }, [type, productsList, programData]);

  const product = useMemo(() => {
    if (type === 'hsa_buyup') {
      return selectableProducts?.find((item) => item?.PlanID === id);
    }
    return selectableProducts?.find((item) => item?.ID === id);
  }, [id, type, selectableProducts]);

  const selectableTypes = useMemo(() => {
    const entries = Object.entries(displayMap);
    if (blockType === 'pricing') {
      return entries.filter(([blockType]) => pricingTypes.includes(blockType));
    } else if (blockType === 'network') {
      return entries.filter(([blockType]) => networkTypes.includes(blockType));
    }
    return entries.filter(([blockType]) => blockType !== 'custom');
  }, [blockType]);

  const isLoading = isLoadingProducts || isLoadingPrograms;

  return { product, selectableProducts, productsList, programData, selectableTypes, isLoading };
};
