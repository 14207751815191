import { CheckCircle, DotsThreeVertical } from '@phosphor-icons/react';
import { Box, Button, Dropdown, Text } from '../../../shared/components';
import { colors } from '../../../shared/styles';
import { useComments } from './useComments';
import { Comment, CommentInput } from '../../../BriteEditor/comments-view';
import { useEffect, useRef, useState } from 'react';
import { Collapse } from '../../../common/components/Collapse';
import { useToolKit } from '../../providers/tool-kit/use-tool-kit';
import { ToolKit } from '../../providers/tool-kit/ToolKit';
import { scrollbar } from '../../../shared/shared-styles';
import { useContent } from '../../providers/content/use-content';
import { format } from 'date-fns';

export const Comments = () => {
  const replyRef = useRef();
  const scrollRef = useRef();

  const { content } = useContent({ ignoreSelection: true });
  const toolkit = useToolKit();
  const selectedComment = ToolKit.getComment(toolkit);
  const isInitialComment = selectedComment?.source === 'initial';

  const comments = useComments();
  const threadId = selectedComment?.threadId;

  const [viewResolved, setViewResolved] = useState(false);

  useEffect(() => {
    if (threadId && scrollRef?.current) {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [threadId]);

  const submitReply = (e, slateContent) => {
    e?.preventDefault();
    comments.submitReply({ threadId, slateContent });
  };

  const createThread = (e, slateContent) => {
    e?.preventDefault();
    const thread = comments.create({ slateContent, blockId: selectedComment?.blockId });
    ToolKit.selectComment(toolkit, {
      source: 'comment',
      blockId: thread?.metadata?.componentId,
      threadId: thread?.id,
    });
  };

  const removeThread = (thread) => {
    comments.remove(thread);
    ToolKit.resetComment(toolkit);
  };

  const selectThread = (thread) => {
    ToolKit.selectComment(toolkit, {
      source: 'comment',
      blockId: thread?.metadata?.componentId,
      threadId: thread?.id,
    });
  };

  const replyCountLabel = (count) => {
    if (!count) {
      return 'Reply';
    } else if (count === 1) {
      return 'View 1 reply';
    } else {
      return `View ${count} replies`;
    }
  };

  return (
    <Box
      css={`
        width: 100%;
        height: 100%;
        gap: 8px;
        overflow-x: clip;
        overflow-y: auto;
        ${scrollbar.hide}
      `}
    >
      <Box flex="space-between">
        <Text
          label
          bold
          css={`
            margin: 16px;
          `}
        >
          Comments
        </Text>
      </Box>
      <Box
        css={`
          display: grid;
          gap: 16px;
        `}
      >
        <Collapse isOpen={isInitialComment}>
          <Box
            css={`
              background-color: white;
              padding: 16px;
              border: 2px solid ${colors.purple};
              margin: 8px;
              border-radius: 8px;
            `}
          >
            <Text
              label
              bold
              css={`
                border-radius: 8px;
                color: ${colors.gray[600]};
              `}
            >
              Add Comment
            </Text>

            <CommentInput
              buttonLabel="Post"
              placeholder="Write a comment"
              submitReply={createThread}
              autoFocus={isInitialComment}
            />
          </Box>
        </Collapse>
        {comments.threads.length ? (
          <Box
            css={`
              display: grid;
              gap: 16px;
            `}
          >
            {comments?.threads?.map((item) => {
              const contentHasThreadId = item?.metadata?.componentId in (content?.data || {});
              return (
                <Box
                  key={item?.id}
                  ref={threadId === item?.id ? scrollRef : null}
                  css={`
                    border-radius: 8px;
                    padding: 16px;
                    margin: 0 8px;
                    ${threadId === item?.id
                      ? `
                    outline: 2px solid ${colors.purple};
                    border: 1px solid transparent;
                  `
                      : `
                    border: 1px solid ${colors.gray[300]};
                    :hover {
                      background-color: ${colors.gray[100]};
                    }
                  `}
                  `}
                  onClick={() => selectThread(item)}
                >
                  <Collapse isOpen={threadId === item?.id || item?.resolved}>
                    <Box
                      flex="space-between"
                      css={`
                        margin: 0 -4px;
                        margin-bottom: 8px;
                      `}
                    >
                      <Button
                        styles="icon sm"
                        hoverLabel={item?.resolved ? 'Unresolve' : 'Resolve'}
                        onClick={() => comments.resolve(item)}
                      >
                        <CheckCircle
                          size={24}
                          color={item?.resolved ? colors.purple : colors.black}
                          weight={item?.resolved ? 'fill' : 'regular'}
                        />
                      </Button>
                      <Dropdown
                        clear
                        stopPropagation
                        popper={{ placement: 'right-start' }}
                        button={() => (
                          <Button styles="icon sm">
                            <DotsThreeVertical size={24} weight="bold" />
                          </Button>
                        )}
                      >
                        <Box option onClick={() => removeThread(item)}>
                          Delete
                        </Box>
                      </Dropdown>
                    </Box>
                  </Collapse>

                  <Box
                    css={`
                      ${selectedComment?.highlightId === item?.id
                        ? `
                          background-color: rgba(0, 0, 255, .1);  
                          border-radius: 4px;
                          padding: 4px;
                        `
                        : ''}
                    `}
                  >
                    <Box flex="space-between">
                      <Text label bold ellipsis>
                        {item?.metadata?.username}
                      </Text>
                      <Text helper>
                        {item?.created_at ? format(new Date(item?.created_at), 'MMM d | h:mm a') : 'Now'}
                      </Text>
                    </Box>
                    <Comment
                      showMenu={threadId === item?.id}
                      canWrite={comments?.canWrite || item?.created_by_user_id === comments?.userId}
                      item={item}
                    />
                    {threadId !== item?.id ? (
                      <Text
                        css={`
                          cursor: pointer;
                          margin-top: 16px;
                        `}
                      >
                        {replyCountLabel(comments?.replies?.[item?.id]?.length)}
                      </Text>
                    ) : null}
                  </Box>
                  <Collapse isOpen={threadId === item?.id}>
                    {comments?.replies?.[item?.id]?.map((reply) => (
                      <Box
                        key={item?.id}
                        css={`
                          display: grid;
                          margin: 8px 0;
                          ${selectedComment?.highlightId === reply?.id
                            ? `
                            background-color: rgba(0, 0, 255, .1);  
                            border-radius: 4px;
                            padding: 4px;
                          `
                            : ''}
                        `}
                      >
                        <Box flex="space-between">
                          <Text label bold ellipsis>
                            {reply?.metadata?.username}
                          </Text>
                          <Text helper>
                            {reply?.created_at ? format(new Date(reply?.created_at), 'MMM d | h:mm a') : 'Now'}
                          </Text>
                        </Box>
                        <Comment
                          canWrite={comments?.canWrite || reply?.created_by_user_id === comments?.userId}
                          item={reply}
                        />
                      </Box>
                    ))}

                    <CommentInput ref={replyRef} submitReply={submitReply} />
                  </Collapse>
                  {!contentHasThreadId && threadId === item?.id ? (
                    <Text
                      css={`
                        color: ${colors.red[100]};
                      `}
                    >
                      This exist does not exist
                    </Text>
                  ) : null}
                </Box>
              );
            })}
          </Box>
        ) : (
          <Box
            css={`
              gap: 16px;
              margin: 0 8px;
              padding: 16px;
              border-radius: 8px;
              background-color: ${colors.gray[100]};
            `}
          >
            <Text bold>No comments.</Text>
            <Text>Select a block to add a comment.</Text>
          </Box>
        )}
        <Box
          css={`
            display: grid;
            gap: 16px;
            margin: 16px 0;
          `}
        >
          {comments?.resolved?.length ? (
            <Box
              option
              flex="center"
              css={`
                border-radius: 8px;
                padding: 8px;
                margin: 0 8px;
                background-color: ${colors.gray[100]};
                user-select: none;
                cursor: pointer;
              `}
              onClick={() => setViewResolved(!viewResolved)}
            >
              <Text>
                {viewResolved ? 'Hide' : 'View'} {comments?.resolved?.length} resolved
              </Text>
            </Box>
          ) : null}
          <Collapse isOpen={comments.resolved.length && viewResolved}>
            <Box
              css={`
                display: grid;
                gap: 16px;
                padding: 16px;
                margin: -14px;
              `}
            >
              {comments?.resolved?.map((item) => {
                const contentHasThreadId = item?.metadata?.componentId in (content?.data || {});
                return (
                  <Box
                    key={item?.id}
                    ref={threadId === item?.id ? scrollRef : null}
                    css={`
                      border-radius: 8px;
                      padding: 16px;
                      margin: 0 8px;
                      ${threadId === item?.id
                        ? `
                    outline: 2px solid ${colors.purple};
                    border: 1px solid transparent;
                  `
                        : `
                    border: 1px solid ${colors.gray[300]};
                    :hover {
                      background-color: ${colors.gray[100]};
                    }
                  `}
                    `}
                    onClick={() => selectThread(item)}
                  >
                    <Collapse isOpen={threadId === item?.id || item?.resolved}>
                      <Box
                        flex="space-between"
                        css={`
                          margin: 0 -4px;
                          margin-bottom: 8px;
                        `}
                      >
                        <Button
                          styles="icon sm"
                          hoverLabel={item?.resolved ? 'Unresolve' : 'Resolve'}
                          onClick={() => comments.resolve(item)}
                        >
                          <CheckCircle
                            size={24}
                            color={item?.resolved ? colors.purple : colors.black}
                            weight={item?.resolved ? 'fill' : 'regular'}
                          />
                        </Button>
                        <Dropdown
                          clear
                          stopPropagation
                          popper={{ placement: 'right-start' }}
                          button={() => (
                            <Button styles="icon sm">
                              <DotsThreeVertical size={24} weight="bold" />
                            </Button>
                          )}
                        >
                          <Box option onClick={() => removeThread(item)}>
                            Delete
                          </Box>
                        </Dropdown>
                      </Box>
                    </Collapse>

                    <Box
                      css={`
                        ${selectedComment?.highlightId === item?.id
                          ? `
                          background-color: rgba(0, 0, 255, .1);  
                          border-radius: 4px;
                          padding: 4px;
                        `
                          : ''}
                      `}
                    >
                      <Text label bold ellipsis>
                        {item?.metadata?.username}
                      </Text>
                      <Comment
                        showMenu={threadId === item?.id}
                        canWrite={comments?.canWrite || item?.created_by_user_id === comments?.userId}
                        item={item}
                      />
                      {threadId !== item?.id ? (
                        <Text
                          css={`
                            cursor: pointer;
                            margin-top: 16px;
                          `}
                        >
                          {replyCountLabel(comments?.replies?.[item?.id]?.length)}
                        </Text>
                      ) : null}
                    </Box>
                    <Collapse isOpen={threadId === item?.id}>
                      {comments?.replies?.[item?.id]?.map((reply) => (
                        <Box
                          key={item?.id}
                          css={`
                            display: grid;
                            margin: 8px 0;
                            ${selectedComment?.highlightId === reply?.id
                              ? `
                            background-color: rgba(0, 0, 255, .1);  
                            border-radius: 4px;
                            padding: 4px;
                          `
                              : ''}
                          `}
                        >
                          <Text label bold ellipsis>
                            {reply?.metadata?.username}
                          </Text>
                          <Comment
                            canWrite={comments?.canWrite || reply?.created_by_user_id === comments?.userId}
                            item={reply}
                          />
                        </Box>
                      ))}

                      <CommentInput ref={replyRef} submitReply={submitReply} />
                    </Collapse>
                    {!contentHasThreadId && threadId === item?.id ? (
                      <Text
                        css={`
                          color: ${colors.red[100]};
                        `}
                      >
                        This exist does not exist
                      </Text>
                    ) : null}
                  </Box>
                );
              })}
            </Box>
          </Collapse>
        </Box>
      </Box>
    </Box>
  );
};
