import { useMemo } from 'react';
import { useEditorResource } from '../../../../BriteEditor/use-editor-resource';
import { Segment } from '../../../providers/segment/Segment';
import { useBlock } from '../../../utility/useBlock';
import { useSmartField } from '../../../utility/editor-utils';
import { useSync } from '../../../utility/useSync';

export const useNetworkBlock = () => {
  const block = useBlock();

  const properties = Segment.getProperties(block?.segment);

  const { data: products, isLoading, refetch } = useEditorResource('products');

  const sync = useSync({ ...properties });

  const { value: productId, isSmartField } = useSmartField(sync?.value?.data?.id, sync?.value?.data?.selectedId);

  const product = useMemo(() => {
    return products?.find((item) => item?.ID === productId);
  }, [isLoading, productId]);

  return {
    ...block,
    product,
    refetch,
    isLoading,
    sync,
    productId,
    isSmartField,
  };
};
