import { css } from '@emotion/css';
import { useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useRequests, useResource } from '../react-query';
import { guides } from '../react-query/resources/guides';
import { Button, Div, Input, Modal, Text, Toggle } from '../shared/components';
import { flex } from '../shared/shared-styles';
import { useStore } from '../store-provider/use-store';
import { CompanySelect } from '../Sidebar/company-select';

export const AssignGuideModal = ({ display, hideAssignGuide, guide }) => {
  const guidesResource = useResource(guides, { staleTime: 5 * 60 * 1000 });
  const guidesRequests = useRequests(guidesResource);
  const {
    data: { selectedBusiness, companies, acls },
  } = useStore();

  const [selected, setSelected] = useState(selectedBusiness);
  const [guideName, setGuideName] = useState('');
  const [loading, setLoading] = useState(false);

  const accessibleCompanies = useMemo(() => {
    const list = companies.filter(({ ID, ParentID }) => {
      const id = ID in acls ? ID : ParentID;
      return acls[id].Acls.includes('write:course');
    });
    return list;
  }, [companies, acls]);

  const handleMove = async () => {
    try {
      setLoading(true);

      const assignGuide = guides.utils.getDuplicatePost(guide.ID, selected?.ID, {
        Name: guideName,
        ConvertToBriteEditor: true,
        CourseFolderID: null,
      });
      await guidesRequests.post(assignGuide);
      const company = companies.find(({ ID }) => ID === selected.ID);
      if (company && selected?.ID !== selectedBusiness?.ID) {
        toast.success(`Successfully assigned ${guideName} to ${company.Name}.`);
      } else {
        toast.success(`Successfully assigned ${guideName}.`);
      }
    } catch (err) {
      console.error(err);
      toast.error(`We're sorry, it looks like we couldn't assign the guide. Please try again.`);
    } finally {
      hideAssignGuide();
      setLoading(false);
    }
  };

  const modalProps = {
    display,
    onClose: () => hideAssignGuide(),
  };

  return (
    <Modal {...modalProps}>
      <Modal.Paper>
        <Modal.Header title="Assign Guide" onClose={modalProps.onClose} />
        <Modal.Body>
          <Div
            css={css`
              ${flex('space-between')}
              padding: 8px 0;
            `}
          >
            <Text label>Select a company</Text>
            <CompanySelect
              selectedBusiness={selected}
              selectBusiness={({ option }) => setSelected(option)}
              defaultBusinessList={accessibleCompanies}
              css={`
                width: 65%;
              `}
              listHeight="30vh"
            />
          </Div>
          <Div
            css={css`
              ${flex('space-between')}
              padding: 8px 0;
            `}
          >
            <Text label>Guide Name</Text>
            <Input
              css={`
                width: 65%;
              `}
              placeholder="Enter Guide Name"
              value={guideName}
              onChange={(event) => setGuideName(event.target.value)}
            />
          </Div>
        </Modal.Body>
        <Modal.Actions>
          <Button
            secondary
            css={`
              margin-right: 16px;
            `}
            onClick={modalProps.onClose}
          >
            Cancel
          </Button>
          <Button onClick={handleMove} disabled={loading}>
            {loading ? 'Saving' : 'Assign'}
          </Button>
        </Modal.Actions>
      </Modal.Paper>
    </Modal>
  );
};
