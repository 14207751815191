import { useRef } from 'react';
import { useEvent } from '../../../shared/use-event';
import { useToolKit } from '../../providers/tool-kit/use-tool-kit';
import { INPUT_DOM_ELEMENTS } from '../constants';
import { dispatchEvent, getScope } from '../editor-utils';
import { getKeydownAction, getKeyupAction, KEYDOWN_ACTIONS, KEYUP_ACTIONS } from './shortcuts';
import { ToolKit } from '../../providers/tool-kit/ToolKit';

const getMeta = (event) => {
  const HTMLElementType = document.activeElement.tagName;
  const scope = getScope(event);

  const hasTextSelection =
    !!INPUT_DOM_ELEMENTS.includes(HTMLElementType) ||
    (!window?.getSelection()?.isCollapsed && !!document.activeElement?.getAttribute('data-slate-editor'));

  const isAvailable = scope === null && !hasTextSelection;

  return {
    isAvailable,
    key: event.key,
    meta: event.metaKey,
    alt: event.altKey,
    ctrl: event.ctrlKey,
    shift: event.shiftKey,
    cmd: event.metaKey || event.ctrlKey,
    any: event.metaKey || event.altKey || event.ctrlKey || event.shiftKey,
  };
};

export const useEditorShortkeys = ({ ws, sendUpdates, content }) => {
  const meta = useRef({});
  const toolkit = useToolKit();

  const handleKeydown = (event) => {
    const keyboardMeta = getMeta(event);
    if (keyboardMeta?.isAvailable) {
      meta.current = keyboardMeta;
      const action = getKeydownAction({ event, meta: keyboardMeta });
      if (action in KEYDOWN_ACTIONS) {
        KEYDOWN_ACTIONS[action]({ event, toolkit, ws, content, sendUpdates });
      }
    }
  };

  const handleKeyup = (event) => {
    if (meta?.current?.isAvailable) {
      const action = getKeyupAction({ event, meta: meta.current });
      if (action in KEYUP_ACTIONS) {
        KEYUP_ACTIONS[action]({ event, toolkit, ws, content, sendUpdates });
      }
    }
    ToolKit.mergeCache(toolkit, 'keyboard', { shift: false });
  };

  useEvent('keydown', handleKeydown);
  useEvent('keyup', handleKeyup);

  useEvent(dispatchEvent.EVENT_TYPES.EDITOR_UNDO, () => ws.undo());
  useEvent(dispatchEvent.EVENT_TYPES.EDITOR_REDO, () => ws.redo());
};
