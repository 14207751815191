import { CostSummary } from '../../../../BriteEditor/editor-components/benefits/summaries/cost-summary';
import { Toolbar } from '../../../utility/Toolbar';
import { EmptySmartBenefit } from '../EmptySmartBenefit';
import { ErrorSmartBenefit } from '../ErrorSmartBenefit';
import { PricingToolbar } from './PricingToolbar';
import { usePricingBlock } from './usePricingBlock';

export const PricingBlock = () => {
  const block = usePricingBlock();

  return (
    <>
      {!block?.sync?.value?.data?.id ? (
        <EmptySmartBenefit type="pricing" />
      ) : !block?.product?.ID ? (
        <ErrorSmartBenefit type="pricing" />
      ) : (
        <CostSummary type={block?.productType} state={block?.productState} summary={block?.properties?.data} />
      )}

      <Toolbar isSelected={block?.isSelected} anchor={block?.anchor}>
        <PricingToolbar
          id={block?.id}
          sync={block?.sync}
          productId={block?.productId}
          isSmartField={block?.isSmartField}
        />
      </Toolbar>
    </>
  );
};
