import { format, startOfToday, subDays } from 'date-fns';

export const TRAFFIC_TAB = 'traffic';
export const ENGAGEMENT_TAB = 'engagement';
export const CONVERSION_TAB = 'conversion';

export const LAST_7_DAYS = 'Last 7 Days';
export const LAST_30_DAYS = 'Last 30 Days';
export const LAST_90_DAYS = 'Last 90 Days';
export const LAST_YEAR = 'Last Year';
export const CUSTOM = 'Custom';

export const DATE_RANGE_OPTIONS = [LAST_7_DAYS, LAST_30_DAYS, LAST_90_DAYS, LAST_YEAR, CUSTOM];

export const formatDateByDay = (date) => format(new Date(date), 'MMM dd yyyy');
export const formatDateByMonth = (date) => format(new Date(date), 'MMM yyyy');

export const getStartAndEndDate = (dateRange) => {
  const today = startOfToday();
  switch (dateRange) {
    case LAST_7_DAYS:
      return {
        startDate: subDays(today, 6),
        endDate: today,
      };
    case LAST_30_DAYS:
      return {
        startDate: subDays(today, 29),
        endDate: today,
      };
    case LAST_90_DAYS:
      return {
        startDate: subDays(today, 89),
        endDate: today,
      };
    case LAST_YEAR:
      return {
        startDate: subDays(today, 365),
        endDate: today,
      };
    default:
      return {};
  }
};

export const AGGREGATION_ALL = { label: 'All Guides', value: 'all' };
export const AGGREGATION_INDIVIDUAL = { label: 'Individual', value: 'guide' };
export const AGGREGATION_OPTIONS = [AGGREGATION_ALL, AGGREGATION_INDIVIDUAL];
