import { useState } from 'react';
import { useMutation } from 'react-query';
import { Box, Input, Text } from '../shared/components';
import { colors } from '../shared/styles';
import { updateDashboard } from './api/dashboard';
import { useDashboard } from './context/DashboardContext';

export const DashboardDescription = () => {
  // Context
  const { dashboard, refetch } = useDashboard();

  // State
  const [description, setDescription] = useState(dashboard?.description);

  // TODO: UI states for all the mutations below.
  const handleUpdateDashboard = useMutation(() => updateDashboard({ dashboard: { ...dashboard, description } }), {
    onMutate: () => {},
    onSuccess: () => refetch(),
    onError: () => {},
    onSettled: () => {},
  });

  return (
    <Box
      css={`
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8px;
        width: 100%;
      `}
    >
      <Text body color={colors.black}>
        <strong>{dashboard?.config?.guides?.length}</strong> Guides
      </Text>
      <Box
        css={`
          width: 1px;
          height: 16px;
          background: ${colors.gray[300]};
        `}
      />
      <Input
        css={`
          flex-grow: 1;
          height: 32px;
          background: transparent;
          border: none;

          :hover {
            border: none;
          }

          input {
            text-overflow: ellipsis;
            color: ${colors.gray[400]};
            padding: 0px;

            ::placeholder {
              color: ${colors.gray[400]};
            }
          }
        `}
        placeholder="Add a short description here..."
        value={description}
        onBlur={() => handleUpdateDashboard.mutate()}
        onChange={(e) => setDescription(e.target.value)}
        onClick={(e) => e.stopPropagation()}
      />
    </Box>
  );
};
