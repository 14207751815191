import { css } from '@emotion/css';
import { Info } from '@phosphor-icons/react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { guide as GUIDE_USE_RESOURCE, useRequests, useResource } from '../../../react-query';
import { Button, Div, Input, Modal, Text, Toggle } from '../../../shared/components';
import { colors } from '../../../shared/styles';

export const CreateLinkModal = ({ hide = () => {}, launchGuide = () => {}, guide = {} }) => {
  const [link, setLink] = useState(guide?.VanityURL);
  const [passcode, setPasscode] = useState(guide?.Passcode);
  const [requirePasscode, setRequirePasscode] = useState(guide?.Passcode !== '');

  const guideResource = useResource(GUIDE_USE_RESOURCE, { queryKey: ['editor', 'guide-lease'] });
  const guideRequests = useRequests(guideResource);

  const handleLinkChange = (e) => {
    const { value } = e.target;
    const linkValue = value?.replace(/[^a-zA-Z0-9-_]/g, '');
    setLink(linkValue);
  };

  const handleRequirePasscode = (e) => {
    const { checked } = e.target;

    if (!checked) {
      setPasscode('');
    }

    setRequirePasscode(checked);
  };

  const handleCreate = async () => {
    const payload = {
      VanityURL: link,
      Passcode: passcode,
    };

    try {
      const putGuide = GUIDE_USE_RESOURCE?.utils?.getPut(guide.ID, {
        ...guideResource?.query?.data,
        ...payload,
      });
      await guideRequests.put(putGuide);
      hide();
      launchGuide();
    } catch (err) {
      toast.error(`Error creating link...`);
    }
  };

  return (
    <Modal display onClose={() => hide()}>
      <Modal.Paper width="656px">
        <Modal.Header title="Create Guide Link" onClose={() => hide()} />
        <Modal.Body>
          <Div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 24px;
            `}
          >
            <Div
              css={css`
                display: flex;
                padding: 16px 24px;
                align-items: center;
                gap: 8px;
                align-self: stretch;
                border-radius: 8px;
                background: var(--100, ${colors.gray[100]});
              `}
            >
              <Info color={colors.purple} weight="fill" />
              <Text label>Before you can share your guide, you need to create a link.</Text>
            </Div>
            <Div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 100%;
                gap: 4px;
              `}
            >
              <Text caption bold color={colors.black}>
                Guide URL
              </Text>
              <Input
                css={css`
                  width: 100%;
                `}
                value={link}
                onChange={(e) => handleLinkChange(e)}
                error={link.length === 0}
              />
            </Div>
            <Div
              css={css`
                display: flex;
                padding: 12px 16px;
                align-items: center;
                gap: 8px;
                align-self: stretch;
                border-radius: 8px;
                background: ${colors.gray[100]};
              `}
            >
              <Div
                css={css`
                  display: flex;
                  justify-content: start;
                  align-items: center;
                `}
              >
                <Text body color={colors.black}>
                  https://britehr.app/
                </Text>
                <Text
                  h4
                  css={css`
                    font-size: 16px;
                  `}
                >
                  {link}
                </Text>
              </Div>
            </Div>
            <Div
              css={css`
                display: flex;
                flex-direction: column;
                width: 100%;
              `}
            >
              <Div
                css={css`
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                `}
              >
                <Text body color={colors.black}>
                  Require Passcode
                </Text>
                <Toggle checked={requirePasscode} onChange={(e) => handleRequirePasscode(e)} />
              </Div>
              {requirePasscode && (
                <Div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 4px;
                  `}
                >
                  <Text caption bold color={colors.black}>
                    Passcode
                  </Text>
                  <Input
                    css={css`
                      width: 100%;
                    `}
                    value={passcode}
                    onChange={(e) => setPasscode(e.target.value)}
                  />
                  <Text helper>If you set a passcode for this guide, only those with the code can view it.</Text>
                </Div>
              )}
            </Div>
          </Div>
        </Modal.Body>
        <Modal.Actions>
          <Button secondary onClick={() => hide()}>
            Cancel
          </Button>
          <Button primary disabled={link.length === 0} onClick={() => handleCreate()}>
            Create Link
          </Button>
        </Modal.Actions>
      </Modal.Paper>
    </Modal>
  );
};
