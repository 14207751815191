export const mapPageViewsData = ({ data }) => {
  // Safely access the nested GuidePages and Pages array, defaulting to an empty array if not present.
  const pages = data?.GuidePages?.[0]?.Pages || [];

  // Helper function to format time (in seconds) into a human-readable string (e.g., "1h 20m 15s").
  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.ceil(seconds % 60);

    // Construct the formatted time string.
    return [hours ? `${hours}h` : '', minutes ? `${minutes}m` : '', `${remainingSeconds}s`]
      .filter(Boolean) // Remove empty strings.
      .join(' ');
  };

  // Transform the pages array.
  return pages.map((page, index) => {
    const { AverageTimeOnPage: rawTimeOnPage, PageName, EntirePageViewedPercentage, Visitors, ...rest } = page;

    // Format the average time on page.
    const AverageTimeOnPage = formatTime(rawTimeOnPage);

    // Construct page details with a 1-based page number.
    const PageDetails = {
      PageName,
      PageNumber: index + 1,
    };

    // Calculate the number and percentage of visitors who viewed the entire page.
    const ViewedEntirePage = {
      Count: Math.round(Visitors * (EntirePageViewedPercentage / 100)),
      Percent: Math.ceil(EntirePageViewedPercentage),
    };

    // Return the transformed page data.
    return {
      AverageTimeOnPage,
      PageDetails,
      ViewedEntirePage,
      Visitors,
      ...rest, // Include any additional properties from the original page object.
    };
  });
};
