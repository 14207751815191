import { useEffect } from 'react';
import { Modify, safelySpread } from '../../providers/content/content-modify';
import { useContent } from '../../providers/content/use-content';
import { convertToHtml } from '../../../BriteEditor/editor-components/text-v2/slate-utils';
import { isEqual } from 'lodash';

const compileSlate = (slate) => {
  const content = convertToHtml(slate?.slateProps?.editor?.children);
  return {
    slate: slate?.slateProps?.editor?.children,
    content,
  };
};

export const UpdateManager = ({ block }) => {
  const { sendUpdates } = useContent();

  const send = () => {
    const title = compileSlate(block?.title);
    const body = compileSlate(block?.body);

    const isTitleChanged = !isEqual(block?.properties?.data?.title, title);
    const isBodyChanged = !isEqual(block?.properties?.data?.body, body);

    if (!isTitleChanged && !isBodyChanged) return;

    Modify.properties(sendUpdates, block?.id, (properties) => ({
      data: {
        ...safelySpread(properties?.data),
        title: isTitleChanged ? title : properties?.data?.title,
        body: isBodyChanged ? body : properties?.data?.body,
      },
    }));
  };

  useEffect(() => {
    return () => send();
  }, []);

  return null;
};
