import { getComponent } from '../../../../BriteEditor/utils';
import {
  attachAction,
  getAction,
  getParentAction,
  insertAction,
  removeEmptyActions,
  updateAction,
} from './action-utils';
import { addChild, getChildFromAction } from './child-utils';
import { addToComposition, compileCompositionActions, removeFromComposition } from './composition-utils';
import * as uuid from 'uuid';
import { createSectionAction } from './section-utils';
import { createRowAction } from './row-utils';
import { createColumnAction } from './column-utils';

export const createBlockAction = ({ type, parentId, properties = {} }) => {
  const id = uuid.v4();
  const block = {
    id,
    type,
    parentId,
    properties,
  };

  const action = {
    id,
    data: block,
    old: null,
    updates: block,
    property: 'data',
    type: 'insert',
  };

  return action;
};

export const getBlockProperties = (toolType) => {
  const data = getComponent(toolType);
  const { component, ...rest } = data;
  return rest;
};

export const addBlockToAdjacentBlock = ({ composition, source, destination, edge }) => {
  const parent = getParentAction(composition, destination?.id);
  const child = getChildFromAction(source);
  const children = addChild(child, parent.data.children, { id: destination.data.id, edge });
  updateAction(composition, parent.id, 'update', { children });
  updateAction(composition, source.id, 'update', { parentId: parent.data.id });
};

export const moveBlock = ({ composition, source, destination, edge }) => {
  removeFromComposition(composition, source);
  addToComposition({ composition, source, destination, edge });
  removeEmptyActions(composition);
  const actions = compileCompositionActions(composition);
  return actions;
};

export const createBlock = ({ composition, drag }) => {
  const properties = getBlockProperties(drag?.toolType);
  const destination = getAction(composition, drag?.destinationId);
  const action = createBlockAction({
    parentId: destination.id,
    type: drag?.toolType,
    properties,
  });
  attachAction(composition, action);
  if (drag?.key === 'empty-section') {
    attachAction(composition, destination);

    const rowAction = createRowAction(destination.id, []);
    attachAction(composition, rowAction);
    const rowChild = getChildFromAction(rowAction);
    updateAction(composition, destination.id, 'update', { children: [rowChild] });

    const columnAction = createColumnAction(rowAction.id, []);
    attachAction(composition, columnAction);
    const columnChild = getChildFromAction(columnAction);
    updateAction(composition, rowAction.id, 'update', { children: [columnChild] });

    const child = getChildFromAction(action);
    updateAction(composition, action.id, 'update', { parentId: columnAction.id });
    updateAction(composition, columnAction.id, 'update', { children: [child] });
  } else {
    addToComposition({ composition, source: action, destination, edge: drag?.edge });
  }
  if (drag?.copiedData !== null) {
    const properties = structuredClone(drag?.copiedData?.properties || {});
    updateAction(composition, action.id, 'update', { properties });
  }
  removeEmptyActions(composition);
  const actions = compileCompositionActions(composition);
  return actions;
};

export const createBlockEmpty = ({ composition, drag }) => {
  // Create new section
  const sectionAction = createSectionAction(0);
  insertAction(composition, sectionAction);

  // Create new row
  const rowAction = createRowAction(sectionAction.id, []);
  insertAction(composition, rowAction);
  const rowChild = getChildFromAction(rowAction);
  updateAction(composition, sectionAction?.id, 'update', { children: [rowChild] });

  // Create new column
  const columnAction = createColumnAction(rowAction.id, []);
  insertAction(composition, columnAction);
  const columnChild = getChildFromAction(columnAction);
  updateAction(composition, rowAction?.id, 'update', { children: [columnChild] });

  // Create new block
  const properties = getBlockProperties(drag?.toolType);
  const blockAction = createBlockAction({
    parentId: columnAction.id,
    type: drag?.toolType,
    properties,
  });
  insertAction(composition, blockAction);
  const child = getChildFromAction(blockAction);
  updateAction(composition, columnAction?.id, 'update', { children: [child] });

  // Prepare actions
  removeEmptyActions(composition);
  const actions = compileCompositionActions(composition);
  return actions;
};
