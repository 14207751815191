import { forwardRef, useEffect, useState } from 'react';
import { useStore } from '../../store-provider/use-store';
import { useEvent } from '../../shared/use-event';
import { Box, Icon, Text } from '../../shared/components';
import { ReactComponent as Cursor } from '../../images/svg/cursor.svg';
import { colors } from '../../shared/styles';
import { useSubscription } from '../../WebSocket/useSubscription';
import { useDebounce } from '../../shared/use-debounce';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import { ToolKit } from '../providers/tool-kit/ToolKit';

function mapCursorPosition(element, data) {
  if (!element || !data?.sender || !data?.position) {
    return { x: 0, y: 0 };
  }
  return {
    x: data.position.xPercent * 100,
    y: data.position.yPixel,
  };
}

function getCursorPosition(element, position) {
  if (!element || !position) return { x: 0, y: 0 };
  const rect = element.getBoundingClientRect();

  const relativeX = (position.x - rect.left) / rect.width;
  const relativeY = position.y - rect.top + element?.scrollTop;
  return {
    position: { xPercent: relativeX, yPixel: relativeY },
    sender: {
      width: rect.width,
      height: rect.height,
    },
  };
}

export const UserOverlay = forwardRef(({ guideId, pageId }, ref) => {
  const toolkit = useToolKit();

  const hasSingleSelection = ToolKit.hasSingleSelection(toolkit);
  const selection = ToolKit.getSelection(toolkit);
  const selectedComment = ToolKit.getComment(toolkit);

  const [users, setUsers] = useState({});

  const onMessage = (message) => {
    switch (message.event.action) {
      case 'user_mouse_position': {
        const userId = message?.client?.user?.ID;
        setUsers((users) => ({
          ...users,
          [userId]: {
            user: message?.client?.user,
            position: mapCursorPosition(ref.current, message?.data),
          },
        }));
        break;
      }
      case 'user_select_block': {
        ToolKit.setUser(toolkit, message?.client?.user, message?.data?.id);
        break;
      }
      case 'unsubscribe':
      case 'user_deselect_block': {
        ToolKit.removeUser(toolkit, message?.client?.user, message?.data?.id);
        break;
      }
      default:
        break;
    }
  };

  const subscription = useSubscription({
    type: 'guide-page-users',
    keys: [guideId, pageId],
    onMessage,
  });

  useEffect(() => {
    if (selectedComment?.blockId || (selection?.id && hasSingleSelection)) {
      const id = selectedComment?.blockId || selection?.id;
      subscription.send('user_select_block', { id });
    } else {
      subscription.send('user_deselect_block');
    }
    return () => {
      subscription.send('unsubscribe');
    };
  }, [hasSingleSelection, selection?.id, selectedComment?.blockId]);

  const send = useDebounce((...args) => subscription.send(...args), 10);

  const handleMouseMove = (event) => {
    const data = {
      ...getCursorPosition(ref.current, { x: event.clientX, y: event.clientY }),
    };
    send('user_mouse_position', data);
  };

  useEvent('mousemove', handleMouseMove);

  const getDisplayLabel = (user) => user?.FirstName || user?.LastName || user?.Email;

  return (
    <Box
      css={`
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 1000;
        pointer-events: none;
        overflow: clip;
      `}
    >
      {Object.values(users || {})?.map((item) => (
        <Box
          css={`
            position: absolute;
            top: ${item?.position?.y}px;
            left: ${item?.position?.x}%;
            z-index: 1000;
          `}
        >
          <Icon
            SVG={Cursor}
            size={20}
            weight="bold"
            color={item?.user?.Preferences?.Color || colors.purple}
            fill={(item?.user?.Preferences?.Color || colors.purple) + '44'}
          />
          <Box
            css={`
              position: absolute;
              top: 16px;
              left: 16px;
              background-color: ${item?.user?.Preferences?.Color || colors.purple};
              padding: 4px 8px;
              border-radius: 16px;
              max-width: 200px;
            `}
            key={item?.user?.ID}
          >
            <Text
              css={`
                color: white;
                font-weight: bold;
              `}
              ellipsis
              helper
            >
              {getDisplayLabel(item?.user)}
            </Text>
          </Box>
        </Box>
      ))}
    </Box>
  );
});
