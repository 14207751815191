import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { useDeepCompareMemo } from 'use-deep-compare';

export const useSync = (valueToSync, deps = []) => {
  const [value, setValue] = useState(valueToSync);

  const isSynced = isEqual(value, valueToSync);

  const compareAt = useDeepCompareMemo(() => {
    return Date.now();
  }, [valueToSync]);

  useEffect(() => {
    setValue(valueToSync);
  }, [compareAt, ...deps]);

  const resync = () => setValue(valueToSync);
  const set = (stateSetter) => setValue(stateSetter);
  const merge = (updates) => setValue({ ...value, ...updates });

  return { value, set, merge, resync, isSynced };
};
