import React, { useEffect, useMemo, useState } from 'react';
import { CircularProgress, TextField, Typography } from '@mui/material';
import { css } from '@emotion/css';
import { toast } from 'react-toastify';
import { history } from '../history';
import { useStore } from '../store-provider/use-store';
import { Autocomplete } from '@mui/lab';
import { Box, Button, Dropdown, Input, Modal } from '../shared/components';
import { useInviteUser } from './useInviteUser';
import { memberRoleMapping } from '../members/business-members';

const emptyState = { Name: 'Select Company', ID: '' };
const initialData = { email: '', roleId: 'viewer' };

export const AddMember = ({ display, onClose, defaultBusiness = emptyState, hideBusinessSelection = false }) => {
  const {
    data: { companies, acls },
  } = useStore();

  const [data, setData] = useState(initialData);
  const [error, setError] = useState('');
  const [business, setBusiness] = useState(defaultBusiness);

  useEffect(() => {
    if (defaultBusiness.ID) {
      setBusiness(defaultBusiness);
    }
  }, [defaultBusiness.ID]);

  const resourceIds = Object.keys(acls);

  const accessibleCompanies = useMemo(() => {
    const list = companies.filter(({ ID, ParentID }) => {
      const id = ID in resourceIds ? ID : ParentID;
      if (id in acls) {
        return acls[id].Acls.includes('write:business_access');
      }
      return false;
    });
    return list;
  }, [resourceIds.length]);

  useEffect(() => {
    setError('');
  }, [data?.email]);

  const handleInviteSuccess = (userId) => {
    setData(initialData);
    toast.success(`Successfully granted access.`);
    if (!hideBusinessSelection) {
      history.push(`/members/${userId}`);
    } else {
      onClose();
    }
  };

  const { sendInvite, isLoading } = useInviteUser({
    onSuccess: handleInviteSuccess,
    onError: (err) => {
      if (err === 'invalid-email') {
        setError('Invalid email');
      } else {
        toast.error(`Failed to add user access.`);
      }
    },
  });

  const handleInvite = () => sendInvite(business.ID, data?.email, data?.roleId);

  if (!display) {
    return null;
  }

  return (
    <Modal onClose={onClose} display>
      <Modal.Paper>
        <Modal.Header title="Add Member" onClose={onClose} />
        <Modal.Body>
          {!hideBusinessSelection && (
            <div
              className={css`
                margin: 16px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 540px;
              `}
            >
              <Typography>Select Company</Typography>
              <Autocomplete
                style={{ width: '350px' }}
                disableClearable
                id="company_select"
                noOptionsText={'Not Found.'}
                options={accessibleCompanies}
                getOptionLabel={(option) => option.Name}
                onChange={(_, value) => setBusiness(value)}
                value={business}
                renderInput={(params) => <TextField {...params} variant="outlined" />}
              />
            </div>
          )}

          <Input
            css={`
              flex-grow: 1;
            `}
            placeholder="Email"
            value={data?.email}
            onChange={(e) => setData({ ...data, email: e.target.value })}
            endIcon={
              <Dropdown
                button={(props) => (
                  <Dropdown.Select
                    css={`
                      border: none;
                      border-radius: 0;
                      padding: 0;
                    `}
                    {...props}
                    label={memberRoleMapping?.[data?.roleId]?.display}
                  />
                )}
              >
                {Object.entries(memberRoleMapping)?.map(([key, role]) => (
                  <Box option onClick={() => setData({ ...data, roleId: key })}>
                    {role?.display}
                  </Box>
                ))}
              </Dropdown>
            }
          />
        </Modal.Body>
        <Modal.Actions>
          <Button secondary onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleInvite} disabled={!!(!data?.email || isLoading || !business.ID)}>
            {!isLoading ? 'Add' : <CircularProgress />}
          </Button>
        </Modal.Actions>
      </Modal.Paper>
    </Modal>
  );
};
