import { Icon, Input, Text } from '../../../shared/components';

import { Property } from './property';
import { ReactComponent as LineWeight } from '../../../images/svg/line-weight.svg';
import { colors } from '../../../shared/styles';

export const StrokeWeightSetting = ({ value, setValue }) => {
  return (
    <Property>
      <Text label>Stroke Weight</Text>
      <Input
        startIcon={<Icon SVG={LineWeight} color={colors.black} />}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    </Property>
  );
};
