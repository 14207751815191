import { Content } from '../Content';
import { attachAction, getAction, getParentAction, removeEmptyActions, updateAction } from './action-utils';
import { addBlockToAdjacentBlock, createBlockAction } from './block-utils';
import { getChildFromAction } from './child-utils';
import { addBlockToAdjacentColumn, createColumnAction } from './column-utils';
import { addBlockToAdjacentRow, createRowAction } from './row-utils';
import { addBlockToAdjacentSection } from './section-utils';

export const compositionTypes = ['section', 'row', 'column'];

export const getComposition = (content) => {
  const composition = {};
  composition._content = content;
  return composition;
};

export const compileCompositionActions = (composition) => {
  const { _content: _A, ...rest } = composition;

  const list = Object.values(rest);

  for (const item of list) {
    if (
      item?.type !== 'remove' &&
      item?.data?.type === 'row' &&
      item?.old?.children?.length !== item?.data?.children?.length
    ) {
      for (const child of item?.data?.children) {
        const action = getAction(composition, child.id);
        if (action?.type !== 'remove') {
          attachAction(composition, action);
          updateAction(composition, action.id, 'update', { properties: {} });
        }
      }
    }
  }

  const { _content: content, ...actions } = composition;

  return Object.values(actions)
    .reduce((prev, action) => {
      const { type, data, ...rest } = action;
      let additional = {};
      if (type === 'remove' && data?.type === 'section') {
        const at = content?.sections?.findIndex((section) => section?.id === data?.id);
        additional = { at };
      }
      if (!!type) {
        return [...prev, { type, ...rest, ...additional }];
      }
      return prev;
    }, [])
    .sort((a, b) => b.createdAt - a.createdAt);
};

export const addToComposition = (props) => {
  if (!compositionTypes.includes(props.destination.data.type)) {
    addBlockToAdjacentBlock(props);
  } else if (props.destination.data.type === 'section') {
    addBlockToAdjacentSection(props);
  } else if (props.destination.data.type === 'row') {
    addBlockToAdjacentRow(props);
  } else if (props.destination.data.type === 'column') {
    addBlockToAdjacentColumn(props);
  }
};

export const removeFromComposition = (composition, action) => {
  const parent = getParentAction(composition, action?.id);
  if (parent) {
    const children = parent?.data?.children?.filter((child) => child.id !== action.id) || [];
    updateAction(composition, parent.id, 'update', { children });
  }
};

export const copySectionToComposition = ({ composition, contentToCopy, sectionFromId, sectionToId }) => {
  const getCopiedChildren = (parentId, id) => {
    let children = [];
    const current = Content.get(contentToCopy, id);
    for (const child of current?.children) {
      const block = Content.get(contentToCopy, child?.id);
      let action;
      if (child?.type === 'row') {
        action = createRowAction(parentId);
        attachAction(composition, action);
        const children = getCopiedChildren(action?.id, child?.id);
        updateAction(composition, action?.id, 'update', { children });
      } else if (child?.type === 'column') {
        action = createColumnAction(parentId);
        attachAction(composition, action);
        const children = getCopiedChildren(action?.id, child?.id);
        updateAction(composition, action?.id, 'update', { children });
      } else {
        action = createBlockAction({ type: child?.type });
        attachAction(composition, action);
      }

      updateAction(composition, action?.id, 'update', { parentId, properties: { ...block?.properties } });
      const childAction = getChildFromAction(action);
      children.push(childAction);
    }

    return children;
  };

  const sectionAction = getAction(composition, sectionToId);
  attachAction(composition, sectionAction);

  const sectionToCopy = Content.get(contentToCopy, sectionFromId);
  const children = getCopiedChildren(sectionAction.id, sectionFromId);
  updateAction(composition, sectionAction?.id, 'update', { children, properties: sectionToCopy?.properties });
};

export const copyIntoSingleSection = ({ content, contentToCopy, sectionFromId, sectionToId }) => {
  const composition = getComposition(content);
  copySectionToComposition({
    composition,
    contentToCopy,
    sectionToId,
    sectionFromId,
  });

  removeEmptyActions(composition);
  return compileCompositionActions(composition);
};
