import { useMemo, useRef } from 'react';
import { useEditor } from './utility/useEditor';
import { Cursor } from './utility/Cursor';
import { useToolKit } from './providers/tool-kit/use-tool-kit';
import { ToolKit } from './providers/tool-kit/ToolKit';
import { Conditional } from './utility/Conditional';
import { PropertyManager } from './providers/property-manager/PropertyManager';
import { SectionsMenu } from './utility/SectionsMenu';
import { useEditorResource } from '../BriteEditor/use-editor-resource';
import { bodyStyles, editorContainer } from './utility/styles';
import { Box, BriteLoader } from '../shared/components';
import { FontLinks } from '../BriteEditor/utility-components';
import { Segment } from './providers/segment/Segment';
import { Section } from './composition/Section';
import { BottomDrag } from './utility/BottomDrag';
import { MultiSelection } from './utility/MultiSelection';
import { ContextMenu } from './utility/ContextMenu';
import { ViewOptions } from './utility/ViewOptions';
import { Sidebar } from './sidebar/Sidebar';
import { ViewBlock } from './utility/ViewBlock';
import { UserOverlay } from './utility/UserOverlay';
import { useFeatureFlagPayload } from 'posthog-js/react';
import { DesignStylesModalV2 } from '../Content/Builder-v2/modals/design-styles-modal';
import { useSearchParams } from '../shared/use-search-params';
import { SaveSectionModal } from './sidebar/Properties/SaveSectionModal';
import { ReadOnlyOverlay } from './utility/ReadOnlyOverlay';
import { AutoScroll } from './utility/AutoScroll';

export const Editor = (props) => {
  const scrollRef = useRef();
  const bodyRef = useRef();
  const editor = useEditor(props?.course, props?.query);
  const { data: designStyles } = useEditorResource('styles');

  const liveUserFF = useFeatureFlagPayload('live-user');

  const { params, removeParam } = useSearchParams();

  const toolkit = useToolKit();
  const { guide, drag, modal } = useMemo(
    () => ({
      modal: ToolKit.getModal(toolkit),
      hasMultiSelection: ToolKit.hasMultiSelection(toolkit),
      drag: ToolKit.getDrag(toolkit),
      guide: ToolKit.getMetadata(toolkit, 'guide'),
    }),
    [toolkit]
  );

  return (
    <>
      <PropertyManager bodyElement={bodyRef.current}>
        <Box flex="left start" css={editorContainer(designStyles)}>
          <FontLinks fontURLs={designStyles?.general?.fontURLs} />
          <Box css={bodyStyles} flex="column start" style={editor?.content?.root?.containerStyle || {}} ref={scrollRef}>
            <Conditional if={drag?.isDragging}>
              <AutoScroll scrollRef={scrollRef} />
            </Conditional>
            {editor?.isLoading ? (
              <BriteLoader />
            ) : (
              <Box
                css={`
                  position: relative;
                  width: 100%;
                  overflow-y: visible;
                  overflow-x: clip;
                  ${toolkit?.readOnly ? '* { pointer-events: none; }' : ''}
                `}
                ref={bodyRef}
              >
                {editor?.content?.sections?.map((section) => (
                  <Segment key={section?.id} scope="editor" element={bodyRef} type="section" id={section?.id}>
                    <Section section={section} root={editor?.content?.root} />
                  </Segment>
                ))}
                {liveUserFF?.value ? (
                  <UserOverlay ref={bodyRef} guideId={props?.course?.ID} pageId={props?.query?.data?.ID} />
                ) : null}
                <Conditional if={toolkit.readOnly}>
                  <ReadOnlyOverlay />
                </Conditional>
              </Box>
            )}
            <Conditional if={!toolkit.readOnly}>
              <BottomDrag bodyRef={bodyRef} isLoading={editor?.isLoading} />
            </Conditional>
            <ContextMenu />
            <MultiSelection />
          </Box>
          <ViewOptions />
          <Sidebar />
        </Box>

        <Conditional if={modal === 'sections-menu'}>
          <SectionsMenu onClose={() => ToolKit.resetModal(toolkit)} />
        </Conditional>

        <Conditional if={modal === 'view-selection'}>
          <ViewBlock onClose={() => ToolKit.resetModal(toolkit)} />
        </Conditional>

        <Conditional if={modal === 'save-section'}>
          <SaveSectionModal onClose={() => ToolKit.resetModal(toolkit)} />
        </Conditional>
      </PropertyManager>

      <Conditional if={drag?.isDragging}>
        <Cursor drag={drag} />
      </Conditional>

      <Conditional if={params?.courseModal === 'design-styles'}>
        <DesignStylesModalV2
          display={true}
          onClose={() => removeParam('courseModal')}
          course={guide?.data}
          saveCourse={guide?.save}
          refetch={guide?.refetch}
        />
      </Conditional>
    </>
  );
};
