import { formatPhoneNumber } from '../../../shared/components/input';
import { safelySpread } from '../../providers/content/content-modify';

export const getNumber = (value) => {
  try {
    return parseInt(value.replace(/\D/g, ''), 10) || 0;
  } catch {
    return 0;
  }
};

const getStyleReference = (data, type) => {
  switch (type) {
    case 'block': {
      return data?.properties?.attributes?.style || {};
    }
    case 'container': {
      return data?.properties?.container?.attributes?.style || {};
    }
    case 'section': {
      return data?.properties?.style || {};
    }
    default: {
      return data?.properties?.container?.attributes?.style || {};
    }
  }
};

const updateAttributes = (data, type = 'container', updates) => {
  switch (type) {
    case 'container': {
      return {
        ...safelySpread(data),
        properties: {
          ...safelySpread(data?.properties),
          container: {
            ...safelySpread(data?.properties?.container),
            attributes: {
              ...safelySpread(data?.properties?.container?.attributes),
              ...updates,
            },
          },
        },
      };
    }
    case 'block': {
      return {
        ...safelySpread(data),
        properties: {
          ...safelySpread(data?.properties),
          attributes: {
            ...safelySpread(data?.properties?.attributes),
            ...updates,
          },
        },
      };
    }
    case 'all': {
      return {
        ...safelySpread(data),
        properties: {
          ...safelySpread(data?.properties),
          attributes: {
            ...safelySpread(data?.properties?.attributes),
            ...updates,
          },
          container: {
            ...safelySpread(data?.properties?.container),
            attributes: {
              ...safelySpread(data?.properties?.container?.attributes),
              ...updates,
            },
          },
        },
      };
    }
    default: {
      return {};
    }
  }
};

const updateStyle = (data, type = 'container', updates) => {
  switch (type) {
    case 'container': {
      return updateAttributes(data, type, {
        style: {
          ...safelySpread(data?.properties?.container?.attributes?.style),
          ...updates,
        },
      });
    }
    case 'block': {
      return updateAttributes(data, type, {
        style: {
          ...safelySpread(data?.properties?.attributes?.style),
          ...updates,
        },
      });
    }
    case 'all': {
      return {
        ...updateAttributes(data, 'container', {
          style: {
            ...safelySpread(data?.properties?.container?.attributes?.style),
            ...updates,
          },
        }),
        ...updateAttributes(data, 'block', {
          style: {
            ...safelySpread(data?.properties?.attributes?.style),
            ...updates,
          },
        }),
      };
    }
    case 'section': {
      return {
        ...safelySpread(data),
        properties: {
          ...safelySpread(data?.properties),
          style: {
            ...safelySpread(data?.properties?.style),
            ...updates,
          },
        },
      };
    }
    default: {
      return {};
    }
  }
};

export const getWidthProperties = ({ data, update, designStyles, type = 'block' }) => {
  const rawWidth = data?.properties?.attributes?.style?.width || designStyles?.image?.width;
  const width = Number(rawWidth.replace(/\D/g, '') || 0);

  return {
    update,
    value: width,
    setValue: (value) => update((data) => updateStyle(data, type, { width: `${value}%` })),
  };
};

export const getAlignProperties = ({ data, update, alignKey, type = 'container' }) => {
  const defaultValue = alignKey === 'justifyContent' ? 'left' : 'start';
  const align = data?.properties?.container?.attributes?.style?.[alignKey] || defaultValue;
  return {
    update,
    value: align,
    setValue: (value) =>
      update((data) => {
        return updateStyle(data, type, {
          display: 'flex',
          [alignKey]: value,
        });
      }),
  };
};

export const getActionProperties = ({ data, update }) => {
  let description = '';
  const action = data?.properties?.action || {};
  if (action.type === 'open-website') {
    description = action?.data?.url || '';
  } else if (action.type === 'open-email') {
    description = action?.data?.sendToEmail;
  } else if (action.type === 'call-phone') {
    description = action?.data?.sendToNumber
      ? formatPhoneNumber(action?.data?.sendToNumber)
      : action?.data?.sendToNumber;
  } else if (action.type === 'open-sms') {
    description = action?.data?.sendToNumber
      ? formatPhoneNumber(action?.data?.sendToNumber)
      : action?.data?.sendToNumber;
  } else if (action.type === 'route-to-page') {
    description = 'Route To Page';
  }

  return {
    description,
    update,
    value: {
      link: data?.properties?.link || null,
      action,
    },
    setValue: (value) =>
      update((data) => ({
        ...safelySpread(data),
        properties: {
          ...safelySpread(data?.properties),
          action: value?.action,
          link: value?.link,
        },
      })),
  };
};

export const getPaddingProperties = ({ data, update, type = 'container' }) => {
  const style = getStyleReference(data, type);
  const padding = {
    paddingTop: style?.paddingTop || '0px',
    paddingBottom: style?.paddingBottom || '0px',
    paddingLeft: style?.paddingLeft || '0px',
    paddingRight: style?.paddingRight || '0px',
  };
  return {
    update,
    value: padding,
    setValue: (key, value) =>
      update((data) => {
        let padding = {};
        if (key === 'all') {
          padding = {
            paddingLeft: value,
            paddingRight: value,
            paddingTop: value,
            paddingBottom: value,
          };
        } else {
          padding[key] = value;
        }
        return updateStyle(data, type, padding);
      }),
  };
};

export const getRadiusProperties = ({ data, update, type }) => {
  const styleReference = getStyleReference(data, type);

  const radius = {
    borderTopLeftRadius: styleReference?.borderTopLeftRadius || '0px',
    borderTopRightRadius: styleReference?.borderTopRightRadius || '0px',
    borderBottomLeftRadius: styleReference?.borderBottomLeftRadius || '0px',
    borderBottomRightRadius: styleReference?.borderBottomRightRadius || '0px',
  };

  return {
    update,
    value: radius,
    setValue: (key, value) =>
      update((data) => {
        let radius = {};
        if (key === 'all') {
          radius = {
            borderTopLeftRadius: value,
            borderTopRightRadius: value,
            borderBottomLeftRadius: value,
            borderBottomRightRadius: value,
          };
        } else {
          radius[key] = value;
        }

        return updateStyle(data, type, radius);
      }),
  };
};

export const getTypographyProperties = ({ data, update }) => {
  return {
    update,
    value: data?.properties?.type,
    setValue: (value) =>
      update((data) => ({
        ...safelySpread(data),
        properties: {
          ...safelySpread(data?.properties),
          type: value,
        },
      })),
  };
};

export const getBgColorProperties = ({ data, update, type = 'container' }) => {
  const styleReference = getStyleReference(data, type);
  return {
    update,
    value: styleReference?.backgroundColor || 'rgba(0, 0, 0, 0);',
    setValue: (value) => update((data) => updateStyle(data, type, { backgroundColor: value })),
  };
};

export const getSectionFullScreenProperties = ({ data, update }) => {
  return {
    update,
    value: data?.properties?.fullScreenWidth || false,
    setValue: (value) =>
      update((data) => ({
        ...safelySpread(data),
        properties: {
          ...safelySpread(data?.properties),
          fullScreenWidth: value,
        },
      })),
  };
};

export const getHideOnMobile = ({ data, update }) => {
  return {
    update,
    value: data?.properties?.hideOnMobile || false,
    setValue: (value) =>
      update((data) => ({
        ...safelySpread(data),
        properties: { ...safelySpread(data?.properties), hideOnMobile: value },
      })),
  };
};

export const getHideOnDesktop = ({ data, update }) => {
  return {
    update,
    value: data?.properties?.hideOnDesktop || false,
    setValue: (value) =>
      update((data) => ({
        ...safelySpread(data),
        properties: { ...safelySpread(data?.properties), hideOnDesktop: value },
      })),
  };
};

export const getHideOnExport = ({ data, update }) => {
  return {
    update,
    value: data?.properties?.hideOnExport || false,
    setValue: (value) =>
      update((data) => ({
        ...safelySpread(data),
        properties: { ...safelySpread(data?.properties), hideOnExport: value },
      })),
  };
};

export const getSectionHasBackgroundProperties = ({ data, update }) => {
  return {
    update,
    value: data?.properties?.hasBackground || false,
    setValue: (value) =>
      update((data) => {
        const background = value
          ? { backgroundColor: 'var(--accent-color-0)' }
          : { backgroundColor: 'rgba(0, 0, 0, 0)' };
        const padding = {};
        if (value && (!data?.properties?.style?.paddingTop || data?.properties?.style?.paddingTop === '0px')) {
          padding.paddingTop = '24px';
          padding.paddingBottom = '24px';
          padding.paddingRight = '24px';
          padding.paddingLeft = '24px';
        }
        return {
          ...safelySpread(data),
          properties: {
            ...safelySpread(data?.properties),
            hasBackground: value,
            style: {
              ...safelySpread(data?.properties?.style),
              ...background,
              ...padding,
            },
          },
        };
      }),
  };
};

export const getAddLineProperties = ({ data, update }) => {
  return {
    update,
    value: data?.type === 'line',
    setValue: (value) =>
      update((data) => {
        const type = value ? 'line' : 'space';
        const minHeightNumber = getNumber(data?.properties?.attributes?.style?.minHeight);
        const minHeight = Math.max(minHeightNumber, 48);

        const styleUpdates =
          type === 'space'
            ? updateStyle(data, 'block', {
                borderTopWidth: '0px',
                borderTopStyle: 'none',
                borderTopColor: 'rgba(0, 0, 0, 0)',
                minHeight: '48px',
              })
            : updateStyle(data, 'block', {
                borderTopWidth: '2px',
                borderTopStyle: 'solid',
                borderTopColor: 'var(--accent-color-0)',
                minHeight: `${minHeight}px`,
              });

        return {
          ...safelySpread(data),
          ...styleUpdates,
          type,
        };
      }),
  };
};

export const getLineColorProperties = ({ data, update }) => {
  return {
    update,
    value: data?.properties?.attributes?.style?.borderTopColor || '',
    setValue: (value) => update((data) => updateStyle(data, 'block', { borderTopColor: value })),
  };
};

export const getLineWeightProperties = ({ data, update }) => {
  const weight = getNumber(data?.properties?.attributes?.style?.borderTopWidth || '0px');
  return {
    update,
    value: weight,
    setValue: (value) => {
      const numberValue = getNumber(value);
      const borderTopWidth = `${numberValue}px`;
      return update((data) => {
        const minHeightNumber = getNumber(data?.properties?.attributes?.style?.minHeight);
        const minHeight = Math.max(minHeightNumber, numberValue);
        return updateStyle(data, 'block', { borderTopWidth, minHeight: `${minHeight}px` });
      });
    },
  };
};

export const getLineStyleProperties = ({ data, update }) => {
  return {
    update,
    value: data?.properties?.attributes?.style?.borderTopStyle || 'solid',
    setValue: (value) => update((data) => updateStyle(data, 'block', { borderTopStyle: value })),
  };
};

export const getPageBreakProperties = ({ data, update }) => {
  return {
    update,
    value: data?.properties?.container?.attributes?.style?.breakAfter === 'page',
    setValue: (value) => update((data) => updateStyle(data, 'container', { breakAfter: value ? 'page' : 'avoid' })),
  };
};

export const getStrokeWeightProperties = ({ data, update, type = 'container' }) => {
  const style = getStyleReference(data, type);
  const styles = [
    style.borderTopWidth || '0px',
    style.borderRightWidth || '0px',
    style.borderBottomWidth || '0px',
    style.borderLeftWidth || '0px',
  ];
  const current = styles.find((style) => style !== '0px') || '0px';
  const weight = getNumber(current);
  return {
    update,
    value: weight,
    setValue: (value) => {
      const numberValue = getNumber(value);
      return update((data) =>
        updateStyle(data, type, {
          borderTopWidth: `${numberValue}px`,
          borderRightWidth: `${numberValue}px`,
          borderBottomWidth: `${numberValue}px`,
          borderLeftWidth: `${numberValue}px`,
        })
      );
    },
  };
};

export const getHasStrokeProperties = ({ data, update, type = 'container' }) => {
  const style = getStyleReference(data, type);
  const styles = [
    style.borderTopWidth || '0px',
    style.borderRightWidth || '0px',
    style.borderBottomWidth || '0px',
    style.borderLeftWidth || '0px',
  ];
  const current = styles.find((style) => style !== '0px') || '0px';
  const widthNumber = getNumber(current || '0px');
  return {
    update,
    value: widthNumber > 0,
    setValue: (value) =>
      update((data) =>
        updateStyle(data, type, {
          margin: '0px',
          borderTopWidth: value ? '1px' : '0px',
          borderRightWidth: value ? '1px' : '0px',
          borderBottomWidth: value ? '1px' : '0px',
          borderLeftWidth: value ? '1px' : '0px',
          borderColor: value ? 'var(--accent-color-0)' : 'transparent',
          borderTopStyle: value ? 'solid' : 'none',
          borderRightStyle: value ? 'solid' : 'none',
          borderBottomStyle: value ? 'solid' : 'none',
          borderLeftStyle: value ? 'solid' : 'none',
        })
      ),
  };
};

export const getStrokeColorProperties = ({ data, update, type = 'container' }) => {
  const style = getStyleReference(data, type);
  const styles = [
    style.borderTopColor || 'rgba(0, 0, 0, 0)',
    style.borderRightColor || 'rgba(0, 0, 0, 0)',
    style.borderBottomColor || 'rgba(0, 0, 0, 0)',
    style.borderLeftColor || 'rgba(0, 0, 0, 0)',
  ];
  const current = styles.find((style) => style !== 'rgba(0, 0, 0, 0)') || 'var(--accent-color-0)';
  return {
    update,
    value: current,
    setValue: (value) =>
      update((data) =>
        updateStyle(data, type, {
          borderTopColor: value,
          borderBottomColor: value,
          borderLeftColor: value,
          borderRightColor: value,
        })
      ),
  };
};

export const getStrokeStyleProperties = ({ data, update, type = 'container' }) => {
  const style = getStyleReference(data, type);

  const styles = [
    style.borderTopStyle || 'solid',
    style.borderRightStyle || 'solid',
    style.borderBottomStyle || 'solid',
    style.borderLeftStyle || 'solid',
  ];
  const value = styles.find((style) => style !== 'none') || 'solid';
  return {
    update,
    value,
    setValue: (value) =>
      update((data) => {
        const style = getStyleReference(data, type);
        return updateStyle(data, type, {
          borderTopStyle: style?.borderTopStyle === 'none' ? 'none' : value,
          borderRightStyle: style?.borderRightStyle === 'none' ? 'none' : value,
          borderBottomStyle: style?.borderBottomStyle === 'none' ? 'none' : value,
          borderLeftStyle: style?.borderLeftStyle === 'none' ? 'none' : value,
        });
      }),
  };
};

export const getHideStrokeProperties = ({ data, update, type = 'container' }) => {
  const style = getStyleReference(data, type);
  return {
    update,
    value: {
      borderTopStyle: style?.borderTopStyle !== 'none',
      borderRightStyle: style?.borderRightStyle !== 'none',
      borderBottomStyle: style?.borderBottomStyle !== 'none',
      borderLeftStyle: style?.borderLeftStyle !== 'none',
    },
    setValue: (value) =>
      update((data) => {
        const style = getStyleReference(data, type);
        const hasValue = style?.[value] === 'none';
        const styles = [style.borderTopStyle, style.borderRightStyle, style.borderBottomStyle, style.borderLeftStyle];
        const currentStyle = styles.find((style) => style !== 'none') || 'solid';
        return updateStyle(data, type, { [value]: hasValue ? currentStyle || 'solid' : 'none' });
      }),
  };
};

export const getStrokePaddingProperties = ({ data, update }) => {
  const style = getStyleReference(data, 'container');
  return {
    update,
    value: style?.margin === '8px',
    setValue: (value) => update((data) => updateStyle(data, 'container', { margin: value ? '8px' : '0px' })),
  };
};
