import { GuideTrafficChart } from './GuideTrafficChart';
import { TrafficByLocationChart } from './TrafficByLocationChart';
import { TrafficSnapshots } from './TrafficSnapshots';
import { TrafficSourcesChart } from './TrafficSourcesChart';
import { VisitorsByDeviceChart } from './VisitorsByDeviceChart';
import { VisitorsBySourceChart } from './VisitorsBySourceChart';

export const TrafficTab = () => {
  return (
    <>
      <TrafficSnapshots />
      <GuideTrafficChart />
      <TrafficSourcesChart />
      <VisitorsBySourceChart />
      <VisitorsByDeviceChart />
      <TrafficByLocationChart />
    </>
  );
};
