import { Crop, LinkSimple, SquaresFour, UploadSimple } from '@phosphor-icons/react';
import { Box, Button, Dropdown, Text } from '../../../shared/components';
import { useContent } from '../../providers/content/use-content';
import { UploadModal } from '../../utility/UploadModal';
import { ImagesModal } from '../../../BriteEditor/toolbar-menus';
import { Modify, safelySpread } from '../../providers/content/content-modify';
import { getLogoStyle } from './image-helpers';
import { SourceModal } from './SourceModal';
import { ImageCropper } from '../../utility/ImageCropper';
import { useModalWithData } from '../../../common/hooks/useModalWithData';
import { Conditional } from '../../utility/Conditional';

export const ImageToolbar = ({ id, sync, image }) => {
  const { sendUpdates } = useContent();

  const modal = useModalWithData(false, '');

  const updateSource = (sync) => {
    Modify.properties(sendUpdates, id, (properties) => {
      const { smartFieldDisplay = '', ...rest } = sync.value;
      const smartField = smartFieldDisplay ? { smartFieldDisplay } : {};
      return {
        ...smartField,
        attributes: {
          ...safelySpread(properties.attributes),
          alt: rest?.alt,
          src: rest?.src,
        },
      };
    });
  };

  const updateImage = (type, attributes) => {
    if (type === 'logos') {
      const style = getLogoStyle();
      Modify.properties(sendUpdates, id, (properties) => ({
        attributes: {
          ...safelySpread(properties.attributes),
          ...attributes,
          style,
        },
        container: {
          ...safelySpread(properties.container),
          attributes: {
            ...safelySpread(properties.container.attributes),
            style,
          },
        },
      }));
    } else {
      Modify.properties(sendUpdates, id, (properties) => ({
        attributes: {
          ...safelySpread(properties.attributes),
          ...attributes,
        },
      }));
    }
  };

  const uploadImage = (document) => {
    updateImage('images', {
      src: document?.PublicURL || document?.LogoURL,
      alt: document?.Name || 'image',
      'data-img-id': document?.ID,
    });
  };

  const updateCrop = (crop) => {
    Modify.properties(sendUpdates, id, () => ({ crop }));
  };

  return (
    <>
      <Box
        css={`
          gap: 8px;
        `}
        flex="left"
      >
        <Dropdown clear button={() => <Button editor>Add Image</Button>}>
          <Box data-scope="menu">
            <Box option onClick={() => modal.show('upload')}>
              <UploadSimple size={24} />
              <Text label>Upload</Text>
            </Box>
            <Box option onClick={() => modal.show('library')}>
              <SquaresFour size={24} />
              <Text label>Library</Text>
            </Box>
            <Box option onClick={() => modal.show('source')}>
              <LinkSimple size={24} />
              <Text label>Source</Text>
            </Box>
          </Box>
        </Dropdown>
        <Button styles="icon-dark sm" hoverLabel="Crop" onClick={() => modal.show('crop')}>
          <Crop size={24} color="currentColor" />
        </Button>
      </Box>

      <Conditional if={modal.isOpen && modal.data === 'upload'}>
        <UploadModal
          title="Upload Image"
          onClose={modal.hide}
          update={uploadImage}
          dropzoneProps={{ accept: { 'image/*': [] } }}
        />
      </Conditional>

      <Conditional if={modal.isOpen && modal.data === 'library'}>
        <ImagesModal display={true} onClose={modal.hide} updateImage={updateImage} />
      </Conditional>

      <Conditional if={modal.isOpen && modal.data === 'crop'}>
        <ImageCropper onClose={modal.hide} img={image?.img} currentCrop={sync?.value?.crop} updateCrop={updateCrop} />
      </Conditional>

      <Conditional if={modal.isOpen && modal.data === 'source'}>
        <SourceModal onClose={modal.hide} sync={sync} updateSource={updateSource} />
      </Conditional>
    </>
  );
};
