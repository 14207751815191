import { modifyContent } from '../../provider/utils';
import { convertToHtml } from '../text-v2/slate-utils';
import { useBriteSlate } from './useBriteSlate';

export const useTitleSlate = ({ editor, element, location }, resources) => {
  const titleStyle = {
    ...resources?.styles?.text?.h4,
    color: element?.title?.attributes?.style?.color || resources?.styles?.text?.h4?.color || '',
  };

  const title = useBriteSlate({
    slate: element?.data?.title?.slate,
    content: element?.data?.title?.content,
    update: () => {
      const content = convertToHtml(title?.slateProps?.editor?.children);
      modifyContent.merge(editor, location, {
        data: {
          title: {
            slate: title?.slateProps?.editor?.children,
            content,
          },
        },
      });
    },
  });

  return {
    ...title,
    editorProps: {
      ...title.editorProps,
      style: titleStyle,
    },
  };
};
