import { MenuItem } from '@mui/material';
import { css } from '@emotion/css';
import { Plus, X } from '@phosphor-icons/react';
import { useEffect, useMemo, useState } from 'react';
import { extendedStates } from '../constants';
import { CustomAxios } from '../redux/axios/axios';
import { Box, Button, Input, Modal, Select, Text } from '../shared/components';
import { scrollbar } from '../shared/shared-styles';
import { colors } from '../shared/styles';
import { useStateSync } from '../shared/use-state-sync';
import { BriteLoader } from '../shared/components/brite-loader';
import { Collapse } from '../common/components/Collapse';
import { toast } from 'react-toastify';

export const ZipCodes = ({ product, onUpdate, ...modalProps }) => {
  const { ApplicableStates = [], ApplicableZipCodes = [] } = product;

  const [state, setState] = useState(ApplicableStates?.includes('ALL') ? 'ALL' : ApplicableStates[0]);
  const [counties, setCounties] = useState([]);
  const [zipCodeList, setZipCodeList] = useState([]);
  const [isLoadingCounties, setIsLoadingCounties] = useState(false);
  const [isLoadingZips, setIsLoadingZips] = useState(false);

  const [search, setSearch] = useState('');
  const [county, setCounty] = useState('');
  const [zipCodes, setZipCodes] = useStateSync(ApplicableZipCodes, [ApplicableZipCodes]);

  const searchableZips = useMemo(() => {
    if (!search) {
      return [];
    }
    return zipCodes.filter((key) => key.includes(search));
  }, [search]);

  const fetchZywave = async (type, url, list = []) => {
    if (type === 'counties') {
      setCounties([]);
      setIsLoadingCounties(true);
    } else if (type === 'zip-codes') {
      setZipCodeList([]);
      setIsLoadingZips(true);
    }
    try {
      const { data } = await CustomAxios.get(url);
      if (type === 'counties') {
        const nextList = data.Data.map((county) => county.County).filter(
          (value, index, array) => array.indexOf(value) === index
        );
        list = [...list, ...nextList];
      } else if (type === 'zip-codes') {
        const nextList = data.Data.map((county) => county.PostalCode);
        list = [...list, ...nextList];
      }
      if (data.Next) {
        await fetchZywave(type, data.Next, list);
      } else {
        if (type === 'counties') {
          const nextList = list.filter((value, index, array) => array.indexOf(value) === index);
          setCounties(nextList);
        } else if (type === 'zip-codes') {
          const nextList = list.filter((value, index, array) => array.indexOf(value) === index);
          setZipCodeList(nextList);
        }
      }
    } catch (err) {
      console.warn(err);
      toast.error(`There was an error loading area data. `);
    } finally {
      setIsLoadingCounties(false);
      setIsLoadingZips(false);
    }
  };

  useEffect(() => {
    if (state && state !== 'ALL') {
      fetchZywave('counties', `/v1/zywave/areas?state=${state}`);
    }
  }, [state]);

  useEffect(() => {
    if (state && state !== 'ALL' && !!county) {
      fetchZywave('zip-codes', `/v1/zywave/areas?state=${state}&county=${county}`);
    }
  }, [county]);

  const stateList = ApplicableStates.includes('ALL')
    ? extendedStates
    : extendedStates.filter(({ id }) => ApplicableStates.includes(id));

  const addZipCodes = () => {
    const zips = new Set([...zipCodes, ...zipCodeList]);
    setZipCodes([...zips]);
    setZipCodeList([]);
    setCounty('');
    setState('');
  };

  const removeZipCode = (zip) => {
    setZipCodes((zips) => zips.filter((item) => item !== zip));
  };

  const handleSave = () => {
    const value = [...new Set(zipCodes)];
    onUpdate('ApplicableZipCodes', value);
    modalProps.onClose();
  };

  const addZipManually = (e) => {
    e.preventDefault();
    if (search.length === 5) {
      setZipCodes([...zipCodes, search]);
      setSearch('');
    }
  };

  return (
    <Modal {...modalProps}>
      <Modal.Paper width="700px">
        <Modal.Header title="Add Zip Codes" onClose={modalProps.onClose} />
        <Modal.Body>
          <Text label bold>
            Enter Zip Code
          </Text>
          <Box
            css={`
              border-radius: 8px;
              border: 1px solid ${colors.gray[300]};
            `}
          >
            <form onSubmit={addZipManually}>
              <Input
                css={`
                  margin: 0 8px;
                  border: none;
                  :hover {
                    border: none;
                  }
                `}
                onChange={(e) => setSearch(e.target.value.replace(/\D/g, '').slice(0, 5))}
                value={search}
              />
            </form>
            <Collapse isOpen={zipCodes?.length}>
              <Box
                className={css`
                  border-bottom: 1px solid ${colors.gray[300]};
                  margin: 0 16px;
                `}
              />
              <Box
                flex="left start wrap"
                css={`
                  height: auto;
                  max-height: 200px;
                  gap: 8px;
                  overflow-y: auto;
                  ${scrollbar.style}
                  padding: 16px;
                `}
              >
                {zipCodes?.map((zip) => (
                  <Box
                    flex="space-between"
                    css={`
                      user-select: none;
                      border-radius: 16px;
                      padding: 4px 8px;
                      background-color: ${colors.gray[100]};
                      :hover {
                        background-color: ${colors.gray[200]};
                        cursor: pointer;
                      }
                      p {
                        margin-right: 8px;
                      }
                      svg {
                        color: ${colors.gray[400]};
                      }
                      ${searchableZips?.includes(zip)
                        ? `
                      outline: 2px solid ${colors.purple};  
                      p,svg {
                        color: ${colors.purple};
                        font-weight: bold;
                      }
                    `
                        : ''}
                    `}
                    onClick={() => removeZipCode(zip)}
                  >
                    <Text label>{zip}</Text>
                    <X size={16} weight="bold" color="currentColor" />
                  </Box>
                ))}
              </Box>
            </Collapse>
          </Box>
          <Box>
            <Text
              h4
              css={`
                margin: 16px 0;
              `}
            >
              Add Zip Codes by County
            </Text>
            <Box
              flex="space-between start"
              css={`
                gap: 16px;
              `}
            >
              <Box
                css={`
                  width: 50%;
                `}
              >
                <Text label bold>
                  1. Select a State
                </Text>
                <Select
                  css={`
                    width: 100%;
                  `}
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                >
                  {stateList.map((state) => (
                    <MenuItem key={state.id} value={state.id}>
                      {state.label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <Box
                css={`
                  width: 50%;
                `}
              >
                <Box flex="space-between">
                  <Text label={!!counties.length} bold>
                    2. Select a County
                  </Text>
                  <BriteLoader size={24} weight={12} isLoading={isLoadingCounties} overlay={false} />
                </Box>
                <Select value={county} onChange={(e) => setCounty(e.target.value)} disabled={!counties?.length}>
                  {counties?.map((county) => (
                    <MenuItem key={county} value={county}>
                      {county}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
            {isLoadingZips || (county && zipCodeList?.length) ? (
              <Box
                css={`
                  margin-top: 16px;
                  border-radius: 8px;
                  background-color: ${colors.gray[100]};
                  padding: 16px;
                `}
                flex="space-between"
              >
                <Box flex="left">
                  <Text label bold>
                    {county}
                  </Text>
                  <Text
                    label
                    css={`
                      margin-left: 16px;
                    `}
                  >
                    {zipCodeList?.length} Zip Codes
                  </Text>
                </Box>
                {isLoadingZips ? (
                  <BriteLoader size={24} weight={12} overlay={false} />
                ) : (
                  <Box flex="right">
                    <Button
                      purple
                      css={`
                        padding: 4px 8px;
                        margin-right: 8px;
                      `}
                      onClick={addZipCodes}
                    >
                      <Plus color="white" size={16} weight="bold" />
                      Add
                    </Button>
                  </Box>
                )}
              </Box>
            ) : null}
          </Box>
        </Modal.Body>
        <Modal.Actions>
          <Button secondary onClick={modalProps.onClose}>
            Cancel
          </Button>
          <Button onClick={handleSave}>Update</Button>
        </Modal.Actions>
      </Modal.Paper>
    </Modal>
  );
};
