import { useCallback, useMemo, useState } from 'react';
import { colors } from '../../shared/styles';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import { ToolKit } from '../providers/tool-kit/ToolKit';
import { overlay } from './styles';
import { useSegment } from '../providers/segment/use-segment';
import { shadows } from '../../shared/shared-styles';

const mapping = {
  top: 'bottom',
  bottom: 'top',
  right: 'left',
  left: 'right',
};

export const useDroppable = ({ key = '', disabled = false, hideBorder = false, activeRects = {} }) => {
  const toolkit = useToolKit();
  const segment = useSegment();
  const drag = ToolKit.getDrag(toolkit);

  const [edge, setEdge] = useState('');

  const onMouseMove = useCallback(
    (event) => {
      event.preventDefault();
      if (event.isHandled || disabled) {
        if (edge) {
          setEdge('');
        }
        return;
      }
      if (segment?.id !== drag?.source?.data?.id) {
        const { clientX, clientY } = event;
        const { left, top, width, height } = event.currentTarget.getBoundingClientRect();

        const mouseXPercent = ((clientX - left) / width) * 100;
        const mouseYPercent = ((clientY - top) / height) * 100;

        let drop = '';
        for (const entry of Object.entries(activeRects || {})) {
          const [key, [x1, y1, x2, y2]] = entry;
          const coorX = mouseXPercent;
          const coorY = mouseYPercent;
          if (coorX > x1 && coorX < x2 && coorY > y1 && coorY < y2) {
            drop = key;
            break;
          }
        }
        if (drop) event.isHandled = true;
        if (edge !== drop) setEdge(drop);
      } else if (edge !== '') {
        setEdge('');
      }
    },
    [edge, disabled]
  );

  const css = useMemo(() => {
    let base = `
      ${overlay}
      ${hideBorder ? `display: none;` : ''}
      ${mapping[edge]}: calc(100% - 5px);
      ${edge}: -5px;
      border-radius: 8px;
      pointer-events: none;
      ${edge ? `background: linear-gradient(45deg, ${colors.purple}, ${colors.purple}, ${colors.lightPurple});` : ''};
      opacity: .6;
      ${shadows.lg}
      z-index: 1000000;
    `;
    return base;
  }, [edge, disabled]);

  const onMouseUp = useCallback(
    (event) => {
      if (edge) {
        event.stopPropagation();
        ToolKit.drop(toolkit, { destinationId: segment.id, edge, key });
        setEdge('');
      }
    },
    [drag, edge, key]
  );

  const active = !disabled && drag?.isDragging;

  return {
    css,
    active,
    attributes: active
      ? {
          onMouseMove,
          onMouseUp,
          onMouseLeave: () => setEdge(''),
        }
      : {},
  };
};
