import { Slate } from 'slate-react';
import { usePropertyManager } from '../../providers/property-manager/PropertyManager';
import { SlateDecoration } from '../Properties/slate-properties/SlateDecoration';
import { SlateHorizontalAlign } from '../Properties/slate-properties/SlateHorizontalAlign';
import { SlateColorPicker } from '../Properties/slate-properties/SlateColorPicker';
import { SlateLink } from '../Properties/slate-properties/SlateLink';
import { Box } from '../../../shared/components';
import {
  getAlignProperties,
  getBgColorProperties,
  getPaddingProperties,
  getRadiusProperties,
  getTypographyProperties,
} from './property-utils';
import { ColorSetting } from '../Properties/ColorSetting';
import { RadiusSetting } from '../Properties/RadiusSetting';
import { PaddingSetting } from '../Properties/PaddingSetting';
import { SlateList } from '../Properties/slate-properties/SlateList';
import { SlateTypography } from '../Properties/slate-properties/SlateTypography';
import { AlignSetting } from '../Properties/AlignSetting';
import { StrokeSetting } from '../Properties/StrokeSetting';
import { PropertiesGrouping } from './PropertiesGrouping';

export const TextSettings = (props) => {
  const { properties } = usePropertyManager();

  const typography = getTypographyProperties(props);
  const bgColor = getBgColorProperties(props);
  const radius = getRadiusProperties({ ...props, type: 'container' });
  const padding = getPaddingProperties(props);
  const verticalAlign = getAlignProperties({ ...props, alignKey: 'alignItems' });

  if (!Object.keys(properties || {}).length) {
    return null;
  }

  return (
    <Slate {...properties}>
      <Box
        css={`
          display: grid;
        `}
      >
        <PropertiesGrouping label="Action" onMouseDown={(e) => e.preventDefault()}>
          <SlateLink {...props} />
        </PropertiesGrouping>
        <PropertiesGrouping label="Styles" defaultOpen>
          <SlateTypography {...typography} designStyles={props?.designStyles} />
          <SlateColorPicker {...props} />
          <SlateHorizontalAlign />
          <SlateList />
          <SlateDecoration />
        </PropertiesGrouping>

        <PropertiesGrouping label="Cell Styles">
          <AlignSetting
            label="Vertical Align"
            type="vertical"
            value={verticalAlign.value}
            setValue={verticalAlign.setValue}
          />
          <ColorSetting label="Background Color" value={bgColor.value} setValue={bgColor.setValue} />
          <RadiusSetting label="Radius" {...radius} />
          <StrokeSetting {...props} />
          <PaddingSetting value={padding.value} setValue={padding.setValue} />
        </PropertiesGrouping>
      </Box>
    </Slate>
  );
};
