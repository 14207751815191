import { MenuItem } from '@mui/material';
import { css } from '@emotion/css';
import { DotsThree, DownloadSimple, Info, Paperclip, WarningCircle, X } from '@phosphor-icons/react';
import { useCallback, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { ExtensionIconMapper } from '../../Documents/ExtensionIconMapper';
import { fileTypesMap } from '../../Documents/FullscreenUploader';
import { history } from '../../history';
import { CustomAxios } from '../../redux/axios/axios';
import { Button, Div, DropMenu, Modal, Text, Toggle } from '../../shared/components';
import { BriteLoader } from '../../shared/components/brite-loader';
import { flex } from '../../shared/shared-styles';
import { colors } from '../../shared/styles';
import TableFill from './table-fill.svg';

export const UploadMedia = ({
  packageId,
  onClose,
  displayDisclaimer = false,
  refetch = null,
  media = [],
  cacheMedia,
}) => {
  const link = useRef();

  const { businessId } = useParams();

  const [message, setMessage] = useState({
    type: '',
    data: {},
  });

  const [trySpreadsheetMapping, setTrySpreadsheetMapping] = useState(true);
  const [files, setFiles] = useState([]);

  const uploadBenefitsMedia = async () => {
    setMessage({
      type: 'uploading',
      data: { message: "Hopefully this doesn't take too long" },
    });

    const timeout = setTimeout(() => {
      setMessage({
        type: 'uploading',
        data: {
          message: '',
          showFileSizeDisclaimer: true,
        },
      });
    }, 12000);

    const promises = await Promise.allSettled(
      files.map((file) => {
        const { name, data } = file;
        const filetype = encodeURIComponent(data.type.replace(/\//g, '.'));
        const url = `v2/media/${name}/${filetype}?is_private=true&related_type=benefits_package&related_id=${packageId}`;
        const formData = new FormData();
        formData.append('file', data);
        const config = { headers: { 'content-type': 'multipart/form-data' } };
        return CustomAxios.post(url, formData, config);
      })
    );

    const list = promises.filter((item) => {
      const [type = ''] =
        Object.entries(fileTypesMap || {}).find(([_, filetype]) => filetype === item?.value?.data?.ContentType) || [];
      return item?.status === 'fulfilled' && (type === 'xlsx' || type === 'pdf');
    });

    if (trySpreadsheetMapping && list?.length) {
      const promises = await Promise.allSettled(
        list.map((promise) => {
          const mediaId = promise?.value?.data?.ID;
          return CustomAxios.post(`/v1/benefitspackage/${packageId}/imports/${mediaId}`);
        })
      );
      const stillProcessing = promises?.some(({ value }) => value?.status === 202);

      // check for error importing.
      const error = promises?.find(({ status }) => status === 'rejected');
      if (error) {
        // If the error is a 417, we just didn't find any products to import.
        if (error?.reason.response.data.code === 417) {
          setMessage({ type: 'no benefits found', data: error });
        } else {
          setMessage({ type: 'error', data: error });
        }
      } else if (stillProcessing) {
        setMessage({ type: 'processing' });
      } else {
        setMessage({ type: 'success' });
      }
    } else {
      setMessage({ type: 'uploaded', data: {} });
    }

    refetch?.();
    clearTimeout(timeout);
  };

  const handleBenefitsMedia = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      const files = acceptedFiles?.reduce((prev, item) => {
        const [type = ''] = Object.entries(fileTypesMap || {}).find(([_, filetype]) => filetype === item?.type) || [];
        return [
          ...prev,
          {
            data: item,
            name: encodeURIComponent(item?.name),
            type: `.${type}`,
          },
        ];
      }, []);

      setFiles(files);
    } else {
      toast.error('Invalid file or too many files');
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleBenefitsMedia,
    accept: [fileTypesMap.xlsx, fileTypesMap.xlsm, fileTypesMap.pdf],
    noDragEventsBubbling: true,
  });

  const downloadDocument = async (document) => {
    try {
      const response = await CustomAxios.get(`v2/media/${document?.ID}`, {
        responseType: 'blob',
      });
      toast.success('Downloading...');
      const url = window.URL.createObjectURL(response.data);
      link.current.href = url;
      link.current.download = document?.Name;
      link.current.click();
      window.URL.revokeObjectURL(url);
    } catch (err) {
      toast.error(`Error downloading file...`);
    }
  };

  const deleteDocument = async (document) => {
    try {
      cacheMedia.setData(media?.filter(({ ID }) => ID !== document.ID));
      await CustomAxios.delete(`/v2/media/${document.ID}`);
    } catch (e) {
      console.log(e);
      toast.error('Could not delete document');
    } finally {
      refetch();
    }
  };

  return (
    <Modal display={true} onClose={!message?.type || message?.type === 'success' ? onClose : null}>
      {/* eslint-disable-next-line   */}
      <a ref={link} style={{ display: 'none' }} />
      {!message.type ? (
        <Div
          css={css`
            width: 500px;
          `}
        >
          <Div
            css={css`
              ${flex('space-between start')}
              padding-bottom: 16px;
              padding: 32px;
            `}
          >
            <Text h2>Attach Spreadsheet or PDF of Benefit Details</Text>
            <Button styles="icon" onClick={onClose}>
              <X />
            </Button>
          </Div>
          <Div
            css={css`
              padding: 0 32px;
            `}
          >
            {displayDisclaimer ? (
              <Div
                css={css`
                  border-radius: 16px;
                  background-color: ${colors.gray[100]};
                  ${flex('left')}
                  padding: 16px;
                  margin-bottom: 16px;
                  svg {
                    margin-right: 16px;
                  }
                `}
              >
                <WarningCircle color={colors.orange[100]} weight="fill" />
                <Text label>This feature requires a benefit detail spreadsheet or pdf</Text>
              </Div>
            ) : null}
            <Text
              css={`
                margin-bottom: 16px;
              `}
            >
              This is the quickest way to add benefits to Brite.
            </Text>

            <Div
              css={css`
                position: relative;
                transition: background-color 0.2s ease;
                ${isDragActive || files?.length
                  ? `
                  background-color: ${colors.gray[100]};
                  ${files?.length ? `border: 2px solid ${colors.black};` : ''}
                `
                  : `border: 2px dashed ${colors.gray[400]};`}
                border-radius:8px;
                padding: 32px;
                min-height: 100px;
                cursor: pointer;
                ${flex('center column')}
              `}
              {...getRootProps()}
            >
              {files?.length ? (
                <div
                  className={css`
                    max-height: 35vh;
                    overflow-y: auto;
                  `}
                >
                  {files?.map((file) => (
                    <Div
                      css={css`
                        ${flex('left start')}
                        padding: 4px 0;
                        svg {
                          margin-right: 16px;
                        }
                        p {
                          width: 350px;
                        }
                      `}
                    >
                      <ExtensionIconMapper fileType={file?.type} size={24} />
                      <Text ellipsis label>
                        {file.name}
                      </Text>
                    </Div>
                  ))}
                </div>
              ) : (
                <>
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <DownloadSimple size={64} color={colors.purple} />
                  ) : (
                    <Text
                      label
                      css={`
                        pointer-events: none;
                      `}
                    >
                      Drop a file here, or click to browse
                    </Text>
                  )}
                </>
              )}
            </Div>
          </Div>

          {files?.map(({ type }) => type).some((t) => ['.xlsx', '.pdf'].includes(t)) ? (
            <Div
              css={css`
                padding: 8px 32px;
              `}
            >
              <Div
                css={css`
                  ${flex('space-between')}
                `}
              >
                <Text label bold>
                  File Mapping
                </Text>
                <Toggle checked={trySpreadsheetMapping} onChange={(e) => setTrySpreadsheetMapping(e.target.checked)} />
              </Div>
              <Text>
                When file mapping is enabled, Brite automatically adds any benefit details we can to your benefit
                package. This feature may override previous mapping.
              </Text>
            </Div>
          ) : !files?.type ? (
            <Div
              css={css`
                padding: 8px 32px;
                max-height: 30vh;
                overflow-x: auto;
              `}
            >
              {media?.map((item) => (
                <Div
                  css={css`
                    padding: 16px;
                    margin-bottom: 8px;
                    ${flex('left')}
                    border-radius: 8px;
                    border: 1px solid ${colors.gray[400]};
                    p {
                      flex-grow: 1;
                    }
                  `}
                >
                  <Paperclip
                    className={css`
                      margin-right: 16px;
                    `}
                  />
                  <Text label ellipsis>
                    {item?.Name}
                  </Text>

                  <DropMenu
                    button={
                      <Button styles="icon sm">
                        <DotsThree />
                      </Button>
                    }
                  >
                    <MenuItem onClick={() => downloadDocument(item)}>Download</MenuItem>
                    <MenuItem
                      onClick={() => deleteDocument(item)}
                      className={css`
                        color: ${colors.red[100]} !important;
                      `}
                    >
                      Remove
                    </MenuItem>
                  </DropMenu>
                </Div>
              ))}
            </Div>
          ) : null}

          <Div
            css={css`
              ${flex('right')}
              padding: 32px;
            `}
          >
            <Button
              secondary
              onClick={onClose}
              css={`
                margin-right: 16px;
              `}
            >
              Skip
            </Button>
            <Button onClick={uploadBenefitsMedia} disabled={!files?.length}>
              Attach
            </Button>
          </Div>
        </Div>
      ) : message.type === 'uploading' ? (
        <Div
          css={css`
            width: 500px;
            padding: 48px 32px;
            ${flex('center column')}
            padding-top: 40px;
          `}
        >
          <BriteLoader overlay={false} />
          <Text
            h2
            css={`
              padding: 32px 0;
            `}
          >
            Uploading your Benefits File...
          </Text>
          <Text>{message?.data?.message}</Text>
          {message?.data?.showFileSizeDisclaimer ? (
            <Div
              css={css`
                width: 100%;
                border-radius: 16px;
                background-color: ${colors.gray[100]};
                ${flex('left start')}
                padding: 16px;
                margin-top: 8px;
                p {
                  margin-top: 4px;
                }
                svg {
                  margin-right: 16px;
                  min-width: 32px;
                }
              `}
            >
              <Info weight="fill" color={colors.purple} />
              <Text label>
                This file is quite large. You might want to consider removing any pages that don’t contain information
                you need in Brite
              </Text>
            </Div>
          ) : null}
        </Div>
      ) : message?.type === 'success' ? (
        <Div
          css={css`
            width: 700px;
          `}
        >
          <Div
            css={css`
              ${flex('right')}
              padding: 32px;
              padding-bottom: 0;
            `}
          >
            <Button styles="icon" onClick={onClose}>
              <X />
            </Button>
          </Div>
          <Div
            css={css`
              padding: 0 32px;
              ${flex('center column')}
            `}
          >
            <img src={TableFill} alt="table-fill" />
            <Text
              h2
              center
              css={`
                margin: 16px 24px;
              `}
            >
              Hey We Were Able To Map Some Of Your Benefits
            </Text>
            <Text
              center
              css={`
                margin: 16px 24px;
                margin-bottom: 0;
                text-align: center;
              `}
            >
              We were able to collect some of your benefit data automatically. Hope we saved you a little time.
            </Text>
          </Div>
          <Div
            css={css`
              ${flex('right')}
              padding: 32px;
            `}
          >
            <Button
              onClick={onClose}
              large
              css={`
                background-color: ${colors.purple};
                min-width: 150px;
              `}
            >
              OK
            </Button>
          </Div>
        </Div>
      ) : message?.type === 'error' ? (
        <Div
          css={css`
            width: 700px;
          `}
        >
          <Div
            css={css`
              ${flex('right')}
              padding: 32px;
              padding-bottom: 0;
            `}
          >
            <Button styles="icon" onClick={onClose}>
              <X />
            </Button>
          </Div>
          <Div
            css={css`
              padding: 0 32px;
              ${flex('center column')}
            `}
          >
            <img src={TableFill} alt="table-fill" />
            <Text
              h2
              center
              css={`
                margin: 16px 24px;
              `}
            >
              Uh oh, something went wrong.
            </Text>
            <Text
              center
              css={`
                margin: 16px 24px;
                margin-bottom: 0;
                text-align: center;
              `}
            >
              Files over 10MB or corrupted files may fail to upload. Double check your file and try again.
            </Text>
          </Div>
          <Div
            css={css`
              ${flex('right')}
              padding: 32px;
            `}
          >
            <Button
              onClick={onClose}
              large
              css={`
                background-color: ${colors.purple};
                min-width: 150px;
              `}
            >
              OK
            </Button>
          </Div>
        </Div>
      ) : message?.type === 'no benefits found' ? (
        <Div
          css={css`
            width: 700px;
          `}
        >
          <Div
            css={css`
              ${flex('right')}
              padding: 32px;
              padding-bottom: 0;
            `}
          >
            <Button styles="icon" onClick={onClose}>
              <X />
            </Button>
          </Div>
          <Div
            css={css`
              padding: 0 32px;
              ${flex('center column')}
            `}
          >
            <img src={TableFill} alt="table-fill" />
            <Text
              h2
              center
              css={`
                margin: 16px 24px;
              `}
            >
              File Uploaded. No benefits mapped.
            </Text>
            <Text
              center
              css={`
                margin: 16px 24px;
                margin-bottom: 0;
                text-align: left;
              `}
            >
              If you would like Brite to map benefit details automatically, try the following suggestions next time:
              <br />
              <list>
                <li>Remove unnecessary data from the file.</li>
                <li>Use a single column (or row) of labels to identify your data.</li>
                <li>Add a "product name" label and value to each benefit in your file.</li>
              </list>
            </Text>
          </Div>
          <Div
            css={css`
              ${flex('right')}
              padding: 32px;
            `}
          >
            <Button
              onClick={onClose}
              large
              css={`
                background-color: ${colors.purple};
                min-width: 150px;
              `}
            >
              OK
            </Button>
          </Div>
        </Div>
      ) : message?.type === 'processing' ? (
        <Div
          css={css`
            width: 500px;
          `}
        >
          <Div
            css={css`
              ${flex('space-between')}
              padding: 32px;
              padding-bottom: 16px;
            `}
          >
            <Text h2>Processing</Text>
            <Button styles="icon" onClick={onClose}>
              <X />
            </Button>
          </Div>
          <Div
            css={css`
              padding: 0 32px;
            `}
          >
            <Text label bold>
              Sorry, it looks like it's taking us a while to process your file.
            </Text>
            <Text
              label
              css={`
                margin: 8px 0;
              `}
            >
              Please check your Benefits Package again in a few minutes to see what was imported.
            </Text>
          </Div>
          <Div
            css={css`
              ${flex('right')}
              padding: 32px;
            `}
          >
            <Button onClick={() => history.push(`/${businessId}/packages`)}>Close</Button>
          </Div>
        </Div>
      ) : message?.type === 'uploaded' ? (
        <Div
          css={css`
            width: 500px;
          `}
        >
          <Div
            css={css`
              ${flex('space-between')}
              padding: 32px;
              padding-bottom: 16px;
            `}
          >
            <Text h2>Success</Text>
            <Button styles="icon" onClick={onClose}>
              <X />
            </Button>
          </Div>
          <Div
            css={css`
              padding: 0 32px;
            `}
          >
            <Text label>Your file was successfully uploaded!</Text>
          </Div>
          <Div
            css={css`
              ${flex('right')}
              padding: 32px;
            `}
          >
            <Button onClick={onClose}>Close</Button>
          </Div>
        </Div>
      ) : null}
    </Modal>
  );
};
