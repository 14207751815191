import { MenuItem } from '@mui/material';
import { css } from '@emotion/css';
import { PencilSimple, WarningCircle, X } from '@phosphor-icons/react';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { mutliNetworkCategoryMap } from '../../../benefit-package/utils';
import { ALL_TYPES, PRODUCT_HEADERS } from '../../../benefit-package/constants';
import { history } from '../../../history';
import { ProductSummary } from '../../../products/product-summary';
import { Box, Button, Div, Modal, Select, Text } from '../../../shared/components';
import { flex, scrollbar } from '../../../shared/shared-styles';
import { colors } from '../../../shared/styles';
import { useForm } from '../../../shared/use-form';
import { useSearchParams } from '../../../shared/use-search-params';
import { useEditorResource } from '../../use-editor-resource';
import { SmartFieldInput } from '../../utility-components/smart-field-input';
import { CostSummary } from './summaries/cost-summary';
import { NetworkSearch } from './summaries/network-search';
import { useProductData } from '../../../products/configs/use-product-data';
import { BriteLoader } from '../../../shared/components/brite-loader';
import { modifyContent } from '../../provider/utils';
import { useStore } from '../../../store-provider/use-store';
import { useQueryAPI } from '../../../react-query';
import { smartComponentNameMap } from './benefits';

export const BenefitSummary = (props) => {
  const { businessId } = useParams();
  const {
    data: { devMode },
  } = useStore();
  const { editor, element, location, packageId } = props;
  const { editorType, viewMode } = editor?.state;

  const onUpdate = (updates) => modifyContent.merge(editor, location, updates);

  const {
    params: { modalToolbar: modal },
    updateParams,
    removeParam,
  } = useSearchParams();

  const setModal = (modalToolbar) => {
    if (!modalToolbar) {
      removeParam('modalToolbar');
    } else {
      updateParams({ modalToolbar });
    }
  };

  const { data: styles } = useEditorResource('styles');
  const { data: smartFields } = useEditorResource('liveSmartFields');
  const { data: programData } = useQueryAPI({
    url: `public/v1/business/${businessId}/programs`,
    defaultValue: [],
  });

  const productId = useMemo(() => {
    if (smartFields?.[element?.data?.id]?.Success && smartFields?.[element?.data?.id]?.Value) {
      return smartFields?.[element?.data?.id]?.Value;
    }
    return element?.data?.id;
  }, [element?.data?.id, smartFields]);

  const state = useProductData({
    devMode,
    productId,
    businessId,
    editorType,
    productType: element?.type,
    packageId,
  });

  const { values, setValues, getProperty, hasChanges } = useForm({ ...element?.data, productId, type: element?.type }, [
    element?.data,
    element?.type,
    modal,
    productId,
  ]);

  useEffect(() => {
    if (state?.product?.ID && state?.product?.ID === element?.data?.id) {
      setModal('');
    }
  }, [state?.product?.ID !== element?.data?.id]);

  const updateSummarySettings = () => {
    // { ...element?.data, productId, type: element?.type }
    const { id, type, smartFieldDisplay = '', relatedProductId, selectedId } = values;
    if (devMode) {
      onUpdate({ data: { id, smartFieldDisplay }, type });
      setModal('');
    } else if (editorType === 'page') {
      onUpdate({
        data: { id, relatedProductId, selectedId },
        type,
      });
      setModal('');
    } else {
      onUpdate({ type });
      setModal('');
    }
  };

  const routeToProductForm = () => {
    history.push(`/${businessId}/packages/${packageId}/${element?.type}/${productId}`);
  };

  const handleSmartField = ({ data }) => {
    setValues((form) => ({
      ...form,
      id: data?.value,
      smartFieldDisplay: data?.name,
    }));
  };

  const removeSmartField = () => {
    setValues((form) => ({
      ...form,
      id: '',
    }));
  };

  const filteredProducts = useMemo(() => {
    if (element?.type === 'hsa_buyup') {
      const productMap = props?.products?.reduce(
        (prev, item) => ({
          ...prev,
          [item?.ID]: item,
        }),
        {}
      );
      return programData?.reduce((prev, { ProgramType, PlanID }) => {
        if (ProgramType === 'health_savings_account' && PlanID in productMap) {
          const product = productMap?.[PlanID];
          return [...prev, product];
        }
        return prev;
      }, []);
    }

    return props?.products?.filter((p) => {
      return p.Type === element?.type;
    });
  }, [element?.type, props?.products, programData]);

  return (
    <>
      {state?.product?.ID ? (
        <Div
          css={css`
            ${flex('center')}
            position: relative;
            width: 100%;
            transition: height 0.2s ease;
          `}
          onDoubleClick={() => setModal('benefits-options')}
        >
          {element?.component === 'benefits' ? (
            <ProductSummary state={state} styles={styles} />
          ) : element?.component === 'pricing' ? (
            <CostSummary
              type={element?.type}
              state={state}
              styles={styles}
              summary={element?.data}
              viewMode={viewMode}
            />
          ) : element?.component === 'network' ? (
            <NetworkSearch state={state} styles={styles} />
          ) : null}
        </Div>
      ) : (
        <Box
          css={`
            position: relative;
            width: 100%;
            border-radius: 16px;
            background-color: ${colors.gray[100]};
            padding: 16px;
            gap: 8px;
          `}
          flex="center column"
        >
          <WarningCircle size={32} weight="fill" color={colors.red[100]} />
          <Text label bold>
            {smartComponentNameMap?.[props?.element?.component] || ''}
          </Text>
          <Text label>Error loading benefit, it may not exist.</Text>
        </Box>
      )}

      {/* DELETE THIS IN EMPLOYEE-VIEW */}
      {props.isSelected && modal === 'benefits-options' && (
        <Modal display={true} onClose={() => setModal('')}>
          <Div
            css={css`
              width: 500px;
              padding: 8px 0;
            `}
          >
            <Div
              css={css`
                ${flex('space-between')} padding: 16px 32px;
                border-bottom: 1px solid ${colors.gray[300]};
              `}
            >
              <Text h2>Edit</Text>
              <Button styles="icon" onClick={() => setModal('')}>
                <X />
              </Button>
            </Div>

            <Div
              css={css`
                position: relative;
                width: 100%;
                padding: 16px 32px;
                ${scrollbar.hide}
              `}
            >
              {devMode && (
                <>
                  <Text label bold>
                    ID
                  </Text>
                  <SmartFieldInput
                    value={values?.id}
                    smartFieldDisplay={values?.smartFieldDisplay || ''}
                    handleSmartField={handleSmartField}
                    removeSmartField={removeSmartField}
                    inputProps={getProperty('id')}
                  />
                </>
              )}

              {editorType === 'page' && !devMode ? (
                <>
                  <Div
                    css={css`
                      ${flex('space-between')}
                    `}
                  >
                    <Text label bold>
                      Edit Benefit
                    </Text>
                    <Button
                      hoverLabel="Go To Benefit Form"
                      styles="icon"
                      className={css`
                        margin-left: 4px;
                      `}
                      onClick={routeToProductForm}
                    >
                      <PencilSimple />
                    </Button>
                  </Div>
                  <Text
                    className={css`
                      flex-grow: 1;
                      margin-top: 16px;
                      margin-bottom: 8px;
                    `}
                    label
                    bold
                  >
                    Selected Benefit
                  </Text>
                  <Div
                    css={css`
                      ${flex('space-between')} margin-bottom: 32px;
                    `}
                  >
                    <Select
                      className={css`
                        flex-grow: 1;
                      `}
                      {...getProperty('id')}
                    >
                      {filteredProducts?.map((item) => (
                        <MenuItem key={item.ID} value={item.ID}>
                          <Div
                            css={css`
                              width: 100%;
                              ${flex('space-between')}
                            `}
                          >
                            <Text label>{item?.ProductName || item?.Title || PRODUCT_HEADERS[item?.Type]}</Text>
                            {item?.MultiNetworkCategory && (
                              <Text>{mutliNetworkCategoryMap?.[item?.MultiNetworkCategory]}</Text>
                            )}
                          </Div>
                        </MenuItem>
                      ))}
                    </Select>
                  </Div>
                </>
              ) : (
                <>
                  <Text
                    className={css`
                      flex-grow: 1;
                      margin-top: 16px;
                      margin-bottom: 8px;
                    `}
                    label
                    bold
                  >
                    Selected Benefit Type
                  </Text>
                  <Div
                    css={css`
                      ${flex('space-between')} margin-bottom: 32px;
                    `}
                  >
                    <Select
                      {...getProperty('type')}
                      className={css`
                        flex-grow: 1;
                      `}
                    >
                      {ALL_TYPES?.map(
                        (item) =>
                          PRODUCT_HEADERS[item] && (
                            <MenuItem key={item} value={item}>
                              <Div
                                css={css`
                                  width: 100%;
                                  ${flex('space-between')}
                                `}
                              >
                                <Text label>{PRODUCT_HEADERS[item]}</Text>
                              </Div>
                            </MenuItem>
                          )
                      )}
                    </Select>
                  </Div>
                </>
              )}

              <BriteLoader
                isLoading={
                  editorType === 'page' && !devMode && state?.product?.ID !== productId && !!state?.product?.ID
                }
                css={`
                  height: 100%;
                `}
              />
            </Div>
            <Div
              css={css`
                ${flex('right')}
                width: 100%;
                height: 100px;
                padding: 32px;
                border-top: 1px solid ${colors.gray[300]};
              `}
            >
              <Button
                className={css`
                  margin-right: 16px;
                `}
                onClick={() => setModal('')}
                styles="secondary"
              >
                Close
              </Button>
              <Button disabled={!hasChanges} onClick={updateSummarySettings}>
                Update
              </Button>
            </Div>
          </Div>
        </Modal>
      )}
      {/* DELETE THIS IN EMPLOYEE-VIEW  ^^^*/}
    </>
  );
};
