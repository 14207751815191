import { CustomAxios } from '../../../redux/axios/axios';

export const getProductSmartFields = async (guide, type, id) => {
  if (!id || !type || !guide?.isPublished) {
    return {};
  }
  try {
    const prodType = type === 'hsa_buyup' ? 'insurance_plan' : type;
    const resp = await CustomAxios.get(
      `/v1/product/types/${prodType}/tags?productID=${id}&benefitsPackageID=${guide.BenefitsPackageID}&use-product-layout=true&direct-ref=true`
    );
    return resp.data;
  } catch (error) {
    console.warn('error loading product tags for smart component, will use hard-coded values instead', error);
    return {};
  }
};

export const smartFieldDrillDown = (mergeTags, searchNames) => {
  if (!mergeTags || !searchNames.length) {
    return null;
  }

  for (let i = 0; i < mergeTags.length; i++) {
    if (mergeTags[i].name?.toLowerCase() !== searchNames[0].toLowerCase()) {
      continue;
    }

    if (mergeTags[i].mergeTags) {
      return smartFieldDrillDown(mergeTags[i].mergeTags, searchNames.slice(1));
    }

    return mergeTags[i].value;
  }

  return null;
};
