import { SidebarSimple } from '@phosphor-icons/react';
import { selectableBox } from '../../utility/settings/styles';
import { Box, Text } from '../../../shared/components';
import { Property } from './property';

export const HideStrokeSetting = ({ value, setValue }) => {
  return (
    <Property>
      <Text label>Stroke Side</Text>
      <Box
        flex="space-between"
        css={`
          gap: 8px;
        `}
      >
        <Box css={selectableBox(value?.borderLeftStyle)} flex="center" onClick={() => setValue('borderLeftStyle')}>
          <SidebarSimple color="currentColor" weight="fill" size={24} />
        </Box>
        <Box
          flex="center"
          onClick={() => setValue('borderRightStyle')}
          css={`
            ${selectableBox(value?.borderRightStyle)}
            svg {
              transform: rotate(180deg);
            }
          `}
        >
          <SidebarSimple color="currentColor" weight="fill" size={24} />
        </Box>
        <Box
          flex="center"
          onClick={() => setValue('borderTopStyle')}
          css={`
            ${selectableBox(value?.borderTopStyle)}
            svg {
              transform: rotate(90deg);
            }
          `}
        >
          <SidebarSimple color="currentColor" weight="fill" size={24} />
        </Box>
        <Box
          flex="center"
          onClick={() => setValue('borderBottomStyle')}
          css={`
            ${selectableBox(value?.borderBottomStyle)}
            svg {
              transform: rotate(270deg);
            }
          `}
        >
          <SidebarSimple color="currentColor" weight="fill" size={24} />
        </Box>
      </Box>
    </Property>
  );
};
