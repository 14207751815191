import { useEffect, useMemo, useState } from 'react';
import { Box } from '../../shared/components';
import { colors } from '../../shared/styles';
import { ToolKit } from '../providers/tool-kit/ToolKit';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import { usePopper } from 'react-popper';
import { useResize } from '../../BriteEditor/editor-components/use-resize';
import { Conditional } from './Conditional';
import { useContent } from '../providers/content/use-content';
import { getRange } from './multi-select-utils';

export const MultiSelection = () => {
  const toolkit = useToolKit();
  const hasMultiSelection = ToolKit.hasMultiSelection(toolkit);

  return (
    <Conditional if={hasMultiSelection}>
      <MultiSelectionComponent />
    </Conditional>
  );
};

export const MultiSelectionComponent = () => {
  const { content } = useContent();
  const toolkit = useToolKit();
  const selection = ToolKit.getSelection(toolkit);

  const [anchor, setAnchor] = useState();
  const [popper, setPopper] = useState();
  const [blockBounds, setBlockBounds] = useState([]);

  const popperProps = usePopper(anchor, popper, { placement: 'right' });
  useResize(anchor, () => popperProps?.update?.());

  const position = useMemo(() => {
    if (blockBounds?.length) {
      const rect = blockBounds.reduce((prev, data) => {
        return {
          top: Math.min(prev.top || data.top, data.top),
          left: Math.min(prev.left || data.left, data.left),
          right: Math.max(prev.right || data.right, data.right),
          bottom: Math.max(prev.bottom || data.bottom, data.bottom),
        };
      }, {});

      const parent = anchor?.parentElement;
      const bounds = parent?.getBoundingClientRect();

      const scrollTop = parent?.scrollTop || 0;
      const scrollLeft = parent?.scrollLeft || 0;

      return {
        top: rect.top - bounds?.top + scrollTop,
        left: rect.left - bounds?.left + scrollLeft,
        right: bounds?.right - rect?.right - scrollLeft - 8,
        bottom: bounds?.bottom - rect?.bottom - scrollTop - 8,
      };
    }
    return {};
  }, [anchor, blockBounds]);

  const hasPosition = !!Object.values(position)?.length;

  useEffect(() => {
    const range = getRange(content, selection?.range);
    ToolKit.setMultiSelect(toolkit, range);
    const elements = document.querySelectorAll('[data-id]');
    const blockBounds = Array.from(elements)
      .filter((el) => {
        const id = el.getAttribute('data-id');
        return range?.ids?.includes(id);
      })
      .map((el) => el.getBoundingClientRect());
    setBlockBounds(blockBounds);
  }, [selection?.range?.anchor, selection?.range?.focus]);

  return (
    <Box
      css={`
        position: absolute;
        margin: -8px;
        ${hasPosition
          ? `
          top: ${position?.top}px;
          left: ${position?.left}px;
          right: ${position?.right}px;
          bottom: ${position?.bottom}px;
        `
          : ''}
        outline-offset: 2px;
        border-radius: 4px;
        outline: 1px dashed ${colors.black};
        background-color: ${colors.lighterPurple}22;
        pointer-events: none;
        z-index: 1000000;
      `}
      ref={setAnchor}
    />
  );
};
