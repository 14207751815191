import { css } from '@emotion/css';
import { colors } from '../styles';

const dotStyle = (color) => css`
  position: absolute;
  background-color: ${color};
  border-radius: 30px;
  padding: 0 8px;
  min-width: 32px;
`;

export const Badge = ({ children, display = true, color = colors.purple, spacing = '12', css: cssString }) => {
  return (
    <>
      {display && (
        <div
          className={css`
            top: ${spacing * -1}px;
            right: ${spacing * -1}px;
            ${dotStyle(color)}
            display: flex;
            justify-content: center;
            ${cssString}
          `}
        >
          {children}
        </div>
      )}
    </>
  );
};
