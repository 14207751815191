import { Box, Slider } from '../../../shared/components';
import { Modify } from '../../providers/content/content-modify';
import { useContent } from '../../providers/content/use-content';
import { ColorPicker } from '../../utility/settings/ColorPicker';

export const SpaceToolbar = ({ id, height, bgColor }) => {
  const { sendUpdates } = useContent();

  const updateHeight = () => {
    const heightValue = Math.max(8, Number(height.value));
    Modify.style(sendUpdates, id, {
      height: '100%',
      minHeight: `${heightValue}px`,
    });
  };

  const updateBgColor = (backgroundColor) => Modify.style(sendUpdates, id, { backgroundColor });

  return (
    <Box
      flex="left"
      css={`
        gap: 8px;
        padding-left: 16px;
      `}
    >
      <Slider
        min={8}
        max={300}
        value={height.value}
        style={{ width: '100px', color: 'white', marginTop: '8px' }}
        valueLabelDisplay="auto"
        onChange={(e, value) => height.set(value)}
        onChangeCommitted={updateHeight}
      />
      <ColorPicker label="Background Color" color={bgColor} update={updateBgColor} />
    </Box>
  );
};
