import { ArrowSquareOut, Check, FirstAidKit, Palette, PencilSimple, Plugs } from '@phosphor-icons/react';
import { Box, Dropdown, Icon, Text } from '../../shared/components';
import { colors } from '../../shared/styles';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import { ToolKit } from '../providers/tool-kit/ToolKit';
import { container } from '../../shared/shared-styles';
import { useQueryAPI } from '../../react-query';
import { ReactComponent as SmartText } from '../../images/svg/smart-text.svg';
import { useFeatureFlagPayload } from 'posthog-js/react';
import { history } from '../../history';
import { useStore } from '../../store-provider/use-store';
import { useMemo } from 'react';
import { useModalWithData } from '../../common/hooks/useModalWithData';
import { DesignStylesModalV2 } from '../../Content/Builder-v2/modals/design-styles-modal';
import { useParams } from 'react-router';
import { Tooltip } from '../../common/components/Tooltip';

const connectionsContainer = `
  width: 300px;
  height: 100%;
  padding: 8px;
  gap: 16px;
  overflow-x: clip;
  .connected {
    padding: 4px 8px;
    background-color: ${colors.green[300]};
    border-radius: 8px;
    color: ${colors.green[200]};
    font-weight: bold;
    margin: 8px 0;
  }
  .add-connection {
    padding: 4px 8px;
    background-color: ${colors.green[300]};
    border-radius: 8px;
    color: ${colors.green[200]};
    font-weight: bold;
    margin: 8px 0;
  }
`;

const resourceContainer = `
  width: 100%;
  padding: 8px;
  border: 1px solid transparent;
  gap: 16px;
  border-radius: 8px;
  ${container.hover}
  svg {
    min-width: 24px;
  }
`;

export const Connections = () => {
  const {
    data: { selectedBusiness },
  } = useStore();

  const toolkit = useToolKit();
  const guide = ToolKit.getMetadata(toolkit, 'guide');

  const smartTextFF = useFeatureFlagPayload('business-smart-fields')?.value;

  const { data: bpList } = useQueryAPI({ url: `v1/benefitspackage` });

  const benefitPackage = useMemo(() => {
    return bpList?.find((bp) => bp.ID === guide?.data?.BenefitsPackageID);
  }, [guide?.data?.BenefitsPackageID]);

  const { data: designStyles, isLoading } = useQueryAPI({
    cacheKey: ['editor'],
    url: `v1/themes`,
    retry: 1,
  });

  const theme = useMemo(() => {
    return designStyles?.find((bp) => bp.ID === guide?.data?.ThemeID);
  }, [guide?.data?.ThemeID, isLoading]);

  const routeToSmartText = () => {
    history.push(`/${selectedBusiness?.ID}/smart-fields`);
  };

  return (
    <>
      <Box css={connectionsContainer} flex="column">
        <Text
          label
          bold
          css={`
            margin: 8px;
          `}
        >
          Connections
        </Text>
        <Box>
          <BenefitsPackageConnection guide={guide} benefitPackage={benefitPackage} bpList={bpList} />
          <DesignStylesConnection guide={guide} theme={theme} designStyles={designStyles} />
          {smartTextFF ? (
            <Box css={resourceContainer} onClick={routeToSmartText} flex="left start">
              <Icon SVG={SmartText} size={24} />
              <Box
                css={`
                  gap: 8px;
                `}
              >
                <Text
                  label
                  bold
                  ellipsis
                  css={`
                    max-width: 180px;
                  `}
                >
                  Smart Text
                </Text>
                <Box flex="left">
                  <Text helper>Click to view</Text>
                  <ArrowSquareOut size={16} color={colors.gray[400]} />
                </Box>
              </Box>
            </Box>
          ) : null}
        </Box>
      </Box>
    </>
  );
};

const BenefitsPackageConnection = ({ guide, benefitPackage, bpList }) => {
  const { businessId } = useParams();
  const updateBP = (BenefitsPackageID) => guide?.save({ BenefitsPackageID });

  const routeToBenefitsPackage = () => history?.push(`/${businessId}/packages/${guide?.data?.BenefitsPackageID}`);

  const hasPackage =
    guide?.data?.BenefitsPackageID && guide?.data?.BenefitsPackageID !== '00000000-0000-0000-0000-000000000000';

  return (
    <Dropdown
      clear
      popper={{ placement: 'left-start' }}
      button={() => (
        <Box css={resourceContainer} flex="left start">
          <FirstAidKit size={24} color={hasPackage ? colors.purple : colors.gray[500]} />
          <Box
            css={`
              gap: 8px;
            `}
          >
            <Text
              label={hasPackage}
              bold
              css={`
                max-width: 170px;
              `}
            >
              {benefitPackage?.Name || 'Benefits Package'}
            </Text>
            {hasPackage ? <Text helper>Click to edit</Text> : <Text helper>Click to add connection</Text>}
          </Box>
        </Box>
      )}
    >
      <Box
        data-scope="menu"
        css={`
          width: 300px;
        `}
      >
        <Box option disabled={!hasPackage} onClick={routeToBenefitsPackage}>
          <PencilSimple size={24} />
          Edit Package
        </Box>
        <Box option disabled={!hasPackage} onClick={() => updateBP(null)}>
          <Plugs size={24} color={colors.red[100]} />
          <Text
            css={`
              color: ${colors.red[100]};
            `}
          >
            Disconnect
          </Text>
        </Box>
        {bpList?.length ? (
          <Box
            css={`
              margin: 8px;
              border-bottom: 1px solid ${colors.gray[300]};
            `}
          />
        ) : null}
        {bpList?.map((pkg) => (
          <Tooltip label={pkg?.Name} popper={{ placement: 'left' }}>
            <Box
              option
              flex="space-between"
              onClick={() => updateBP(pkg?.ID)}
              css={`
                max-width: 300px;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              `}
            >
              {pkg?.Name}
              {guide?.data?.BenefitsPackageID === pkg?.ID ? <Check size={24} /> : null}
            </Box>
          </Tooltip>
        ))}
      </Box>
    </Dropdown>
  );
};

const DesignStylesConnection = ({ theme, guide, designStyles }) => {
  const modal = useModalWithData();
  const updateTheme = (ThemeID) => guide?.save({ ThemeID });
  const hasTheme = guide?.data?.ThemeID && guide?.data?.ThemeID !== '00000000-0000-0000-0000-000000000000';

  return (
    <>
      <Dropdown
        clear
        popper={{ placement: 'left-start' }}
        button={() => (
          <Box css={resourceContainer} flex="left start">
            <Palette size={24} color={hasTheme ? colors.purple : colors.gray[500]} />
            <Box
              css={`
                gap: 8px;
              `}
            >
              <Text
                label={hasTheme}
                bold
                css={`
                  max-width: 170px;
                `}
              >
                {theme?.Name || 'Design Style'}
              </Text>
              {hasTheme ? <Text helper>Click to edit</Text> : <Text helper>Click to add connection</Text>}
            </Box>
          </Box>
        )}
      >
        <Box
          data-scope="menu"
          css={`
            width: 300px;
          `}
        >
          {hasTheme ? (
            <Box
              option
              disabled={!hasTheme}
              onClick={() => {
                modal.show('design-styles');
              }}
            >
              <PencilSimple size={24} />
              Edit Design Style
            </Box>
          ) : (
            <Box
              option
              onClick={() => {
                modal.show('design-styles');
              }}
            >
              <ArrowSquareOut size={24} />
              View Design Styles
            </Box>
          )}

          <Box option disabled={!hasTheme} onClick={() => updateTheme(null)}>
            <Plugs size={24} color={colors.red[100]} />
            <Text
              css={`
                color: ${colors.red[100]};
              `}
            >
              Disconnect
            </Text>
          </Box>
          {designStyles?.length ? (
            <Box
              css={`
                margin: 8px;
                border-bottom: 1px solid ${colors.gray[300]};
              `}
            />
          ) : null}
          {designStyles?.map((style) => (
            <Tooltip label={style?.Name} popper={{ placement: 'left' }}>
              <Box
                option
                ellipsis
                flex="space-between"
                onClick={() => updateTheme(style?.ID)}
                css={`
                  max-width: 300px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                `}
              >
                {style?.Name}
                {guide?.data?.ThemeID === style?.ID ? <Check size={24} /> : null}
              </Box>
            </Tooltip>
          ))}
        </Box>
      </Dropdown>
      {modal.isOpen && modal?.data === 'design-styles' ? (
        <DesignStylesModalV2
          display={true}
          onClose={modal.hide}
          saveCourse={guide.save}
          refetch={guide.refetch}
          course={guide.data}
        />
      ) : null}
    </>
  );
};
