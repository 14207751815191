import { useSlate } from 'slate-react';
import {
  getActiveMarks,
  removeAllMarks,
  updateMark,
} from '../../../../BriteEditor/editor-components/text-v2/slate-utils';
import { Box, Dropdown, Text } from '../../../../shared/components';
import { TYPOGRAPHY_MAP } from '../../../utility/constants';
import { Range } from 'slate';

export const SlateTypography = ({ value, setValue, designStyles }) => {
  const slate = useSlate();
  const activeMarks = getActiveMarks(slate);
  const currentTypography = activeMarks?.typography || value;
  const { border, ...style } = designStyles?.text?.[currentTypography] || {};

  const updateTypography = (type) => {
    if (Range.isCollapsed(slate?.selection)) {
      removeAllMarks(slate, 'typography');
      setValue(type);
    } else {
      if (type === value) {
        removeAllMarks(slate, 'typography');
      } else {
        updateMark(slate, 'typography', type);
      }
    }
  };

  return (
    <Box
      css={`
        display: grid;
        gap: 8px;
      `}
    >
      <Text label>Font</Text>
      <Dropdown
        clear
        listCss={`background-color: ${designStyles?.general?.backgroundColor};`}
        button={(props) => (
          <Dropdown.Select
            {...props}
            onMouseDown={(e) => e.preventDefault()}
            label={
              <Text
                style={{
                  ...style,
                  fontSize: '20px',
                  lineHeight: '24px',
                }}
              >
                {TYPOGRAPHY_MAP[currentTypography]}
              </Text>
            }
            css={`
              background-color: ${designStyles?.general?.backgroundColor};
              padding: 8px 16px;
              :hover {
                background-color: ${designStyles?.general?.backgroundColor};
                border: 1px solid ${style?.color};
              }
              * {
                color: ${style?.color};
              }
            `}
          />
        )}
      >
        <Box data-scope="menu">
          {Object.entries(TYPOGRAPHY_MAP).map(([key, label]) => (
            <Box
              option
              style={designStyles?.text?.[key]}
              onClick={() => updateTypography(key)}
              onMouseDown={(e) => e.preventDefault()}
            >
              {label}
            </Box>
          ))}
        </Box>
      </Dropdown>
    </Box>
  );
};
