import { colors } from '../../../shared/styles';

export const getImageStatus = (imageBlock) => {
  const { isSmartField, smartFields, src, image, validUrl } = imageBlock;
  const isBrokenSmartImage = isSmartField && src && smartFields?.[src]?.Success;
  const smartImageLocationExists = !isBrokenSmartImage && !(image?.error && isSmartField);
  const smartImageHasValue = !!src || !isSmartField;
  const invalidImageUrl = !validUrl && !!src;
  const imageCanLoad = !image?.error || !src;
  const hasError = !smartImageLocationExists && !smartImageHasValue && invalidImageUrl && !imageCanLoad;
  // const errorsExist = (isSmartField && (!src || isBrokenSmartImage || image?.error)) || (!validUrl && !!src);

  return {
    isBrokenSmartImage,
    smartImageLocationExists,
    smartImageHasValue,
    invalidImageUrl,
    imageCanLoad,
    hasError,
  };
};

export const getErrorColors = (block, imageStatus) => {
  const errorColors = {
    warning: imageStatus?.isBrokenSmartImage ? colors.red[100] : colors.orange[100],
    image: block?.image?.error && !!block?.src?.value ? colors.red[100] : colors.gray[500],
  };
  return errorColors;
};

export const getLogoStyle = () => ({
  borderTopLeftRadius: '0',
  borderTopRightRadius: '0',
  borderBottomLeftRadius: '0',
  borderBottomRightRadius: '0',
});

export function getPlaceholderHeight(naturalWidth, naturalHeight, containerWidth) {
  if (naturalWidth <= 0 || naturalHeight <= 0 || containerWidth <= 0) {
    return 0;
  }
  return (containerWidth / naturalWidth) * naturalHeight;
}

export const getImageTransform = ({ scale, rotate, horizontalFlip, verticalFlip }) => {
  let transform = `scale(${scale}) rotate(${rotate}deg)`;
  if (horizontalFlip) {
    transform += ' scaleX(-1)';
  }
  if (verticalFlip) {
    transform += ' scaleY(-1)';
  }
  return transform;
};
