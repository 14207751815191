import { useState } from 'react';
import { css } from '@emotion/css';
import { CircularProgress } from '@mui/material';
import { ContactInfo } from './ContactInfo';
import { WarningCircle, X } from '@phosphor-icons/react';
import { Div, Text, Toggle, Button, Input, Box } from '../shared/components';
import { history } from '../history';
import { useParams } from 'react-router';
import { flex } from '../shared/shared-styles';
import { Select, ConfirmationModal } from '../shared/components';
import MenuItem from '@mui/material/MenuItem';
import { useStore } from '../store-provider/use-store';
import { colors } from '../shared/styles';
import { useSync } from '../BriteEditor.v2/utility/useSync';

const briteMasterBusinessID = 'b155ac0b-5ec2-4082-8b31-bae8e19e6363';

export const CourseSettings = (props) => {
  const { businessId, id, courseId } = useParams();
  const {
    data: { selectedBusiness, devMode },
  } = useStore();

  const sync = useSync({ ...props?.course });
  const [showTypeSwitchWarning, setShowTypeSwitchWarning] = useState('');

  const handleClose = () => {
    const value = id || courseId;
    history.replace(`/${businessId}/courses/${value}?pageId=${props.page?.ID}`);
  };

  const handleSave = () => {
    const payload = { ...sync?.value };
    props.updateCourseChanges(payload);
    handleClose();
  };

  const handleCourseLink = (e) => {
    const val = e.target.value;
    const next = val?.replace(/[^a-zA-Z0-9-_]/g, '');
    sync?.merge({ VanityURL: next });
  };

  return (
    <div
      className={css`
        height: 100%;
        overflow: auto;
      `}
    >
      <div
        className={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 28px;
          border-bottom: 1px solid #d1dae3;
          margin-bottom: 40px;
        `}
      >
        <Text h1>Guide Settings</Text>

        <Box
          flex="right"
          css={`
            gap: 16px;
          `}
        >
          <Button secondary onClick={handleClose}>
            Close
          </Button>
          <Button disabled={sync?.isSynced} onClick={handleSave}>
            Save
          </Button>
        </Box>
      </div>
      {!('ID' in props.course) ? (
        <div
          className={css`
            display: flex;
            justify-content: center;
          `}
        >
          <CircularProgress />
        </div>
      ) : (
        <div
          className={css`
            width: 100%;
            max-width: 700px;
            margin: 0 auto;
            margin-bottom: 180px;
          `}
        >
          <div
            className={css`
              margin: 48px 0;
              padding-bottom: 40px;
              border-bottom: 1px solid ${colors.gray[300]};
            `}
          >
            <Text h4>Guide Link</Text>

            <Text
              css={`
                margin-top: 8px;
              `}
            >
              Before you can share a guide, you need to create a custom URL. This link will be shared with your
              employees to view the guide.
            </Text>
            <div
              className={css`
                border-radius: 8px;
                background-color: ${colors.gray[100]};
                padding: 16px;
                margin: 24px 0;
                ${flex('left')}
                svg {
                  margin-right: 16px;
                }
              `}
            >
              <WarningCircle color={colors.orange[100]} weight="fill" />
              <Text label>Changing this field will break any guide links you may have already sent out.</Text>
            </div>
            <Text
              label
              css={`
                margin-top: 16px;
                margin-bottom: 8px;
              `}
            >
              Guide Link
            </Text>
            <div
              className={css`
                border-radius: 8px;
                border: 1px solid ${colors.gray[300]};
                ${flex('left')}
              `}
            >
              <Text
                css={`
                  padding: 12px 16px;
                  border-right: 1px solid ${colors.gray[300]};
                `}
              >
                https://britehr.app/
              </Text>
              <Input
                css={`
                  flex-grow: 1;
                  border: none;
                  :hover {
                    border: none;
                  }
                `}
                value={sync?.value?.VanityURL || ''}
                onChange={handleCourseLink}
              />
            </div>
          </div>
          {props.course.Type !== 'vendor' && (
            <div
              className={css`
                margin: 48px 0;
                padding-bottom: 40px;
                border-bottom: 1px solid ${colors.gray[300]};
              `}
            >
              <Text h4>Guide Type</Text>
              <Text
                css={`
                  margin-top: 8px;
                `}
              >
                We use this info to customize our tools for you and give you more accurate analytics.
              </Text>
              <Text
                label
                css={`
                  margin-top: 16px;
                  margin-bottom: 8px;
                `}
              >
                Guide Type
              </Text>
              <Select
                css={`
                  width: 100%;
                `}
                placeholder="Select a Type"
                value={sync?.value?.Type}
                onChange={(e) => {
                  if (sync?.value?.Type === 'openenrollment' || sync?.value?.Type === 'newhire') {
                    if (e.target.value === 'openenrollment' || e.target.value === 'newhire') {
                      sync?.merge({ Type: e.target.value });
                    } else {
                      setShowTypeSwitchWarning(e.target.value);
                    }
                  } else {
                    sync?.merge({ Type: e.target.value });
                  }
                }}
              >
                <MenuItem value={'openenrollment'}>Open Enrollment</MenuItem>
                <MenuItem value={'newhire'}>New Hire</MenuItem>
                <MenuItem value={'recruiting'}>Recruiting</MenuItem>
                <MenuItem value={'other'}>Other</MenuItem>
              </Select>
            </div>
          )}
          <div
            className={css`
              margin: 48px 0;
              padding-bottom: 40px;
              border-bottom: 1px solid ${colors.gray[300]};
            `}
          >
            <Text h4>Add Enrollment URL</Text>
            <Text
              css={`
                margin: 8px 0;
              `}
            >
              An enrollment URL is used to generate components that help employees access your company's enrollment
              software.
            </Text>
            <Text
              label
              css={`
                margin: 8px 0;
                margin-top: 16px;
              `}
            >
              Enrollment URL
            </Text>
            <Input
              value={sync?.value?.EnrollmentURL || ''}
              onChange={(e) => sync.merge({ EnrollmentURL: e.target.value })}
            />
            <Text>Adding an Enrollment URL will add an additional enrollment page to the end of your guide.</Text>
          </div>
          <div
            className={css`
              margin: 48px 0;
              padding-bottom: 40px;
              border-bottom: 1px solid ${colors.gray[300]};
            `}
          >
            <Text h4>Add Passcode</Text>
            <Text
              css={`
                margin-top: 8px;
                margin-bottom: 16px;
              `}
            >
              If you set a passcode for this guide, only those with the code can view it.
            </Text>

            <Text
              label
              css={`
                margin-bottom: 8px;
              `}
            >
              Passcode
            </Text>
            <Input value={sync?.value?.Passcode || ''} onChange={(e) => sync?.merge({ Passcode: e.target.value })} />
          </div>
          <ContactInfo course={props.course} updateCourse={props.updateCourseChanges} />
          {/* special-case here for brite master business, which is not a multi type business. Someday we might convert it to one, but I want to make sure that wouldn't break anything else before we do. */}
          {(selectedBusiness.Type === 'multi' || selectedBusiness.ID === briteMasterBusinessID) && (
            <div
              className={css`
                border-bottom: 1px solid ${colors.gray[300]};

                font-size: 12px;
                line-height: 18px;
                padding-top: 24px;
                padding-bottom: 24px;
                margin-bottom: 60px;
              `}
            >
              <Div
                css={css`
                  ${flex('space-between start')}
                `}
              >
                <div>
                  <Text h4>Publish to Base Guides Catalog</Text>
                  <Text
                    css={`
                      margin-top: 8px;
                      margin-bottom: 16px;
                    `}
                  >
                    Share this guide with your client business accounts. Makes it show as an option when generating a
                    guide from a benefits package.
                  </Text>
                </div>
                <Toggle
                  checked={sync?.value?.IsPublished}
                  onChange={(e) => sync?.merge({ IsPublished: e.target.checked })}
                />
              </Div>
            </div>
          )}
          <div
            className={css`
              border-bottom: 1px solid #d1dae3;
              margin-bottom: 40px;
              margin-top: 8px;
              padding-bottom: 40px;
            `}
          >
            <Div
              css={css`
                ${flex('space-between start')}
              `}
            >
              <div>
                <Text h4>Show Guide Name in Page Menu</Text>
                <Text
                  css={`
                    margin-top: 8px;
                  `}
                >
                  If enabled, the guide name will be displayed at the top of the page menu.
                </Text>
              </div>
              <Toggle
                checked={sync?.value?.ShowGuideName}
                onChange={(e) => sync?.merge({ ShowGuideName: e.target.checked })}
              />
            </Div>
          </div>
          {devMode && (
            <div
              className={css`
                border-bottom: 1px solid #d1dae3;
                margin-bottom: 40px;
                margin-top: 8px;
                padding-bottom: 40px;
              `}
            >
              <Div
                css={css`
                  ${flex('space-between start')}
                `}
              >
                <div>
                  <Text h4>Hide Navigation Buttons</Text>
                  <Text
                    css={`
                      margin-top: 8px;
                    `}
                  >
                    Turn on to hide the next and previous buttons in the guide.
                  </Text>
                </div>
                <Toggle
                  checked={sync?.value?.HideNavigation}
                  onChange={(e) => sync?.merge({ HideNavigation: e.target.checked })}
                />
              </Div>
            </div>
          )}
          <Text>
            These settings are specific to this guide. They will not be reflected on any other guide that you have.
          </Text>
        </div>
      )}
      <ConfirmationModal
        title="This change will result in the decision tool feature being removed from this guide."
        confirmText="Confirm"
        display={!!showTypeSwitchWarning}
        onClose={() => setShowTypeSwitchWarning('')}
        onConfirm={async () => {
          sync?.merge({ Type: showTypeSwitchWarning });
          setShowTypeSwitchWarning('');
        }}
      />
    </div>
  );
};
