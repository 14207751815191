import { useParams } from 'react-router';
import { useEditorResource } from '../../../../BriteEditor/use-editor-resource';
import { useStore } from '../../../../store-provider/use-store';
import { ToolKit } from '../../../providers/tool-kit/ToolKit';
import { useBlock } from '../../../utility/useBlock';
import { Segment } from '../../../providers/segment/Segment';
import { useSearchParams } from '../../../../shared/use-search-params';
import { useSync } from '../../../utility/useSync';
import { useSmartField } from '../../../utility/editor-utils';
import { useMemo } from 'react';
import { useProductData } from '../../../../products/configs/use-product-data';
import { useQueryAPI } from '../../../../react-query';

export const usePricingBlock = () => {
  const { businessId } = useParams();
  const {
    params: { packageId },
  } = useSearchParams();
  const {
    data: { devMode },
  } = useStore();

  const block = useBlock();

  const properties = Segment.getProperties(block?.segment);
  const editorType = ToolKit.getEditorType(block?.toolkit);
  const productType = properties?.type;

  const { data: designStyles } = useEditorResource('styles');

  const {
    data: products,
    isLoading: isLoadingProducts,
    isFetched,
    refetch,
  } = useEditorResource('products', {
    enabled: productType !== 'hsa_buyup',
  });

  const { data: programData, isLoading: isLoadingPrograms } = useQueryAPI({
    url: `public/v1/business/${businessId}/programs`,
    defaultValue: [],
    enabled: productType === 'hsa_buyup',
  });

  const isLoading = isLoadingProducts || isLoadingPrograms;

  const sync = useSync({ ...properties });

  const { value: productId, isSmartField } = useSmartField(sync?.value?.data?.id, sync?.value?.data?.selectedId);

  const product = useMemo(() => {
    if (productType === 'hsa_buyup') {
      return programData?.find((item) => item?.PlanID === productId);
    }
    return products?.find((item) => item?.ID === productId);
  }, [isLoading, productId, isFetched, productType]);

  const productState = useProductData({
    devMode,
    productId,
    businessId,
    editorType,
    productType,
    packageId,
  });

  return {
    ...block,
    properties,
    designStyles,
    productState,
    isSmartField,
    isLoading,
    productType,
    productId,
    product,
    refetch,
    sync,
  };
};
