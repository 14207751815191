import {
  ArrowLeft,
  ArrowUUpLeft,
  ArrowUUpRight,
  CaretRight,
  CloudCheck,
  Copy,
  DotsThreeVertical,
  DownloadSimple,
  Eye,
  GearSix,
  Heart,
  Image,
  Trash,
} from '@phosphor-icons/react';
import { Box, Button, Dropdown, Text } from '../shared/components';
import { colors } from '../shared/styles';
import { useSync } from './utility/useSync';
import { useEffect, useMemo, useRef } from 'react';
import { css } from '@emotion/css';
import { useFeatureFlagPayload } from 'posthog-js/react';
import { Subscribers } from '../shared/components/Subscribers';
import { ExportGuideToPDFModal } from '../Content/Builder-v2/modals/ExportGuideToPDFModal';
import { useSearchParams } from '../shared/use-search-params';
import { useStore } from '../store-provider/use-store';
import { useModalWithData } from '../common/hooks/useModalWithData';
import { CreateLinkModal } from '../Messaging/editor/modals/CreateLinkModal';
import { LaunchGuideModal } from '../Messaging/editor/modals/LaunchGuideModal';
import { SavePageTemplate } from '../Content/Builder-v2/modals/save-page-template';
import { PageSettings } from '../Content/Builder-v2/page-list/page-settings';
import { PagesModal } from '../Content/Builder-v2/modals/pages-modal';
import { BackgroundImage } from '../BriteEditor/utility-components/background-image';
import { useAcls } from '../shared/use-acls';
import { WRITE_COURSE } from '../shared/acl-constants';
import { useGuide } from './providers/guide/Guide';
import { CustomAxios } from '../redux/axios/axios';
import { toast } from 'react-toastify';
import { dispatchEvent } from './utility/editor-utils';

const sharedStyle = (cssString) => `
  margin: 0;
  padding: 0 8px;
  font-family: roboto;
  font-size: 16px;
  min-width: 0;
  border: 1px solid transparent;
  ${cssString}
`;

const spanStyle = (cssString) => css`
  position: absolute;
  visibility: hidden;
  white-space: pre;
  pointer-events: none;
  ${sharedStyle(cssString)}
`;

const inputStyle = (cssString) => css`
  height: 32px;
  border-radius: 8px;
  border: 1px solid transparent;
  outline: none;
  background: none;
  color: ${colors.black};
  transition: width 0.2s ease;
  :hover {
    border: 1px solid ${colors.gray[300]};
  }
  :focus {
    transition: none;
    background-color: rgba(0, 0, 0, 0.05);
  }
  ${sharedStyle(cssString)}
`;

const AutoGrowInput = ({ value, minWidth = 0, css: cssString = '', ...rest }) => {
  const spanRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (spanRef.current && inputRef.current) {
      spanRef.current.textContent = value ?? '';
      inputRef.current.style.width = `${Math.max(spanRef.current.offsetWidth, minWidth)}px`;
    }
  }, [value]);

  return (
    <Box
      css={`
        position: relative;
        display: inline-block;
        box-sizing: border-box;
      `}
    >
      <span ref={spanRef} className={spanStyle(cssString)} />
      <input ref={inputRef} className={inputStyle(cssString)} value={value} {...rest} />
    </Box>
  );
};

export const GuideHeader = () => {
  const { exitCourse, guide, page, pages, refetch } = useGuide();

  const {
    data: { devMode, selectedBusiness },
  } = useStore();

  const showPageSettings = selectedBusiness?.ID === selectedBusiness?.ParentID;

  const canWrite = useAcls([WRITE_COURSE]);

  const { updateParams } = useSearchParams();

  const modal = useModalWithData(false, '');
  const sync = useSync({
    guideName: guide?.data?.Name,
    pageName: page?.data?.Name,
  });

  const collaborationFF = useFeatureFlagPayload('editor-collaboration')?.value;
  const editorLaunchGuideFF = useFeatureFlagPayload('editor-launch-guide')?.value;
  const showPDFDownloadFF = useFeatureFlagPayload('show-pdf-download')?.value;

  const handleSaveGuide = () => guide?.save({ Name: sync?.value?.guideName });
  const handleSavePage = async () => {
    await page?.save({ Name: sync?.value?.pageName });
    refetch();
  };

  const routeToCourse = () => {
    const pathParam = guide?.data?.VanityURL || guide?.data?.ID;
    const evDomain = window.location.origin?.includes('admin-frontend-staging')
      ? 'employee-view-staging-tqtafkpv4q-uw.a.run.app'
      : 'britehr.app';

    const url = !devMode
      ? `https://${evDomain}/${pathParam}/${page?.ID}?lt=preview`
      : `http://localhost:3001/${pathParam}/${page?.ID}}?lt=preview`;
    window.open(url, '_blank');
  };

  const undo = () => dispatchEvent(dispatchEvent.EVENT_TYPES.EDITOR_UNDO);
  const redo = () => dispatchEvent(dispatchEvent.EVENT_TYPES.EDITOR_REDO);

  const setRoot = async (root) => {
    try {
      await page?.save({ Content: { ...page?.data?.Content, root } });
    } catch {
    } finally {
      refetch();
    }
  };

  const openLaunch = () => {
    if (guide?.data?.VanityURL === '') {
      modal.show('create-launch-link');
    } else {
      modal.show('launch-guide');
    }
  };

  const deletePage = () => pages?.handleDelete(page?.data?.ID);

  const pageRoles = useMemo(() => {
    return pages?.data?.reduce((prev, item) => {
      if (!!item?.Options?.Role) {
        const activePageId = item?.Active ? item?.ID : prev?.[item?.Options?.Role]?.activePageId || '';
        return {
          ...prev,
          [item?.Options?.Role]: {
            activePageId,
            pages: [...(prev?.[item?.Options?.Role]?.pages || []), item],
          },
        };
      }
      return prev;
    }, {});
  });

  const previewPDF = async (pageId) => {
    try {
      let apiUrl = `/v1/guide/${guide?.data.ID}/pdf/${page?.data?.ID}`;

      if (guide?.data?.Passcode) {
        apiUrl += '?passcode=' + guide?.data?.Passcode;
      }

      const pdfPromise = CustomAxios.post(apiUrl, {}, { responseType: 'arraybuffer', timeout: 300000 });

      toast.promise(
        pdfPromise,
        {
          pending: 'Generating PDF for preview...',
          success: 'PDF generated successfully.\nCheck your tabs.',
          error: 'Unable to generate PDF for preview. Please try again later.',
        },
        {
          position: 'bottom-center',
        }
      );

      pdfPromise.then((res) => {
        const blob = new Blob([res.data], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        // Append parameters to hide the toolbar in the PDF viewer.
        const pdfViewerUrl = `${url}#toolbar=0`;

        // Opens in a new tab.
        const link = document.createElement('a');
        link.href = pdfViewerUrl;
        link.target = '_blank';
        link.click();
      });
    } catch (e) {
      console.error(e);
    }
  };

  const existingRoles = Object.keys(pageRoles || {});

  const showLaunchButton =
    editorLaunchGuideFF && selectedBusiness?.Type !== 'multi' && selectedBusiness?.Type !== 'carrier';

  return (
    <>
      <Box
        flex="space-between"
        css={`
          height: 50px;
          padding: 16px;
          background-color: white;
          border-bottom: 1px solid ${colors.gray[300]};
          box-sizing: border-box;
          margin-bottom: 6px;
        `}
      >
        <Box
          flex="left"
          css={`
            min-width: 0;
            gap: 8px;
            flex-grow: 1;
            box-sizing: border-box;
            margin-right: 16px;
            svg {
              min-width: 24px;
            }
          `}
        >
          <Button styles="icon sm" onClick={exitCourse}>
            <ArrowLeft size={24} color={colors.gray[600]} />
          </Button>
          <Box
            flex="left"
            css={`
              border-right: 1px solid ${colors.gray[200]};
              padding-right: 8px;
              margin-right: 8px;
            `}
          >
            <AutoGrowInput
              css={`
                max-width: 200px;
                font-weight: bold;
              `}
              disabled={!canWrite}
              value={sync?.value?.guideName}
              onChange={(event) => sync.merge({ guideName: event.target.value })}
              onBlur={handleSaveGuide}
            />
            <CaretRight size={24} color={colors.gray[500]} />
            <AutoGrowInput
              css={`
                max-width: 300px;
                font-weight: bold;
              `}
              disabled={!canWrite}
              value={sync?.value?.pageName}
              onChange={(event) => sync.merge({ pageName: event.target.value })}
              onBlur={handleSavePage}
            />
            <Dropdown
              clear
              popper={{ placement: 'bottom' }}
              button={() => (
                <Button styles="icon sm" disabled={!canWrite}>
                  <DotsThreeVertical size={24} color={colors.gray[600]} />
                </Button>
              )}
            >
              <Box option onClick={() => modal.show('duplicate')}>
                <Copy size={24} />
                Duplicate
              </Box>
              <Box option onClick={() => modal.show('save-template')}>
                <Heart size={24} />
                Save as Template
              </Box>
              {showPDFDownloadFF && (page?.data?.Type === 'brite-editor' || page?.data?.Type === 'brite-editor-v2') && (
                <Box option onClick={previewPDF}>
                  <Eye size={24} />
                  <Text label>Preview Page PDF</Text>
                </Box>
              )}
              {showPageSettings ? (
                <Box option onClick={() => modal.show('page-settings')}>
                  <GearSix size={24} />
                  Page Settings
                </Box>
              ) : null}
              <Box option onClick={() => modal.show('background-image')}>
                <Image size={24} />
                <Text label>Background Image</Text>
              </Box>
              <Box
                option
                css={`
                  > * {
                    color: red;
                  }
                `}
                onClick={deletePage}
              >
                <Trash size={24} color="currentColor" />
                <Text>Delete Page</Text>
              </Box>
            </Dropdown>
          </Box>
          <Button styles="icon sm" disabled={!canWrite} onClick={undo}>
            <ArrowUUpLeft size={24} color={colors.gray[600]} />
          </Button>
          <Button styles="icon sm" disabled={!canWrite} onClick={redo}>
            <ArrowUUpRight size={24} color={colors.gray[600]} />
          </Button>
          <Button styles="icon sm" disabled={!canWrite}>
            <CloudCheck size={24} color={colors.gray[600]} />
          </Button>
        </Box>

        <Box
          flex="right"
          css={`
            gap: 8px;
            min-width: max-content;
            box-sizing: border-box;
            .basic {
              padding: 4px 16px;
              height: 32px;
            }
          `}
        >
          {collaborationFF ? <Subscribers resourceType="guide" resourceId={guide?.data?.ID} /> : null}
          <Button
            styles="icon sm"
            onClick={() => modal.show('export-pdf')}
            hoverLabel="Export PDF"
            disabled={!canWrite}
          >
            <DownloadSimple size={24} color={colors.gray[600]} />
          </Button>
          <Button
            styles="icon sm"
            hoverLabel="Guide Settings"
            onClick={() => updateParams({ courseModal: 'settings' })}
            disabled={!canWrite}
          >
            <GearSix size={24} color={colors.gray[600]} />
          </Button>
          <Button secondary title className="basic" onClick={routeToCourse}>
            Preview
            <Eye size={20} weight="bold" />
          </Button>
          {showLaunchButton ? (
            <Button title className="basic" onClick={openLaunch} disabled={!canWrite}>
              Launch
            </Button>
          ) : null}
        </Box>
      </Box>
      {modal.isOpen && modal.data === 'export-pdf' && (
        <ExportGuideToPDFModal display guide={guide?.data} onClose={modal.hide} />
      )}
      {modal.isOpen && modal.data === 'create-launch-link' && (
        <CreateLinkModal hide={modal.hide} launchGuide={() => modal.show('launch-guide')} guide={guide?.data} />
      )}
      {modal.isOpen && modal.data === 'launch-guide' && <LaunchGuideModal hide={modal.hide} guide={guide?.data} />}
      {modal.isOpen && modal.data === 'save-template' ? (
        <SavePageTemplate display close={modal.hide} page={page?.data} />
      ) : null}
      {modal.isOpen && modal.data === 'page-settings' ? (
        <PageSettings
          display
          onClose={modal.hide}
          existingRoles={existingRoles}
          pageOptions={page?.data?.Options || {}}
          onSave={(opt) => pages?.handleOptions(page?.data?.ID, opt)}
        />
      ) : null}
      {modal.isOpen && modal.data === 'duplicate' ? (
        <PagesModal
          display
          type="duplicate"
          onClose={modal.hide}
          onConfirm={(name) => pages?.handleDuplicate(page?.data?.ID, name)}
          confirmText="Duplicate"
          title="Duplicate Page"
        />
      ) : null}
      {modal.isOpen && modal.data === 'background-image' ? (
        <BackgroundImage display onClose={modal.hide} root={page?.data?.Content?.root} setRoot={setRoot} />
      ) : null}
    </>
  );
};
