import { Box } from '../shared/components';
import { useStore } from '../store-provider/use-store';
import { useWS } from '../WebSocket/WS';

export const WSStatus = () => {
  const { data } = useStore();

  const ws = useWS();

  return data?.devMode ? (
    <Box
      onClick={() => console.log(ws.subs)}
      css={`
        z-index: 10000000000;
        position: fixed;
        top: 0;
        right: 0;
        margin: 16px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: ${ws?.status === 'connected' ? 'lime' : ws?.status === 'disconnected' ? 'red' : ''};
        @keyframes pulse {
          0% {
            width: 0px;
            height: 0px;
            opacity: 0.8;
          }
          100% {
            width: 40px;
            height: 40px;
            opacity: 0;
          }
        }
        ::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          width: 20px;
          height: 20px;
          opacity: 0.8;
          background-color: ${ws?.status === 'connected' ? 'lime' : ws?.status === 'disconnected' ? 'red' : ''};
          border-radius: 50%;
          transform: translate(-50%, -50%);
          box-shadow: 0 0 10px ${ws?.status === 'connected' ? 'lime' : ws?.status === 'disconnected' ? 'red' : ''};
          animation: pulse 2s infinite ease-out;
        }
      `}
    />
  ) : null;
};
