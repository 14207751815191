import { Box } from '../../shared/components';
import { overlay } from './styles';

export const ReadOnlyOverlay = () => {
  return (
    <Box
      css={`
        ${overlay}
        height: 100%;
        z-index: 1000;
        transition: background-color 0.2s ease;
        pointer-events: none;
        :active {
          background-color: rgba(255, 255, 255, 0.15);
        }
        :not(:active) {
          .hover {
            display: none;
          }
        }
      `}
    />
  );
};
