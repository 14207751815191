import { useSlate } from 'slate-react';
import {
  getActiveColor,
  removeAllMarks,
  updateMark,
} from '../../../../BriteEditor/editor-components/text-v2/slate-utils';
import { ColorPicker } from '../../../utility/settings/ColorPicker';
import { Range } from 'slate';
import { Text } from '../../../../shared/components';
import { Property } from '../property';

export const SlateColorPicker = ({ data, update, designStyles }) => {
  const slate = useSlate();
  const textType = data?.properties?.type;
  const color = data?.properties?.attributes?.style?.color || designStyles?.text?.[textType]?.color || '';
  const activeColor = getActiveColor(slate) || color || `var(--text-${textType})`;

  const handleColorUpdate = (color) => {
    if (Range.isCollapsed(slate?.selection) && color) {
      removeAllMarks(slate, 'color');
      update((data) => ({
        ...data,
        properties: {
          ...data?.properties,
          attributes: {
            ...data?.properties?.attributes,
            style: { ...data?.properties?.attributes?.style, color },
          },
        },
      }));
    } else {
      if (color) {
        updateMark(slate, 'color', color);
      } else {
        removeAllMarks(slate, 'color');
      }
    }
  };

  return (
    <Property>
      <Text label>Font Color</Text>
      <ColorPicker color={activeColor} update={handleColorUpdate} inline={false} clearColor={true} />
    </Property>
  );
};
