import {
  Cpu,
  CurrencyDollarSimple,
  File,
  FirstAidKit,
  Image,
  MapPin,
  Minus,
  Newspaper,
  PlusCircle,
  Rows,
  SquaresFour,
  Tabs,
  TextT,
  VideoCamera,
} from '@phosphor-icons/react';
import { Icon } from '../../shared/components/icon';
import { ReactComponent as ListPlus } from '../../images/svg/list-plus.svg';
import { ReactComponent as ButtonBlock } from '../../images/svg/button-block.svg';
import { ReactComponent as SectionBlock } from '../../images/svg/section-block.svg';
import { ReactComponent as PageBreak } from '../../images/svg/page-break.svg';

export const ComponentIconMap = ({ type, ...rest }) => {
  if (type === 'text' || type === 'textV2') {
    return <TextT {...rest} />;
  } else if (type === 'image') {
    return <Image {...rest} />;
  } else if (type === 'space' || type === 'line' || type === 'divider') {
    return <Minus {...rest} />;
  } else if (type === 'button') {
    return <Icon SVG={ButtonBlock} {...rest} />;
  } else if (type === 'accordion') {
    return <PlusCircle {...rest} />;
  } else if (type === 'video') {
    return <VideoCamera {...rest} />;
  } else if (type === 'website') {
    return <Newspaper {...rest} />;
  } else if (type === 'tab') {
    return <Tabs {...rest} />;
  } else if (type === 'benefits') {
    return <FirstAidKit {...rest} />;
  } else if (type === 'pricing') {
    return <CurrencyDollarSimple {...rest} />;
  } else if (type === 'network') {
    return <MapPin {...rest} />;
  } else if (type === 'document') {
    return <File {...rest} />;
  } else if (type === 'content-block') {
    return <Icon SVG={ListPlus} {...rest} />;
  } else if (type === 'row') {
    return <Rows {...rest} />;
  } else if (type === 'group') {
    return <SquaresFour {...rest} />;
  } else if (type === 'dev-block') {
    return <Cpu {...rest} />;
  } else if (type === 'section') {
    return <Icon SVG={SectionBlock} {...rest} />;
  } else if (type === 'pageBreak') {
    return <Icon SVG={PageBreak} weight={2} {...rest} />;
  } else {
    return null;
  }
};
