import { Circle } from '@phosphor-icons/react';
import { LegendOrdinal } from '@visx/legend';
import { scaleOrdinal } from '@visx/scale';
import { colors } from '../../../shared/styles';
import { Box, Text } from '../../../shared/components';

export const VisitorsBySourceChartLegend = ({ colorScale = scaleOrdinal(), hidden = [], toggle = () => {} }) => {
  return (
    <LegendOrdinal scale={colorScale}>
      {(labels) => (
        <Box
          css={`
            display: flex;
            flex-direction: row;
            gap: 24px;
          `}
        >
          {labels.map((label) => {
            const { text, value } = label;
            return (
              <Box
                key={text}
                css={`
                  display: flex;
                  align-items: center;
                  gap: 8px;
                  overflow: hidden;
                  cursor: pointer;
                  user-select: none;
                `}
                onClick={() => toggle(text)}
              >
                <Circle color={value} size={16} weight={hidden.includes(text) ? 'regular' : 'fill'} />
                <Text
                  label
                  color={hidden.includes(text) ? colors.gray[500] : colors.black}
                  css={`
                    font-size: 14px;
                    font-weight: 700;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    user-select: none;
                  `}
                >
                  {text}
                </Text>
              </Box>
            );
          })}
        </Box>
      )}
    </LegendOrdinal>
  );
};
