import { interpolateWarm } from 'd3-scale-chromatic';
import { isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { GlyphCircle } from '@visx/glyph';
import { ParentSize } from '@visx/responsive';
import { scaleOrdinal } from '@visx/scale';
import { AnimatedAxis, AnimatedGrid, AnimatedLineSeries, Tooltip, XYChart } from '@visx/xychart';
import { Box, Text } from '../../../shared/components';
import { colors } from '../../../shared/styles';
import { getTotalVisitors } from '../../api/traffic';
import { useDashboard } from '../../context/DashboardContext';
import { AGGREGATION_ALL, AGGREGATION_INDIVIDUAL, AGGREGATION_OPTIONS } from '../../utils/dashboard';
import {
  LINE_SERIES_UNIQUE_VISITORS,
  mapGuideTrafficData,
  TIME_INTERVAL_DAY,
  TIME_INTERVAL_OPTIONS,
} from '../../utils/traffic';
import { ChartContainer } from '../chart/ChartContainer';
import { ChartControlDropdown } from '../chart/ChartControlDropdown';
import { ChartControlToggle } from '../chart/ChartContainerToggle';
import { GuideTrafficChartLegend } from './GuideTrafficChartLegend';

export const GuideTrafficChart = () => {
  // Routing
  const { businessId } = useParams();

  // Context
  const { dashboard, range } = useDashboard();

  // State
  const [cumulative, setCumulative] = useState(false);
  const [aggregation, setAggregation] = useState(AGGREGATION_ALL);
  const [timeInterval, setTimeInterval] = useState(TIME_INTERVAL_DAY);
  const [hiddenIndividualGuides, setHiddenIndividualGuides] = useState([]);

  // Queries
  const { data, isLoading } = useQuery(['totalVisitors', businessId, timeInterval, range], () =>
    getTotalVisitors({ dashboard, timeInterval, range })
  );

  const guideTrafficData = useMemo(() => {
    if (isLoading || !data?.ViewsPerInterval) return {};
    return mapGuideTrafficData({ dashboard, cumulative, aggregation, timeInterval, data });
  }, [dashboard, cumulative, aggregation, timeInterval, data]);

  const colorScale = useMemo(() => {
    if (isEmpty(guideTrafficData)) return scaleOrdinal();
    const { GuideNames } = guideTrafficData;
    return scaleOrdinal({
      domain: GuideNames,
      range:
        aggregation === AGGREGATION_ALL
          ? [colors.purple, colors.lite.purple]
          : GuideNames.map((_, index) => interpolateWarm(0.8 - index / GuideNames.length)),
    });
  }, [guideTrafficData, aggregation]);

  // Toggle visibility of individual guides in the chart.
  const toggleIndividualGuide = (guide) => {
    setHiddenIndividualGuides((prevHiddenGuides) => {
      // If the guide is already hidden, remove it from the hidden list.
      if (prevHiddenGuides.includes(guide)) {
        return prevHiddenGuides.filter((hiddenGuide) => hiddenGuide !== guide);
      }
      // If not all guides are hidden, add the guide to the hidden list.
      if (prevHiddenGuides.length !== guideTrafficData?.GuideNames.length - 1) {
        return [...prevHiddenGuides, guide];
      }

      return prevHiddenGuides;
    });
  };

  return (
    <ChartContainer
      isLoading={isLoading}
      title="Guide Traffic"
      description="Shows the number of unique visitors and views for guides associated with this dashboard."
      chartControls={
        <Box
          css={`
            display: flex;
            align-items: center;
            gap: 8px;
          `}
        >
          <ChartControlToggle label="Cumulative" value={cumulative} onChange={() => setCumulative(!cumulative)} />
          <ChartControlDropdown
            label={aggregation.label}
            options={AGGREGATION_OPTIONS}
            onClick={(option) => setAggregation(option)}
          />
          <ChartControlDropdown
            label={timeInterval.label}
            options={TIME_INTERVAL_OPTIONS}
            onClick={(option) => setTimeInterval(option)}
          />
        </Box>
      }
    >
      <ParentSize>
        {({ width }) =>
          width > 0 && (
            <XYChart width={width} height={400} xScale={{ type: 'band' }} yScale={{ type: 'linear' }}>
              <AnimatedAxis orientation="left" animationTrajectory="min" />
              <AnimatedAxis orientation="bottom" animationTrajectory="max" />
              <AnimatedGrid columns={false} numTicks={4} />
              {guideTrafficData?.GuideNames?.filter((GuideName) => !hiddenIndividualGuides.includes(GuideName)).map(
                (guide) => (
                  <AnimatedLineSeries
                    key={guide}
                    dataKey={guide}
                    data={guideTrafficData?.Data}
                    xAccessor={(d) => d.Date}
                    yAccessor={(d) => d[guide]}
                    colorAccessor={() => colorScale(guide)}
                    strokeDasharray={
                      guide === LINE_SERIES_UNIQUE_VISITORS || aggregation === AGGREGATION_INDIVIDUAL ? '4,4' : '0'
                    }
                  />
                )
              )}
              <Tooltip
                snapTooltipToDatumX
                snapTooltipToDatumY
                showVerticalCrosshair
                showDatumGlyph
                renderGlyph={({ key }) => <GlyphCircle fill={colorScale(key)} size={48} />}
                renderTooltip={({ tooltipData }) => {
                  const { datum, key } = tooltipData?.nearestDatum;
                  return (
                    <Box
                      css={`
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                      `}
                    >
                      <Text helper color={colors.gray[500]}>
                        {key}
                      </Text>
                      <Box
                        css={`
                          display: flex;
                          flex-direction: row;
                          gap: 4px;
                        `}
                      >
                        <Text helper bold color={colors.black}>
                          {datum?.Date}
                        </Text>
                        <Text helper bold color={colorScale(key)}>
                          {`(${datum[key]})`}
                        </Text>
                      </Box>
                    </Box>
                  );
                }}
              />
            </XYChart>
          )
        }
      </ParentSize>
      <GuideTrafficChartLegend
        aggregation={aggregation}
        colorScale={colorScale}
        hidden={hiddenIndividualGuides}
        toggle={toggleIndividualGuide}
      />
    </ChartContainer>
  );
};
