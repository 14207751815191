import { usePopper } from 'react-popper';
import { useResize } from '../../BriteEditor/editor-components/use-resize';
import { Box, Button } from '../../shared/components';
import { Portal } from '../../shared/components/Portal';
import { shadows } from '../../shared/shared-styles';
import { Children, useState } from 'react';
import { ChatCircle, DotsThreeVertical, Trash } from '@phosphor-icons/react';
import { useSegment } from '../providers/segment/use-segment';
import { colors } from '../../shared/styles';
import { useContent } from '../providers/content/use-content';
import { Content } from '../providers/content/Content';
import { ToolKit } from '../providers/tool-kit/ToolKit';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import { propertiesMap } from '../sidebar/PropertiesPanel';

export const Toolbar = ({ children, isSelected, anchor }) => {
  if (!isSelected) {
    return null;
  }
  return <ToolbarComponent anchor={anchor}>{children}</ToolbarComponent>;
};

const modifiers = [
  {
    name: 'preventOverflow',
    options: {
      boundary: 'window',
      tether: false, // Ensures Popper does not push the page
      altAxis: true, // Prevents pushing along the vertical axis
      mainAxis: false, // Prevents Popper from adjusting height
    },
  },
  {
    name: 'flip',
    options: {
      fallbackPlacements: ['top', 'bottom'],
      boundary: 'window',
    },
  },
  {
    name: 'hide',
    options: {
      enabled: true,
    },
  },
];

export const ToolbarComponent = ({ children, anchor }) => {
  const { sendUpdates } = useContent();
  const segment = useSegment();
  const toolkit = useToolKit();

  const [toolbarPopper, setToolbarPopper] = useState();
  const popper = usePopper(anchor, toolbarPopper, { modifiers });
  useResize(anchor, () => popper?.update());
  useResize(toolbarPopper, () => popper?.update());

  const handleDelete = () => Content.remove(sendUpdates, segment?.id);
  const displaySettings = () => ToolKit.setDisplaySettings(toolkit, true);

  const hasChildren = Children.count(children) > 0;
  const hasPropertiesPanel = segment?.type in propertiesMap;

  return (
    <Portal>
      <Box
        ref={setToolbarPopper}
        style={popper.styles.popper}
        {...popper.attributes.popper}
        data-scope="toolbar"
        css={`
          z-index: 1000;
        `}
      >
        <Box
          flex="left"
          css={`
            margin: 8px;
            padding: 8px;
            border-radius: 30px;
            background-color: ${colors.gray[600]};
            ${shadows.lg}
          `}
        >
          {children}
          <Box
            flex="right"
            css={`
              ${hasChildren
                ? `
              margin-left: 16px;
              border-left: 1px solid ${colors.black};
              padding-left: 8px;
            `
                : ''}
            `}
          >
            <Button styles="icon-dark sm" hoverLabel="Delete" onClick={handleDelete}>
              <Trash size={24} color="currentColor" />
            </Button>
            {hasPropertiesPanel ? (
              <Button styles="icon-dark sm" hoverLabel="More" onClick={displaySettings}>
                <DotsThreeVertical size={24} color="currentColor" />
              </Button>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Portal>
  );
};
