import { useMemo, useState } from 'react';
import { Box, Button, Input, Modal, Tabs, Text } from '../../shared/components';
import { useEditorResource } from '../../BriteEditor/use-editor-resource';
import { useStore } from '../../store-provider/use-store';
import { copyIntoSingleSection } from '../providers/content/composition/composition-utils';
import { useContent } from '../providers/content/use-content';
import { Content } from '../providers/content/Content';
import { toast } from 'react-toastify';
import { animation, container, scrollbar, shadows } from '../../shared/shared-styles';
import { Image, MagnifyingGlass, TrashSimple, Users } from '@phosphor-icons/react';
import { colors } from '../../shared/styles';
import { useOptimistically } from '../../react-query/use-resource';
import { CustomAxios } from '../../redux/axios/axios';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import { ToolKit } from '../providers/tool-kit/ToolKit';
import { useQuery } from 'react-query';
import BriteLogo from '../../images/brite-logo.png';

const getGlobalSections = async () => {
  const { data } = await CustomAxios.get(`v1/bp/course_editor_block?with_share_scope=global`, {
    headers: { 'Accept-Profile': 'brite_public' },
  });
  return data;
};

export const SectionsMenu = ({ onClose }) => {
  const toolkit = useToolKit();
  const section = ToolKit.getModalData(toolkit);

  const { data: designStyles } = useEditorResource('styles');

  const {
    data: { selectedBusiness, isBriteEmployee },
  } = useStore();

  const { sendUpdates } = useContent({ ignoreSelection: true });

  const [tab, setTab] = useState('briteSections');
  const [search, setSearch] = useState('');

  const globalSections = useQuery([selectedBusiness?.ID, 'global-sections'], getGlobalSections);
  const { data: globalSectionsData } = globalSections;

  const query = useEditorResource('blocks', {
    select: (data) => {
      return data.filter((item) => 'id' in item || item.business_id !== selectedBusiness?.ID);
    },
  });
  const optimisticQuery = useOptimistically(query?.queryKey);
  const { data } = query;

  const list = useMemo(() => {
    let list = tab === 'mySavedSections' ? data : globalSectionsData;
    if (!search) {
      return list;
    }
    return list?.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()));
  }, [data?.length, globalSectionsData?.length, search, tab]);

  const addContent = (item) => {
    if (item?.layout_v2) {
      sendUpdates((old) => {
        const actions = copyIntoSingleSection({
          content: old,
          sectionToId: section?.id,
          contentToCopy: item?.layout_v2,
          sectionFromId: item?.layout_v2?.sections?.[0]?.id,
        });
        const content = Content.commit(old, actions);
        return { content, actions };
      });
    } else {
      toast.error('This section is not compatible with the current editor version.');
    }
    onClose();
  };

  const deleteSection = async (e, section) => {
    e.stopPropagation();
    try {
      optimisticQuery.update((old) => old.filter((item) => item?.id !== section?.id));
      const config = {
        headers: { 'Content-Profile': 'brite_public' },
      };
      await CustomAxios.delete(`v1/bp/course_editor_block?id=eq.${section?.id}`, config);
    } catch (err) {
      optimisticQuery.revert();
      toast.error('Error deleting section, try again.');
    }
  };

  return (
    <Modal display onClose={onClose}>
      <Modal.Paper width="600px">
        <Modal.Header title="Add Section" onClose={onClose} />
        <Modal.Body>
          <Tabs
            current={tab}
            setCurrent={setTab}
            tabs={{
              briteSections: 'Brite Sections',
              mySavedSections: 'My Saved Sections',
            }}
          />
          <Box>
            <Input
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              styles="search"
              startIcon={<MagnifyingGlass color={colors.gray[500]} />}
              placeholder="Search"
              css={`
                margin: 8px 0;
                max-width: 300px;
                border-radius: 30px;
              `}
            />
            <Box
              css={`
                height: 40vh;
                max-height: 600px;
                overflow-y: auto;
                ${scrollbar.hide}
                gap: 24px;
                padding: 16px 0;
              `}
              flex="left start wrap"
            >
              {list?.map((item) => (
                <Box
                  onClick={() => addContent(item)}
                  css={`
                    position: relative;
                    ${container.box}
                    ${shadows.md}
                      border: 1px solid ${colors.gray[100]};
                    overflow: hidden;
                    padding: 0;
                    width: 250px;
                    height: 200px;
                    ${container.hoverOutline}
                    :not(:hover) {
                      .hover-button {
                        display: none;
                      }
                    }
                    .hover-button {
                      position: absolute;
                      top: 0;
                      right: 0;
                      margin: 8px;
                    }
                    img {
                      object-fit: cover;
                      object-position: top center;
                      width: 100%;
                      height: auto;
                      max-height: 100%;
                      margin: auto 0;
                    }
                  `}
                >
                  <SectionImage item={item} designStyles={designStyles} />
                  {isBriteEmployee || tab !== 'briteSections' ? (
                    <Button className="hover-button" styles="icon sm" onClick={(e) => deleteSection(e, item)}>
                      <TrashSimple size={24} />
                    </Button>
                  ) : null}
                  <Box
                    css={`
                      background-color: ${colors.gray[100]};
                      height: 50px;
                      width: 100%;
                      padding: 10px 8px;
                      z-index: 1000;
                      img {
                        margin-top: -2px;
                        width: 24px;
                        height: 24px;
                      }
                    `}
                    flex="space-between"
                  >
                    <Text label bold>
                      {item?.name}
                    </Text>
                    {item?.share_scope === 'global' ? (
                      <img src={BriteLogo} />
                    ) : item?.share_scope === 'inherited' ? (
                      <Users size={20} color={colors.black} weight="bold" />
                    ) : null}
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Modal.Body>
      </Modal.Paper>
    </Modal>
  );
};

const SectionImage = ({ item, designStyles }) => {
  const [loading, setLoading] = useState(true);

  const bgColor = useMemo(() => {
    const color = item?.layout_v2?.sections?.[0]?.properties?.style?.backgroundColor || 'white';
    if (color.startsWith('var(--')) {
      const property = color.replace('var(--', '').replace(')', '');
      return designStyles?.general?.colorPalette[property] || 'white';
    }
    return color;
  }, [item]);

  return (
    <Box
      css={`
        width: 100%;
        height: 150px;
        padding: 8px;
        background-color: ${bgColor};
        ${item?.thumbnail_url
          ? `background-color: ${bgColor}; img { opacity: 0; }`
          : `background: linear-gradient(300deg, #b49eff, #ffaac4);`};
        ${!loading ? `img { ${animation('fadeIn', '.4s ease .05s forwards')}` : ''}
      `}
      flex="center"
    >
      {item?.thumbnail_url ? (
        <img src={item?.thumbnail_url} onLoad={() => setLoading(false)} />
      ) : (
        <Image color="white" />
      )}
    </Box>
  );
};
