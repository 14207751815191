import { isEmpty } from 'lodash';
import { useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { GlyphCircle } from '@visx/glyph';
import { ParentSize } from '@visx/responsive';
import { scaleOrdinal } from '@visx/scale';
import { AnimatedAxis, AnimatedGrid, AnimatedLineSeries, Tooltip, XYChart } from '@visx/xychart';
import { Box, Text } from '../../../shared/components';
import { colors } from '../../../shared/styles';
import { getVisitorsBySource } from '../../api/traffic';
import { useDashboard } from '../../context/DashboardContext';
import {
  mapVisitorsBySourceData,
  SOURCES_COLOR_SCALE,
  TIME_INTERVAL_DAY,
  TIME_INTERVAL_OPTIONS,
} from '../../utils/traffic';
import { ChartContainer } from '../chart/ChartContainer';
import { ChartControlToggle } from '../chart/ChartContainerToggle';
import { ChartControlDropdown } from '../chart/ChartControlDropdown';
import { VisitorsBySourceChartLegend } from './VisitorsBySourceChartLegend';

export const VisitorsBySourceChart = () => {
  // Routing
  const { businessId } = useParams();

  // Context
  const { range, GUIDE_OPTIONS } = useDashboard();

  // State
  const [cumulative, setCumulative] = useState(false);
  const [guide, setGuide] = useState(GUIDE_OPTIONS[0]);
  const [timeInterval, setTimeInterval] = useState(TIME_INTERVAL_DAY);
  const [hiddenSources, setHiddenSources] = useState([]);

  // Queries
  const { data, isLoading } = useQuery(['visitorsBySource', businessId, guide, timeInterval, range], () =>
    getVisitorsBySource({ guide, timeInterval, range })
  );

  const visitorsBySourceData = useMemo(() => {
    if (isLoading || !data?.SourcesPerInterval) return {};
    return mapVisitorsBySourceData({ guide, cumulative, timeInterval, data });
  }, [guide, cumulative, timeInterval, data]);

  const sources = useMemo(() => {
    if (isEmpty(visitorsBySourceData)) return [];

    // Get all possible sources from the color scale.
    const possibleSources = Object.keys(SOURCES_COLOR_SCALE);

    // Filter sources that are present in the traffic data.
    return possibleSources.filter((source) => visitorsBySourceData?.Data?.some((d) => d[source]));
  }, [visitorsBySourceData]);

  const colorScale = scaleOrdinal({
    domain: sources,
    range: sources.map((source) => SOURCES_COLOR_SCALE[source]),
  });

  const toggleSource = (source) => {
    setHiddenSources((prevHiddenSources) => {
      if (prevHiddenSources.includes(source)) {
        return prevHiddenSources.filter((hiddenSource) => hiddenSource !== source);
      }

      if (prevHiddenSources.length !== sources?.length - 1) {
        return [...prevHiddenSources, source];
      }

      return prevHiddenSources;
    });
  };

  const chartHeight = 400;

  return (
    <ChartContainer
      isLoading={isLoading}
      title="Visitors by Source"
      description="Shows the percentage of sources visitors are using to access this guide."
      chartControls={
        <Box
          css={`
            display: flex;
            align-items: center;
            gap: 8px;
          `}
        >
          <ChartControlToggle label="Cumulative" value={cumulative} onChange={() => setCumulative(!cumulative)} />
          <ChartControlDropdown label={guide.label} options={GUIDE_OPTIONS} onClick={(option) => setGuide(option)} />
          <ChartControlDropdown
            label={timeInterval.label}
            options={TIME_INTERVAL_OPTIONS}
            onClick={(option) => setTimeInterval(option)}
          />
        </Box>
      }
    >
      {isEmpty(visitorsBySourceData) && (
        <Box
          css={`
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: ${chartHeight}px;
          `}
        >
          <Text>No data available for the selected guide.</Text>
        </Box>
      )}
      <ParentSize>
        {({ width }) =>
          width > 0 && (
            <XYChart
              width={width}
              height={isEmpty(visitorsBySourceData) ? 0 : chartHeight}
              xScale={{ type: 'band' }}
              yScale={{ type: 'linear' }}
            >
              <AnimatedAxis orientation="left" animationTrajectory="min" />
              <AnimatedAxis orientation="bottom" animationTrajectory="max" />
              <AnimatedGrid columns={false} numTicks={4} />
              {sources
                .filter((source) => !hiddenSources.includes(source))
                .map((source) => (
                  <AnimatedLineSeries
                    key={source}
                    dataKey={source}
                    data={visitorsBySourceData?.Data}
                    xAccessor={(d) => d.Date}
                    yAccessor={(d) => d[source]}
                    colorAccessor={() => colorScale(source)}
                  />
                ))}
              <Tooltip
                snapTooltipToDatumX
                snapTooltipToDatumY
                showVerticalCrosshair
                showDatumGlyph
                renderGlyph={({ key }) => <GlyphCircle fill={colorScale(key)} size={48} />}
                renderTooltip={({ tooltipData }) => {
                  const { datum, key } = tooltipData?.nearestDatum;
                  return (
                    <Box
                      css={`
                        display: flex;
                        flex-direction: column;
                        gap: 4px;
                      `}
                    >
                      <Text helper color={colors.gray[500]}>
                        {key}
                      </Text>
                      <Box
                        css={`
                          display: flex;
                          flex-direction: row;
                          gap: 4px;
                        `}
                      >
                        <Text helper bold color={colors.black}>
                          {datum?.Date}
                        </Text>
                        <Text helper bold color={colorScale(key)}>
                          {`(${datum[key]})`}
                        </Text>
                      </Box>
                    </Box>
                  );
                }}
              />
            </XYChart>
          )
        }
      </ParentSize>
      <VisitorsBySourceChartLegend colorScale={colorScale} hidden={hiddenSources} toggle={toggleSource} />
    </ChartContainer>
  );
};
