import { ReactEditor, useSlate } from 'slate-react';
import { Box, Dropdown, Text } from '../../../../shared/components';
import {
  getActiveBlock,
  insertNode,
  removeLink,
  toggleInline,
} from '../../../../BriteEditor/editor-components/text-v2/slate-utils';
import { container } from '../../../../shared/shared-styles';
import { ArrowSquareOut, Plus, Trash } from '@phosphor-icons/react';
import { colors } from '../../../../shared/styles';
import { useMemo } from 'react';
import { Editor, Range, Transforms } from 'slate';
import { Property } from '../property';
import { SmartFieldInput } from '../../../../BriteEditor/utility-components/smart-field-input';
import { formatUrl } from '../../../utility/editor-utils';
import { useToolKit } from '../../../providers/tool-kit/use-tool-kit';
import { ToolKit } from '../../../providers/tool-kit/ToolKit';
import { useQueryAPI } from '../../../../react-query';

const LINK_TYPE_MAP = {
  url: 'Url',
  page: 'Page',
};

const TARGET_MAP = {
  _blank: 'New Tab',
  _self: 'Same Tab',
};

const disabledStyle = `
  border: 1px solid ${colors.red[100]};
  :hover {
    border: 1px solid ${colors.red[100]};
  }
`;

export const SlateLink = () => {
  const toolkit = useToolKit();
  const guide = ToolKit.getMetadata(toolkit, 'guide');

  const pages = useQueryAPI({
    url: `v2/course/${guide?.data?.ID}/pages`,
    cacheKey: ['editor'],
    retry: 1,
  });

  const slate = useSlate();
  const activeBlock = getActiveBlock(slate, ['link']);
  const link = activeBlock || null;

  const hasSelection = slate?.selection && !Range.isCollapsed(slate?.selection);

  const setupLink = () => {
    if (!activeBlock && hasSelection) {
      toggleInline(slate, 'underline', { toggleOn: true });
      const linkData = { type: 'link', linkType: 'url', href: '', linkText: '', target: '_blank' };
      insertNode(slate, linkData);
    }
  };

  const updateLink = (updates) => {
    const data = { ...link, ...updates };
    const path = slate?.selection?.focus?.path;
    const [parentNode, parentPath] = Editor.parent(slate, path);
    if (parentNode.type === 'link') {
      Transforms.setNodes(slate, data, { at: parentPath });
    }
  };

  const updateUrl = (value) => {
    const [href, isValid] = formatUrl(value);
    updateLink({ href: isValid ? href : '', linkText: value });
  };

  const isValid = useMemo(() => {
    if (link?.linkType === 'url') {
      const [, isValid] = formatUrl(link?.linkText);
      return isValid;
    }
    return true;
  }, [link?.linkType, link?.linkText]);

  const handleSmartField = ({ data }) => {
    updateLink({ href: data?.value, linkText: data?.name });
  };

  const removeSmartField = () => {
    updateLink({ href: '', linkText: '' });
  };

  const remove = () => {
    ReactEditor.focus(slate);
    removeLink(slate);
  };

  const pageLabel = link?.linkType === 'page' ? pages?.data?.find((page) => page.ID === link?.linkText)?.Name : '';

  return (
    <Property
      css={`
        display: grid;
        gap: 8px;
        transition: opacity 0.2s ease;
        ${!hasSelection && !activeBlock
          ? `
          pointer-events: none;
          opacity: .5;
        `
          : ''}
      `}
    >
      {!link ? (
        <>
          <Box
            css={`
              padding: 8px;
              border-radius: 8px;
              ${container.hover}
            `}
            onClick={setupLink}
          >
            <Box flex="space-between">
              <Text label>Add action</Text>
              <Plus size={24} />
            </Box>
            {!hasSelection ? <Text helper>Select text to create an action</Text> : null}
          </Box>
        </>
      ) : (
        <Box
          css={`
            display: grid;
            gap: 16px;
          `}
        >
          <Box
            flex="space-between"
            css={`
              padding: 8px;
              border-radius: 8px;
              ${container.hover}
            `}
            onClick={remove}
          >
            <Text label color={colors.red[100]}>
              Remove action
            </Text>
            <Trash size={24} color={colors.red[100]} />
          </Box>
          <Dropdown
            clear
            button={(props) => (
              <Dropdown.Select {...props} label={link?.linkType ? LINK_TYPE_MAP?.[link?.linkType] : 'Link Type'} />
            )}
          >
            <Box
              data-scope="menu"
              css={`
                width: 180px;
              `}
            >
              <Box option onClick={() => updateLink({ linkType: 'url' })}>
                Url
              </Box>
              <Box option onClick={() => updateLink({ linkType: 'page', href: '' })}>
                Page
              </Box>
            </Box>
          </Dropdown>

          {link?.linkType === 'url' ? (
            <>
              <SmartFieldInput
                nameFilter="URL"
                value={link?.href}
                smartFieldDisplay={link?.linkText || ''}
                handleSmartField={handleSmartField}
                removeSmartField={removeSmartField}
                inputProps={{
                  css: `
                    ${isValid ? `` : disabledStyle}
                    max-width: 265px;
                  `,
                  onMouseDown: (e) => e.stopPropagation(),
                  value: link?.linkText,
                  onChange: (e) => updateUrl(e.target.value),
                }}
              />
              <Dropdown clear button={(props) => <Dropdown.Select {...props} label={TARGET_MAP?.[link?.target]} />}>
                <Box
                  data-scope="menu"
                  css={`
                    width: 200px;
                  `}
                >
                  <Box option onClick={() => updateLink({ target: '_blank' })}>
                    New Tab
                  </Box>
                  <Box option onClick={() => updateLink({ target: '_self' })}>
                    Same Tab
                  </Box>
                </Box>
              </Dropdown>
              <Box
                flex="space-between"
                css={`
                  padding: 8px;
                  border-radius: 8px;
                  transition: opacity 0.2s ease;
                  ${isValid
                    ? ''
                    : `
                    opacity: .5;
                    pointer-events: none;
                  `}
                  ${container.hover}
                `}
                onClick={() => window.open(link?.href, '_blank')}
              >
                <Text label>Open Url</Text>
                <ArrowSquareOut size={24} />
              </Box>
            </>
          ) : link?.linkType === 'page' ? (
            <>
              <Dropdown clear button={(props) => <Dropdown.Select {...props} label={pageLabel} />}>
                <Box
                  data-scope="menu"
                  css={`
                    width: 200px;
                  `}
                >
                  {pages?.data?.map(({ Name, ID }) => (
                    <Box option onClick={() => updateLink({ linkText: ID })}>
                      {Name}
                    </Box>
                  ))}
                </Box>
              </Dropdown>
            </>
          ) : null}
        </Box>
      )}
    </Property>
  );
};
