import { PencilSimple } from '@phosphor-icons/react';
import { Box, Text } from '../../../shared/components';
import { container } from '../../../shared/shared-styles';
import { PaddingSetting } from '../Properties/PaddingSetting';
import { getPaddingProperties } from './property-utils';
import { history } from '../../../history';
import { useParams } from 'react-router';
import { useSearchParams } from '../../../shared/use-search-params';
import { useSmartField } from '../../utility/editor-utils';

export const NetworkSearchSettings = (props) => {
  const { businessId } = useParams();
  const {
    params: { packageId },
  } = useSearchParams();
  const padding = getPaddingProperties(props);

  const { value: productId } = useSmartField(props?.data?.properties?.data?.id);

  const routeToProduct = () => {
    const { type } = props?.data?.properties;
    history.push(`/${businessId}/packages/${packageId}/${type}/${productId}`);
  };

  return (
    <Box
      css={`
        display: grid;
        gap: 24px;
        padding: 16px;
      `}
    >
      <Box card disabled={!props?.data?.properties?.data?.id} onClick={routeToProduct}>
        <PencilSimple />
        <Text label>Edit Product Form</Text>
      </Box>
      <PaddingSetting {...padding} />
    </Box>
  );
};
